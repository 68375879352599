export const namePattern = (pattern: string, first = 0, count = 1) => {
  const countInner = count + first;
  const res = [];
  const zero = Number(pattern.match(/{(\d+)}/)?.[1]);

  // eslint-disable-next-line no-plusplus
  for (let n = first; n < countInner; n++) {
    let name = n.toString();
    if ((!Number.isNaN(zero) && zero - name.length) > 0) { name = '0'.repeat(zero - name.length) + name; }
    res.push(pattern.replace(/{\d+}/, name));
  }

  return res;
};
