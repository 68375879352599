import { useMemo, useState } from 'react';

interface IPaginationSettings<T> {
  list: T[],
  totalItems: number,
}
export const usePagination = <T>(settings: IPaginationSettings<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = useMemo(() => {
    if (Array.isArray(settings.list)) {
      return settings.list.length;
    } if (typeof settings.list === 'object') {
      return Object.keys(settings.list);
    }
    return 1;
  }, [settings]);

  const totalPages = useMemo(() => {
    return Math.trunc(settings.totalItems / settings.list.length);
  }, [settings]);

  return {
    currentPage,
    itemsPerPage,
    totalPages,
    setCurrentPage,
  };
};
