/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';

import { useFavoriteStorage } from 'src/hooks/app';
import { SvgHeart } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgHeart';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useStyles } from './styles';

export const AppFavoriteIcon: React.FC = () => {
  const styles = useStyles({});
  const theme: Theme = useTheme();
  const { storeFavCount, isUp } = useFavoriteStorage();

  return (
    <>

      <div
        className={`${styles.heartContainer} ${
          isUp === null
            ? ''
            : isUp
              ? styles.heartContainerUp
              : styles.heartContainerDown}
        `}
      >
        <SvgHeart width="24" height="24" fill={storeFavCount ? theme.palette.primary.main : 'none'} />

        {!!storeFavCount && storeFavCount !== 0 && (
          <>
            <div className={styles.wrapperFavoriteCount}>
              <span>{ `${storeFavCount > 9 ? '9+' : storeFavCount}` }</span>
            </div>
          </>
        ) }
      </div>
    </>
  );
};
