import { ListOfKeysFilterAllData } from 'src/typings/filter';

export const useApartmentsSorting = () => {
  const sortApartments = <T extends any>(data: T[], key: ListOfKeysFilterAllData): T[] => {
    return data.sort((a, b) => {
      // @ts-ignore
      const argumentA = Array.isArray(a[key]) ? a[key]?.[0] : a[key];
      // @ts-ignore
      const argumentB = Array.isArray(b[key]) ? b[key]?.[0] : b[key];

      return (Number.isNaN(+argumentA) || Number.isNaN(+argumentB))
        ? String(argumentA).toLowerCase().localeCompare(String(argumentB).toLowerCase(), undefined, { numeric: true, sensitivity: 'base' })
        : argumentA - argumentB;
    });
  };
  return {
    sortApartments,
  };
};
