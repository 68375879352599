import { getPlacementById, getPlacementDataViewConfig } from 'src/api';
import { PlacementActions } from 'src/store/placement/actions';
import { PlacementViewConfigActions } from 'src/store/placementViewConfig/actions';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useBuildType } from 'src/hooks/_use_build_type';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageState } from 'src/hooks/useLanguageState';
import { useEffect, useState } from 'react';
import { IViewConfig, UtilityButtonTypeEnum } from 'src/typings/viewConfig';
import { getPlacementViewConfig } from 'src/store/placementViewConfig/selector';
import { useSortIfArray } from 'src/hooks/app/useSortIfArray';

export const useViewConfig = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const { isCrossTable } = useBuildType();
  const dispatch = useDispatch();
  const { currentAppLanguage } = useLanguageState();
  const [isFavoritesEnabled, setIsFavoritesEnabled] = useState<boolean>(false);
  const [isPaymentPlansSeparated, setIsPaymentPlansSeparated] = useState<boolean>(false);
  const placementViewConfig = useSelector(getPlacementViewConfig);

  useEffect(() => {
    if (placementViewConfig?.headerSection) {
      checkIsFavoritesEnabled(placementViewConfig);
      checkIsPaymentPlansSeparated(placementViewConfig);
    }
  }, [placementViewConfig]);

  const getViewConfig = async (placementId: string) => {
    const { data: placementRes } = await getPlacementById(jwt, placementId);
    const { data: placementViewConfigRes } = await getPlacementDataViewConfig(
      placementRes.placementType.id,
      isCrossTable ? 'crosstable' : 'module',
      true,
    );
    dispatch(PlacementActions.setPlacementData(placementRes));
    const value = placementViewConfigRes['hydra:member'][0];
    dispatch(PlacementViewConfigActions.setPlacementViewConfig({ ...value, lang: currentAppLanguage, placement: placementRes }));
  };

  const checkIsFavoritesEnabled = (conf: IViewConfig) => {
    conf.headerSection.utilityButtons.forEach((button) => {
      if (button.applicationProperty.systemName === UtilityButtonTypeEnum.addToFavorites) {
        setIsFavoritesEnabled(!button.isHidden);
      }
    });
  };

  const checkIsPaymentPlansSeparated = (conf: IViewConfig) => {
    conf.headerSection.utilityButtons.forEach((button) => {
      if (button.applicationProperty.systemName === UtilityButtonTypeEnum.separatedPlacementPlans) {
        setIsPaymentPlansSeparated(!button.isHidden);
      }
    });
  };

  return {
    isFavoritesEnabled,
    isPaymentPlansSeparated,
    getViewConfig,
  };
};
