import { useState, useEffect } from 'react';
import { ICategory, IStatus } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';

import { getBitrixCategory, getPlacementStatusByComplexes } from 'src/api';
import { getComplexLink, getSortedStatuses } from 'src/components/profileComponents/Integrations/tools';

export interface ISortedStatuses {
  [key: string]: IStatus[]
}

export const useGetBitrixCategory = (id: string|null) => {
  const [category, setCategory] = useState<ICategory|null>(null);
  const [statuses, setStatuses] = useState<ISortedStatuses | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchCategory = async () => {
    setIsLoading(true);
    if (id) {
      try {
        const { data }: {data: ICategory} = await getBitrixCategory(jwt, id);
        setCategory(data);
        const complexLink = getComplexLink(data.complexes);
        const { data: statusData } = await getPlacementStatusByComplexes(jwt, complexLink);
        const stasusesArray: IStatus[] = statusData['hydra:member'];
        setStatuses(getSortedStatuses(stasusesArray));
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    fetchCategory();
    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return {
    category,
    statuses,
    isLoading,
    fetchCategory,
    setCategory,
  };
};
