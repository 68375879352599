import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { IGlobalPolygonConfig } from 'src/typings/app';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperMarkUp: {
    [theme.breakpoints.down('md')]: {
      padding: '4px',
    },
  },
  markUpDesktop: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  markUpMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '& .dropdown:nth-child(2)': {
      marginTop: '10px',
    },
  },
  wrapperSections: {
    position: 'relative',
    margin: '0px auto',
    display: 'flex',
    width: '95%',
    height: '95%',
    justifyContent: 'center',
    '& *': {
      width: '100%',
      height: '100%',
    },
    '& > svg': {
      position: 'absolute',
      top: '0px',
      '& > *': {
        '&.active': {
          fill: theme.palette.primary.main,
          stroke: theme.palette.primary.main,
          fillOpacity: 0.5,
        },
      },
    },
  },
  titleSection: {
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  fragment: (props: IGlobalPolygonConfig | any) => {
    return {
      cursor: 'pointer',
      fill: 'transparent',
      '&:hover': {
        fill: props.clicked_body_color,
        fillOpacity: props.clicked_body_opacity,
        stroke: props.clicked_border_color,
      },
    };
  },
  activeFragment: (props: IGlobalPolygonConfig | any) => {
    return {
      fill: props.static_body_color,
      fillOpacity: 0.2,
      stroke: props.static_border_color,
      cursor: 'pointer',
      '&:hover': {
        fill: props.clicked_body_color,
        fillOpacity: props.clicked_body_opacity,
        stroke: props.clicked_border_color,
      },
    };
  },
  blink: {
    animation: '1.5s linear 2s 5 $blinker',
  },

  '@keyframes blinker': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },

}));
