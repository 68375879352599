import React, { useCallback, useEffect } from 'react';

import { AppModal } from 'src/components/AppModal';
import { AppForm } from 'src/components/AppForm';
import { locals } from 'src/constants/locals';
import { useTranslations } from 'src/hooks/translataions';
import {
  useEmbeddedContactForm, useStandartContactForm, useAttributesContactForm, useIsDevMode,
} from 'src/hooks/app';
import { IComplex, IGtmEventsTypes } from 'src/typings/complexes';
import { useBuildType } from 'src/hooks';
import { isProdEnv } from 'src/helpers/buildEnv';
import { Index } from './TickDone';
import { getBookNowFormSchema } from './formSchema';
import { useSendForm } from './hooks';
import { useStyles } from './styles';

interface IModalBookNow {
  isOpen: boolean;
  onClose: () => void;
  complex?: IComplex | null;
}

export const ModalBookNow: React.FC<IModalBookNow> = ({
  isOpen,
  onClose,
  complex,
}) => {
  const { isCrossTable } = useBuildType();
  const styles = useStyles({});
  const { standartForm } = useStandartContactForm();
  const bookNowFormSchema = getBookNowFormSchema(standartForm);
  const { getTranslations } = useTranslations();
  const { isDevMode } = useIsDevMode();
  const {
    isSending,
    isDone,
    isError,
    handleSendForm,
  } = useSendForm();
  const { contactForm } = useEmbeddedContactForm();
  const { attributesForm } = useAttributesContactForm();

  const handleClick = useCallback((data: any) => {
    const formSubmitGTMEvent = complex?.frontConfig.website.gtm.events.find((event) => event.type === IGtmEventsTypes.formSubmit);
    if (!isCrossTable && !isDevMode && isProdEnv() && window.dataLayer && formSubmitGTMEvent && typeof formSubmitGTMEvent.eventJson === 'object' && !Array.isArray(formSubmitGTMEvent.eventJson)) {
      window.dataLayer.push(formSubmitGTMEvent.eventJson);
    }
    handleSendForm(data);
  }, [complex]);

  useEffect(() => {
    if (isDone) {
      setTimeout(onClose, 5000);
    }
  }, [isDone, onClose]);

  if (attributesForm) return null;

  if (contactForm && !standartForm) {
    const contactFormSrc = `<html><head>
    <style>
      html, body { height: 100%; width: 100%; margin: 0; padding: 0}
      iframe { min-height: 100% }
    </style>
    </head><body>${contactForm.html}</body></html>`;
    return (
      <AppModal
        open={isOpen}
        onClose={onClose}
        toggleClose={onClose}
        contentHeader={isDone ? null : <h3 style={{ textAlign: 'center' }}>{getTranslations(locals.appTranslations.appContactWithManager)}</h3>}
      >
        <iframe
          srcDoc={contactFormSrc}
          style={{
            width: '540px',
            height: '600px',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          title="contact form"
          frameBorder="0"
        />
      </AppModal>
    );
  }

  return (
    <AppModal
      open={isOpen}
      onClose={onClose}
      toggleClose={onClose}
      contentHeader={isDone ? null : <h3 style={{ textAlign: 'center' }}>{getTranslations(locals.appTranslations.appContactWithManager)}</h3>}
    >
      <>
        {
          isDone
            ? (
              <div className={styles.isDoneWrapper}>
                <Index />
                <p className={styles.isDoneText}>{getTranslations(locals.appTranslations.appPromiseCallbackToClient)}</p>
              </div>
            )
            : (
              <AppForm
                isLoading={isSending}
                schema={bookNowFormSchema}
                handleSendForm={handleClick}
              />
            )
        }
        {isError.state && <p className={styles.errorTextMessage}>{isError.msg}</p>}
      </>
    </AppModal>
  );
};
