import { IAction } from 'src/typings/index';
import { ITypeDisplayedSpinner } from 'src/store/type-displayed-spinner/types';
import { ActionTypes } from './actions';

export const initialState: ITypeDisplayedSpinner = {
  listOfTypes: [],
  selectedView: '',
};

export const typeDisplayedSpinnerReducer = (
  state = initialState,
  { type, payload }: IAction,
): ITypeDisplayedSpinner => {
  switch (type) {
    case ActionTypes.SET_TYPE_DISPLAYED_SPINNER:
      return {
        ...state,
        selectedView: payload,
      };
    case ActionTypes.SET_LIST_TYPES_SUCCESS:
      return {
        ...state,
        listOfTypes: payload,
      };

    default:
      return state;
  }
};
