import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';

export interface ISpinner {
  firstSpinner: IFirstSpinner | any;
  house: ISpinnerRenderData;
  [key: string]: ISpinnerRenderData;
}

export interface ISpinnerData {
  spinners: ISpinner
  spinnersAlt: ISpinner
  ['detail-info']: {
    placement: {
      [key: string]: IFilterAllData;
    };
  };
  ['3d-models']: any;
  markups: {
    [key: string]: {
      [key: string]: IMarkUps;
    }
  };
  panorama: IPanoramaRequestData[];
  ['hover-text']: {
    [key: string]: {
      id: string;
      ['polygon_hover_text.text']: string;
    }
  };
  infrastructure: {
    [key: string]: IInfrastructure;
  };
}

export interface IPanoramaRequestData {
  [key: string]: {
    config: IPanoramaRequestInnerData;
  }
}

export interface IPanoramaRequestInnerData {
  backgroundImage: string;
  groupKey: string;
  name: string;
  preview: string;
}

export interface IInfrastructure {
  id: string;
  ['infrastructure.body_text']: string;
  ['infrastructure.head_text']: string;
  ['infrastructure.image_url']: string;
  ['infrastructure.links']: IInfrastructureLink[];
  ['infrastructure.gallery']: string[];
  ['infrastructure.group_by']: null;
  ['infrastructure.hover_show_header']: boolean;
  ['infrastructure.set_by']: null;
  ['infrastructure.src']: string;
  ['infrastructure.type']: InfrastructureTypes;
}

interface IInfrastructureLink {
  ['infrastructure_links.link_data']: string;
  ['infrastructure_links.link_text']: string;
  ['infrastructure_links.type']: IInfrastructureLinkType;
}

enum IInfrastructureLinkType {
  url = 'url',
}

export enum InfrastructureTypes {
  image = 'image',
  gallery = 'gallery',
  panorama = 'panorama',
  video = 'video',
  videoLoop = 'loop autoplay video',
}

export interface ISpinnerRenderData {
  [key: string]: ISpinnerDataPossibilityInnerData;
}

export enum ISpinnerFitType {
  cover = 'cover',
  contain = 'contain',
}

export interface ISpinnerDataPossibilityInnerData {
  data: ISpinnerDataScheme[];
  count: number;
  objectFit: ISpinnerFitType;
  additional_data: IAdditionalData;
  stops?: number[];
  _windRose?: IWindRose;
  startFrame?: number;
}

export interface IFirstSpinner {
  name: string;
  'name-id': any;
}

export interface ISpinnerDataScheme {
  src: string;
  type: string;
  first?: number;
}

export interface IWindRose {
  src: string;
  degree: string;
  disabled: boolean;
  bg_src?: string;
}
export interface IEntityConfig {
  floor_compass_angle: number;
  floor_compass_bg_image: string;
  floor_compass_display: boolean;
  floor_compass_image: string;
  floor_compass_position: string;
  floor_fill_type: string;
  floor_window_image: string;
  floor_window_size: number;
  objectFit: string;
  reverse: boolean;
  rotationControlDesktop: boolean;
  rotationControlMobile: boolean;
  rotationControlPlacementGalleryDesktop: boolean;
  rotationControlPlacementGalleryMobile: boolean;
  spinner_compass_angle: number;
  spinner_compass_bg_image: string;
  spinner_compass_display: string;
  spinner_compass_image: string;
  spinner_desktop_placement_gallery_rotation_control: boolean;
  spinner_desktop_placement_gallery_zoom_control: boolean;
  spinner_desktop_rotation_control: boolean;
  spinner_desktop_zoom_control: boolean;
  spinner_fill_type: string;
  spinner_logo_display: boolean;
  spinner_logo_image: string;
  spinner_mobile_placement_gallery_rotation_control: boolean;
  spinner_mobile_placement_gallery_zoom_control: boolean;
  spinner_mobile_rotation_control: boolean;
  spinner_mobile_zoom_control: boolean;
  spinner_reverse: boolean;
  spinner_turnaround_time: number;
  turnaroundTime: number;
  zoomControlDesktop: boolean;
  zoomControlMobile: boolean;
  zoomControlPlacementGalleryDesktop: boolean;
  zoomControlPlacementGalleryMobile: boolean;
}

export interface IAdditionalData {
  id: string;
  spinner_svg: ISpinnerSvgData;
  ['floor.numeration']?: string;
  section_id?: string;
  house_id?: string;
  [ListOfKeysFilterAllData.placementHouse]?: string;
}

export interface ISpinnerSvgData {
  [key: string]: ISpinnerSvgBlock[];
}

export interface ISpinnerSvgBlock {
  // [key: string]: ISpinnerSvgDataChildren[];
  tag: string;
  attr: ISpinnerSvgDataChildrenAttr | IPlacementWindowSvgDataAttr | any;
  handleClose: () => any;
}

export interface ISpinnerSvgDataChildren {
  tag: string;
  attr: ISpinnerSvgDataChildrenAttr | IPlacementWindowSvgDataAttr;
  handleClose: () => any;
}

export interface ISpinnerSvgDataChildrenAttr {
  points: string;
  ['entity-view']: string;
  ['entity-view-name']: string;
  ['entity-view-stop']: string;
  ['entity-view-name-id']: string;
}

export interface IPlacementWindowSvgDataAttr {
  cx: string;
  cy: string;
  ['data-panorama2-view-center-agl']?: string;
  ['data-panorama2-id']?: string;
  ['data-panorama2-view-max-azm-agl']?: string;
  ['data-panorama2-view-min-azm-agl']?: string;
  ['data-parent-uuid']: string;
  ['data-role']: string;
  ['data-uuid']: string;
  height: string;
  points: string;
  width: string;
  x?: string;
  y?: string;
}

export function instanceOfPlacementWindow(object: any): object is IPlacementWindowSvgDataAttr {
  return 'data-role' in object;
}

export interface IDetailInfo {
  id: string;
  house_id: string;
  section_id: string;
  floor_ids: string;
  [key: string]: string | string[] | null;
}

export interface IMarkUps {
  [key: string]: IMarkUpsInner;
}

export interface IMarkUpsInner {
  img_url: string;
  svg?: IMarkUpsSvg[]
}

export interface IMarkUpsSvg {
  tag: string;
  attr: {
    markup: string;
    points: string;
    ['markup-id']: string;
  }
}

// This enum list I've used for backlight polygons on the spinner
export enum ListOfBacklightSvg {
  houseId = 'house',
  placementId = 'placement',
}

export interface IBacklightState {
  [ListOfBacklightSvg.houseId]: string[];
  [ListOfBacklightSvg.placementId]: string[];
}

// Hits over houses and sections
export interface IHitsList {
  [key: string]: {
    [key: string]: {
      count: number;
    }
  }
}

export interface ISpinnerStopFrame {
  [key: string]: ISpinnerStopFrameItem;
}

export interface ISpinnerStopFrameItem {
  [key: string]: number;
}
