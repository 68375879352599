import React, { useCallback } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ButtonApp } from 'src/components/Button';
import { useLanguageState } from 'src/hooks';
import { IButtonLanguage } from './types';
import { useStyles } from './styles';

export const ButtonLanguage: React.FC<IButtonLanguage> = ({
  buttonStyles,
}) => {
  const styles = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    currentAppLanguage,
    renderLanguageList,
    handleChangeAppLanguage,
  } = useLanguageState();

  const handleClick = useCallback((event: any) => {
    if (renderLanguageList.length) {
      setAnchorEl(event.currentTarget);
    }
  }, [renderLanguageList]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!renderLanguageList.length) return null;

  return (
    <>
      <ButtonApp
        text={currentAppLanguage}
        className={`${styles.currentLanguage} ${buttonStyles}`}
        onClick={handleClick}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.wrapperLanguageMenu}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          renderLanguageList.map((lng) => {
            return (
              <MenuItem
                key={lng}
                className={styles.languageMenuItem}
                onClick={() => {
                  handleChangeAppLanguage(lng);
                  handleClose();
                }}
              >
                {lng}
              </MenuItem>
            );
          })
        }
      </Menu>
    </>
  );
};
