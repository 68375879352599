import React from 'react';
import { useSelector } from 'react-redux';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { getCrmData } from 'src/store/app/selectors';
import { CRMNames } from 'src/typings/app';
import { AmoDeal } from './amo';
import { BitrixDeal } from './bitrix';
import { CreatioDeal } from './creatio';
import { NetHuntDeal } from './netHunt';

export interface IApartmentConnectDeal {
  placeInfo: IFilterAllData;
  isSmall?: boolean,
  showDealDetails?: boolean,
}

export const ApartmentConnectDeal: React.FC<IApartmentConnectDeal> = React.memo(({
  placeInfo,
  isSmall,
  showDealDetails,
}) => {
  const crmData = useSelector(getCrmData);
  if (!crmData) {
    return null;
  }

  switch (crmData.crm) {
    case CRMNames.amocrm:
      return <AmoDeal placeInfo={placeInfo} isSmall={isSmall} />;

    case CRMNames.bitrix:
      return <BitrixDeal placeInfo={placeInfo} isSmall={isSmall} showDealDetails={showDealDetails} />;

    case CRMNames.creatio:
      return <CreatioDeal placeInfo={placeInfo} isSmall={isSmall} />;

    case CRMNames.netHunt:
      return <NetHuntDeal placeInfo={placeInfo} isSmall={isSmall} />;

    default:
      return null;
  }
});
