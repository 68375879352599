import { useDispatch } from 'react-redux';
import { Actions } from 'src/store/floors/actions';
import { useCallback } from 'react';

export const useSetSection = () => {
  const dispatch = useDispatch();
  const setSection = useCallback((markup: string | null, markupId: string | null) => {
    dispatch(Actions.setSelectedSection({
      markup,
      'markup-id': markupId,
    }));
  }, []);
  return {
    setSection,
  };
};
