import { useEffect, useState } from 'react';

import { IView2dData } from 'src/store/view-2d/reducer';
import { ListOfKeysFilterAllData } from 'src/typings/filter';

export const useCrossTableData = (view2dData: IView2dData) => {
  const [houseKey, setHouseKeys] = useState<string[]>([]);

  useEffect(() => {
    if (view2dData) {
      const houseKeys = Object.keys(view2dData);
      houseKeys.sort((a, b) => {
        const firstEl = Object.keys(view2dData[a])[0];
        const secondEl = Object.keys(view2dData[b])[0];
        if (!Number(view2dData[a][firstEl][0][ListOfKeysFilterAllData.placementHouseSequence])
      || !Number(view2dData[b][secondEl][0][ListOfKeysFilterAllData.placementHouseSequence])) {
          return 0;
        }
        if (Number(view2dData[a][firstEl][0][ListOfKeysFilterAllData.placementHouseSequence])
      < Number(view2dData[b][secondEl][0][ListOfKeysFilterAllData.placementHouseSequence])) {
          return -1;
        }
        if (Number(view2dData[a][firstEl][0][ListOfKeysFilterAllData.placementHouseSequence])
      > Number(view2dData[b][secondEl][0][ListOfKeysFilterAllData.placementHouseSequence])) {
          return 1;
        }
        return 0;
      });
      setHouseKeys(houseKeys);
    }
  }, [view2dData]);

  return {
    houseKey,
  };
};
