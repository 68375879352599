import React from 'react';
import { SvgLink } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgLink';
import CheckIcon from '@mui/icons-material/Check';
import { ButtonApp } from 'src/components/ApartmentPageV2/Button';
import { useCopyUrlToClipboard } from 'src/hooks/useCopyUrlToClipboard';
import { useStyles } from 'src/containers/MainPageApartmentsV2/styles';

export const CopyToClipboardButton: React.FC<{title: string}> = ({ title }) => {
  const styles = useStyles({});
  const { copyUrlToClipboard, urlHasBeenCopied } = useCopyUrlToClipboard();
  return (
    <>
      <ButtonApp className={styles.actionsItem} onClick={copyUrlToClipboard}>
        {!urlHasBeenCopied && (
          <SvgLink />
        )}
        {urlHasBeenCopied && (
          <CheckIcon sx={{ width: '15px' }} />
        )}
        <div>{title}</div>
      </ButtonApp>
    </>
  );
};
