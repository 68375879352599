import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useBuildType, useGetFilteredData, useGetFilterParams } from 'src/hooks';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { getCrmData } from 'src/store/app/selectors';
import { useGetFilteredIds } from './useGetFilteredIds';
import { usePolygonConfig } from './usePolygonConfig';

export const useFilledPolygons = () => {
  const { filteredIds, getIdsByType } = useGetFilteredIds();
  const { selectedParams } = useGetFilterParams();
  const { globalPolygonConfig } = usePolygonConfig();
  const currentSpinner = useSelector(getCurrentSpinner);
  const { isCrossTable } = useBuildType();

  const handleIsSelectedFillColor = useCallback((name: string, nameId: string) => {
    /*
    *   if we have selected only ONE VALUE in the filter,
    *   and THIS VALUE is the HOUSE NUMBER,
    *   and we are on the house’s spinner,
    *   then nothing needs to be highlighted
    */
    const value = getIdsByType(name);
    const highlightAllPolygons = !isCrossTable && isEmpty(value) && globalPolygonConfig?.is_pre_highlight_polygons_in_module;
    const isHouseSpinnerWithoutFilter = selectedParams.some((item) => item.name === ListOfKeysFilterAllData.placementHouse)
      && selectedParams.length === 1
      && currentSpinner?.name !== 'complex';
    if (isHouseSpinnerWithoutFilter && !globalPolygonConfig?.is_pre_highlight_polygons_in_module) {
      return false;
    }
    return value?.includes(nameId) || highlightAllPolygons;
  }, [currentSpinner, selectedParams, getIdsByType]);

  return {
    handleIsSelectedFillColor,
  };
};
