import { useState } from 'react';
import { getNetHuntFields } from 'src/api';
import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { INetHuntField } from 'src/components/profileComponents/Integrations/Nethunt/types';

export const useGetFunnelFields = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<INetHuntField[] | []>([]);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchFields = (project: IProject, funnelId: string) => {
    setIsLoading(true);
    getNetHuntFields(jwt, project['@id'], funnelId)
      .then((res: {data: INetHuntField[]}) => {
        setFields(res.data);
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    fields,
    fetchFields,
  };
};
