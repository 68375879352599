import React from 'react';

export const SvgPDF: React.FC<{width?: string, height?: string, fill?: string}> = ({ width = 22, height = 22, fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1362_52)">
        <path
          d="M12.8184 2.81818V6.45454C12.8184 6.69565 12.9141 6.92688 13.0846 7.09736C13.2551 7.26785 13.4863 7.36363 13.7275 7.36363H17.3638"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.63623 11V4.63636C4.63623 4.15415 4.82779 3.69168 5.16876 3.35071C5.50974 3.00973 5.9722 2.81818 6.45441 2.81818H12.818L17.3635 7.36363V11"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.63623 16.4545H5.99987C6.36153 16.4545 6.70837 16.3109 6.9641 16.0551C7.21984 15.7994 7.3635 15.4526 7.3635 15.0909C7.3635 14.7292 7.21984 14.3824 6.9641 14.1267C6.70837 13.8709 6.36153 13.7273 5.99987 13.7273H4.63623V19.1818"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5454 16.4546H17.3636"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2727 13.7273H15.5454V19.1818"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0908 13.7273V19.1818H10.9999C11.4821 19.1818 11.9446 18.9903 12.2856 18.6493C12.6265 18.3083 12.8181 17.8458 12.8181 17.3636V15.5454C12.8181 15.0632 12.6265 14.6008 12.2856 14.2598C11.9446 13.9188 11.4821 13.7273 10.9999 13.7273H10.0908Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1362_52">
          <rect width={width} height={height} fill="white" transform="translate(0.0908203 0.0909119)" />
        </clipPath>
      </defs>
    </svg>

  );
};
