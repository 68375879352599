import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { IGlobalPolygonConfig } from 'src/typings/app';

export const useSpinnerStyles = makeStyles((theme: Theme) => createStyles({
  mapPopover: {
    maxWidth: '225px',
    '&:hover': {
      top: '0px',
    },
  },
  defaultSvgStyle: (props: any) => ({
    strokeWidth: props.global_border_width || 0,
    shapeRendering: 'auto',
    [theme.breakpoints.down('md')]: {
      shapeRendering: 'optimizeSpeed',
    },

  }),
  transparentFragment: (props: any) => {
    return {
      fill: props.isCrossTable ? props.static_body_color : 'transparent',
      fillOpacity: props.isCrossTable ? 0.3 : props.hover_body_opacity,
      stroke: props.isCrossTable ? props.static_border_color : 'transparent',
    };
  },
  selectedDeal: (props: any) => ({
    fill: props.hover_body_color,
    fillOpacity: props.hover_body_opacity,
    stroke: '#000',
    strokeWidth: '6 !important',
  }),
  activeFragment: (props: IGlobalPolygonConfig | any) => {
    return {
      fill: props.static_body_color,
      fillOpacity: props.static_body_opacity,
      stroke: props.static_border_color,
      cursor: 'pointer',
    };
  },

  hoverFragment: (props: IGlobalPolygonConfig | any) => {
    return {
      fill: props.hover_body_color,
      fillOpacity: `${props.hover_body_opacity} !important`,
      stroke: props.hover_border_color,
    };
  },
  clickedFragment: (props: IGlobalPolygonConfig | any) => {
    return {
      fill: props.clicked_body_color,
      fillOpacity: props.clicked_body_opacity,
      stroke: props.clicked_border_color,
    };
  },
}));

export const useAnimationStyles = (props: any) => {
  const classes = makeStyles({
    '@keyframes highlightPolygon': {
      '0%': {
        fill: 'transparent',
        stroke: 'transparent',
      },
      '100%': {
        fill: props.static_body_color,
        stroke: props.static_border_color,
      },
    },
    highlightPolygon: {
      animation: '$highlightPolygon 1000ms infinite',
    },
  });

  return classes();
};
