import { useCallback, useState } from 'react';

export const useCopyUrlToClipboard = () => {
  const [urlHasBeenCopied, setUrlHasBeenCopied] = useState<boolean>(false);

  const copyUrlToClipboard = useCallback(() => {
    navigator.clipboard.writeText(window.location.toString());
    setUrlHasBeenCopied(true);
    setTimeout(() => {
      setUrlHasBeenCopied(false);
    }, 1000);
  }, []);

  return {
    setUrlHasBeenCopied,
    urlHasBeenCopied,
    copyUrlToClipboard,
  };
};
