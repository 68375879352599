import { IDogeFields } from 'src/typings/global-config';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IPanorama extends IDogeFields {
  additionalData: any[];
  complex: string;
  config: { [key: string]: any[] };
  id: number;
}
export interface IPanoramaWrapper extends IDogeFields {
  sequence: number;
  id: number;
  name: {
    [key: string]: string;
  }
  nightPanoramaV2: IPanorama;
  panoramaV2: IPanorama;
}

export interface ICurrentPano {
  entity: string;
  entityId: number|string;
  id: number;
}

export interface IPanoramaReducer {
  currentPanorama: ICurrentPano | null;
  availablePanoramas: {
    [key: string]: {
      [key: string]: IPanoramaWrapper[]
    }
  } | null;
  lookAtMyPanorama: boolean;
}

export const initialState: IPanoramaReducer = {
  currentPanorama: null,
  availablePanoramas: null,
  lookAtMyPanorama: false,
};

export const panoramaReducer = (
  state = initialState,
  { type, payload }: IAction,
): IPanoramaReducer => {
  switch (type) {
    case ActionTypes.SET_CURRENT_PANORAMA:
      return {
        ...state,
        currentPanorama: payload,
      };
    case ActionTypes.SET_AVAILABLE_PANORAMAS:
      return {
        ...state,
        availablePanoramas: payload,
      };
    case ActionTypes.SET_LOOK_AT_PANO:
      return {
        ...state,
        lookAtMyPanorama: payload,
      };

    default:
      return state;
  }
};
