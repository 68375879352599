import React from 'react';

export const SvgLinkHeader: React.FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4043 13.5991L13.597 8.4064"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1348 5.81007L10.5355 5.34619C11.3471 4.53469 12.4478 4.07884 13.5956 4.07892C14.7433 4.079 15.844 4.53501
         16.6555 5.34663C17.467 6.15824 17.9228 7.25899 17.9227 8.40671C17.9226 9.55443 17.4666 10.6551 16.655
          11.4666L16.1929 11.8682"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8663 16.1954L11.5227 16.6576C10.7016 17.4695 9.59343 17.9249 8.43868 17.9249C7.28393 17.9249 6.17576
         17.4695 5.35467 16.6576C4.94995 16.2574 4.62864 15.7809 4.40935 15.2557C4.19006 14.7304 4.07715 14.1669
          4.07715 13.5978C4.07715 13.0286 4.19006 12.4651 4.40935 11.9399C4.62864 11.4147 4.94995 10.9382 5.35467
           10.538L5.80816 10.1373"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};
