export const appIcon = {
  iconEdit: require('./icon_edit.svg'),
  iconSortArrow: require('./icon_sortArrow.svg'),
  iconDelete: require('./icon_delete.svg'),
  iconLetter: require('./icon_letter.svg'),
  iconUserDisable: require('./icon_userDisable.svg'),
  iconUpload: require('./icon_upload.svg'),
  iconSearch: require('./icon_search.svg'),
  iconStar: require('./icon_star.svg'),
  icon360: require('./icon_360.svg'),
  iconFB: require('./icon_fb.svg'),
  iconGoogle: require('./icon_google.svg'),
  iconAddFavoriteIcon: require('./icon_add_to_fav.svg'),
  iconFloors: require('./icon_floors.svg'),
  iconShowInMap: require('./icon_in_map.svg'),
  iconLoader: require('./icon_loader.svg'),
  iconMoreFilter: require('./icon_more_filter.svg'),
  iconShowLayouts: require('./icon_show_layouts.svg'),
  iconSwipeLine: require('./icon_swipe_line.svg'),
  iconLeftArrow: require('./icon_left_arrow.svg'),
  iconGalleryLeftArrow: require('./icon_gallery_arrow_left.svg'),
  iconGalleryRightArrow: require('./icon_gallery_arrow_right.svg'),
  iconFullScreen: require('./icon_full_screen.svg'),
  noImg: require('./no-img.jpg'),
  siteLogo: require('./site-logo.svg'),
  favicon: require('./favicon.png'),
  mainLogo: require('./main_logo.svg'),
  mainLogoMobile: require('./main_logo_mobile.svg'),
  bigLogo: require('./big_logo.svg'),
  authBG: require('./authBG.png'),
  avatar: require('./avatar.svg'),
  apartmentBed: require('./apartment_bed.svg'),
  iconHeart: require('./icon_heart.svg'),
  iconHeartDesktop: require('./icon_heart_desktop.svg'),
  iconPDF: require('./icon_pdf.svg'),
  iconPDFDesktop: require('./icon_pdf_desktop.svg'),
  iconLink: require('./icon_link.svg'),
  iconDownload: require('./icon_download.svg'),
  iconCheck: require('./icon_check.svg'),
  night: require('./night.svg'),
  day: require('./day.svg'),
  iconLogs: require('./icon_logs.svg'),
};
