import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  windRose: {
    left: '20px',
    top: '90px',
    borderRadius: '50%',
    transition: '.5s opacity',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 1,
    position: 'absolute',
    '& .img-wrapper': {
      '& img': {
        objectFit: 'contain',
      },
    },
  },
  onTop: {
    top: 'unset',
    left: 'unset',
    transform: 'none',
    position: 'relative',
    margin: '0 auto',
    marginBottom: '15px',
  },
  isGallery: {
    width: '60px',
    height: '60px',
    left: '10px',
    top: '10px',
  },
  arrowWrapper: {
    position: 'absolute',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '37px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '100px',
    },
    [theme.breakpoints.up('md')]: {
      width: '45%',
      height: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '35%',
      height: '35%',
    },
  },
  arrowWrapperWithBg: {
    position: 'absolute',
    top: '20px',
    left: '22px',
  },
  arrowWithBg: {
    width: '40px',
  },
  arrow: {
    width: '100%',
    height: '100%',
  },
}));
