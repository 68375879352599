import React from 'react';

export const SvgLink: React.FC = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.25 9.25073L9.75 4.75073" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.75 2.50072L7.09725 2.09872C7.8006 1.39546 8.75452 1.00042 9.74914 1.00049C10.7438 1.00056 11.6976 1.39574 12.4009
       2.09909C13.1041 2.80245 13.4992 3.75636 13.4991 4.75098C13.499 5.74561 13.1039 6.69946 12.4005 7.40272L12 7.75072"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25011 11.5007L7.95236 11.9012C7.24079 12.6049 6.28045 12.9995 5.27973 12.9995C4.27902 12.9995 3.31867 12.6049
       2.60711 11.9012C2.25637 11.5544 1.97793 11.1415 1.78789 10.6863C1.59785 10.2312 1.5 9.74284 1.5 9.24961C1.5 8.75637 1.59785 8.26803 1.78789 7.81288C1.97793 7.35772 2.25637 6.94478 2.60711 6.59798L3.00011 6.25073"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};
