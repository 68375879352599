import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ButtonApp } from 'src/components/Button';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { getCrmData } from 'src/store/app/selectors';
import { useGetAuthData } from 'src/hooks';
import { Actions } from 'src/store/requests/filter/actions';
import { Actions as SpinnerActions } from 'src/store/requests/spinner-views/actions';
import {
  patchPlacement,
} from 'src/api';
import { useConfirm } from 'material-ui-confirm';
import { IApartmentConnectDeal } from './index';
import { dealsTheme, useStyles } from './styles';
import { translations } from '../../constants/translations';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const AmoDeal: React.FC<IApartmentConnectDeal> = React.memo(({
  placeInfo,
  isSmall,
}) => {
  const styles = useStyles({});
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const crmData = useSelector(getCrmData);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [currentDealId, setCurrentDealId] = useState<number|null>(crmData?.dealId || null);
  const [currentDeals, setCurrentDeals] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (crmData) {
      setCurrentDeals(placeInfo[ListOfKeysFilterAllData.placementAmoDeals]);
    }
  }, [placeInfo, crmData]);

  useEffect(() => {
    let temp = '';
    if (currentDeals) {
      currentDeals.forEach((item) => {
        if (item.includes(String(currentDealId))) {
          temp = item;
        }
      });
    }
    setIsSelected(!!temp);
  }, [currentDeals, crmData, currentDealId]);

  if (!crmData || !Number(placeInfo[ListOfKeysFilterAllData.placementCanBeSet])) {
    return null;
  }

  const {
    dealId, domain, statusId,
  } = crmData;

  const sendPlacementData = useCallback(async (deals: String[], isRemove: boolean) => {
    setIsLoading(true);
    const data = isRemove ? {
      amo_deals: deals,
    } : {
      amo_deals: deals,
      amo_status_id: statusId,
    };
    const place = await patchPlacement(
      jwt,
      placeInfo[ListOfKeysFilterAllData.id],
      data,
    );
    setCurrentDeals(place.data.amo_deals);
    window.parent.postMessage({ type: 'iFrameRequest' }, '*');
    dispatch(Actions.getDataFilterUpdate());
    dispatch(SpinnerActions.spinnerDataEditPlacement({
      id: placeInfo[ListOfKeysFilterAllData.id],
      data: place.data.amo_deals,
    }));
    setIsLoading(false);
  }, [crmData, placeInfo, jwt, currentDeals]);

  const handleAddDeal = useCallback(() => {
    const tempDeal = `${crmData.pipelineId}.${dealId}`;
    const tempDeals = currentDeals ? [...currentDeals] : [];
    tempDeals.push(tempDeal);
    sendPlacementData(tempDeals, false);
    setCurrentDealId(dealId);
  }, [currentDeals, crmData, dealId]);

  const handleRemoveDeal = useCallback((selectedDeal: any = 0) => {
    const current = selectedDeal;
    const tempDeals = currentDeals.filter((item) => {
      return !item.includes(String(current));
    });
    sendPlacementData(tempDeals, true);
  }, [currentDeals]);

  const handleUnbindDeal = useCallback((e, itemId) => {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      title: `${translations.dialogTitle} №${placeInfo[ListOfKeysFilterAllData.placementNumeration]}`,
    }).then(() => {
      handleRemoveDeal(itemId);
    });
  }, [placeInfo]);

  const getBtnText = () => {
    const dotSplit = String(dealId).split('.');
    const itemId = dotSplit[dotSplit.length - 1];
    if (isSelected && !isLoading) return `Отвязать от сделки №${itemId}`;
    if (isSelected && isLoading) return 'Удаляем...';
    if (!isSelected && !isLoading) return `Привязать к сделке №${itemId}`;
    if (!isSelected && isLoading) return 'Привязываем...';
    return '';
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dealsTheme}>
        <Box key={placeInfo[ListOfKeysFilterAllData.id]} mt={isSmall ? 1 : 0}>
          {(!Number(placeInfo[ListOfKeysFilterAllData.placementOnlyOneDeal]) || isSelected) && (
          <Box mb={6}>
            <ButtonApp
              text={getBtnText()}
              type="button"
              color={isSelected ? 'secondary' : 'primary'}
              onClick={isSelected ? () => {
                handleRemoveDeal(dealId);
              } : handleAddDeal}
              isCapitalize
              isFullSize
              disabled={isLoading}
              customClassName={isSmall ? styles.isSmallBtn : ''}
              variant={isSelected ? 'outlined' : 'contained'}
            />
          </Box>
          )}
          {currentDeals && currentDeals.length && !(currentDeals.length === 1 && isSelected) ? (
            <Box>
              Другие сделки
              {currentDeals.map((item: string) => {
                if (!item || item.includes(String(currentDealId))) return null;
                const dotSplit = item.split('.');
                const itemId = dotSplit[dotSplit.length - 1];
                return (
                  <a
                    href={`https://${domain}/leads/detail/${itemId}`}
                    title="Открыть сделку в CRM"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    key={itemId}
                  >
                    <Box
                      fontSize="17px"
                      width="100%"
                      mt={2}
                      borderRadius="5px"
                      position="relative"
                      className={styles.dealBtn}
                    >
                      {`Сделка №${itemId}`}
                      <Box
                        borderRadius="0 5px 5px 0"
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        top={0}
                        right={0}
                        bottom={0}
                        className={`${styles.removeDeal} removeBtn`}
                        title="Отвязать сделку"
                        onClick={(e) => handleUnbindDeal(e, itemId)}
                      >
                        <DeleteForeverIcon />
                      </Box>
                    </Box>
                  </a>
                );
              })}
            </Box>
          ) : null}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
