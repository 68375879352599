import { useSelector } from 'react-redux';

import {
  getFloorList,
  getView2dData, getView2dDataList,
} from 'src/store/view-2d/selectors';
import { useEffect, useState } from 'react';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useSelectedIds } from 'src/components/CrosstableContent/components/Section/hooks';

export const useCrossTableState = () => {
  const floorList = useSelector(getFloorList);
  const view2dData = useSelector(getView2dData);
  const view2dDataList = useSelector(getView2dDataList);
  const { selectedIds } = useSelectedIds();
  const [view2dDataObject, setView2dDataObject] = useState<{[key: string]: IFilterAllData}>({});

  useEffect(() => {
    if (view2dDataList) {
      const tempPlacementsObj = {};
      view2dDataList.forEach((item) => {
        if (selectedIds.includes(item[ListOfKeysFilterAllData.id])) {
          // @ts-ignore
          tempPlacementsObj[item[ListOfKeysFilterAllData.id] as string] = item;
        }
      });
      setView2dDataObject(tempPlacementsObj);
    }
  }, [view2dDataList, selectedIds]);

  return {
    floorList,
    view2dData,
    view2dDataList,
    view2dDataObject,
  };
};
