import React, { useEffect, useState } from 'react';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { Link, useHistory } from 'react-router-dom';
import { pathList } from 'src/route-list';
import Fade from 'react-reveal';
import { appIcon } from 'src/assets';
import { ButtonApp } from 'src/components/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useBuildType, useFavoriteListState } from 'src/hooks';
import { useFavoriteList } from 'src/components/FavoritesList/hooks';
import { useSelector } from 'react-redux';
import { getComplexId } from 'src/store/app/selectors';
import { useComparePageLink } from 'src/hooks/useComparePageLink';
import { useStyles } from './styles';

export const CompareItem: React.FC<{item: IFilterAllData, removeItemFromCompare: (id: string) => void}> = ({ item, removeItemFromCompare }) => {
  const styles = useStyles();
  const {
    toggleFavoriteItem,
  } = useFavoriteListState(item[ListOfKeysFilterAllData.id]);
  const history = useHistory();
  const complexId = useSelector(getComplexId);
  const { isCrossTable } = useBuildType();
  const { comparePageRouteLink } = useComparePageLink();
  const complexPrefix = isCrossTable ? `/${complexId}` : '';

  useEffect(() => {
    if (comparePageRouteLink) {
      history.push(`${complexPrefix}${comparePageRouteLink}`);
    }
  }, [comparePageRouteLink]);

  return (
    <div key={item.id} className={`${styles.wrapperImageProperty}`}>
      <Link to={`${complexPrefix}${pathList.own}/${item.id}`}>
        <Fade>
          <img
            alt="plan"
            className={styles.imageStyle}
            src={
              item.main_filter_img
                ? item.main_filter_img.replace('desktop', 'mobile')
                : appIcon.noImg.default
            }
          />
        </Fade>
      </Link>
      <ButtonApp
        startIcon={<CloseIcon />}
        className={styles.closeIcon}
        onClick={() => {
          toggleFavoriteItem();
          removeItemFromCompare(item[ListOfKeysFilterAllData.id]);
        }}
      />
    </div>
  );
};
