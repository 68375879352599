import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';

import { ButtonApp } from 'src/components/Button';
import { ControlledAutocomplete } from 'src/components/ControledAutocomplete';
import { borderColor } from 'src/constants/styles';
import { translations } from 'src/constants/translations';
import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import {
  getBitrixFormConfigurations,
  getBitrixFormConfigurationTimelines,
  postBitrixFormConfigurationTimelines,
  patchBitrixFormConfigurationTimelines,
  getCreatioConfig,
} from 'src/api';
import { FormFields } from 'src/typings/forms';
import { IDogeFields } from 'src/typings/global-config';
import { makeId } from 'src/helpers';
import { CRMNames, IntegrationType } from 'src/typings/app';
import { toUpperCase } from 'src/helpers/toUpperCase';
import { crosstableWithoutApi } from 'src/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from 'src/components/profileComponents/Integrations/Bitrix/styles';
import { methodsCollection } from 'src/components/profileComponents/FeedbackSettings/methods';

interface IFeedbackSettings {
  integration: IntegrationType;
  project: IProject;
}

enum TypeOfField {
  string = 'string',
  selector = 'selector'
}

interface creatioTempFields {
  creatioEntityName: string;
  creatioEntityFieldName: string;
  creatioEntityFieldId: string;
  creatioEntityFieldType: string;
  creatioEntityFieldValuesIdToFsFormValues: {
    [key: string]: string
  } | null;
}
interface nethuntTempFields {
  netHuntEntityName: string;
  netHuntEntityFieldName: string;
  netHuntEntityFieldId: string;
  netHuntEntityFieldType: string;
  netHuntEntityFieldValuesIdToFsFormValues: {
    [key: string]: string
  } | null;
}

export interface IFeedbackField extends creatioTempFields, nethuntTempFields, IDogeFields {
  tempId: string;
  id?: string;
  fsFormFieldKeyName: string;
  bitrixEntityName: string;
  bitrixEntityFieldName: string;
  bitrixEntityFieldId: string;
  bitrixEntityFieldType: string;
  fsFieldType: TypeOfField;
  isFilter: boolean;
  bitrixEntityFieldValuesIdToFsFormValues: {
    [key: string]: string
  } | null;
}
export interface ITimelineField {
  id?: string,
  tempId: string,
  linkToEntity: string,
  fsFormFieldKeyNames: string[],
}

let defaultEntity = ['lead', 'deal', 'contact', 'company'];

const defaultStringFields = [FormFields.phone, FormFields.email, FormFields.bio, FormFields.clientIp, FormFields.utm, FormFields.googleClientId];

export const FeedbackSettings: React.FC<IFeedbackSettings> = ({ integration, project }) => {
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [fields, setFields] = useState<{
    string: IFeedbackField[],
    selector: IFeedbackField[],
  }>({
    string: [],
    selector: [],
  });
  const [fieldsTimeline, setFieldsTimeline] = useState<ITimelineField[]>([]);
  const [entityValues, setEntityValues] = useState<{
    [key: string]: any
  }>({});
  const [rerender, setRerender] = useState<boolean>(false);
  const [removeIds, setRemoveIds] = useState<{
    id: string;
    type: string;
  }[]>([]);
  const {
    register, handleSubmit, getValues, setValue, control,
  } = useForm({
    mode: 'onChange',
  });

  const fetchCRFields = async (tempEntityValues?: {[key: string]: any}) => {
    const resultConf = await getCreatioConfig(jwt);
    const { data } = resultConf;

    if (data['hydra:member'].length) {
      const strings: IFeedbackField[] = [];
      const selectors: IFeedbackField[] = [];
      data['hydra:member'].forEach((item: IFeedbackField) => {
        if (item.fsFieldType === TypeOfField.string) {
          strings.push(item);
        } else {
          selectors.push(item);
        }
      });
      setFields({
        ...fields,
        string: strings,
        selector: selectors,
      });
      setEntityValues({ ...tempEntityValues });
    }
  };

  const fetchBTFields = async (tempEntityValues?: {
    [key: string]: any
  }) => {
    const resultConf = await getBitrixFormConfigurations(jwt, project.id);
    const { webhookUrl } = project;

    const { data } = resultConf;

    if (data['hydra:member'].length) {
      const strings: IFeedbackField[] = [];
      const selectors: IFeedbackField[] = [];
      data['hydra:member'].forEach((item: IFeedbackField) => {
        if (item.fsFieldType === TypeOfField.string) {
          strings.push(item);
        } else {
          selectors.push(item);
        }
      });
      setFields({
        ...fields,
        string: strings,
        selector: selectors,
      });
      const currenttEntityValues = tempEntityValues || entityValues;
      const iblockElems = selectors.filter((selector: IFeedbackField) => selector[`${integration}EntityFieldType`] === 'iblock_element');
      const iblockItems = await Promise.all(iblockElems.map((iblock) => {
        const findItem = Object.keys(currenttEntityValues[iblock[`${integration}EntityName`]]).find((item: any) => {
          return currenttEntityValues[iblock[`${integration}EntityName`]][item].title === iblock[`${integration}EntityFieldName`]
                  || currenttEntityValues[iblock[`${integration}EntityName`]][item].listLabel === iblock[`${integration}EntityFieldName`];
        });
        if (webhookUrl && findItem) {
          const iblockId = currenttEntityValues[`${integration}EntityName`][findItem].settings.IBLOCK_ID;
          return axios.get(`${webhookUrl}lists.element.get.json?IBLOCK_TYPE_ID=lists&IBLOCK_ID=${iblockId}`);
        }
        return null;
      }));
      const tempIblockValues = { ...currenttEntityValues };
      iblockItems.forEach((item: any) => {
        tempIblockValues[`iblock${item.data.result[0].IBLOCK_ID}`] = item.data.result;
      });
      setEntityValues(tempIblockValues);
    } else {
      const temp = { ...fields };
      temp.string = defaultStringFields.map((item) => {
        return {
          tempId: makeId(5),
          fsFormFieldKeyName: item,
          [`${integration}EntityName`]: '',
          [`${integration}EntityFieldName`]: '',
          [`${integration}EntityFieldId`]: '',
          fsFieldType: TypeOfField.string,
          [`${integration}EntityFieldType`]: '',
          [`${integration}EntityFieldValuesIdToFsFormValues`]: null,
        } as unknown as IFeedbackField;
      });
      setFields(temp);
    }

    const { data: dataTimelines } = await getBitrixFormConfigurationTimelines(jwt, project.id);
    if (dataTimelines['hydra:member'].length) {
      setFieldsTimeline(dataTimelines['hydra:member']);
      dataTimelines['hydra:member'].forEach((item: ITimelineField) => {
        setValue(`timeline.${item.id}.fsFormFieldKeyNames`, item.fsFormFieldKeyNames);
      });
    }
  };

  const fetchCreatioFields = async () => {
    const tempEntityValues: {
      [key: string]: any[]
    } = {};
    await Promise.all(defaultEntity.map((defaultEntityItem) => {
      return axios.get(`${crosstableWithoutApi}/api/creatio/${defaultEntityItem}_metadata?creatioProjectId=${project.id}`, {
        headers: { Authorization: `Bearer ${jwt}` },
      })
        .then((response) => {
          const { data } = response;
          tempEntityValues[defaultEntityItem] = data;
          return data;
        });
    }));
    setEntityValues(tempEntityValues);
    fetchCRFields(tempEntityValues);
  };

  const fetchBitrixFields = async () => {
    const { webhookUrl } = project;
    if (webhookUrl) {
      const tempEntityValues: {
        [key: string]: any[]
      } = {};
      await Promise.all(defaultEntity.map(((defaultEntityItem) => {
        return axios.get(`${webhookUrl}crm.${defaultEntityItem}.fields.json`)
          .then((response) => {
          // handle success
            const { data } = response;
            const { result } = data;
            tempEntityValues[defaultEntityItem] = result;
            return result;
          })
          .catch((error) => error);
      })));

      await axios.get(`${webhookUrl}crm.status.list.json`)
        .then((response) => {
          // handle success
          const { data } = response;
          const { result } = data;
          tempEntityValues.status = result;
          return result;
        })
        .catch((error) => error);

      setEntityValues(tempEntityValues);
      fetchBTFields(tempEntityValues);
    }
  };

  const onSubmit = async (data:any) => {
    await Promise.all(Object.keys(data).map((type: string) => {
      return Object.keys(data[type]).map((fieldId) => {
        if (type !== 'timeline') {
          if (data[type][fieldId] && data[type][fieldId].fsFormFieldKeyName
            && data[type][fieldId][`${integration}EntityName`]) {
            const EntityFieldValuesIdToFsFormValues = `${integration}EntityFieldValuesIdToFsFormValues`;
            const newArr = data[type][fieldId][EntityFieldValuesIdToFsFormValues]
              ? Object.keys(data[type][fieldId][EntityFieldValuesIdToFsFormValues]).reduce((acc: any, id: string) => {
                return {
                  ...acc,
                  [id.replace('ID', '')]: data[type][fieldId][EntityFieldValuesIdToFsFormValues][id],
                };
              }, {})
              : null;
            if (!data[type][fieldId].id) {
              return methodsCollection.feedback[integration].post(jwt, {
                ...data[type][fieldId],
                [`${integration}EntityFieldValuesIdToFsFormValues`]: newArr,
                [`${integration}Project`]: project['@id'],
              });
            }
            return methodsCollection.feedback[integration].patch(jwt, data[type][fieldId].id, {
              ...data[type][fieldId],
              [`${integration}EntityFieldValuesIdToFsFormValues`]: newArr,
            });
          }
        } else if (data[type][fieldId] && data[type][fieldId].linkToEntity
              && data[type][fieldId].fsFormFieldKeyNames
        && integration === CRMNames.bitrix) { // TODO: Remove this condition when creatio timelines endpoints be done.
          if (!data[type][fieldId].id) {
            return postBitrixFormConfigurationTimelines(jwt, {
              ...data[type][fieldId],
              [`${integration}Project`]: project['@id'],
            });
          }
          return patchBitrixFormConfigurationTimelines(jwt, data[type][fieldId].id, data[type][fieldId]);
        }
        return null;
      });
    }));
    await Promise.all(removeIds.map((itemId) => {
      if (itemId.type === 'timeline') {
        return methodsCollection.timeline[integration].delete(jwt, itemId.id);
      }
      return methodsCollection.feedback[integration].delete(jwt, itemId.id);
    }));
    setRemoveIds([]);
    setFields({
      string: [],
      selector: [],
    });
    switch (integration) {
      case CRMNames.bitrix:
        fetchBTFields();
        break;
      case CRMNames.creatio:
        fetchCRFields();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (integration) {
      case CRMNames.bitrix:
        fetchBitrixFields();
        break;
      case CRMNames.creatio:
        defaultEntity = ['lead', 'contact'];
        fetchCreatioFields();
        break;
      default:
        break;
    }
  }, [project]);

  const handleGetFieldID = async (fieldId: string, type: TypeOfField) => {
    const entityName = getValues(`${type}.${fieldId}.${integration}EntityName`);
    const entityFieldName = getValues(`${type}.${fieldId}.${integration}EntityFieldName`);
    const { webhookUrl } = project;

    if (entityValues[entityName]) {
      const findedItem = Object.keys(entityValues[entityName]).find((item: any) => {
        return entityValues[entityName][item].title === entityFieldName
        || entityValues[entityName][item].listLabel === entityFieldName;
      });

      if (findedItem) {
        if (entityValues[entityName][findedItem].type === 'iblock_element') {
          const iblockID = entityValues[entityName][findedItem].settings.IBLOCK_ID;
          if (!entityValues[`iblock${iblockID}`]) {
            const iblockItem = await axios.get(`${webhookUrl}lists.element.get.json?IBLOCK_TYPE_ID=lists&IBLOCK_ID=${iblockID}`);
            setEntityValues({
              ...entityValues,
              [`iblock${iblockID}`]: iblockItem.data.result,
            });
          }
        }
        setValue(`${type}.${fieldId}.${integration}EntityFieldId`, findedItem);
        setValue(`${type}.${fieldId}.${integration}EntityFieldType`, entityValues[entityName][findedItem].type);
        if (type === TypeOfField.selector) {
          setRerender(!rerender);
        }
      }
    }
  };

  const handleAddElement = (type: TypeOfField) => {
    const temp = [...fields[type]];
    temp.push({
      tempId: makeId(5),
      fsFormFieldKeyName: '',
      [`${integration}EntityName`]: '',
      [`${integration}EntityFieldName`]: '',
      [`${integration}EntityFieldId`]: '',
      [`${integration}EntityFieldType`]: '',
      fsFieldType: type,
      [`${integration}EntityFieldValuesIdToFsFormValues`]: null,
    } as unknown as IFeedbackField);
    setFields({
      ...fields,
      [type]: temp,
    });
  };
  const handleAddTimeline = () => {
    const temp = [...fieldsTimeline];
    temp.push({
      tempId: makeId(5),
      linkToEntity: '',
      fsFormFieldKeyNames: [],
    } as ITimelineField);
    setFieldsTimeline(temp);
  };

  const removeElement = (itemString: IFeedbackField, type: TypeOfField) => {
    const temp = [...fields[type]];
    const findedIndex = fields[type].findIndex((item) => item === itemString);
    temp.splice(findedIndex, 1);
    setFields({
      ...fields,
      [type]: temp,
    });
    if (itemString.id) {
      removeIds.push({
        type,
        id: itemString.id,
      });
    }
  };

  const removeTimeline = (itemString: ITimelineField) => {
    const temp = [...fieldsTimeline];
    const findedIndex = fieldsTimeline.findIndex((item: any) => item === itemString);
    temp.splice(findedIndex, 1);
    setFieldsTimeline(temp);
    if (itemString.id) {
      removeIds.push({
        type: 'timeline',
        id: itemString.id,
      });
    }
  };

  const getFieldsList = (fieldId: string, defEntityName: string) => {
    const entityName = getValues(`string.${fieldId}.${integration}EntityName`) || defEntityName;
    return entityValues[entityName] ? Object.keys(entityValues[entityName]).map((item: string) => (
      entityValues[entityName][item].listLabel || entityValues[entityName][item].title
    )) : [];
  };

  const getFieldsListSelector = (fieldId: string, defEntityName: string) => {
    const entityName = getValues(`selector.${fieldId}.${integration}EntityName`) || defEntityName;
    if (entityValues[entityName]) {
      const filtered = Object.keys(entityValues[entityName]).filter((item: string) => ((
        entityValues[entityName][item].type === 'enumeration'
        || entityValues[entityName][item].type === 'crm_status'
        || entityValues[entityName][item].type === 'iblock_element'
      )));
      return filtered.map((item: string) => (entityValues[entityName][item].listLabel || entityValues[entityName][item].title));
    }

    return [];
  };

  return (
    <Box py={5}>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        {`Mapping Feedback Form data to ${toUpperCase(integration)} Lead fields`}
      </Box>

      {(!fields.string.length && !fields.selector.length) || !Object.keys(entityValues).length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress style={{ color: 'black' }} size="24px" />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Box
              top="-10px"
              position="absolute"
              bgcolor="white"
              fontSize="14px"
            >
              Entity Type - String
            </Box>
            <Box
              border={`1px solid ${borderColor}`}
              borderRadius="3px"
              position="relative"
              width="100%"
              p={2}
              mb={2}
            >
              <span role="img" aria-label="attention">
                ❗Since contact search is performed by phone number (although there may be other criteria,
                such as email - in which case, they also need to be added),
                it is essential to include the phone number in the entity type STRING for the Contact entity.
              </span>
            </Box>
            {fields.string.map((item: IFeedbackField) => (
              <Box
                key={item.id || item.tempId}
                display="flex"
                justifyContent="space-beetwen"
                alignItems="center"
                mb={2}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      control={control}
                      name={`string.${item.id || item.tempId}.fsFormFieldKeyName`}
                      options={Object.values(FormFields)}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Field Name from FS Form"
                          variant="outlined"
                        />
                      )}
                      defaultValue={item.fsFormFieldKeyName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      control={control}
                      name={`string.${item.id || item.tempId}.${integration}EntityName`}
                      options={defaultEntity}
                      getOptionLabel={(option: any) => option}
                      onChange={(e:any) => {
                        setRerender(!rerender);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Entity Name in B24"
                          variant="outlined"
                        />
                      )}
                      defaultValue={item[`${integration}EntityName`]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      control={control}
                      defaultValue={item[`${integration}EntityFieldName`]}
                      name={`string.${item.id || item.tempId}.${integration}EntityFieldName`}
                      options={getFieldsList(item.id || item.tempId, item[`${integration}EntityName`])}
                      getOptionLabel={(option: any) => option}
                      onChange={(data:any) => {
                        setValue(`string.${item.id || item.tempId}.${integration}EntityFieldName`, data);
                        handleGetFieldID(item.id || item.tempId, TypeOfField.string);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Field Name in B24 Entity"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Field ID in B24 Entity"
                      name={`string.${item.id || item.tempId}.${integration}EntityFieldId`}
                      defaultValue={item[`${integration}EntityFieldId`]}
                      variant="outlined"
                      inputRef={register}
                    />

                  </Grid>
                </Grid>
                <Box display="none">
                  <TextField
                    label="Тип поля"
                    name={`string.${item.id || item.tempId}.${integration}EntityFieldType`}
                    defaultValue={item[`${integration}EntityFieldType`]}
                    variant="outlined"
                    inputRef={register}
                  />
                </Box>
                <Input
                  type="hidden"
                  name={`string.${item.id || item.tempId}.fsFieldType`}
                  defaultValue="string"
                  inputRef={register}
                />
                {item.id && (
                <Input
                  type="hidden"
                  name={`string.${item.id}.id`}
                  defaultValue={item.id}
                  inputRef={register}
                />
                )}
                <Box ml={1}>
                  <Tooltip placement="top" title="Искать дубли по этому полю?">
                    <div>
                      <Checkbox
                        defaultChecked={item.isFilter}
                        name={`string.${item.id || item.tempId}.isFilter`}
                        color="primary"
                        inputRef={register}
                      />
                    </div>
                  </Tooltip>
                </Box>
                <Box ml={1}>
                  <IconButton
                    onClick={() => removeElement(item, TypeOfField.string)}
                    aria-label="delete"
                    size="large"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Box>
              <ButtonApp
                text="Add string"
                color="primary"
                variant="contained"
                onClick={() => handleAddElement(TypeOfField.string)}
                size="large"
                className={styles.btn}
              />
            </Box>
          </Box>

          {/* TYPE SELECTOR */}
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Box
              top="-10px"
              position="absolute"
              bgcolor="white"
              fontSize="14px"
            >
              Entity Type - Selector
            </Box>
            {fields.selector.map((item: IFeedbackField) => {
              const fieldId = getValues(`selector.${item.id || item.tempId}.${integration}EntityFieldId`);
              const entityName = getValues(`selector.${item.id || item.tempId}.${integration}EntityName`);

              let fieldItems: any = null;
              if (entityValues[entityName] && entityValues[entityName][fieldId]) {
                switch (entityValues[entityName][fieldId].type) {
                  case 'crm_status': {
                    const entityId = fieldId.replace('_ID', '');
                    const tempItems = entityValues.status.filter((statusItem: any) => {
                      return statusItem.ENTITY_ID === entityId;
                    });
                    fieldItems = tempItems.map((statusItem: any) => {
                      return {
                        ID: statusItem.STATUS_ID,
                        VALUE: statusItem.NAME,
                      };
                    });
                    break;
                  }
                  case 'iblock_element': {
                    const iblockID = entityValues[entityName][fieldId].settings.IBLOCK_ID;
                    if (entityValues[`iblock${iblockID}`]) {
                      fieldItems = entityValues[`iblock${iblockID}`].map((blockItem: any) => {
                        return {
                          ID: blockItem.ID,
                          VALUE: blockItem.NAME,
                        };
                      });
                    }

                    break;
                  }

                  default:
                    fieldItems = (entityValues[entityName] && entityValues[entityName][fieldId])
                      ? entityValues[entityName][fieldId].items : null;
                    break;
                }
              }
              return (
                <Box
                  border={`1px solid ${borderColor}`}
                  borderRadius="3px"
                  p={2}
                  mb={2}
                  key={item.id || item.tempId}
                >
                  <Box
                    display="flex"
                    justifyContent="space-beetwen"
                    alignItems="center"
                    mb={2}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <ControlledAutocomplete
                          defaultValue={item.fsFormFieldKeyName}
                          control={control}
                          name={`selector.${item.id || item.tempId}.fsFormFieldKeyName`}
                          options={Object.values(FormFields)}
                          getOptionLabel={(option: any) => option}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Field Name from FS Form"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ControlledAutocomplete
                          defaultValue={item[`${integration}EntityName`]}
                          control={control}
                          name={`selector.${item.id || item.tempId}.${integration}EntityName`}
                          options={defaultEntity}
                          getOptionLabel={(option: any) => option}
                          onChange={(e: any) => {
                            setRerender(!rerender);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Entity Name in B24"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ControlledAutocomplete
                          defaultValue={item[`${integration}EntityFieldName`]}
                          control={control}
                          name={`selector.${item.id || item.tempId}.${integration}EntityFieldName`}
                          options={getFieldsListSelector(item.id || item.tempId, item[`${integration}EntityName`])}
                          getOptionLabel={(option: any) => option}
                          onChange={(data:any) => {
                            setValue(`selector.${item.id || item.tempId}.${integration}EntityFieldName`, data);
                            handleGetFieldID(item.id || item.tempId, TypeOfField.selector);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Field Name in B24 Entity"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Field ID in B24 Entity"
                          name={`selector.${item.id || item.tempId}.${integration}EntityFieldId`}
                          defaultValue={item[`${integration}EntityFieldId`]}
                          variant="outlined"
                          inputRef={register}
                        />
                      </Grid>
                    </Grid>
                    <Input
                      type="hidden"
                      name={`selector.${item.id || item.tempId}.${integration}EntityFieldType`}
                      defaultValue={item[`${integration}EntityFieldType`]}
                      inputRef={register}
                    />
                    <Input
                      type="hidden"
                      name={`selector.${item.id || item.tempId}.fsFieldType`}
                      defaultValue="selector"
                      inputRef={register}
                    />

                    {item.id && (
                    <Input
                      type="hidden"
                      name={`selector.${item.id}.id`}
                      defaultValue={item.id}
                      inputRef={register}
                    />
                    )}
                    <Box ml={1}>
                      <IconButton
                        onClick={() => removeElement(item, TypeOfField.selector)}
                        aria-label="delete"
                        size="large"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box display="none">
                    <TextField
                      label="Тип поля"
                      name={`selector.${item.id || item.tempId}.${integration}EntityFieldType`}
                      defaultValue={item[`${integration}EntityFieldType`]}
                      variant="outlined"
                      inputRef={register}
                    />
                  </Box>
                  {fieldItems && (
                  <Box>

                    {fieldItems.map((fieldItem: { ID: string; VALUE: string; }) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={2}
                        key={fieldItem.ID}
                      >
                        <Box width="250px">
                          {`ID = ${fieldItem.ID}, VALUE = ${fieldItem.VALUE}`}
                        </Box>
                        <Box ml={4} width="300px">
                          <TextField
                            label="ID значение поля формы FS"
                            name={`selector.${item.id || item.tempId}.${integration}EntityFieldValuesIdToFsFormValues.ID${fieldItem.ID}`}
                            defaultValue={item[`${integration}EntityFieldValuesIdToFsFormValues`]
                                            && item[`${integration}EntityFieldValuesIdToFsFormValues`]![fieldItem.ID]}
                            variant="outlined"
                            inputRef={register}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  )}
                </Box>
              );
            })}
            <Box>
              <ButtonApp
                text="Add selector"
                color="primary"
                variant="contained"
                onClick={() => handleAddElement(TypeOfField.selector)}
                size="large"
                className={styles.btn}
              />
            </Box>
          </Box>
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Box
              top="-10px"
              position="absolute"
              bgcolor="white"
              fontSize="14px"
            >
              Entity Type - Timeline
            </Box>
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <Box
              border={`1px solid ${borderColor}`}
              borderRadius="3px"
              position="relative"
              width="100%"
              p={2}
              mb={2}
            >
              ❗ For each entity (deal, lead, contact), the timeline needs to be configured separately.
            </Box>
            {fieldsTimeline.map((item: ITimelineField) => (
              <Box
                key={item.id || item.tempId}
                display="flex"
                justifyContent="space-beetwen"
                alignItems="center"
                mb={2}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="Название поля из формы FS"
                      SelectProps={{
                        multiple: true,
                        defaultValue: item.fsFormFieldKeyNames,
                      }}
                      onChange={(e) => {
                        setValue(`timeline.${item.id || item.tempId}.fsFormFieldKeyNames`, e.target.value);
                      }}
                            // @ts-ignore
                      inputProps={register(`timeline.${item.id || item.tempId}.fsFormFieldKeyNames`)}
                    >
                      {Object.values(FormFields).map((fieldName: string) => (
                        <MenuItem key={`${item.id || item.tempId}${fieldName}`} value={fieldName}>{fieldName}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <ControlledAutocomplete
                      defaultValue={item.linkToEntity}
                      control={control}
                      name={`timeline.${item.id || item.tempId}.linkToEntity`}
                      options={defaultEntity}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Entity Name in B24"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Box ml={1}>
                    <IconButton onClick={() => removeTimeline(item)} aria-label="delete" size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
                <Input
                  type="hidden"
                  name={`timeline.${item.id || item.tempId}.${integration}EntityFieldType`}
                  defaultValue="timeline"
                  inputRef={register}
                />
                {item.id && (
                <Input
                  type="hidden"
                  name={`timeline.${item.id}.id`}
                  defaultValue={item.id}
                  inputRef={register}
                />
                )}

              </Box>
            ))}
            <Box>
              <ButtonApp
                text="Add Timeline"
                color="primary"
                variant="contained"
                onClick={() => handleAddTimeline()}
                size="large"
                className={styles.btn}
              />
            </Box>
          </Box>
          <Box>
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              className={styles.btn}
            />
          </Box>
        </form>
      )}

      {/* <Box> */}
      {/*  Введите webhook url в блоке &quot;Основные данные&ldquo; */}
      {/* </Box> */}

    </Box>
  );
};
