import React, { useState, useEffect, useCallback } from 'react';
import {
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { getComplexId, getCrmData } from 'src/store/app/selectors';
import { Actions } from 'src/store/requests/user/actions';
import { CRMNames } from 'src/typings/app';
import { getAvailableComplexes } from 'src/store/crosstable/selectors';
import {
  useAppVisibilityBtn,
  useGetAuthData,
  useTypeDisplayedSpinner,
} from 'src/hooks';
import { ButtonApp } from 'src/components/Button';
import { CustomDropdown } from 'src/components/dropdowns/CustomDropdown';
import { appIcon } from 'src/assets';
import { IComplex } from 'src/typings/complexes';
import { pathList } from 'src/route-list';
import { translations } from 'src/constants/translations';
import { Actions as AppActions } from 'src/store/app/actions';
import { AppFavoriteIcon } from 'src/components/AppHeaderFavoriteIcon';
import { useComparePageLink } from 'src/hooks/useComparePageLink';
import { useSetSection } from 'src/hooks/floors/use-set-section';
import { Actions as ActionsFilterParams } from 'src/store/filter/filter-params/actions';
import { Actions as ActionsFloors } from 'src/store/floors/actions';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { ButtonLanguage } from 'src/components/ButtonLanguage';
import { Hidden } from '@mui/material';
import { useStyles } from './styles';
import { useStyles as AppHeaderStyles } from '../AppHeader/styles';
import { IAppHeader, ICurrentComplex } from './types';

export const AppHeaderCrosstable: React.FC<IAppHeader> = ({
  user,
}) => {
  const styles = useStyles({});
  const headerStyles = AppHeaderStyles({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [dev, setDev] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [complex, setComplex] = useState<ICurrentComplex>({
    logo: '',
    name: '',
  });
  const { removeJWT } = useGetAuthData();
  const crmData = useSelector(getCrmData);
  const complexId = useSelector(getComplexId);
  const availableComplexes = useSelector(getAvailableComplexes);
  const { comparePageRouteLink } = useComparePageLink();
  const { filter_favorites } = useAppVisibilityBtn();
  const { setSection } = useSetSection();
  const {
    handleDropToPreviousView,
  } = useTypeDisplayedSpinner();
  const currentSpinner = useSelector(getCurrentSpinner);

  const {
    username,
    developers,
    complexes,
  } = user;
  const [displayedComplexes] = useState(crmData?.crm === CRMNames.bitrix || crmData?.crm === CRMNames.creatio
    ? availableComplexes : (availableComplexes || complexes));
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    removeJWT();
    history.replace('/');
    dispatch(Actions.getUserDataSuccess({}));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (developers) {
      complexes.forEach((comp: any) => {
        if (String(comp.id) === String(complexId)) {
          setComplex({
            logo: comp.logoUri,
            name: comp.title[comp.language],
          });
          if (comp.developers[0]) {
            setDev(comp.developers[0].logoUri);
          }
        }
      });
    }
  }, [developers, complexId]);

  const ComplexesBtn = () => (
    <div className={styles.complexButton}>
      {complex.name}
      <img
        className={styles.complexLogo}
        src={complex.logo || ''}
        alt="complex logo"
      />
    </div>
  );

  const handleComplexLink = useCallback(() => {
    history.push(pathList.profileComplexes);
  }, [history]);

  const handleOpenFavorite = useCallback(() => {
    history.push(`/${complexId}${comparePageRouteLink}`);
  }, [complexId, comparePageRouteLink]);

  const changeComplex = useCallback((compId: string | number) => {
    // Reset current language for complex change flow.
    dispatch(AppActions.setCurrentLang(''));
    dispatch(ActionsFilterParams.clearFilterParameter());
    setSection(null, null);
    if (currentSpinner) {
      dispatch(ActionsFloors.setFromComplex(currentSpinner.name !== 'complex'));
    }
    handleDropToPreviousView();
    window.location.href = `${window.location.origin}/#/${compId}/`;
  }, [currentSpinner]);

  return (
    <>
      <div className={styles.topWrapper}>
        {/* old version of close filters */}
        {/* <ButtonApp onClick={() => handleToggleOpenFilter()} className={styles.userButton}>
          <ChevronLeftIcon fontSize="large" />
        </ButtonApp> */}
        <div className={styles.userWrapper}>
          <ButtonApp onClick={handleClick} className={styles.userButton}>
            <div className={styles.avatar}>
              <img className={styles.avatarImg} src={appIcon.avatar.default} alt="user avatar" />
            </div>
            {username}
          </ButtonApp>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box display="flex" flexDirection="column">
              <ButtonApp
                onClick={handleComplexLink}
                color="secondary"
              >
                {translations.leaveToComplexes}
              </ButtonApp>
              <ButtonApp onClick={logout} color="secondary">
                {translations.logOut}
              </ButtonApp>
            </Box>
          </Popover>
          <Hidden mdDown>
            <ButtonLanguage
              buttonStyles={headerStyles.headerButton}
            />
          </Hidden>
          {filter_favorites && (
            <ButtonApp
              startIcon={<AppFavoriteIcon />}
              className={`${headerStyles.headerButton} `}
              onClick={handleOpenFavorite}
            />
          )}
        </div>
        <img
          style={{
            maxHeight: '50px',
            maxWidth: '150px',
          }}
          src={dev || ''}
          alt="developer logo"
        />
      </div>
      <Box mb={4}>
        <CustomDropdown
          arrowLeft
          isSmall
          button={<ComplexesBtn />}
        >
          {displayedComplexes.map((comp: IComplex) => {
            const {
              id: compId,
              language: compLang,
              logoUri,
              title,
            } = comp;
            if (String(compId) === String(complexId) || !comp.showInCrosstable) {
              return null;
            }
            return (
              <Box
                key={compId}
                py={2}
                px={3}
                mx={-3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={styles.dropItem}
                onClick={() => changeComplex(compId)}
              >
                {title[compLang]}
                <Box display="flex" fontSize="30px" mr={1}>
                  {logoUri ? (
                    <img className={styles.complexLogo} height={30} src={logoUri} alt={title[compLang]} />
                  ) : (
                    <PhotoSizeSelectActualOutlinedIcon fontSize="inherit" />
                  )}
                </Box>
              </Box>
            );
          })}
        </CustomDropdown>
      </Box>
    </>
  );
};
