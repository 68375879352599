import React, { useState } from 'react';

import {
  useBuildType,
  useIsMobile,
  useOpenFilter,
  useTypeDisplayedSpinner,
} from 'src/hooks';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { AppHeaderLogo } from 'src/components/ApartmentPageV2/AppHeader/ComponentLogo';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useStyles as useStylesSpinner } from './styles-spinner';
import { useStyles as useStylesCrossTable } from './styles-crosstable';
import {
  CrossTableTypesRender,
  SpinnerFullSize,
  TypeDisplayedSpinner,
} from './components';

export const FilterMapCrossTable: React.FC = () => {
  const stylesSpinner = useStylesSpinner();
  const stylesCrossTable = useStylesCrossTable();
  const { isOpen } = useOpenFilter();
  const { isCrossTable } = useBuildType();
  const { isMobileScreen } = useIsMobile();
  const { getSelectedViewId } = useTypeDisplayedSpinner();
  const isFloors = TypesOfSpinner.viewFloors === getSelectedViewId;
  const isView2D = TypesOfSpinner.view2d === getSelectedViewId;
  const [isFullSizeFloorSpinner, setFullSizeFloorSpinner] = useState(false);
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerViewData);

  return (
    <div
      className={`${stylesCrossTable.mapWrapper} ${
        isOpen ? stylesSpinner.filterOpen : stylesSpinner.filterClose} ${
        (isFloors && !isFullSizeFloorSpinner) ? stylesSpinner.floorWrapper : ''}`}
    >
      {!isMobileScreen && (
        <>
          <div className={stylesCrossTable.wrapperMapHeader}>
            <div className={stylesCrossTable.wrapperDesktopTypeSelector}>
              <TypeDisplayedSpinner isActive />
            </div>
          </div>
          <div className={`${stylesSpinner.fullscreenView} ${!isOpen && stylesSpinner.fullscreenViewClose}`}>
            <SpinnerFullSize />
          </div>
          {((isCrossTable && !isOpen && !isView2D && (isEmpty(spinnerData?.complex) || (!isEmpty(spinnerData?.complex) && getSelectedViewId !== TypesOfSpinner.viewSpinner)))) && (
            <div className={stylesCrossTable.logo}>
              <AppHeaderLogo isVisible />
            </div>
          )}
        </>
      )}
      <CrossTableTypesRender setFullSizeFloor={setFullSizeFloorSpinner} />
    </div>
  );
};
