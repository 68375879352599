import React, { useRef, useMemo, useState } from 'react';
import { IHouses, IHouse } from 'src/store/crosstable/reducer';
import { useGetAuthData } from 'src/hooks';

import { getHouseHistory } from 'src/api';
import { useQuery } from '@tanstack/react-query';
import { IHouseHistoryItem } from '../typings/house';

export function useGetHouseHistory(id: string|undefined) {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const ref = useRef({
    members: [],
    totalItems: 0,
    hydraView: undefined,
  });
  const [page, setPage] = useState<number>(1);
  const res = useQuery({
    queryKey: ['houseHistory', id, page],
    queryFn: async (): Promise<IHouseHistoryItem[]|null> => {
      if (!id) return null;
      const { data } = await getHouseHistory(jwt, id, page);
      ref.current.totalItems = data['hydra:totalItems'];
      ref.current.hydraView = data['hydra:view'];
      // @ts-ignore
      ref.current.members = [...data['hydra:member']];
      // return [...ref.current.members];
      return data['hydra:member'];
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...res,
    members: ref.current.members as IHouseHistoryItem[],
    loadMore: () => setPage(page + 1),
    setPage,
    totalItems: ref.current.totalItems,
  };
}
