import { IntegrationType } from 'src/typings/app';
import {
  deleteBitrixFormConfigurations, deleteBitrixFormConfigurationTimelines, deleteCreatioFormConfigurations,
  patchBitrixFormConfigurations, patchBitrixFormConfigurationTimelines, patchCreatioFormConfigurations,
  postBitrixFormConfigurations, postBitrixFormConfigurationTimelines,
  postCreatioFormConfigurations,
} from 'src/api';

interface methodsCollectionTypes {
  post: Function,
  patch: Function,
  delete: Function,
}

export const methodsCollection: {feedback: {
    [kay in IntegrationType]: methodsCollectionTypes},
  timeline: {[kay in IntegrationType]: methodsCollectionTypes}
} = {
  feedback: {
    bitrix: {
      post: postBitrixFormConfigurations,
      patch: patchBitrixFormConfigurations,
      delete: deleteBitrixFormConfigurations,
    },
    creatio: {
      post: postCreatioFormConfigurations,
      patch: patchCreatioFormConfigurations,
      delete: deleteCreatioFormConfigurations,
    },
    netHunt: {
      post: postBitrixFormConfigurations,
      patch: patchBitrixFormConfigurations,
      delete: deleteBitrixFormConfigurations,
    },
  },
  timeline: {
    bitrix: {
      post: postBitrixFormConfigurationTimelines,
      patch: patchBitrixFormConfigurationTimelines,
      delete: deleteBitrixFormConfigurationTimelines,
    },
    creatio: { // TODO: Replace this methods by creatio methods.
      post: postBitrixFormConfigurationTimelines,
      patch: patchBitrixFormConfigurationTimelines,
      delete: deleteBitrixFormConfigurationTimelines,
    },
    netHunt: {
      post: postBitrixFormConfigurationTimelines,
      patch: patchBitrixFormConfigurationTimelines,
      delete: deleteBitrixFormConfigurationTimelines,
    },
  },
};
