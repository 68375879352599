import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useGetFilterParams, useSetFilterParams } from 'src/hooks';
import { IRangeAndSetData } from '../../../typings/filter';

export const useCheckboxState = (
  fieldKeyName: string,
  placeholder: string,
  currentData: IRangeAndSetData[],
) => {
  const { filterParams } = useGetFilterParams();
  const { handleWriteToRedux } = useSetFilterParams();
  const [selectedCheckbox, setSelectedCheckbox] = useState<any[]>(filterParams[fieldKeyName]?.data || []);
  const [formatPlaceholder, setPlaceholder] = useState<string>('');
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const handleChangeSelectedCheckbox = useCallback((value: string) => {
    if (filterParams[fieldKeyName]?.data.includes(value)) {
      const transformSelectBox = filterParams[fieldKeyName].data.filter((item: any) => item !== value);
      setSelectedCheckbox(transformSelectBox);

      handleWriteToRedux(fieldKeyName, transformSelectBox);
    } else {
      const transformSelectBox = [...filterParams[fieldKeyName].data, value];
      setSelectedCheckbox(transformSelectBox);

      handleWriteToRedux(fieldKeyName, transformSelectBox);
    }
  }, [filterParams, handleWriteToRedux, fieldKeyName]);

  const handleSelectAll = useCallback(() => {
    const allValues = currentData.map((item) => item.value);
    setSelectedCheckbox(allValues);

    handleWriteToRedux(fieldKeyName, allValues);
  }, [currentData, filterParams, handleWriteToRedux, fieldKeyName]);

  const handleOnClearCheckBox = useCallback(() => {
    handleWriteToRedux(fieldKeyName, []);
  }, [fieldKeyName, handleWriteToRedux]);

  useEffect(() => {
    if (filterParams[fieldKeyName]?.data.length) {
      setPlaceholder(`${placeholder} ${filterParams[fieldKeyName]?.data.join(', ')}`);
      setSelectedCheckbox(filterParams[fieldKeyName]?.data);
    } else {
      setPlaceholder('');
      setSelectedCheckbox([]);
    }

    setDisabled(filterParams?.[fieldKeyName]?.isDisabled || false);
  }, [fieldKeyName, filterParams, placeholder, formatPlaceholder]);

  const setInitValue = useCallback((value: string[] | undefined) => {
    if (value?.length) {
      handleWriteToRedux(fieldKeyName, value || []);
    }
  }, [filterParams, handleWriteToRedux, fieldKeyName]);

  return {
    isDisabled,
    selectedCheckbox,
    formatPlaceholder,
    handleOnClearCheckBox,
    handleChangeSelectedCheckbox,
    handleSelectAll,
    setInitValue,
  };
};
