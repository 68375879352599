import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  iconWrapper: {
    display: 'flex',
    position: 'relative',
  },
  filtersCountWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    width: '15px',
    height: '15px',
    border: '2px solid white',
    borderRadius: '50%',
    position: 'absolute',
    right: '-5px',
    top: '-5px',
    '& span': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '9px',
    },
  },
}));
