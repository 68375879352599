import React from 'react';

export const SvgPlay: React.FC<{playBtnColor?: string, width?: string, height?: string}> = (
  { playBtnColor = 'white', width = 68, height = 68 },
) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="34" cy="34" r="34" fill="currentColor" fillOpacity="0.2" />
      <circle cx="34" cy="34" r="21.5" fill="currentColor" stroke="currentColor" />
      <path
        d="M31 39V29L39.125 34L31 39Z"
        fill={playBtnColor}
        stroke={playBtnColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};
