import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  titleBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      marginTop: '0px',
    },
  },
  titleBlock: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '24px',
      marginTop: '0px',
    },
  },
  headerBlock: {
    padding: '25px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0px 6px 0',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  wrapperOutputCompareData: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    margin: '0px -30px',
    padding: '0px 30px',
    '& > div:first-child': {
      '& > div:first-child': {
        borderRadius: '10px 10px 0px 0px',
      },
    },
    '& > div:last-child': {
      '& > div:first-child': {
        borderRadius: '0px 0px 10px 10px',
      },
    },
    '& > .compareRowData:nth-child(2n)': {
      borderRadius: '10px',
      backgroundColor: '#F6F7F6',
      '& div': {
        [theme.breakpoints.down('md')]: {
          backgroundColor: 'transparent',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px -20px',
      padding: '0px 20px',
      '& > div:nth-child(2n)': {
        backgroundColor: '#F6F7F6',
        borderRadius: '10px',
      },
    },
  },
  mobileShowDiff: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));
