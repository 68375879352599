import React, { useState, useEffect, useCallback } from 'react';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { SvgZoomOut } from 'src/components/CrosstableContent/components/ZoomTable/SvgZoomOut';
import { SvgZoomIn } from 'src/components/CrosstableContent/components/ZoomTable/SvgZoomIn';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

export const ZoomTable: React.FC<{setCurrentValue?: number}> = ({ setCurrentValue }) => {
  const styles = useStyles();
  const [value, setValue] = useState<number>(50);

  useEffect(() => {
    if (setCurrentValue !== undefined) {
      handleChange(setCurrentValue);
    }
  }, [setCurrentValue]);

  useEffect(() => {
    const crossSize = Number(document.documentElement.style.getPropertyValue('--crosstable-size'));
    if (crossSize) {
      setValue(crossSize * 50);
    }
  }, []);

  const handleChange = useCallback((newValue: number) => {
    setValue(newValue);
    document.documentElement.style.setProperty('--crosstable-size', String(newValue / 50));
  }, [document]);

  const handleZoomOut = useCallback(() => {
    if (value > 30) { handleChange(value - 10); }
  }, [value]);

  const handleZoomIn = useCallback(() => {
    if (value < 70) { handleChange(value + 10); }
  }, [value]);

  return (
    <Grid alignItems="center" container spacing={2} className={styles.zoomWrapper}>
      <Grid item className={styles.zoom}>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={handleZoomOut}
        >
          <SvgZoomOut />
        </Box>
      </Grid>
      <Grid item xs>
        <Slider
          min={30}
          step={10}
          max={70}
          value={value}
          aria-labelledby="discrete-slider"
          marks
          onChange={(e, newValue) => {
            if (!Array.isArray(newValue)) {
              handleChange(newValue);
            }
          }}
        />
      </Grid>
      <Grid item className={styles.zoom}>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={handleZoomIn}
        >
          <SvgZoomIn />
        </Box>
      </Grid>
    </Grid>

  );
};
