import { useAnimationStyles, useSpinnerStyles } from 'src/components/ViewSpinner/components/RenderSvg/styles';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { IGlobalPolygonConfig } from 'src/typings/app';
import { useBuildType } from 'src/hooks';
import { usePolygonConfig } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { useSelector } from 'react-redux';
import { getSpinnerDetailInfo } from 'src/store/requests/spinner-views/selector';
import { requestSelectors } from 'src/store/requests/selectors';

export const useSpinnerStyle = () => {
  const { isCrossTable } = useBuildType();
  const { globalPolygonConfig } = usePolygonConfig();
  const detailInfo = useSelector(getSpinnerDetailInfo);
  const getPopupConfig = useSelector(requestSelectors.app.getPopupConfig);
  const getSpinnerStyles = (nameId: string) => {
    const styles = useSpinnerStyles({
      ...globalPolygonConfig,
      isCrossTable,
      static_body_color: ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
        && detailInfo
        && detailInfo?.placement
        && detailInfo?.placement[nameId]
      ) ? `${
          detailInfo.placement[nameId][isCrossTable ? (
            ListOfKeysFilterAllData.placementStatusCrossTableColor
          ) : ListOfKeysFilterAllData.placementStatusColor]
        }` : `${globalPolygonConfig?.static_body_color}`,
      hover_body_color: ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
        && detailInfo
        && detailInfo?.placement
        && detailInfo?.placement[nameId]
      ) ? `${
          detailInfo.placement[nameId][isCrossTable ? (
            ListOfKeysFilterAllData.placementStatusCrossTableColor || ListOfKeysFilterAllData.placementStatusColor
          ) : ListOfKeysFilterAllData.placementStatusColor]
        } !important` : `${globalPolygonConfig?.hover_body_color} !important`,
    });

    return { styles };
  };

  const getSpinnerAnimatedStyles = (nameId: string) => {
    return useAnimationStyles({
      ...globalPolygonConfig,
      static_body_color: ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
        && detailInfo
        && detailInfo?.placement
        && detailInfo?.placement[nameId]
      ) ? `${
          detailInfo.placement[nameId][isCrossTable ? (
            ListOfKeysFilterAllData.placementStatusCrossTableColor
          ) : ListOfKeysFilterAllData.placementStatusColor]
        }` : `${globalPolygonConfig?.static_body_color}`,
    } as IGlobalPolygonConfig);
  };

  return {
    getSpinnerStyles,
    getSpinnerAnimatedStyles,
  };
};
