export const primaryColor = '#6C59E6';
export const primaryColorLight = '#7b69ea';
export const placeholderColor = '#9E9E9E';
export const greyColor = '#B6B6B6';
export const purpleColor = '#6C59E6';
export const whiteColor = '#FFF';
export const fontBlackColor = '#201E2A';
export const borderColor = '#E9E6EF';
export const containerColor = '#F4F6F9';
export const textColor = '#212121';

export const crossTableTitle = 32;
export const crossTableTitleSpace = 20;
export const crossTableTitleItemSize = 32;
export const crossTableTitleItemMargin = 2;
export const crossTableBigItemHeight = 50;
export const crossTableBigItemWidth = 160;

// createTheme
export const muiXl = {
  breakPointXlSize: 1921,
  fontSize: '1.3vh',
};
