import React from 'react';

import { IPlacementWindowSvgDataAttr, ISpinnerSvgDataChildrenAttr } from 'src/typings/spinner';
import { LayoutApartmentItem } from 'src/components/LayoutApartmentItem';
import {
  IFilterAllData, ListOfKeysFilterAllData, PlacementDisplayedType, PlacementShowModule,
} from 'src/typings/filter';
import { useAppVisibilityBtn, useBuildType } from 'src/hooks';
import { useGetPlacementPopoverData } from './hooks';
import { useSpinnerStyles } from './styles';

interface IPopoverInfo {
  attr?: ISpinnerSvgDataChildrenAttr | IPlacementWindowSvgDataAttr;
  placement?: IFilterAllData;
  handleClose: () => any;
}

export const PopoverInfo: React.FC<IPopoverInfo> = React.memo(({
  attr,
  placement,
  handleClose,
}) => {
  const styles = useSpinnerStyles({});
  const { isCrossTable } = useBuildType();
  const { property }: { property: IFilterAllData } = useGetPlacementPopoverData(attr, placement);
  const { filter_favorites } = useAppVisibilityBtn();
  const isDisabledLink = property && property[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.hide;
  const isFavEnabled = (): boolean => {
    if (filter_favorites) {
      if (!isCrossTable) {
        return !isDisabledLink;
      }
      return true;
    }
    return false;
  };

  return (
    <div>
      {property && (
        <LayoutApartmentItem
          withSizeLimits
          withCloseButton
          withMoreButton
          isDisabledLink={isCrossTable ? false : isDisabledLink}
          isShowFavIcon={isFavEnabled()}
          wrapperStyle={styles.mapPopover}
          placementDisplayedType={isCrossTable ? PlacementDisplayedType.filter : PlacementDisplayedType.popup}
          property={property}
          onClosePopover={handleClose}
        />
      )}
    </div>
  );
});
