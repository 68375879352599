import { getNetHuntPlacementPropertiesFields } from 'src/api';
import { useState } from 'react';
import { useGetAuthData } from 'src/hooks';
import { IProject } from 'src/typings/complexes';
import { INetHuntPlacementPropertiesFields } from 'src/components/profileComponents/Integrations/Nethunt/types';

export const useGetPlacementPropertiesFields = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [placementPropertiesFields, setPlacementPropertiesFields] = useState<INetHuntPlacementPropertiesFields[]>();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const getPlacementPropertiesFields = () => {
    setIsLoading(true);
    getNetHuntPlacementPropertiesFields(jwt)
      .then((res: {data: INetHuntPlacementPropertiesFields[]}) => {
        setPlacementPropertiesFields(res.data);
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    placementPropertiesFields,
    getPlacementPropertiesFields,
  };
};
