import React, { useRef, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import { AppSpinner } from 'src/components/AppSpinner';
import { isString } from 'lodash';
import { IApartmentFloor } from './types';
import { useGetFloorsSpinner } from './hooks';
import { useStyles } from './styles';

export const ApartmentFloor: React.FC<IApartmentFloor> = ({
  idApartment,
  idFloor,
}) => {
  const [computedHeight, setComputedHeight] = useState<number|null>(null);
  const styles = useStyles({});
  const imgRef = useRef<HTMLImageElement>(null);
  const { spinnerFloor } = useGetFloorsSpinner(idFloor);

  const handleOnLoad = useCallback(() => {
    if (imgRef.current?.height) {
      setComputedHeight(imgRef.current?.height > 250 ? 250 : imgRef.current?.height);
    }
  }, [imgRef.current]);

  if (spinnerFloor) {
    return (
      <>
        <Box
          position="absolute"
          top={0}
          left={-9999999}
        >
          <img
            ref={imgRef}
            width={210}
            src={window.Spinner.namePattern(isString(spinnerFloor.data[0]) ? spinnerFloor.data[0] : spinnerFloor.data[0].src)}
            alt="width detector"
            onLoad={handleOnLoad}
          />
        </Box>
        {computedHeight && (
          <Box className={styles.spinnerFloor}>
            <AppSpinner
              // displayIds={idApartment ? [idApartment] : ['0']}
              spinnerData={spinnerFloor}
              spinnerHeight={computedHeight}
              roseInTop
              type="floor"
            />
          </Box>
        )}
      </>
    );
  }

  return null;
};
