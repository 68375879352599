import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { range } from 'lodash';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { ButtonApp } from 'src/components/Button';
import { ControlledAutocomplete } from 'src/components/ControledAutocomplete';
import { borderColor } from 'src/constants/styles';
import { translations } from 'src/constants/translations';
import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { getBitrixFields, patchBitrixFields } from 'src/api';
import { IntegrationType } from 'src/typings/app';
import { toUpperCase } from 'src/helpers/toUpperCase';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from '../Bitrix/styles';

interface IBitrixFields {
  integration: IntegrationType;
  fields: any;
  entityValues: { [key: string]: any } | null;
  saveData: (token: string, id: string, data: any) => Promise<any>;
}

const schema = yup.object().shape({

});

const fieldsArray = [
  {
    label: 'Placement Type',
    value: 'placementType',
    subValue: 'title',
  },
  {
    label: 'Placement Planning Type',
    value: 'planningType',
  },
  {
    label: 'Numeration',
    value: 'numeration',
  },
  {
    label: 'Floors',
    value: 'floors',
    subValue: 'numeration',
  },
  {
    label: 'Section',
    value: 'section',
    subValue: 'numeration',
  },
  {
    label: 'House',
    value: 'house',
    subValue: 'numeration',
  },
  {
    label: 'Complex',
    value: 'complex',
    subValue: 'title',
  },
  {
    label: 'Rooms',
    value: 'rooms',
  },
  {
    label: 'Bedrooms',
    value: 'bedrooms',
  },
  {
    label: 'Bathrooms',
    value: 'bathrooms',
  },
  {
    label: 'Tag',
    value: 'tag',
  },
  {
    label: 'Note',
    value: 'note',
  },
  {
    label: 'Total Area',
    value: 'totalArea',
  },
  {
    label: 'Living Area',
    value: 'livingArea',
  },
  {
    label: 'Balcony Area',
    value: 'balconyArea',
  },
  {
    label: 'Total Price',
    value: 'totalPrice',
  },
  {
    label: 'Total Price Alternative',
    value: 'totalPriceAlternative',
  },
  {
    label: 'Price per sq m',
    value: 'pricePerSqM',
  },
  {
    label: 'Price per sq m Alternative',
    value: 'pricePerSqMAlternative',
  },
  {
    label: 'Status',
    value: 'placementStatus',
    subValue: 'crosstableName',
  },
];

export const Fields: React.FC<IBitrixFields> = ({
  integration, fields, entityValues, saveData,
}) => {
  const {
    handleSubmit, control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const onSubmit = async (data:any) => {
    const res = await saveData(jwt, fields.id, data);
  };

  const arrayOfKeys = entityValues ? Object.keys(entityValues) : [];
  return (
    <Box py={5}>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        {`${toUpperCase(integration)} deal fields mapping`}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!fields && !entityValues && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress style={{ color: 'black' }} size="24px" />
        </Box>
        )}
        {fields && entityValues && (
        <>
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Grid container spacing={2}>
              {fieldsArray.map((item) => (
                <Grid key={item.label} item xs={3}>
                  <ControlledAutocomplete
                    control={control}
                    name={`${item.value}${item.subValue ? `.${item.subValue}` : ''}`}
                    options={arrayOfKeys}
                    getOptionLabel={(option: any) => {
                      return option && `${(entityValues[option] && entityValues[option].listLabel) || entityValues[option]?.title} - ${option}`;
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={item.label}
                        variant="outlined"
                      />
                    )}
                    defaultValue={(item.subValue && fields[item.value]) ? fields[item.value][item.subValue] : fields[item.value]}
                  />
                </Grid>
              ))}

              {range(10).map((item) => (
                <Grid key={item} item xs={3}>
                  <ControlledAutocomplete
                    control={control}
                    name={`row${item}`}
                    options={Object.keys(entityValues)}
                    getOptionLabel={(option: any) => {
                      return option && `${(entityValues[option] && entityValues[option].listLabel) || entityValues[option]?.title} - ${option}`;
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={`Row${item}`}
                        variant="outlined"
                      />
                    )}
                    defaultValue={(fields[`row${item}`] && entityValues[fields[`row${item}`]]) ? fields[`row${item}`] : null}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              className={styles.btn}
            />
          </Box>
        </>
        )}
      </form>

    </Box>
  );
};
