import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const galleryPadding = 24;
export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperGallery: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      maxWidth: '68%',
      flexGrow: '2',
      position: 'sticky',
      top: '-32px',
      padding: `${galleryPadding}px`,
      // paddingBottom: '20px',
      backgroundColor: theme.palette.background.default,
      borderRadius: '8px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 0 20px!important',
    },
    [theme.breakpoints.down('lg')]: {
      position: 'static',
      maxWidth: '100%',
      marginBottom: '32px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0 28px 0 28px!important',
    },
    '& .MuiTabPanel-root': {
      padding: '0',
    },
    '&.wide-view': {
      padding: '0 0 24px 0',
      // '& $galleryTab': {
      //   height: '521px',
      // },
    },
    [theme.breakpoints.up('xl')]: {
      top: '-96px',
      padding: '32px',
      borderRadius: '12px',
      '&.wide-view': {
        padding: '0 0 32px 0',
      },
    },
    [theme.breakpoints.up(3000)]: {
      top: '-96px',
      padding: `${galleryPadding * 2}px`,
      borderRadius: '20px',
      '&.wide-view': {
        padding: `0 0 ${galleryPadding * 2}px 0`,
      },
    },
  },
  tabs: {
    marginBottom: '16px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: '32px',
    },
  },
  galleryControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      height: '44px',
      [theme.breakpoints.up('xl')]: {
        height: '56px',
      },
      [theme.breakpoints.up(3000)]: {
        height: '88px',
      },
    },
  },
  galleryControlsInner: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.up('xl')]: {
      borderRadius: '12px',
    },
    [theme.breakpoints.up(3000)]: {
      borderRadius: '20px',
    },
    '& button': {
      fontSize: '14px',
      lineHeight: '28px',
      padding: '5px 20px',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.background.paper}`,
      textTransform: 'none',
      width: '100%',
      whiteSpace: 'nowrap',
      '&:hover': {
        border: `1px solid ${theme.palette.background.paper}`,
        backgroundColor: 'unset',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '18px',
        padding: '10px 30px',
        borderRadius: '12px',
      },
      [theme.breakpoints.up(3000)]: {
        fontSize: '28px',
        padding: '10px 30px',
        borderRadius: '20px',
      },
    },
    '& button.active': {
      border: `1px solid ${theme.palette.primary.main}!important`,
    },
  },
  wrapperGallerySection: {
    display: 'flex',
    flex: '1',
    height: '100%',
    width: '100%',
    '& .image-gallery-bullets': {
      [theme.breakpoints.down('md')]: {
        bottom: '8px',
      },
      '& .image-gallery-bullet': {
        transform: 'none',
        padding: '3px',
        margin: '0 2.5px',
        backgroundColor: theme.palette.grey['400'],
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
          bottom: '8px',
        },
        [theme.breakpoints.up('xl')]: {
          padding: '5px',
          margin: '0 5px',
        },
        [theme.breakpoints.up(3000)]: {
          padding: '7px',
          margin: '0 5px',
        },
        '&.active': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    '& .image-gallery-content': {
      '& .image-gallery-slide-wrapper': {
        borderRadius: '10px',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.background.default}`,
        [theme.breakpoints.up('xl')]: {
          borderRadius: '12px',
        },
        [theme.breakpoints.up(3000)]: {
          borderRadius: '16px',
        },
        [theme.breakpoints.down('md')]: {
          padding: '0px 0',
        },
      },
    },
    '& .image-gallery-slides': {
      [theme.breakpoints.between('xs', 'md')]: {
        aspectRatio: '1 / 1',
        objectFit: 'contain',
      },
    },
    '& .image-gallery-icon.image-gallery-right-nav svg': {
      rotate: '180deg',
    },
    '& .image-gallery-icon': {
      color: theme.palette.primary.main,
      [theme.breakpoints.up('xl')]: {
        margin: '0 32px!important',
      },
      [theme.breakpoints.up(3000)]: {
        margin: '0 40px!important',
      },
      '& svg': {
        [theme.breakpoints.up('xl')]: {
          width: '28px',
          height: '28px',
        },
        [theme.breakpoints.up(3000)]: {
          width: '48px',
          height: '48px',
        },
      },
    },
    [theme.breakpoints.up('xl')]: {
      '& .app-slide-wrapper-v2': {
        borderRadius: '12px',
      },
    },
    [theme.breakpoints.up(3000)]: {
      '& .app-slide-wrapper-v2': {
        borderRadius: '16px',
      },
    },
  },
  galleryTab: {
    gap: '8px',
    // backgroundColor: '#fff',
    borderRadius: '8px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'flex-start',
    // justifyContent: 'flex-start',
    height: 'calc(100vh - 275px);',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
      gap: '0',
      height: '100%',
    },
    '& .spinner_place-v2': {
      borderRadius: '8px 8px 0 0',
    },
    [theme.breakpoints.up('xl')]: {
      gap: '12px',
      height: 'calc(100vh - 550px);',
      '& .spinner_place-v2': {
        borderRadius: '12px 12px 0 0',
      },
    },
    [theme.breakpoints.up(3000)]: {
      gap: '16px',
      height: 'calc(100vh - 550px);',
      '& .spinner_place-v2': {
        borderRadius: '16px 16px 0 0',
      },
    },
    '& .gallery-playcanvas-v2': {
      border: 'none',
      width: '100% !important',
    },
    '&.spinner-wide-view': {
      height: `calc(100vh - (275px - ${galleryPadding}px))!important`,
      [theme.breakpoints.up('xl')]: {
        height: 'calc(100vh - (550px - 32px))!important',
        '& .gallery-playcanvas-v2': {
          borderRadius: '12px 12px 0 0',
        },
      },
      [theme.breakpoints.up(3000)]: {
        height: `calc(100vh - (550px - ${galleryPadding * 2}px))!important`,
        '& .gallery-playcanvas-v2': {
          borderRadius: '16px 16px 0 0',
        },
      },
      '& .gallery-playcanvas-v2': {
        borderRadius: '8px 8px 0 0',
      },
    },
  },
  previewIcon: {
    width: '100%',
    height: '100%',
  },
  zoomButton: {
    bottom: '20px',
    right: '20px',
    background: theme.palette.background.default,
    minWidth: 'auto',
    width: '44px',
    height: '44px',
    padding: '10px',
    zIndex: 11,
    '&:hover': {
      background: theme.palette.background.default,
      textDecoration: 'none',
      opacity: '.7',
    },
    [theme.breakpoints.down('md')]: {
      background: 'none',
      bottom: '-7px',
      right: '-7px',
      padding: '0',
    },
    [theme.breakpoints.up('xl')]: {
      width: '56px',
      height: '56px',
      borderRadius: '12px',
      bottom: '32px',
      right: '32px',
      '& svg': {
        width: '36px',
        height: '36px',
      },
    },
    [theme.breakpoints.up(3000)]: {
      width: '88px',
      height: '88px',
      borderRadius: '20px',
      bottom: '40px',
      right: '40px',
      '& svg': {
        width: '48px',
        height: '48px',
      },
    },
  },
  zoomStyle: {
    width: '24px',
    height: '24px',
  },
  svg: {
    position: 'absolute',
    left: 0,
    top: '10px',
    marginTop: '-10px',
    marginBottom: '-10px',
  },
  thumbnailInner: {
    '& .appFillStyles': {
      fill: theme.palette.primary.main,
    },
    '& .appStrokeStyles': {
      stroke: theme.palette.primary.main,
    },
  },
  galleryImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));
