import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import {
  CreatioAuthorizationForm,
} from 'src/components/profileComponents/Integrations/Creatio/components/CreatioAuthorizationForm';
import { IProject } from 'src/typings/complexes';
import { CreatioOauth } from 'src/components/profileComponents/Integrations/Creatio/components/CreatioOauth';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const CreatioAuthorization: React.FC<{project: IProject | null}> = ({
  project,
}) => {
  const [tabIndex, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const theme = useTheme();

  function TabPanel(props: TabPanelProps) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  return (
    <>
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab
            label="Login and password"
            {...a11yProps(0)}
          />
          <Tab
            label="OAuth"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <SwipeableViews
        index={tabIndex}
        value={tabIndex}
        onChangeIndex={handleChangeIndex}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <CreatioAuthorizationForm project={project} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <CreatioOauth project={project} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};
