import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { locals } from 'src/constants/locals';
import { ListOfDropdown } from 'src/typings/filter';
import { useGetComplexConfig } from 'src/hooks/app';
import { DropdownSingleFloors } from 'src/components/DropdownsFloors/dropdown-single';
import { getMarkups } from 'src/store/requests/spinner-views/selector';
import { Actions as ActionsFloors } from 'src/store/floors/actions';
import { IMarkUps, IFirstSpinner } from 'src/typings/spinner';
import { getSelectedHouse } from 'src/store/floors/selectors';
import { useTranslations } from 'src/hooks/translataions';
import { isString } from 'lodash';
import { useStyles } from './styles';
import { IMobileMarkup } from './types';

export const MobileMarkup: React.FC<IMobileMarkup> = ({
  currentSection,
  renderMarkup,
  handleSelectSection,
}) => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const houseMarkups: IMarkUps | any = useSelector(getMarkups);
  const selectedHouse: IFirstSpinner | null = useSelector(getSelectedHouse);
  const { complexConfigEntityInnerDataRelation } = useGetComplexConfig();
  const { getTranslations } = useTranslations();
  const listOfHouses = useMemo(() => Object.keys(houseMarkups.house).reduce((acc: any, item) => {
    if (complexConfigEntityInnerDataRelation?.house[item] !== undefined
        && houseMarkups.house[item]?.svg
      && houseMarkups.house[item]?.svg[0]?.attr['markup-id']) {
      acc.push({
        key: item,
        text: complexConfigEntityInnerDataRelation?.house[item],
        value: item,
      });
    }
    return acc;
  }, []), [houseMarkups, complexConfigEntityInnerDataRelation]);

  const listOfSections = useMemo(() => renderMarkup?.svg?.map((item) => {
    if (complexConfigEntityInnerDataRelation?.section[item.attr['markup-id']] !== undefined) {
      return {
        key: item.attr['markup-id'],
        text: complexConfigEntityInnerDataRelation?.section[item.attr['markup-id']],
        value: complexConfigEntityInnerDataRelation?.section[item.attr['markup-id']],
      };
    }

    return item.attr['markup-id'];
  }) || [], [renderMarkup, complexConfigEntityInnerDataRelation]);

  const houseTitle = getTranslations('placement.house');

  const handleChangeHouse = useCallback((event, data) => {
    const { value } = data;

    if (isString(value)) {
      dispatch(ActionsFloors.setSelectedHouse({
        name: 'house',
        'name-id': value,
      }));
      dispatch(ActionsFloors.setSelectedFloor(undefined));
      handleSelectSection(
        houseMarkups.house[value]?.svg[0]?.attr.markup,
        houseMarkups.house[value]?.svg[0]?.attr['markup-id'],
      );
    }
  }, [dispatch, houseMarkups]);

  const handleChangeSection = useCallback((event, data) => {
    const { value, options } = data;
    const getIdFloor = options?.find((item: any) => item.value === value);

    if (renderMarkup?.svg?.length) {
      handleSelectSection(
        renderMarkup.svg[0].attr.markup,
        getIdFloor?.key as string,
        true,
      );
    }
  }, [renderMarkup]);

  return (
    <div className={`markUpMobile ${styles.markUpMobile}`}>
      {selectedHouse && listOfHouses.length > 1 && (
      <DropdownSingleFloors
        dropdownInfo={{
          attribute: locals.filter.house,
          type: ListOfDropdown.dropdownSelectList,
          data: listOfHouses,
        }}
        text={
          `${houseTitle.charAt(0).toUpperCase() + houseTitle.slice(1)} ${
            selectedHouse['name-id']
            && complexConfigEntityInnerDataRelation?.house?.[selectedHouse['name-id']]}`
        }
        value={selectedHouse['name-id'] || undefined}
        onChange={handleChangeHouse}
      />
      )}
      {renderMarkup?.svg && renderMarkup.svg.length > 1 && (
      <DropdownSingleFloors
        dropdownInfo={{
          attribute: locals.filter.section,
          type: ListOfDropdown.dropdownSelectList,
          data: listOfSections,
        }}
        text={
          `${getTranslations(locals.appTranslations.appSectionTitle)} ${
            currentSection['markup-id']
            && complexConfigEntityInnerDataRelation?.section?.[currentSection['markup-id']]}`
        }
        value={currentSection['markup-id'] || undefined}
        onChange={handleChangeSection}
      />
      )}
    </div>
  );
};
