import { ActionTypes } from 'src/store/requests/filter/actions';
import { IStatus } from 'src/typings/complexes';
import { IFilterData, IBitrixDealData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { IAction } from 'src/typings/index';
import { ICreatioDeal, INetHuntDeal } from 'src/store/crosstable/reducer';
import { IPlacementType } from 'src/store/type-displayed-spinner/types';

export interface IFilterStateReducer {
  isLoading: boolean;
  error: Error | null;
  data: IFilterData | undefined;
}

export const initialState: IFilterStateReducer = {
  isLoading: true,
  error: null,
  data: undefined,
};

export const filterReducer = (
  state = initialState,
  { type, payload }: IAction,
): IFilterStateReducer => {
  switch (type) {
    case ActionTypes.GET_FILTER_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypes.GET_FILTER_DATA_UPDATE: {
      return {
        ...state,
      };
    }

    case ActionTypes.GET_FILTER_DATA_RESET: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.GET_FILTER_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case ActionTypes.GET_FILTER_DATA_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case ActionTypes.SET_FILTER_TYPE_PLACEMENT_TO_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.UPDATE_PLACEMENT: {
      const { data, lang }: {
        data: {
          id: string|number;
          placementType: IPlacementType;
          placementStatus: IStatus;
          bitrixDeals: IBitrixDealData[];
          creatioOpportunities: ICreatioDeal[];
          netHuntOpportunities: INetHuntDeal[];
        },
        lang: string
      } = payload;
      const {
        id,
        placementType,
        placementStatus,
        bitrixDeals,
        creatioOpportunities = [],
        netHuntOpportunities = [],
      } = data;
      const tempData: IFilterData = { ...state.data };

      if (tempData) {
        const placement = { ...tempData[placementType.title[lang]].filter_all_data[id] };
        placement.bitrix_deals = bitrixDeals;
        placement.creatioOpportunities = creatioOpportunities;
        placement.netHuntOpportunities = netHuntOpportunities;
        placement[ListOfKeysFilterAllData.statusId] = String(placementStatus.id);
        placement[ListOfKeysFilterAllData.placementStatusColor] = placementStatus.color;
        placement[ListOfKeysFilterAllData.placementStatusCrossTableColor] = placementStatus.crosstableColor;
        placement[ListOfKeysFilterAllData.placementStatusCrossTableName] = placementStatus.crosstable_name[lang];
        placement[ListOfKeysFilterAllData.placementStatus] = placementStatus.crosstable_name[lang];
        placement[ListOfKeysFilterAllData.placementStatusModuleName] = placementStatus.module_name[lang];
        placement[ListOfKeysFilterAllData.placementCanBeSet] = placementStatus.amoCanBeSetForDeal ? '1' : '0';

        tempData[placementType.title[lang]].filter_all_data[id] = placement;
      }
      return {
        ...state,
        data: tempData,
      };
    }

    default:
      return state;
  }
};
