import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { patchCreatioProject, PostCreatioProject, postCreatioProject } from 'src/api';
import { useGetAuthData } from 'src/hooks';
import { IProject } from 'src/typings/complexes';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const schema = yup.object().shape({
  login: yup.string(),
  password: yup.string(),
});

export const CreatioAuthorizationForm: React.FC<{project: IProject | null}> = ({
  project,
}) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [error, setError] = useState('');
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const handleClose = () => {
    setError('');
  };
  const onSubmit = async (data: PostCreatioProject) => {
    let res;
    if (project?.id) {
      res = await patchCreatioProject(jwt, String(project.id), data);
    } else {
      res = await postCreatioProject(jwt, data);
    }
    if (!res.ok) {
      setError(res.data['hydra:description']);
    }
  };
  return (
    <Box>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        Авторизация
      </Box>
      <Box fontSize="14px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={4} display="flex" alignItems="center">
            <Box width="260px" mr={3}>
              Login
            </Box>
            <Box width="260px">
              <TextField
                id="login"
                size="small"
                variant="outlined"
                name="login"
                inputRef={register}
                defaultValue={project && (project.login)}
              />
            </Box>
          </Box>
          <Box mb={4} display="flex" alignItems="center">
            <Box width="260px" mr={3}>
              Password
            </Box>
            <Box width="260px">
              <TextField
                id="password"
                size="small"
                variant="outlined"
                name="password"
                inputRef={register}
                defaultValue={project && (project.password)}
              />
            </Box>
          </Box>
          <Box width="260px">
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              disabled={!formState.isValid}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};
