import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperButton: (props: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    color: props.filterBackgroundColor,
    backgroundColor: `${props.primaryColor}e6`,
    fontSize: '13px',
    padding: '10px',
    whiteSpace: 'nowrap',
    '& .appFillStyles': {
      fill: props.filterBackgroundColor,
    },
    '& .appStrokeStyles': {
      stroke: props.filterBackgroundColor,
    },
  }),
  hiddenText: {
    maxWidth: '100%',
    '& .MuiButton-startIcon': {
      marginLeft: '0px',
      marginRight: '0px',
    },
  },
  textBlock: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    opacity: 1,
    overflow: 'hidden',
  },

  wrapperButtonHouse: {
    padding: '0px !important',
  },
  activeBtn: (props: any) => ({
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '4px 0px 0px 4px',
    '&:hover': {
      background: `${props.primaryColor}f2`,
    },
  }),
}));
