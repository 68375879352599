import { useSelector } from 'react-redux';
import {
  useCallback, useEffect, useState,
} from 'react';
import _ from 'lodash';

import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { useGetQueryParams } from 'src/hooks';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';

export const useGetInitialData = () => {
  // This part we used if url has some query parameters
  const schemaQuery: string[] = ['types', 'listIds'];
  const { listQueries } = useGetQueryParams(schemaQuery, false);
  const typeOfPropertySelector = useSelector(getSelectedPlacement);

  const listArray = listQueries[schemaQuery[1]] ? listQueries[schemaQuery[1]].split(',') : [];
  const [listOfComparePlacement, setListOfComparePlacement] = useState<IFilterAllData[]>([]);

  const getAllData = useSelector(getFilterInitialAllData);

  useEffect(() => {
    setListOfComparePlacement(listArray.reduce((acc: any, cur: string) => {
      if (typeOfPropertySelector && getAllData?.[typeOfPropertySelector]?.filter_all_data[cur]) {
        const currentValue: IFilterAllData = getAllData?.[typeOfPropertySelector]?.filter_all_data[cur];
        const excludeKeysFromCurrentValue = _.pickBy(currentValue, (v, k) => {
          if (
            k !== ListOfKeysFilterAllData.placementStatusShowInModule
            && k !== ListOfKeysFilterAllData.placementPdf
          ) {
            return k;
          }
          return null;
        });

        return [...acc, excludeKeysFromCurrentValue];
      }

      return acc;
    }, []));
  }, [typeOfPropertySelector, getAllData]);

  const removeItemFromCompare = useCallback((id: string) => {
    setListOfComparePlacement(listOfComparePlacement.filter((item) => item.id !== id));
  }, [listOfComparePlacement]);

  return {
    listOfComparePlacement,
    removeItemFromCompare,
  };
};
