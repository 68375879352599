import { action } from 'src/store/common';
import { IComplex, IStatus } from 'src/typings/complexes';
import { IHouses, IBitrixDeal, ICreatioDeal } from './reducer';

export const ActionTypes = {
  SET_COMPLEX: '[CROSSTABLE]SET_COMPLEX',
  SET_STATUSES: '[CROSSTABLE]SET_STATUSES',
  SET_HOUSES: '[CROSSTABLE]SET_HOUSES',
  SET_BITRIX_DEAL: '[CROSSTABLE]SET_BITRIX_DEAL',
  SET_CREATIO_DEAL: '[CROSSTABLE]SET_CREATIO_DEAL',
  SET_NETHUNT_DEAL: '[CROSSTABLE]SET_NETHUNT_DEAL',
  SET_DEFAULT_PLACEMENT_TYPE: '[CROSSTABLE]SET_DEFAULT_PLACEMENT_TYPE',
  SET_AVAILABLE_COMPLEXES: '[CROSSTABLE]SET_AVAILABLE_COMPLEXES',
};

export const Actions = {
  setComplex: action<IComplex>(ActionTypes.SET_COMPLEX),
  setStatuses: action<IStatus[]>(ActionTypes.SET_STATUSES),
  setHouses: action<IHouses>(ActionTypes.SET_HOUSES),
  setBitrixDeal: action<IBitrixDeal>(ActionTypes.SET_BITRIX_DEAL),
  setCreatioDeal: action<ICreatioDeal>(ActionTypes.SET_CREATIO_DEAL),
  setNetHuntDeal: action<ICreatioDeal>(ActionTypes.SET_NETHUNT_DEAL),
  setDefaultPlacementType: action<string|null>(ActionTypes.SET_DEFAULT_PLACEMENT_TYPE),
  setAvailableComplexes: action<IHouses>(ActionTypes.SET_AVAILABLE_COMPLEXES),
};
