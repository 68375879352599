import React from 'react';

import { useApartmentsSorting } from 'src/hooks/layouts/useApartmentsSorting';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { Section } from '../Section';
import { useStyles } from './styles';
import { ISectionsList } from './types';
import { useSplitHouseToSections } from './useSplitHouseToSections';

export const SectionsList: React.FC<ISectionsList> = React.memo(({
  floorList,
  houseName,
  houseData,
  houseKeys,
}) => {
  const styles = useStyles({});
  const { sectionKey } = useSplitHouseToSections(houseData);
  const { sortApartments } = useApartmentsSorting();
  const getName = (section: string): string => {
    return `${houseKeys.length > 1 ? sectionKey.length > 1 ? `${houseName} /` : houseName : ''} ${sectionKey.length > 1 ? section : ''}`;
  };
  return (
    <div id="sectionListWrapper" className={styles.sectionListWrapper}>
      <>
        {sectionKey.sort().map((section) => {
          return (
            <Section
              key={section}
              floorList={floorList}
              sectionName={getName(section)}
              sectionData={sortApartments<IFilterAllData>(houseData[section], ListOfKeysFilterAllData.placementNumeration)}
            />
          );
        })}
      </>
    </div>
  );
});
