import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { useApartmentsSorting } from 'src/hooks/layouts/useApartmentsSorting';

export const useSortingByKey = () => {
  const { sortApartments } = useApartmentsSorting();
  const getUniqueByKey = <T extends any>(arr: T[], key: ListOfKeysFilterAllData) => {
    // @ts-ignore
    return [...new Set(arr.map((item) => (Array.isArray(item[key]) ? item[key][0] : item[key])))];
  };

  const getSortedByKey = <T extends any>(uniqueKeys: string[], data: T[], uniqKeyName: ListOfKeysFilterAllData, key: ListOfKeysFilterAllData): T[] => {
    const result: any = [];
    uniqueKeys.forEach((uniqueKey) => {
      // @ts-ignore
      const allItemsByUniqKey = data.filter((value) => (Array.isArray(value[uniqKeyName]) ? value[uniqKeyName][0] : value[uniqKeyName]) === uniqueKey);
      const sortedApartmentsByUniqKey = sortApartments(allItemsByUniqKey, key);
      result.push(...sortedApartmentsByUniqKey);
    });
    return result;
  };
  return {
    getUniqueByKey,
    getSortedByKey,
  };
};
