import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { IComplex } from 'src/typings/complexes';
import { IUsers, TypeOfRole } from 'src/typings/user';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { SearchField } from 'src/components/SearchField';
import { deleteUsersIdData } from 'src/api';
import { useGetAuthData } from 'src/hooks';
import { appIcon } from 'src/assets';
import { useStyles } from '../styles';

export const ManagersList: React.FC<IComplex> = ({ id, users }) => {
  const styles = useStyles({});
  const history = useHistory();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [alphSort, setAlphSort] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [baseUsers, setBaseUsers] = useState<IUsers[]>(users);
  const [sortedUsers, setSortedUsers] = useState<IUsers[]>(users);

  useEffect(() => {
    const filteredUsers = baseUsers.filter((item: IUsers) => {
      if (item.role?.['@id'] === TypeOfRole.superadmin) return false;

      const formatSearchTerm = searchTerm.toLowerCase();
      const resUsername = item.username.toLowerCase().indexOf(formatSearchTerm);
      const resEmail = item.email.toLowerCase().indexOf(formatSearchTerm);

      return resUsername !== -1 || resEmail !== -1;
    });

    if (alphSort) {
      setSortedUsers(filteredUsers.sort((a, b) => {
        if (a.username < b.username) { return -1; }
        if (a.username > b.username) { return 1; }
        return 0;
      }));
    } else {
      setSortedUsers(filteredUsers.sort((a, b) => {
        if (a.username < b.username) { return 1; }
        if (a.username > b.username) { return -1; }
        return 0;
      }));
    }
  }, [alphSort, searchTerm, baseUsers]);

  const addManager = () => {
    history.push(`/profile/complexes/${id}/manager`);
  };

  const editManager = (userId: string|number) => {
    history.push(`/profile/complexes/${id}/manager/${userId}`);
  };

  const deleteUser = (userId: string|number) => {
    deleteUsersIdData(jwt, userId);
    const tempArray = baseUsers;
    const index = baseUsers.findIndex((item) => item.id === userId);
    tempArray.splice(index, 1);
    setBaseUsers([...tempArray]);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
      >
        <Box fontSize="24px" fontWeight={600}>
          {translations.managers}
        </Box>
        <ButtonApp
          text={translations.addManager}
          onClick={addManager}
          type="button"
          color="primary"
          isCapitalize
          variant="contained"
          style={{
            fontSize: '18px',
          }}
        />
      </Box>
      <Box>
        <Box py={2} display="flex" alignItems="center">
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              fontSize="18px"
              fontWeight="600"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setAlphSort(!alphSort)}
            >
              {translations.manager}
              <img
                style={{
                  display: 'inline-block',
                  marginLeft: '5px',
                }}
                src={appIcon.iconSortArrow.default}
                alt=""

              />
            </Box>
          </Box>
          <Box width="725px">
            <SearchField
              handleChange={(value: string) => setSearchTerm(value)}
              placeholder={translations.managersSearchPlaceholder}
            />
          </Box>
        </Box>
        <Box>
          {sortedUsers && sortedUsers.map((user) => (
            <Box key={user['@id']} py={2} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" justifyContent="space-between">
                <Box
                  borderRadius="50%"
                  overflow="hidden"
                  width="50px"
                  height="50px"
                  mr={3}
                  style={{
                    backgroundColor: '#F2F2F2',
                    backgroundImage: `url(${user.iconUri || appIcon.avatar.default})`,
                    backgroundSize: 'cover',
                  }}
                />
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    fontSize="24px"
                    mb={1}
                  >
                    {user.username}
                    {(!user.role || user.role?.id === 4) ? null : (
                      <Box display="flex" ml={1}>
                        <img
                          width="18px"
                          height="18px"
                          src={appIcon.iconStar.default}
                          title={translations.supervisor}
                          alt={translations.supervisor}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box fontSize="16px" mb={1}>
                    {user.email}
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                width="725px"
              >
                <Box display="flex" alignItems="center">
                  <Box mr={2}>
                    <ButtonApp
                      text={translations.edit}
                      color="secondary"
                      variant="outlined"
                      onClick={() => editManager(user.id)}
                      className={styles.managerControl}
                      startIcon={<img src={appIcon.iconEdit.default} alt="Edit manager" />}
                    />
                  </Box>
                  <Box mr={2}>
                    <ButtonApp
                      text={translations.invite}
                      color="secondary"
                      variant="outlined"
                      className={styles.managerControl}
                      startIcon={<img src={appIcon.iconLetter.default} alt="Invite manager" />}
                    />
                  </Box>
                  <Box mr={2}>
                    <ButtonApp
                      text={translations.deactivate}
                      color="secondary"
                      variant="outlined"
                      className={styles.managerControl}
                      startIcon={<img src={appIcon.iconUserDisable.default} alt="Disable manager" />}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box onClick={() => deleteUser(user.id)} className={styles.deleteManager}>
                    <img width="20px" height="20px" src={appIcon.iconDelete.default} alt="Delete manager" />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}

        </Box>
      </Box>
    </Box>
  );
};
