import { put, takeEvery, select } from 'redux-saga/effects';

import { ActionTypes } from 'src/store/requests/type-of-property/actions';
import { Actions as ActionsFilterParams } from 'src/store/filter/filter-params/actions';
import { Actions as ActionsApp } from 'src/store/app/actions';
import { generateFilterInitialValue, getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';
import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { getMarkups, getSpinnerFirstSpinner } from 'src/store/requests/spinner-views/selector';
import { IFilterData } from 'src/typings/filter';
import { IFirstSpinner, IMarkUps } from 'src/typings/spinner';
import { Actions as ActionsSpinnerState } from 'src/store/spinner/actions';
import { requestSelectors } from 'src/store/requests/selectors';
import { listOfTypes } from 'src/store/type-displayed-spinner/constant';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { locals } from 'src/constants/locals';
import { Actions as ActionsFloors } from 'src/store/floors/actions';
import { Actions } from 'src/store/type-displayed-spinner/actions';
import { getListOfTypeDisplayedSpinner } from 'src/store/type-displayed-spinner/selectors';
import { IPlacementType } from 'src/store/type-displayed-spinner/types';
import { IFrontConfigTypeEnum } from 'src/typings/table';

export function* setTypePropertyCustomSaga(action: {
  type: string,
  payload: string,
}): any {
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  const firstSpinner: IFirstSpinner = yield select(getSpinnerFirstSpinner);
  const filterAllData: IFilterData = yield select(getFilterInitialAllData);
  const currentSpinner: IFirstSpinner | null = yield select(getCurrentSpinner);
  const houseMarkups: IMarkUps | undefined = yield select(getMarkups);
  const defaultLang: string|undefined = yield select(requestSelectors.app.getLang);
  const listOfDisplaySpinnerType: IPlacementType[] = yield select(getListOfTypeDisplayedSpinner);
  let displaySpinnerType: IPlacementType | undefined = defaultLang ? listOfDisplaySpinnerType.find((type) => {
    if (type.title[defaultLang] === action.payload) {
      return true;
    }
    return false;
  }) : listOfDisplaySpinnerType[0];
  if (!displaySpinnerType) {
    displaySpinnerType = { ...listOfDisplaySpinnerType[0] };
  }
  // yield put(ActionsFloors.setInitialReducer());
  const newView = displaySpinnerType.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => view.isEnabled)[0];
  yield put(Actions.setTypeDisplayedSpinner(newView.type));
  if (!currentSpinner) {
    if (firstSpinner) {
      yield put(ActionsSpinnerState.setCurrentSpinner({
        name: firstSpinner.name,
        'name-id': firstSpinner['name-id'],
      }));
    }
  } else {
    if (currentSpinner && currentSpinner.name === 'house') {
      yield put(ActionsFloors.setFromComplex(false));
      yield put(ActionsFloors.setSelectedHouse(currentSpinner));
    }
    if (currentSpinner && houseMarkups && currentSpinner.name === 'complex') {
      yield put(ActionsFloors.setFromComplex(true));
      yield put(ActionsFloors.setSelectedHouse({
        name: 'house',
        'name-id': Object.keys(houseMarkups.house)[0],
      }));
    }
    if (newView && listOfTypes[newView.type] && listOfTypes[newView.type].isMain) {
      if (firstSpinner) {
        yield put(ActionsSpinnerState.setCurrentSpinner({
          name: firstSpinner.name,
          'name-id': firstSpinner['name-id'],
        }));
      }
    }
  }

  if (action.payload === locals.appTranslations.appInfrastructure) {
    yield put(ActionsApp.showInfrastructure(false));
    return false; // todo add logic for filtering infrastructure
  }

  const filterInitialParameters = filterAllData[action.payload]?.filter_default_values.reduce((accu, cur) => {
    return {
      ...accu,
      [cur.attribute]: {
        data: generateFilterInitialValue(cur.type),
        type: cur.type,
      },
    };
  }, {});

  yield put(ActionsFilterParams.clearFilterParameter());
  yield put(ActionsFilterParams.setInitialFilterParameter(filterInitialParameters));
  return null;
}

export function* getTypeOfPropertyWatchSaga() {
  yield takeEvery(ActionTypes.CHANGE_SELECTED_TYPE_PLACEMENT, setTypePropertyCustomSaga);
}
