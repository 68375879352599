export const urlToBase64 = (url: string): Promise<{ base64Url: string | ArrayBuffer | null, image: HTMLImageElement }> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          resolve({ base64Url: reader.result, image });
        };
        image.src = String(reader.result);
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};
