import React, { useMemo } from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useTypeOfSpinner } from 'src/hooks/spinner';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { requestSelectors } from 'src/store/requests/selectors';
import { useStyles } from './styles';
import { IButtonBackSpinner } from './types';
import { HouseIcon } from './HouseIcon';
import { ListOfKeysFilterAllData } from '../../typings/filter';
import { useIsMobile } from '../../hooks';
import { ButtonApp } from '../Button';

export const ButtonBackSpinner: React.FC<IButtonBackSpinner> = ({
  className,
}) => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const style = useStyles(mainColor);
  const { getTranslations } = useTranslations();
  const { isMobileScreen } = useIsMobile();
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const { isMainSpinner, currentSpinner, handleBackToMainSpinner } = useTypeOfSpinner();

  const houseName = useMemo(() => {
    if (currentSpinner && spinnerData?.house) {
      const houseData = spinnerData.house[currentSpinner['name-id']];
      if (houseData) {
        return houseData.additional_data[ListOfKeysFilterAllData.placementHouse];
      }
    }
    return '';
  }, [currentSpinner, spinnerData]);

  if (isEmpty(spinnerData?.complex)) {
    return null;
  }

  if (isMobileScreen) {
    return !isMainSpinner
      ? (
        <ButtonApp
          variant="contained"
          className={`${style.wrapperButton} ${style.hiddenText} ${className}`}
          onClick={handleBackToMainSpinner}
          startIcon={<ArrowLeftIcon />}
        >
          <span className={style.textBlock}>
            {getTranslations(locals.appTranslations.appBack)}
          </span>
        </ButtonApp>
      ) : null;
  }

  return (
    <Box className={`${!isMainSpinner && style.wrapperButtonHouse} ${style.wrapperButton} ${className}`}>
      <Box
        display="flex"
        alignItems="center"
        className={`${!isMainSpinner && style.activeBtn}`}
        onClick={!isMainSpinner ? handleBackToMainSpinner : noop}
      >
        <HouseIcon />
        <Box mr="4px" />
        <Box fontWeight={isMainSpinner ? 'bold' : 'normal'}>
          {getTranslations(isMainSpinner ? locals.appTranslations.appChooseHouseBreadcrumbs : locals.appTranslations.appAllHousesBreadcrumbs)}
        </Box>
      </Box>
      {!isMainSpinner && (
        <>
          <Box fontWeight="bold" mr="10px">|</Box>
          <Box fontWeight="bold" pr={2}>
            {`${getTranslations('placement.house')} ${houseName}`}
          </Box>
        </>
      )}
    </Box>
  );
};
