import { useSelector } from 'react-redux';

import { IRangeAndSet } from 'src/typings/filter';
import { requestSelectors } from 'src/store/requests/selectors';
import { getFilterValueData } from 'src/store/filter/filter-value/selectors';
import { useSelectorsInfrastructure } from 'src/hooks/infrastructure';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useSelectorsApartments = () => {
  const placementState = useSelector(requestSelectors.typeOfProperty.getTypesPlacementState);
  const isLoadingFilterData: boolean = useSelector(requestSelectors.filter.getFilterInitialDataLoading);
  const filterData: IRangeAndSet[] = useSelector(getFilterValueData);
  const { filterInfrastructureData } = useSelectorsInfrastructure();

  return {
    isLoadingFilterData,
    filterData,
    infrastructureFilters: filterInfrastructureData?.infrastructureFilters || [],
    placementState,
  };
};
