import { useGetAuthData } from 'src/hooks';
import { useState } from 'react';
import { getDeveloperData, getNetHuntProject } from 'src/api';
import { INetHuntIntegration } from 'src/components/profileComponents/Integrations/Nethunt/types';

export const useGetNetHuntIntegration = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [netHuntIntegration, setIntegration] = useState<INetHuntIntegration>();

  const fetchIntegration = (projectId: string) => {
    if (projectId) {
      setIsLoading(true);
      getNetHuntProject(jwt, projectId)
        .then((res: any) => {
          const { data } = res;
          setIntegration(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return {
    netHuntIntegration,
    isLoading,
    fetchIntegration,
  };
};
