import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';
import { ISpinnerSvgData } from 'src/typings/spinner';
import { getCurrentSpinner, getSpinnerMode } from 'src/store/spinner/selectors';
import { spinnerMode } from 'src/store/spinner/reducer';

export const useGetSpinnerSvg = () => {
  const spinnerSvgCurrentData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const spinnerSvgCurrentDataAlt = useSelector(requestSelectors.spinner.getSpinnerViewDataAlt);
  const currentSpinnerMode = useSelector(getSpinnerMode);
  const currentSpinner = useSelector(getCurrentSpinner);

  const displayedSvgData = useMemo(() => {
    if (spinnerSvgCurrentData && currentSpinner) {
      const dataToUse = (currentSpinnerMode === spinnerMode.day || currentSpinner.name !== 'house') ? spinnerSvgCurrentData : (spinnerSvgCurrentDataAlt || spinnerSvgCurrentData);
      return dataToUse[currentSpinner.name][currentSpinner['name-id']]?.additional_data?.spinner_svg;
    }
    return undefined;
  }, [spinnerSvgCurrentData, spinnerSvgCurrentDataAlt, currentSpinner, currentSpinnerMode]);

  return {
    currentSpinner,
    displayedSvgData,
    currentSpinnerMode,
    spinnerSvgCurrentData,
    spinnerSvgCurrentDataAlt,
  };
};
