interface ICheckboxes {
  [key: string]: string;
}

export const checkboxes: ICheckboxes = {
  unlinkPlacementAtStageWithLowerPriority: `
  1. When moving a deal with a property to this stage, it results in the property 
  <b> becoming unlinked </b>
  from deals that are in stages with
   <b> lower priority</b>.
  `,
  unlinkPlacementAtStageWithHigherPriority: `
  2. When moving a deal with a property to this stage, it results in the 
  <b>property becoming unlinked</b>
   from deals that are in stages with <b>higher priority.</b>
  `,
  unlinkPlacementAtStageWithSamePriority: `
  3. When moving a deal with a property to this stage, it results in the property becoming
   <b>unlinked</b>
    from deals that are in stages with the <b>same priority.</b>
  `,
  unlinkAllPlacementsAtAllOtherStages: `
  4. When transitioning/adding a property to a deal in this stage, it results in the property becoming
  <b>unlinked</b>
  from all
  <b>other deals</b>,
  regardless of their priority.
  `,
  unlinkPlacementAtThisStage: `
  5. When moving a deal with a property to this stage, it results in the property becoming 
  <b>unlinked</b> from the <b>current deal</b> (for example, when the deal is lost).
  `,
  blockMoveAndLinkDealsWithPlacementAtOtherStages: `
  6.
  <b>Freeze</b>
   all deals with the same property in other stages (blocking movement through the funnel) and prevent the property from being linked to new deals.
  `,
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: `
  7. When moving a deal with a property to this stage,
  <b>other deals with the same property are allowed</b>
   to move through stages <b>up to the current stage</b>
   , with the option to link the property to new deals up to this stage.
  `,
};
