import { action } from 'src/store/common';
import React from 'react';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { IFilterAllData } from 'src/typings/filter';
import { GridColDef } from '@mui/x-data-grid';

export const ActionTypes = {
  SET_TABLE_API_REF: '[TABLE]SET_TABLE_API_REF',
  SET_TABLE_ROWS: '[TABLE]SET_TABLE_ROWS',
  SET_TABLE_COLUMNS: '[TABLE]SET_TABLE_COLUMNS',
};

export const Actions = {
  setTableApiRef: action<React.MutableRefObject<GridApiCommunity>>(ActionTypes.SET_TABLE_API_REF),
  setTableRows: action<IFilterAllData[]>(ActionTypes.SET_TABLE_ROWS),
  setTableColumns: action<GridColDef[]>(ActionTypes.SET_TABLE_COLUMNS),
};
