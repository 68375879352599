import { getPlacementProperties, getPlacementTypesByComplex } from 'src/api';
import { useBuildType } from 'src/hooks/_use_build_type';

import { useEffect, useState } from 'react';
import { IPlacementProperty, IPropertySettings } from 'src/typings/viewConfig';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useGetTypeOfProperty } from 'src/hooks/use-get-type-of-property';
import { useLanguageState } from 'src/hooks/useLanguageState';
import { IFrontConfigBase, ISubView, IView } from 'src/store/type-displayed-spinner/types';
import { IFrontConfigTypeEnum } from 'src/typings/table';

export const usePlacementTypeViews = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const { typeOfProperty } = useGetTypeOfProperty();
  const { currentAppLanguage } = useLanguageState();
  const { isCrossTable } = useBuildType();
  const viewType = isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website;
  const [currentConfig, setCurrentConfig] = useState<IFrontConfigBase>();
  const [currentView, setCurrentView] = useState<IView | undefined>();
  const [currentListSubView, setCurrentListSubView] = useState<ISubView | undefined>();
  const [enabledSubViewsCount, setEnabledSubViewsCount] = useState<number>();
  const [currentPropertySettings, setCurrentPropertySettings] = useState<IPropertySettings[] | undefined>();
  const [complexPlacementProperties, setComplexPlacementProperties] = useState<IPlacementProperty[]>();

  useEffect(() => {
    if (currentPropertySettings) {
      getPlacementPropertiesList()
        .then((placementProperties) => {
          setComplexPlacementProperties(placementProperties);
        });
    }
  }, [currentPropertySettings]);

  useEffect(() => {
    if (typeOfProperty) {
      getViewsConfig()
        .then((frontConfig) => {
          setCurrentConfig(frontConfig);
        });
    }
  }, [typeOfProperty]);

  useEffect(() => {
    if (currentConfig) {
      const currentViewTemp = getCrosstableView();
      setCurrentView(currentViewTemp);
      const currentSubViewTemp = getListSubView();
      setCurrentListSubView(currentSubViewTemp);
      setEnabledSubViewsCount(currentSubViewTemp?.placementReportSetting.exportButtons.filter((button) => button.isEnabled).length);
      setCurrentPropertySettings(currentSubViewTemp?.propertySettings);
    }
  }, [currentConfig]);

  const getPlacementPropertiesList = async (): Promise<IPlacementProperty[]> => {
    const { data } = await getPlacementProperties(jwt);
    return new Promise((resolve) => {
      resolve(data['hydra:member']);
    });
  };

  const getViewsConfig = async (): Promise<IFrontConfigBase> => {
    const { data } = await getPlacementTypesByComplex();
    // @ts-ignore
    const current = data['hydra:member'].find((item) => item.title[currentAppLanguage] === typeOfProperty);
    return new Promise((resolve) => {
      if (current?.frontConfig) {
        resolve(current?.frontConfig[viewType]);
      }
    });
  };

  const getCrosstableView = (): IView | undefined => {
    return currentConfig?.views.find((view) => view.type === 'Crosstable');
  };

  const getListSubView = (): ISubView | undefined => {
    return getCrosstableView()?.subViews?.find((sub) => sub.type === 'ListSubView');
  };

  return {
    currentConfig,
    currentView,
    currentListSubView,
    currentPropertySettings,
    complexPlacementProperties,
    viewType,
    enabledSubViewsCount,
  };
};
