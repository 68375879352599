import React from 'react';
import {
  Backdrop,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ButtonApp } from 'src/components/Button';
import { ModalContent, ModalHeader } from 'semantic-ui-react';
import { SvgClose } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgClose';
import { IAppModal } from './types';
import { useStyles } from './styles';

export const AppModal: React.FC<IAppModal> = ({
  open,
  onClose = () => {},
  children,
  toggleClose,
  contentHeader,
  listClassName,
  ...props
}) => {
  const style = useStyles({});

  return (
    <Modal
      closeAfterTransition
      open={open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${style.wrapperModal} ${listClassName?.wrapperModal || ''}`}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={onClose}
      onClick={(event: any) => {
        if (event.target.classList.contains(style.modalWindow)) {
          toggleClose();
        }
      }}
      {...props}
    >
      <div className={`${style.modalWindow} ${listClassName?.modalWindow || ''}`}>
        <div className={`${style.wrapperContent} ${listClassName?.wrapperContent || ''}`}>
          <ModalHeader>
            {contentHeader && (
              <div
                id="transition-modal-title"
                className={`${style.wrapperContentHeader} ${listClassName?.wrapperContentHeader || ''}`}
              >
                {contentHeader}
              </div>
            )}
            <ButtonApp
              startIcon={<SvgClose />}
              className={style.closeButton}
              onClick={toggleClose}
            />
          </ModalHeader>
          <ModalContent>
            {children}
          </ModalContent>
        </div>
      </div>
    </Modal>
  );
};
