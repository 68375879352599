export const appTranslations = {
  appSection: 'app.section',
  appSectionTitle: 'app.sectionTitle',
  appInfrastructure: 'app.infrastructure',
  appApartmentNotAvailable: 'app.apartmentNotAvailable',
  appCrossTableLegend: 'app.crossTableLegend',
  app3dView: 'app.view3d',
  app2dView: 'app.view2d',
  appNoDataToDisplay: 'app.noDataToDisplay',
  appPleaseStartFiltering: 'app.pleaseStartFiltering',
  appFromIn: 'app.formIn',
  appCopyUrlParams: 'app.copyUrlParams',
  appFromOut: 'app.formOutOf',
  appBack: 'app.back',
  appClose: 'app.close',
  appLoadMore: 'app.loadMore',
  appShowInfrastructure: 'app.showInfrastructure',
  appEmptyCompares: 'app.emptyCompares',
  appEmptyFavorites: 'app.emptyFavorites',
  appCopyLink: 'app.copyLink',
  appTotal: 'app.total',
  appPlanning: 'app.planning',
  appFrom: 'app.from',
  appTo: 'app.to',
  appFilter: 'app.filter',
  appShow: 'app.show',
  appShowed: 'app.showed',
  appShowFilter: 'app.showFilter',
  appHideFilter: 'app.hideFilter',
  appMoreFilter: 'app.moreFilter',
  appLessFilter: 'app.lessFilter',
  appUnits: 'app.units',
  appClear: 'app.clear',
  appClearAll: 'app.clearAll',
  appApply: 'app.apply',
  app3dModel: 'app.3dModel',
  appLayouts: 'app.layouts',
  appOnMap: 'app.inMap',
  appFloors: 'app.floors',
  appPanoV2: 'app.panoV2',
  appSpinnerV2: 'app.spinnerV2',
  appFloorTitle: 'app.floorTitle',
  appCharacteristics: 'app.characteristics',
  appExplication: 'app.explication',
  appBook: 'app.book',
  appBookNow: 'app.bookNow',
  appContactWithManager: 'app.contactWithManager',
  appRequestACall: 'app.requestACall',
  appUnitPageRedirect: 'app.unitPageRedirect',
  appHomePage: 'app.homePage',
  appPlacement: 'app.placement',
  appFavorites: 'app.favorites',
  appCompare: 'app.compare',
  appCompareApartments: 'app.compareApartments',
  appOnlyDifferences: 'app.onlyDifferences',
  appMore: 'app.more',
  appPromiseCallbackToClient: 'app.promiseCallbackToClient',
  appSend: 'app.send',
  appPhoneNumber: 'app.phoneNumber',
  appEmail: 'app.email',
  appUserName: 'app.userName',
  additionalInfo: 'app.additionalInfo',
  appErrorFieldIsRequired: 'app.errorFieldIsRequired',
  appErrorPhoneNumberFormat: 'app.errorPhoneNumberFormat',
  appErrorEmailFormat: 'app.errorEmailFormat',
  appErrorSendData: 'app.errorSendData',
  appTitleMainPage: 'app.titleMainPage',
  appTitlePlacement: 'app.titlePlacement',
  appTitleFavorites: 'app.titleFavorites',
  appTitleCompare: 'app.titleCompare',
  appTitle404: 'app.title404',
  appSelectAll: 'app.selectAll',
  appWindowViewHover: 'app.windowViewHover',
  appWindowViewTitle: 'app.windowViewTitle',
  appChooseHouseBreadcrumbs: 'app.chooseHouseBreadcrumbs',
  appAllHousesBreadcrumbs: 'app.allHousesBreadcrumbs',
  appCrosstableChangeSubView: 'app.crosstableChangeSubView',
  appCrosstableDetailedSubView: 'app.crosstableDetailedSubView',
  appCrosstableSimpleSubView: 'app.crosstableSimpleSubView',
  appCrosstableListSubView: 'app.crosstableListSubView',

};
