import { useSelector } from 'react-redux';
import { useGetQueryParams } from 'src/hooks/use-get-query-params';
import { useFavoriteStorage } from 'src/hooks/app';
import { useEffect, useState } from 'react';
import { pathList } from 'src/route-list';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';

export const useComparePageLink = () => {
  const typeOfPropertySelector = useSelector(getSelectedPlacement);
  const { favIdsList } = useFavoriteStorage();

  const [comparePageRouteLink, setComparePageRouteLink] = useState<string>('');

  useEffect(() => {
    if (typeOfPropertySelector) {
      setComparePageRouteLink(`${pathList.compare}?types=${typeOfPropertySelector}&listIds=${favIdsList.join(',')}`);
    }
  }, [typeOfPropertySelector, favIdsList]);

  return {
    comparePageRouteLink,
  };
};
