/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IDeveloper, IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';

import { CRMNames, IntegrationType } from 'src/typings/app';
import { useStyles } from 'src/components/profileComponents/Integrations/Bitrix/styles';
import {
  CreatioMainDataFormFields,
} from 'src/components/profileComponents/Integrations/Creatio/components/creatioMainDataFormFields';
import {
  BitrixMainDataFormFields,
} from 'src/components/profileComponents/Integrations/Bitrix/components/bitrixMainDataFormFields';
import {
  NethuntMainDataFormFields,
} from 'src/components/profileComponents/Integrations/Nethunt/components/nethuntMainDataFormFields';

interface IMainDataForm {
  developer: IDeveloper;
  project: IProject | null;
  fetchProject: Function;
  integration: IntegrationType;
  updateFormData: Function;
  createFormData: Function;
}

const schema = yup.object().shape({
  bitrixGetStagesListEndpoint: yup.string(),
  bitrixGetDealEndpoint: yup.string(),
  domain: yup.string(),
  serverIp: yup.string(),
});

export const MainDataForm: React.FC<IMainDataForm> = ({
  developer,
  project,
  fetchProject,
  integration,
  updateFormData,
  createFormData,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const {
    register, handleSubmit, formState, watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (data:any) => {
    let res;
    if (project) {
      res = await updateFormData(jwt, project['@id'], data);
    } else {
    // eslint-disable-next-line no-param-reassign
      data.developer = developer['@id'];
      res = await createFormData(jwt, data);
    }
    if (res.ok) {
      if (project) {
        fetchProject();
      } else {
        window.location.reload();
      }
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (

    <Box>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        {translations.mainData}
      </Box>
      <Box fontSize="14px">
        <form onSubmit={handleSubmit(onSubmit)}>
          {integration === CRMNames.creatio && (
            <CreatioMainDataFormFields
              integration={integration}
              register={register}
              project={project}
            />
          )}
          {integration === CRMNames.bitrix && (
            <BitrixMainDataFormFields
              integration={integration}
              register={register}
              project={project}
            />
          )}
          {integration === CRMNames.netHunt && (
            <NethuntMainDataFormFields
              integration={integration}
              register={register}
              project={project}
            />
          )}

          <Box width="260px">
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              className={styles.btn}
              disabled={!formState.isValid && !developer}
            />
          </Box>
        </form>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
