import React, { useEffect, useState } from 'react';
import { AppModal } from 'src/components/AppModal';
import MenuItem from '@mui/material/MenuItem';
import { appIcon } from 'src/assets';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useIsMobile } from 'src/hooks';
import { useStyles } from './styles';

interface IPDFDownload {
  open: boolean,
  plansList: any[];
  isLoading: boolean;
  onClose: () => void;
  onSelectPlan: (plan: any) => void;
}
export const PDFDownload: React.FC<IPDFDownload> = ({
  open, plansList, onClose, onSelectPlan, isLoading,
}) => {
  const { isMobileScreen } = useIsMobile();
  const [planIdDownload, setPlanIdDownload] = useState<string | null>(null);
  const [showCheckForId, setShowCheckForId] = useState<string | null>(null);
  const styles = useStyles();
  const theme: Theme = useTheme();
  const checkIconTimeout = 3000;
  const onClick = (plan: any) => {
    if (!isLoading) {
      setShowCheckForId(null);
      setPlanIdDownload(plan.id);
      onSelectPlan(plan);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setShowCheckForId(planIdDownload);
      setPlanIdDownload(null);
      setTimeout(() => {
        setShowCheckForId(null);
      }, checkIconTimeout);
    }
  }, [isLoading]);

  return (
    <AppModal
      listClassName={{
        wrapperModal: isMobileScreen ? styles.wrapperModalMobile : undefined,
        wrapperContentHeader: styles.wrapperContentHeader,
        wrapperContent: isMobileScreen ? styles.wrapperContentMobile : styles.wrapperContentDesktop,
      }}
      open={open}
      onClose={onClose}
      toggleClose={onClose}
      contentHeader={!isMobileScreen ? (<div />) : null}
    >
      <ul className={styles.ul}>
        { plansList && plansList.map((plan) => (
          <MenuItem key={plan.id} className={styles.listItem} onClick={() => onClick(plan)}>
            { planIdDownload !== plan.id && showCheckForId !== plan.id && (
            <img className={styles.icon} src={appIcon.iconDownload.default} alt="download" />
            ) }
            { !isLoading && showCheckForId === plan.id && (
            <img className={styles.icon} src={appIcon.iconCheck.default} alt="download" />
            ) }
            { planIdDownload === plan.id && (
            <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
            )}
            <span className={styles.title}>{ plan.name }</span>
          </MenuItem>
        ))}
      </ul>
    </AppModal>
  );
};
