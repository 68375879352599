import React from 'react';

import {
  AppFullScreenContainer,
  FilterMap,
} from 'src/components';
import { locals } from 'src/constants/locals';
import {
  useSetDocumentTitle,
  useTranslations,
  useDisablePinchZoomEffect,
  useIsMobile,
} from 'src/hooks';
import { FilterDesktop } from '../../components/FilterProperty/components';

export const MainPage: React.FC = () => {
  const { getTranslations } = useTranslations();
  const { isMobileScreen } = useIsMobile();
  useDisablePinchZoomEffect();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitleMainPage));

  return (
    <AppFullScreenContainer>
      <>
        {!isMobileScreen && <FilterDesktop />}
        <FilterMap />
      </>
    </AppFullScreenContainer>
  );
};
