import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  styleOfDropdown: (props: any) => ({
    color: `${theme.palette.text.primary} !important`,
    background: `${props.filterBackgroundColor} !important`,
    '& .text': {
      color: `${theme.palette.text.primary} !important`,
    },

    '& .menu': {
      background: `${props.filterBackgroundColor} !important`,
      '&:after': {
        background: `${props.filterBackgroundColor} !important`,
      },
    },
    '& .ui.checkbox .box:before, .ui.checkbox label:before': {
      background: `${props.filterBackgroundColor} !important`,
    },
  }),
}));
