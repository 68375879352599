import React from 'react';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export const AppTable: React.FC<DataGridProps> = ({ ...props }) => {
  return (
    <DataGrid
      {...props}
    />
  );
};
