import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  sidebarWrapper: (props: any) => ({
    overflowY: 'auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 10,
      background: props.filterBackgroundColor,
    },
    [theme.breakpoints.up(1920)]: {
      minWidth: '260px',
    },
    [theme.breakpoints.up(2000)]: {
      minWidth: '300px',
    },
    [theme.breakpoints.up(3000)]: {
      minWidth: '380px',
    },
  }),
  popoverContent: {
    width: '100%',
    padding: '10px',
  },
  popoverContentActions: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  wrapperLayoutStyles: {
    '&:hover': {
      top: '0px !important',
    },
  },
  wrapperOpen: {
    animation: '$wrapperOpen 400ms forwards',
    [theme.breakpoints.up(1920)]: {
      animation: '$wrapperOpen 400ms forwards',
    },
    [theme.breakpoints.up(2000)]: {
      animation: '$wrapperOpen_300 400ms forwards',
    },
    [theme.breakpoints.up(3000)]: {
      animation: '$wrapperOpen_380 400ms forwards',
    },
    [theme.breakpoints.down('md')]: {
      animation: '$wrapperOpenPhone 400ms forwards',
    },
  },
  wrapperClose: {
    animation: '$wrapperClose 400ms forwards',
    [theme.breakpoints.up(1920)]: {
      animation: '$wrapperClose 400ms forwards',
    },
    [theme.breakpoints.up(2000)]: {
      animation: '$$wrapperClose_300 400ms forwards',
    },
    [theme.breakpoints.up(3000)]: {
      animation: '$$wrapperClose_380 400ms forwards',
    },
    [theme.breakpoints.down('md')]: {
      animation: '$wrapperOpenPhone 400ms forwards',
    },
    [theme.breakpoints.down('md')]: {
      animation: '$wrapperClosePhone 400ms forwards',
    },
  },
  '@keyframes wrapperOpen': {
    '0%': { width: '0px', minWidth: '0px' },
    '100%': { width: '260px', minWidth: '260px' },
  },
  '@keyframes wrapperOpen_300': {
    '0%': { width: '0px', minWidth: '0px' },
    '100%': { width: '300px', minWidth: '300px' },
  },
  '@keyframes wrapperOpen_380': {
    '0%': { width: '0px', minWidth: '0px' },
    '100%': { width: '380px', minWidth: '380px' },
  },

  '@keyframes wrapperClose': {
    '0%': { width: '260px', minWidth: '260px' },
    '100%': { width: '0px', minWidth: '0px' },
  },
  '@keyframes wrapperClose_300': {
    '0%': { width: '300px', minWidth: '300px' },
    '100%': { width: '0px', minWidth: '0px' },
  },
  '@keyframes wrapperClose_380': {
    '0%': { width: '380px', minWidth: '380px' },
    '100%': { width: '0px', minWidth: '0px' },
  },
  '@keyframes wrapperOpenPhone': {
    '0%': { width: '0px' },
    '100%': { width: '100%' },
  },
  '@keyframes wrapperClosePhone': {
    '0%': { width: '100%' },
    '100%': { width: '0px' },
  },
}));
