import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useGetParamsData } from 'src/containers/MainPageApartments/hooks';
import { useStandartContactForm, useGetRequestAppData } from 'src/hooks/app';
import { useBuildType, useInitEmbeddedJS } from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { getComplexId } from 'src/store/app/selectors';
import { IAppRequestEntity } from 'src/typings/app';
import { allUtms, ApiUrl } from 'src/constants/config';
import { FormFields } from 'src/typings/forms';
import { useBookNowForm } from 'src/hooks/useBookNowForm';
import { translations } from 'src/constants/translations';

function getGAclientId() {
  let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const raw = (match) ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  const gacid = (match) ? match[1] : null;
  if (gacid) {
    return gacid;
  }
  return null;
}

export const useSendForm = () => {
  const errorInitialState = { state: false, msg: '' };
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isError, setIsError] = useState<{ state: boolean, msg: string }>(errorInitialState);
  const requestsConfig: IAppRequestEntity | undefined = useSelector(requestSelectors.app.getRequestsConfig);
  const complexId = useSelector(getComplexId);
  const { isCrossTable } = useBuildType();
  const { sendBookNowForm } = useBookNowForm();
  const { standartForm } = useStandartContactForm();
  const { handleOnFormSubmit } = useInitEmbeddedJS();
  const { appRequestData } = useGetRequestAppData();
  const {
    isSingleId,
    chooseData: placementData,
  } = useGetParamsData();

  const handleSendForm = useCallback(async (data: any) => {
    if (requestsConfig && !isSending) {
      setIsSending(true);
      setIsError(errorInitialState);

      const formatedData = (
        standartForm
        && standartForm.bio
        && standartForm.phoneNumber
        && standartForm.additionalInfo
      ) ? {
          [FormFields.bio]: data[FormFields.bio],
          [FormFields.phone]: data[FormFields.phone],
          [FormFields.additionalInfo]: `${data.email}|${data.url}|${data.additionalInfo}`,
        } : data;

      formatedData[FormFields.email] = data.email;
      formatedData[FormFields.url] = data.url;
      formatedData[FormFields.comment] = data.additionalInfo;
      const gaClientId = getGAclientId();

      if (gaClientId) {
        formatedData[FormFields.googleClientId] = gaClientId;
      }

      try {
        const res = await fetch('https://api.ipify.org/?format=json');
        const ipres = await res.json();
        formatedData[FormFields.clientIp] = ipres.ip;
      } catch (error) {
        console.log('ADBLOCK ENABLE, I DONT KNOWN IP');
      }

      const utmString = allUtms.reduce((acc: string, item: string) => {
        if (Cookies.get(item)) {
          formatedData[item] = Cookies.get(item);
          return `${acc} ${item},`;
        }
        return acc;
      }, '');
      const url = (standartForm && standartForm.client_end_point_url) ? standartForm.client_end_point_url : requestsConfig.mail_clients_url;
      const headers = standartForm ? {
        'Content-Type': standartForm.request_content_type,
      } : {};
      const hiddenInputs: any = standartForm?.static_hidden_inputs;
      const title = isCrossTable ? appRequestData?.crosstable_title : appRequestData?.module_title;

      formatedData[FormFields.source] = 'FLAT.SHOW';
      formatedData[FormFields.utm] = utmString;
      formatedData[FormFields.complexId] = complexId;
      if (placementData) {
        if (isSingleId) {
          formatedData[FormFields.placementAllocation] = `${title} - ${
            placementData[ListOfKeysFilterAllData.placementType]} - ${translations.building} ${
            placementData[ListOfKeysFilterAllData.placementHouse]} - ${translations.section} ${placementData[ListOfKeysFilterAllData.placementSection]} - ${translations.floor} ${
            placementData[ListOfKeysFilterAllData.placementFloor]} - ${translations.unitNumber} ${
            placementData[ListOfKeysFilterAllData.placementNumeration]} - ${translations.totalArea} ${
            placementData[ListOfKeysFilterAllData.placementTotalArea]}, ${translations.layoutType} ${placementData[ListOfKeysFilterAllData.placementPlanningType]}`;
        } else {
          formatedData[FormFields.placementAllocation] = `${title} - ${
            placementData[ListOfKeysFilterAllData.placementType]} - ${translations.building} ${
            placementData[ListOfKeysFilterAllData.placementHouse]} - ${translations.section} ${
            placementData[ListOfKeysFilterAllData.placementSection]} - ${translations.totalArea} ${
            placementData[ListOfKeysFilterAllData.placementTotalArea]}, ${translations.layoutType} ${placementData[ListOfKeysFilterAllData.placementPlanningType]}`;
        }
      }
      if (hiddenInputs) {
        Object.keys(hiddenInputs).forEach((key) => {
          formatedData[key] = hiddenInputs[key];
        });
      }
      // какой-то костыль чтоб на лету добавлять поля
      if (window.getDynamicFields) {
        const dynamicFields = window.getDynamicFields();
        Object.keys(dynamicFields).forEach((key) => {
          formatedData[key] = dynamicFields[key];
        });
      }

      try {
        const res = await sendBookNowForm(formatedData);
        setIsSending(false);
        if (res.data.error) {
          setIsError(res.data.error);
          setIsError({
            state: res.data.error,
            msg: res.data.message,
          });
        } else {
          setIsDone(true);
          handleOnFormSubmit();
          if (window.dataLayer) {
            window.dataLayer.push({ event: 'request_plan' });
          }
          if (standartForm && standartForm.redirect_url) {
            window.location.replace(standartForm?.redirect_url);
          }
        }
      } catch (error) {
        setIsSending(false);
        setIsError({
          state: true,
          msg: 'Something went wrong',
        });
      }
    }
  }, [isSending, requestsConfig, errorInitialState, standartForm]);

  return {
    isSending,
    isDone,
    isError,
    handleSendForm,
  };
};
