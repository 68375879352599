import { ReactImageGalleryItem } from 'react-image-gallery';
import { IPanoAppartments } from 'src/containers/MainPageApartmentsV2/hooks';
import { IFilterAllData } from 'src/typings/filter';
import { ISpinnerDataPossibilityInnerData } from 'src/typings/spinner';
import { IViewConfig } from 'src/typings/viewConfig';

export interface IGalleryListOfSpinner {
  name: string;
  order: number;
  data: ISpinnerDataPossibilityInnerData;
  icon: string | null;
}

export interface IApartmentGallery {
  combineIds: string[];
  spinnerApartments: IGalleryListOfSpinner[] | [];
  panoApartments: IPanoAppartments[] | [];
  gallery: string[];
  chooseData: IFilterAllData;
  isSingleId: boolean;
  setRoomTours?: Function;
  placementViewConfig: IViewConfig;
}

export interface ReactImageGalleryItemWithSpinnerData extends ReactImageGalleryItem {
  viewType?: SpinnerLabel;
  spinnerData?: ISpinnerDataPossibilityInnerData | undefined;
  icon?: string | null;
  type?: string | undefined;
  entity?: string;
  entityId?: number|string;
  id?: number;
  order?: number,
  appProperty: ApplicationPropertyType;
}

export interface IComponentGalleryItem {
  combineIds: string[];
  galleryItem: ReactImageGalleryItemWithSpinnerData;
  galleryImagesTransform: any;
  floorIds: string[];
  placementId: string;
}

export enum SpinnerLabel {
  spinner = 'SPINNER',
  complex = 'complex',
  floor = 'floor',
  house = 'house',
  placement = 'placement',
  section = 'section',
  apartment = 'apartment',
  tour = 'tour',
  panorama = 'panorama',
}

export type ApplicationPropertyType =
  'app.gallery' |
  'app.3DBuilding' |
  'app.roomsTour' |
  'app.favorites' |
  'app.mobLocationBuilding' |
  'app.mobLocationFloor' |
  'app.mobRoomsTour';

export enum ApplicationPropertyTypeEnum {
  gallery = 'app.gallery',
  building = 'app.3DBuilding',
  roomsTour = 'app.roomsTour',
  favorites = 'app.favorites',
  mobLocationBuilding = 'app.mobLocationBuilding',
  mobLocationFloor = 'app.mobLocationFloor',
  mobRoomsTour = 'app.mobRoomsTour',
}
