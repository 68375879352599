import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { greyColor, fontBlackColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
  },
  greyButton: {
    borderColor: greyColor,
    color: fontBlackColor,
    textTransform: 'none',
    '&:hover': {
      // background: 'white',
      borderColor: greyColor,
    },
  },
  button: {
    fontSize: '18px',
  },
  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: '0',
  },
  btn: {
    padding: '15px 30px',
    fontSize: '18px',
  },
  closeBtn: {
    cursor: 'pointer',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  },
}));
