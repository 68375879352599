import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { locals } from 'src/constants/locals';

export enum TypeViewIconName {
  svg360 = 'svg360',
  layouts = 'layouts',
  floors = 'floors',
  model2d = 'model2d',
}

export interface IListOfTypes {
  id: string;
  iconName: TypeViewIconName;
  label: string;
  path?: string;
  isMain?: boolean;
  isNested?: boolean;
  showNestedFilter?: boolean;
}

export const listOfTypes: any = {
  [TypesOfSpinner.viewSpinner]: {
    id: TypesOfSpinner.viewSpinner,
    iconName: TypeViewIconName.svg360,
    isMain: true,
  },
  [TypesOfSpinner.view2d]: {
    id: TypesOfSpinner.view2d,
    iconName: TypeViewIconName.model2d,
    showNestedFilter: true,
  },
  [TypesOfSpinner.viewLayouts]: {
    id: TypesOfSpinner.viewLayouts,
    iconName: TypeViewIconName.layouts,
    showNestedFilter: true,
  },
  [TypesOfSpinner.viewFloors]: {
    id: TypesOfSpinner.viewFloors,
    iconName: TypeViewIconName.floors,
    path: '/floors',
    showNestedFilter: true,
    isNested: true,
  },
  // ///////////
  [TypesOfSpinner.viewInMap]: {
    id: TypesOfSpinner.viewInMap,
    iconName: TypeViewIconName.floors,
    showNestedFilter: true,
    isNested: true,
  },
};
