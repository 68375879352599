import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { useGetDeveloper } from 'src/hooks';
import { CRMNames, IntegrationType } from 'src/typings/app';
import { IProject } from 'src/typings/complexes';
import { NethuntFunnels } from 'src/components/profileComponents/Integrations/Nethunt/components/CategoriesTable';
import { useGetFunnelFields } from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetFunnelFields';
import { INetHuntFolder } from 'src/components/profileComponents/Integrations/Nethunt/types';
import {
  useGetNetHuntIntegration,
} from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetNetHuntIntegration';
import { useGetListFolders } from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetListFolders';

interface IBitrixIntegration {
  integration: IntegrationType;
  project: IProject | null,
  fetchProject: () => void,
}

export const NetHuntIntegration: React.FC<IBitrixIntegration> = ({
  integration, project, fetchProject,
}) => {
  const { id }: { id: string } = useParams();
  const { isLoading, developer } = useGetDeveloper(id);
  const {
    fields, isLoading: isLoadingFields, fetchFields,
  } = useGetFunnelFields();

  const { netHuntIntegration, fetchIntegration } = useGetNetHuntIntegration();
  const { listFolders, getListFolders } = useGetListFolders();
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (project) {
      if (project.authKey && project.authEmail && project.workspaceId) {
        getListFolders(project);
      }
      fetchIntegration(String(project.id));
    }
  }, [project]);

  useEffect(() => {
    if (project && selectedFolderId) {
      fetchFields(project, selectedFolderId);
    }
  }, [selectedFolderId]);

  if (isLoading || !developer || !netHuntIntegration) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Box mb={12}>
        <NethuntFunnels
          developer={developer}
          project={project}
          fetchProject={fetchProject}
          listFolders={listFolders}
          fields={fields}
          selectedFolderId={setSelectedFolderId}
        />
      </Box>
    </>
  );
};
