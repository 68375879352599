import React from 'react';
import { ReactImageGalleryItemWithSpinnerData } from 'src/components/ApartmentPageV2/ApartmentGallery/types';
import { getOs, OSType } from 'src/helpers';
import { useIsMobile } from 'src/hooks';
import { SvgPlay } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgPlay';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';

export const ApartmentDescriptionTour: React.FC<{tours: ReactImageGalleryItemWithSpinnerData[]}> = ({ tours }) => {
  const os = getOs();
  const theme: Theme = useTheme();
  const { isMobileScreen } = useIsMobile();
  const onClock = (tour: ReactImageGalleryItemWithSpinnerData) => {
    window.open(tour.original, '_blank');
  };

  return (
    <>
      { tours.map((tour) => {
        return (
          <>
            {isMobileScreen && (
              <>
                {os === OSType.MAC && (
                  <Box
                    className="gallery-playcanvas-v2 mac-background"
                    onClick={() => onClock(tour)}
                  >
                    <SvgPlay playBtnColor={theme.palette.background.default} />
                  </Box>
                )}
                {os !== OSType.MAC && (
                  <iframe
                    key={tour.original}
                    title="sometitle"
                    src={tour.original}
                    className="gallery-playcanvas-v2"
                  />
                )}
              </>
            )}
            {!isMobileScreen && (
              <iframe
                key={tour.original}
                title="sometitle"
                src={tour.original}
                className="gallery-playcanvas-v2"
              />
            ) }
          </>
        );
      }) }
    </>
  );
};
