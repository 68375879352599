import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useTranslations } from 'src/hooks/translataions';
import { EFloorType } from 'src/store/view-2d/reducer';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';
import { IFloorControl } from './types';

export const FloorControl: React.FC<IFloorControl> = ({
  floorList,
  floorItemType,
}) => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles({
    ...mainColor,
    isBigFloorItem: floorItemType === EFloorType.moreInfo,
  });
  const { getTranslations } = useTranslations();

  useEffect(() => {
    const resizeEvent = new Event('resize');

    window.dispatchEvent(resizeEvent);
  }, [floorList]);

  return (
    <div className={styles.floorContainer}>
      <div className={styles.floorTitle} />
      <div className={styles.floorListWrapper}>
        {floorList.map((floor) => {
          return (
            <div key={floor} className={styles.floorItem}>
              <span>{floor}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
