import { IDogeFieldsOptional } from 'src/typings/global-config';
import { IntegrationType } from 'src/typings/app';

export interface INetHuntIntegration extends IDogeFieldsOptional {
  id: number,
  authEmail: string,
  authKey: string,
  developer: string,
  externalLeadFolderId: string,
  funnels: INetHuntFunnel[],
  netHuntFormConfigurations: INetHuntFeedbackFormSaveItem[],
  formMessageConfiguration: INetHuntFeedbackMessageConfiguration | null,
  workspaceId: string,
}

export interface INetHuntField { name: string}
export interface INetHuntFunnel extends IDogeFieldsOptional {
  id?: string,
  externalFolderId: string,
  externalFolderName: string,
  externalStageFieldName: string,
  externalResponsibleUserPropertyName: ResponsibleUserType,
  complexes: string[],
  netHuntOpportunityPlacementMappings: INetHuntOpportunityPlacementMapping[],
  opportunityPropertyExternalName?: string,
  isMultipleExternalResponsibleUserProperty?: boolean,
}
export interface INetHuntFolder {
  id: string,
  name: string,
}
export interface INetHuntFunnelOption {
  label: string,
  value: string,
}

export interface INetHuntFeedbackField {
  name: string,
  value: string,
}

export interface INetHuntPlacementPropertiesFields extends INetHuntFunnelOption {}

export interface INetHuntEmptyOpportunityStatuses extends IDogeFieldsOptional {
  id?: string,
  complex: string,
  placementStatus: string
}

export interface INetHuntOpportunityPlacementMapping extends IDogeFieldsOptional {
  id?: string,
  externalPropertyEmptyValue: string,
  externalPropertyName: string,
  externalPropertyType: string,
  placementPropertySystemName: string,
  netHuntFunnel: string,
}

export interface INetHuntFeedbackFormItem extends IDogeFieldsOptional {
  id?: string,
  tempId?: string,
  formKeyName: INetHuntFeedbackField,
  externalPropertyName: INetHuntFeedbackField,
  externalPropertyType: string,
  externalPropertyEmptyValue: string,
  netHuntIntegration: string | undefined,
}

export interface INetHuntFeedbackFormSaveItem extends IDogeFieldsOptional {
  id?: string,
  formKeyName: string,
  externalPropertyName: string,
  externalPropertyType: string,
  externalPropertyEmptyValue: string,
  netHuntIntegration: string | undefined,
}

export interface INetHuntFeedbackMessageConfiguration extends ITimelineField, IDogeFieldsOptional {
  id?: string,
  formKeyNames: string[],
}

export interface ITimelineField {
  id?: string,
  tempId: string,
  netHuntIntegration: string,
  formKeyNames: string[],
}

export const projectType: ProjectType<IntegrationFields> = {
  bitrix: 'bitrixProject',
  creatio: 'creatioProject',
  netHunt: 'netHuntIntegration',
};
export type ProjectType<T> = {
  [key in IntegrationType]: T;
}
export type IntegrationFields = 'bitrixProject' | 'creatioProject' | 'netHuntIntegration';
export type ResponsibleUserType = 'Manager' | string;
export enum ResponsibleUserEnum {
  MANAGER = 'Manager'
}
