/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Modal, Backdrop,
  Fade, Paper, TextField,
  Snackbar, Alert, Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UploadButton } from 'src/components/UploadButton';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IComplex } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import { uploadFile, postComplexData, patchComplexData } from 'src/api';
import { useStyles } from '../styles';

interface IBoxFormTextField {
  label: string;
  name: string;
  inputRef: React.Ref<any>;
}

const BoxFormTextField: React.FC<IBoxFormTextField> = ({ label, name, inputRef }) => {
  return (
    <Box mb={6} width="100%" display="flex" flexDirection="column">
      { label }
      <Box mt={2}>
        <TextField
          id={name}
          name={name}
          variant="outlined"
          inputRef={inputRef}
        />
      </Box>
    </Box>
  );
};

const allLanguagesOptions: string[] = [
  'en',
  'uk',
  'ru',
  'ge',
  'gr',
  'fr',
  'ar',
  'pl',
  'de',
  'tr',
];

interface IBoxFormAutocomplete {
  label: string;
  name: string;
  value: string | string[] | undefined;
  options: Array<string>;
  onChange: Function;
}

const BoxFormAutocomplete: React.FC<IBoxFormAutocomplete> = ({
  label, name, options, value, onChange,
}) => {
  return (
    <Box mb={6} width="100%" display="flex" flexDirection="column">
      { label }
      <Box mt={2}>
        <Autocomplete
          multiple={Array.isArray(value)}
          id={name}
          options={options}
          value={value}
          disableCloseOnSelect
          disableClearable
          onChange={(ev, currValue) => onChange(currValue)}
          // getOptionLabel={(option) => option.label}
          // filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} placeholder="" sx={{ fontSize: '14px' }} />
          )}
        />
      </Box>
    </Box>
  );
};

interface StatusModal {
  isOpen: boolean;
  handleChangeOpen: Function;
  refreshComplexes: Function;
  complex?: IComplex|null;
}

const schema = yup.object().shape({
  projectName: yup.string().required(translations.requiredField),
  projectShortName: yup.string().required(translations.requiredField).matches(/^[a-zA-Z0-9]+$/, translations.invalidComplexShortName),
  projectAddress: yup.string().required(translations.requiredField),
});

export const ComplexModal: React.FC<StatusModal> = ({
  isOpen, handleChangeOpen, complex, refreshComplexes,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const jwt = useGetAuthData().getJWT();

  const [systemPrimaryLanguage, _setSystemPrimaryLanguage] = useState<string|undefined>();
  const [systemSecondaryLanguagesOptions, _setSystemSecondaryLanguagesOptions] = useState<string[]>([]);
  const [systemSecondaryLanguages, setSystemSecondaryLanguages] = useState<string[]>([]);

  const setSystemPrimaryLanguage = (currLanguage: string) => {
    _setSystemPrimaryLanguage(currLanguage);
    _setSystemSecondaryLanguagesOptions(allLanguagesOptions.filter((language) => language !== currLanguage));
    setSystemSecondaryLanguages(systemSecondaryLanguages.filter((language) => language !== currLanguage));
  };

  const [adminPanelPrimaryLanguage, _setAdminPanelPrimaryLanguage] = useState<string|undefined>();
  const [adminPanelSecondaryLanguagesOptions, _setAdminPanelSecondaryLanguagesOptions] = useState<string[]>([]);
  const [adminPanelSecondaryLanguages, setAdminPanelSecondaryLanguages] = useState<string[]>([]);

  const setAdminPanelPrimaryLanguage = (currLanguage: string) => {
    _setAdminPanelPrimaryLanguage(currLanguage);
    _setAdminPanelSecondaryLanguagesOptions(allLanguagesOptions.filter((language) => language !== currLanguage));
    setAdminPanelSecondaryLanguages(adminPanelSecondaryLanguages.filter((language) => language !== currLanguage));
  };

  const [websitePrimaryLanguage, _setWebsitePrimaryLanguage] = useState<string|undefined>();
  const [websiteSecondaryLanguagesOptions, _setWebsiteSecondaryLanguagesOptions] = useState<string[]>([]);
  const [websiteSecondaryLanguages, setWebsiteSecondaryLanguages] = useState<string[]>([]);

  const setWebsitePrimaryLanguage = (currLanguage: string) => {
    _setWebsitePrimaryLanguage(currLanguage);
    _setWebsiteSecondaryLanguagesOptions(allLanguagesOptions.filter((language) => language !== currLanguage));
    setWebsiteSecondaryLanguages(websiteSecondaryLanguages.filter((language) => language !== currLanguage));
  };

  useEffect(() => {
    setSystemPrimaryLanguage(allLanguagesOptions[0]);
    setAdminPanelPrimaryLanguage(allLanguagesOptions[0]);
    setWebsitePrimaryLanguage(allLanguagesOptions[0]);
  }, []);

  const {
    register, handleSubmit, formState, watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (_data:any) => {
    const data = {
      // @ts-ignore
      title: { [systemPrimaryLanguage]: _data.projectName },
      complexShortName: _data.projectShortName,
      // @ts-ignore
      address: { [systemPrimaryLanguage]: _data.projectAddress },
      language: systemPrimaryLanguage,
      secondaryLanguages: systemSecondaryLanguages,
      crosstableLanguage: adminPanelPrimaryLanguage,
      crosstableSecondaryLanguages: adminPanelSecondaryLanguages,
      moduleLanguage: websitePrimaryLanguage,
      moduleSecondaryLanguages: websiteSecondaryLanguages,
    };

    let res;

    if (complex) {
      res = await patchComplexData(jwt, complex.id, data);
    } else {
      res = await postComplexData(jwt, data);
    }

    if (res.ok) {
      handleChangeOpen(false);
      refreshComplexes();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  const close = () => handleChangeOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="800px" maxHeight="90vh" overflow="auto" p="52px">
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {complex ? translations.projectEditing : translations.createProject}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box flex="1" display="flex" gap="40px">
                  <BoxFormTextField label={translations.projectName} name="projectName" inputRef={register} />
                  <BoxFormTextField label={translations.projectShortName} name="projectShortName" inputRef={register} />
                </Box>

                <BoxFormTextField label={translations.projectAddress} name="projectAddress" inputRef={register} />

                <Box flex="1" display="flex" gap="40px">
                  <BoxFormAutocomplete
                    label={translations.systemPrimaryLanguage}
                    name="systemPrimaryLanguage"
                    options={allLanguagesOptions}
                    value={systemPrimaryLanguage}
                    onChange={setSystemPrimaryLanguage}
                  />
                  <BoxFormAutocomplete
                    label={translations.systemSecondaryLanguages}
                    name="systemSecondaryLanguages"
                    options={systemSecondaryLanguagesOptions}
                    value={systemSecondaryLanguages}
                    onChange={setSystemSecondaryLanguages}
                  />
                </Box>

                <Box flex="1" display="flex" gap="40px">
                  <BoxFormAutocomplete
                    label={translations.adminPanelPrimaryLanguage}
                    name="adminPanelPrimaryLanguage"
                    options={allLanguagesOptions}
                    value={adminPanelPrimaryLanguage}
                    onChange={setAdminPanelPrimaryLanguage}
                  />
                  <BoxFormAutocomplete
                    label={translations.adminPanelSecondaryLanguages}
                    name="adminPanelSecondaryLanguages"
                    options={adminPanelSecondaryLanguagesOptions}
                    value={adminPanelSecondaryLanguages}
                    onChange={setAdminPanelSecondaryLanguages}
                  />
                </Box>

                <Box flex="1" display="flex" gap="40px">
                  <BoxFormAutocomplete
                    label={translations.websitePrimaryLanguage}
                    name="websitePrimaryLanguage"
                    options={allLanguagesOptions}
                    value={websitePrimaryLanguage}
                    onChange={setWebsitePrimaryLanguage}
                  />
                  <BoxFormAutocomplete
                    label={translations.websiteSecondaryLanguages}
                    name="websiteSecondaryLanguages"
                    options={websiteSecondaryLanguagesOptions}
                    value={websiteSecondaryLanguages}
                    onChange={setWebsiteSecondaryLanguages}
                  />
                </Box>

                <Box display="flex" justifyContent="flex-end" gap="20px">
                  <ButtonApp
                    onClick={close}
                    text={translations.cancel}
                    color="primary"
                    variant="outlined"
                    type="button"
                    className={styles.btn}
                  />
                  <ButtonApp
                    text={translations.createProject}
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={styles.btn}
                    disabled={!formState.isValid && !complex}
                  />
                </Box>
              </form>

              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
