import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { Actions } from 'src/store/app/actions';
import { getBitrixDeal, getCreatioDeal, getNetHuntDeal } from 'src/store/crosstable/selectors';
import { getCrmData, getSelectedAppartment, getIsOpenAppartSidebar } from 'src/store/app/selectors';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { CRMNames } from 'src/typings/app';
import { useBuildType, useGetFilteredData } from 'src/hooks';
import { shadeColor } from 'src/helpers';
import {
  greyColor,
} from 'src/constants';
import { useContrastRatio } from 'src/hooks/useContrastRatio';
import { useSelectedIds } from '../../hooks';
import { useStyles } from '../../styles';

function getFirstLetters(str: string) {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export interface IFloorItemBig {
  fillFloorsItem: IFilterAllData;
}

export const FloorItemBig: React.FC<IFloorItemBig> = React.memo(({
  fillFloorsItem,
}) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [isSelectedDeal, setIsSelectedDeal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { isCrossTable } = useBuildType();
  const { selectedIds } = useSelectedIds();
  const isSelected = selectedIds.includes(fillFloorsItem.id);
  const { resultFiltering } = useGetFilteredData();

  const [backgroundColor, setBackgroundColor] = useState<string>(isCrossTable
    ? (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusCrossTableColor]?.toString()
      || fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '')
    : (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || ''));
  const { getColorByBgColor } = useContrastRatio();

  const styles = useStyles({
    floors: fillFloorsItem[ListOfKeysFilterAllData.placementFloor].length,
  });
  const bitrixDeal = useSelector(getBitrixDeal);
  const creatioDeal = useSelector(getCreatioDeal);
  const netHuntDeal = useSelector(getNetHuntDeal);
  const crmData = useSelector(getCrmData);
  const selectedAppartment = useSelector(getSelectedAppartment);
  const isOpenAppartSidebar = useSelector(getIsOpenAppartSidebar);
  const id = (isOpen)
    ? 'floorItemPopover' : undefined;

  const bgColor = isCrossTable
    ? (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusCrossTableColor]?.toString()
  || fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '')
    : (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '');

  useEffect(() => {
    if (isOpenAppartSidebar && selectedAppartment == fillFloorsItem[ListOfKeysFilterAllData.id]) {
      setIsOpen(true);
    } else if (isOpen) {
      setIsOpen(false);
    }
  }, [selectedAppartment, isOpenAppartSidebar]);

  useEffect(() => {
    if (fillFloorsItem) {
      const { bitrix_deals: bitrixDeals, netHuntOpportunities, creatioOpportunities } = fillFloorsItem;
      if (crmData?.crm === CRMNames.amocrm) {
        if (fillFloorsItem[ListOfKeysFilterAllData.placementAmoDeals]) {
          fillFloorsItem[ListOfKeysFilterAllData.placementAmoDeals].forEach((item) => {
            if (crmData && item.includes(String(crmData?.dealId))) {
              setIsSelectedDeal(true);
            }
          });
        }
      } else if (crmData?.crm === CRMNames.bitrix) {
        if (bitrixDeals && bitrixDeals.find((item) => bitrixDeal && item.id === bitrixDeal.id)) {
          setIsSelectedDeal(true);
        } else {
          setIsSelectedDeal(false);
        }
      } else if (crmData?.crm === CRMNames.creatio) {
        if (creatioOpportunities && creatioOpportunities.find((item) => creatioDeal && item.id === creatioDeal.id)) {
          setIsSelectedDeal(true);
        } else {
          setIsSelectedDeal(false);
        }
      } else if (crmData?.crm === CRMNames.netHunt) {
        if (netHuntOpportunities && netHuntOpportunities.find((item) => netHuntDeal && item.id === netHuntDeal.id)) {
          setIsSelectedDeal(true);
        } else {
          setIsSelectedDeal(false);
        }
      }
    }
  }, [fillFloorsItem, crmData, netHuntDeal, bitrixDeal, creatioDeal]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isSelected && !isCrossTable) return;

    if (isOpen) {
      dispatch(Actions.setIsOpenAppartSidebar(false));
      dispatch(FloorsActions.setSelectedApartmentId(undefined));
    } else {
      dispatch(FloorsActions.setSelectedApartmentId(fillFloorsItem[ListOfKeysFilterAllData.id]));
      dispatch(Actions.selectAppartment(fillFloorsItem[ListOfKeysFilterAllData.id]));
      dispatch(Actions.setIsOpenAppartSidebar(true));
    }
  };

  return (
    <div className={styles.sectionContentWrapperBig}>

      <Box
        aria-describedby={id}
        className={`${styles.sectionContentItemBig} ${(!isSelected || !resultFiltering.length) && 'blockedItem'} ${isSelectedDeal && styles.selectedDeal}`}
        data-floors={ListOfKeysFilterAllData.placementFloor}
        onClick={handleClick}
        position="relative"
      >
        {fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] && (
        <Tooltip
          placement="top"
          TransitionComponent={Zoom}
          arrow
          title={fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] || ''}
        >
          <Box
            className={styles.additionalCondition}
            top="1px"
            right="1px"
            bgcolor={bgColor && shadeColor(bgColor, 60)}
          >
            {getFirstLetters(fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] || '')}
          </Box>
        </Tooltip>
        )}
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={1}
        >
          <Box
            ml={1}
            mr="12px"
            px={1}
            minWidth="20px"
            height="20px"
            fontSize="10px"
            display="flex"
            justifyContent="center"
            fontWeight="bold"
            alignItems="center"
            borderRadius="12px"
            lineHeight="10px"
            style={{
              color: getColorByBgColor(backgroundColor),
              backgroundColor,
            }}
          >
            {fillFloorsItem[ListOfKeysFilterAllData.placementPlanningType]}
          </Box>
          {fillFloorsItem[ListOfKeysFilterAllData.placementTotalPrice] && (
          <Box fontWeight="bold">
            {
              // @ts-ignore
            `${parseFloat(fillFloorsItem[ListOfKeysFilterAllData.placementTotalPrice]).toLocaleString('ru-RU')}`
}
          </Box>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          fontSize="11px"
        >
          {fillFloorsItem[ListOfKeysFilterAllData.placementNumeration] && (
          <Box
            minWidth="30px"
            mr="7px"
            textAlign="center"
            color={greyColor}
          >
            {`№${fillFloorsItem[ListOfKeysFilterAllData.placementNumeration]}`}
          </Box>
          )}
          {fillFloorsItem[ListOfKeysFilterAllData.placementTotalArea] && (
          <Box>
            {`${fillFloorsItem[ListOfKeysFilterAllData.placementTotalArea]}`}
          </Box>
          )}
        </Box>
      </Box>
    </div>
  );
});
