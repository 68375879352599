import {
  takeEvery, select, put,
} from 'redux-saga/effects';
import { IFirstSpinner, IEntityConfig, ISpinnerRenderData } from 'src/typings/spinner';
import { requestSelectors } from 'src/store/requests/selectors';
import { ActionTypes, Actions } from 'src/store/spinner/actions';
import { getEntityOptions } from 'src/api';
// @ts-ignore
import { fsUtils } from 'viewer';

function* setCompleteSpinnerSaga(action: {
  type: string,
  payload: IFirstSpinner,
}) {
  const spinnerData: {
    [key: string]: ISpinnerRenderData;
    firstSpinner: any;
} = yield select(requestSelectors.spinner.getSpinnerViewData);
  const currentSpinner = action.payload;
  const spinnerWindRose: {
    src: string | undefined,
    deg: number | string | undefined,
    disabled: boolean | undefined,
    bg_src: string | undefined,
  } = {
    src: '',
    deg: 0,
    disabled: true,
    bg_src: '',
  };
  const fetchData: {
    data: IEntityConfig
  } = yield getEntityOptions(currentSpinner.name, currentSpinner['name-id']);

  if (fetchData.data) {
    if (currentSpinner.name === 'floor') {
      spinnerWindRose.src = fetchData.data.floor_compass_image;
      spinnerWindRose.deg = fetchData.data.floor_compass_angle;
      spinnerWindRose.disabled = fetchData.data.floor_compass_display;
      spinnerWindRose.bg_src = fetchData.data.floor_compass_bg_image;
    } else {
      spinnerWindRose.src = fetchData.data.spinner_compass_image;
      spinnerWindRose.deg = fetchData.data.spinner_compass_angle;
      spinnerWindRose.disabled = Boolean(fetchData.data.spinner_compass_display);
      spinnerWindRose.bg_src = fetchData.data.spinner_compass_bg_image;
    }
  }
  if (spinnerData && currentSpinner && spinnerData[currentSpinner.name][currentSpinner['name-id']]
    && spinnerData[currentSpinner.name][currentSpinner['name-id']]._windRose) {
    spinnerWindRose.src = spinnerData[currentSpinner.name][currentSpinner['name-id']]._windRose?.src;
    spinnerWindRose.deg = spinnerData[currentSpinner.name][currentSpinner['name-id']]._windRose?.degree;
    spinnerWindRose.disabled = spinnerData[currentSpinner.name][currentSpinner['name-id']]._windRose?.disabled;
    spinnerWindRose.bg_src = spinnerData[currentSpinner.name][currentSpinner['name-id']]._windRose?.bg_src || '';
  }
  // @ts-ignore
  const config = yield fsUtils.mergeConfig(fetchData.data, currentSpinner.name, currentSpinner['name-id']);
  yield put(Actions.setEntityConfig(config));
  if (action.type === ActionTypes.SET_APP_CURRENT_SPINNER) {
    yield put(Actions.setAppWindRose(spinnerWindRose));
  } else {
    yield put(Actions.setWindRose(spinnerWindRose));
  }
}

export function* spinnerWatchSaga() {
  yield takeEvery(ActionTypes.SET_CURRENT_SPINNER, setCompleteSpinnerSaga);
  yield takeEvery(ActionTypes.SET_APP_CURRENT_SPINNER, setCompleteSpinnerSaga);
}
