import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import { PropTypes } from '@mui/material';

import { useStyles } from './styles';

interface IButtonApp extends ButtonProps {
  onlyStartIcon?: boolean;
  isCapitalize?: boolean
  isFullSize?: boolean;
  withBorder?: boolean;
  text?: string | React.ReactNode;
  customStyles?: string;
  customClassName?: string;
  height?: string;
}

export const ButtonApp: React.FC<IButtonApp> = React.forwardRef(({
  children,
  variant,
  text,
  color = 'primary',
  onClick,
  onlyStartIcon = false,
  isFullSize = false,
  isCapitalize = false,
  withBorder = false,
  customStyles,
  customClassName = '',
  ...props
}, ref) => {
  const styles = useStyles({});

  return (
    <Button
      ref={ref}
      variant={variant}
      color={color}
      onClick={onClick}
      className={
        `${styles.defaultButtonStyles} ${
          isFullSize ? styles.isFullSize : ''} ${
          isCapitalize ? styles.isCapitalize : ''} ${
          onlyStartIcon ? styles.onlyStartIcon : ''} ${
          withBorder ? styles.withBorderStyle : ''} ${
          customStyles || ''} ${
          customClassName || ''}`
      }
      {...props}
    >
      {children}
      {text}
    </Button>
  );
});
