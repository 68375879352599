import { useCallback } from 'react';

import { useClearSelectedFilterParams, useGetFilterParams, useSetFilterParams } from 'src/hooks';
import { filterAllPlacementsValue, ListOfDropdown } from 'src/typings/filter';

export const useCheckboxState = (
  nameOfField: string,
) => {
  const { filterParams } = useGetFilterParams();
  const { handleWriteToRedux } = useSetFilterParams();
  const { handleClearFilterParam } = useClearSelectedFilterParams();

  const handleSelectSingleCheckbox = useCallback((value: string) => {
    if (filterParams[nameOfField]?.isDisabled) {
      return;
    }

    if (filterParams && filterParams?.all?.data === filterAllPlacementsValue) {
      handleClearFilterParam(ListOfDropdown.all);
    }

    if (filterParams[nameOfField]?.data.includes(value)) {
      handleWriteToRedux(
        nameOfField,
        filterParams[nameOfField]?.data.filter((item: string) => item !== value),
      );
    } else {
      handleWriteToRedux(
        nameOfField,
        [...filterParams[nameOfField]?.data, value],
      );
    }
  }, [filterParams, handleWriteToRedux, nameOfField]);

  return {
    filterValue: filterParams[nameOfField]?.data || [],
    handleSelectSingleCheckbox,
  };
};
