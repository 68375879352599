import { IDogeFields } from 'src/typings/global-config';
import { IPlacement } from 'src/store/placement/reducer';
import { ApplicationPropertyType } from 'src/components/ApartmentPageV2/ApartmentGallery/types';

export interface IViewConfig extends IDogeFields {
    id: number | null,
    name: ITranslate,
    complex: string,
    isActive: boolean,
    isDefault: boolean,
    headerSection: IHeaderSection,
    descriptionSection: IDescriptionSection,
    placementProperties: IPlacementProperty[],
    lang: string,
    placement?: IPlacement,
    applicationProperties: IApplicationProperty[],
}

export interface IPlacementExplication extends IDogeFields, IDField {
    area: number,
    level: number,
    sortOrder: number,
    name: IPlacementExplicationName,
}

export interface IPlacementExplicationName extends IDogeFields, IDField {
    title: ITranslate | null,
    complex: IPlacementExplicationComplex,
}

export interface IPlacementExplicationComplex extends IDogeFields, IDField {
    title: ITranslate | null;
}

export interface IPlacementPropertyItem extends IDogeFields, IDField {
    systemName: string,
    translates: ITranslate
}

export interface IGroupProperty extends IDogeFields, IDField {
    name: ITranslate | null,
    propertySettings: IPropertySettings[],
    styles: [],
}

export interface IDescriptionGroup extends IDogeFields, IDField {
    isNamed: boolean,
    headerName: string,
    groupName: string | null,
    accordionState: AccordionStateType,
    type: DescriptionGroupType,
    propertySettingsGroup: IGroupProperty
    sortOrder: number,
}

export interface IApplicationProperty extends IDogeFields, IDField {
    systemName: ApplicationPropertyType,
    translates: ITranslate | null,
}

export interface IDField {
    id: number,
}

export interface IDescriptionSection extends IDogeFields, IDField {
    descriptionSectionPropertySettingsGroups: IDescriptionGroup[],
}

export interface ICallToAction extends IDogeFields, IDField {
    isHidden: boolean,
    styles: ICallToActionStyles,
}

export interface IUnitPageRedirect extends IDogeFields, IDField {
    isHidden: boolean;
    name: IPlacementProperty | null;
    placementProperty: IPlacementProperty | null;
    styles: ICallToActionStyles;
}

export interface IHeaderSection extends IDogeFields, IDField {
    callToAction: ICallToAction,
    unitPageRedirect: IUnitPageRedirect,
    utilityButtons: IUtilityButton[],
    mainHeader: IHeader<IMainHeader>,
    subHeader: IHeader<ISubHeader>,
}

export type IHeader<T> = T;

export interface IMainHeader extends IDogeFields, IDField {
    name: string | null // we do not use it for now.
    styles: Omit<IStyles, 'fontDecoration'>,
    propertySettings: IOption[],
}

export interface ISubHeader extends IDogeFields, IDField {
    name: string | null,
    styles: null,
    propertySettings: ISubHeaderProperty[],
}

export interface IUtilityButton extends IDogeFields, IDField {
    isHidden: boolean,
    applicationProperty: IUtilityButtonProperty,
    headerName: string | null,
    // name: string | null
    sortOrder: number,
}

export interface IUtilityButtonProperty extends IDogeFields, IDField {
    systemName: string,
    translates: ITranslate | null
}

export interface IPropertySettings extends IDogeFields, IDField {
    type: DescriptionGroupItemType,
    headerName: string,
    name: string,
    value: string,
    prefix: ITranslate | null,
    suffix: ITranslate | null,
    prefixIcon: IFile | null,
    styles: IStyles | null
    sortOrder: number,
    placementProperty: IPlacementProperty,
}

export interface IOptionBase {
    name: string,
}

export interface IOptionWithProps extends IOptionBase {
    props?: { [key: string]: string };
}

export interface IOption extends IOptionBase, IDogeFields, IDField {
    active?: boolean,
    headerName?: string,
    value: string,
    prefix: ITranslate | null,
    suffix: ITranslate | null,
    prefixIcon: string | null,
    styles: IStyles | null,
    placementProperty: IOptionProperty,
    sortOrder: number,
}

export interface IOptionProperty extends IDogeFields, IDField {
    systemName: string,
    translates: ITranslate | null,
}

export interface IPlacementProperty extends IDogeFields, IDField {
    oldSystemName?: string,
    systemName: string,
    translates: ITranslate | null,
}

export interface ISubHeaderProperty extends IOption {
    styles: IStyles,
}

export interface IStyles {
    fontWeight: number,
    fontColor: string,
    fontDecoration: string,
}

export interface ICallToActionStyles {
    fontWeight: number,
    fontColor: string,
    backgroundColor: string,
}

export interface IFile extends IDogeFields, IDField {
    alternativeTypes: null
    domain: string
    extension: string
    fileUrl: string,
    name: string
    path: string
    resolutions: null
}

export type ITranslate = {
    [key: string]: string
}

export type DescriptionGroupItemType = 'iconAndValue' | 'statusAndValue' | 'textAndValue';
export type AccordionStateType = 'open' | 'closed';
export enum AccordionStateTypeEnum {
    open = 'open',
    closed = 'closed'
}
export type DescriptionGroupType = 'leftAligned' | 'lineDivided' | 'explication';
export enum DescriptionGroupTypeEnum {
    leftAligned = 'leftAligned',
    lineDivided = 'lineDivided',
    explication = 'explication'
}
export enum UtilityButtonTypeEnum {
    downloadPDF = 'app.downloadPlacementPdf',
    copyLink = 'app.copyLink',
    addToFavorites = 'app.addToFavorites',
    separatedPlacementPlans = 'app.downloadSeparatePaymentPlans',
}
