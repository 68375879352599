export const useContrastRatio = () => {
  const blackColor = '#000000';
  const whiteColor = '#FFFFFF';

  const getColorByBgColor = (bgColor: string) => {
    const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
      ? blackColor : whiteColor;
  };
  return {
    getColorByBgColor,
  };
};
