import { useGetAuthData } from 'src/hooks';
import { useState } from 'react';
import { getNetHuntStagesByFunnel } from 'src/api';
import { INetHuntStages } from 'src/typings/complexes';

export const useGetNetHuntStages = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [netHuntStages, setNetHuntStages] = useState<INetHuntStages[] | null>(null);

  const fetchStages = (funnelId: string) => {
    if (funnelId) {
      setIsLoading(true);
      getNetHuntStagesByFunnel(jwt, funnelId)
        .then((res: any) => {
          const { data } = res;
          setNetHuntStages(data['hydra:member']);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  return {
    netHuntStages,
    isLoading,
    fetchStages,
    setNetHuntStages,
  };
};
