import { takeEvery, call, put } from 'redux-saga/effects';
import { LocationChangePayload } from 'connected-react-router';
import { BuildEnv } from 'src/typings/env';
import { getBuildType } from 'src/helpers';
import { Actions } from './actions';

export function* setComplexIdWatchSaga(action: {
  type: string,
  payload: LocationChangePayload,
}) {
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  if (action.payload.isFirstRendering && !isCrossTable) {
    if (window.complexId === 'COMPLEX_ID') {
      const string = new URL(document.URL).pathname.split('/');
      const complexIdString: string = string[string.length - 1];
      let complexId = process.env.REACT_APP_COMPLEX_ID;
      // @ts-ignore
      if (!Number.isNaN(parseFloat(complexIdString)) && !Number.isNaN(complexIdString - 0)) {
        complexId = complexIdString;
      } else if (complexIdString && complexIdString !== 'app') {
        // @ts-ignore
        const response = yield call(async () => {
          const fetchResponse = await fetch(`${process.env.REACT_APP_REST_API}/api/complexes?complexShortName=${complexIdString}&properties[]=id`);
          const data = await fetchResponse.json();
          return data;
        });
        complexId = response['hydra:member'][0].id;
      }
      yield put(Actions.setComplexId(complexId));
    } else {
      yield put(Actions.setComplexId(window.complexId));
    }
  }
}

export function* getComplexIdWatchSaga() {
  yield takeEvery('@@router/LOCATION_CHANGE', setComplexIdWatchSaga);
}
