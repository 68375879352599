import _ from 'lodash';
import { put, select, takeEvery } from 'redux-saga/effects';

import { IAction } from 'src/typings/index';
import { Actions, ActionTypes } from 'src/store/filter/filter-params/actions';
import { Actions as ActionsFiltered } from 'src/store/filter/filtered-data/actions';
import { Actions as ActionsValue } from 'src/store/filter/filter-value/actions';
import { Actions as ActionsSpinner } from 'src/store/spinner/actions';
import { Actions as ActionsLayouts } from 'src/store/layouts/actions';
import { Actions as ActionsFloors } from 'src/store/floors/actions';
import {
  IFilterAllData,
  IFilterParams,
  IRangeAndSet,
  ListOfDropdown,
  ListOfKeysFilterAllData,
  PlacementShowModule,
} from 'src/typings/filter';
import {
  IBacklightState, IHitsList, ISpinnerData, ListOfBacklightSvg,
} from 'src/typings/spinner';
import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { getFilterParamData } from 'src/store/filter/filter-params/selectors';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { getFilterValueData } from 'src/store/filter/filter-value/selectors';
import { compareNumbers, getBuildType } from 'src/helpers';
import { IAppLayouts, IAppLayoutsItem } from 'src/typings/layouts';
import { getLayoutsRequestData } from 'src/store/requests/layouts/selector';
import { requestSelectors } from 'src/store/requests/selectors';
import { BuildEnv } from 'src/typings/env';

// eslint-disable-next-line consistent-return
function customizer(objValue: any, srcValue: any) {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  if (_.isString(objValue)) {
    return [objValue, srcValue];
  }
}

export function* setFilterParamsSaga(): any {
  const isCrossTable = getBuildType() === BuildEnv.crossTable;

  // Get all data for start filtering
  const filterAllData = yield select(getFilterInitialAllData);
  const filterParamData: IFilterParams = yield select(getFilterParamData);
  const selectedPlacement: string = yield select(getSelectedPlacement);
  const layoutsData = yield select(getLayoutsRequestData);
  const previousFilterValue: IRangeAndSet[] = yield select(getFilterValueData);
  const spinnerPlacementData: ISpinnerData = yield select(requestSelectors.spinner.getSpinnerData);
  if (spinnerPlacementData) {
    const defaultFilterValue: IRangeAndSet[] = filterAllData[selectedPlacement]?.filter_default_values;
    const defaultSpinnerPlacementData = spinnerPlacementData['detail-info'].placement;
    const defaultSpinnerPlacementDataArray = _.values(defaultSpinnerPlacementData);
    const layoutsDataTypes: IAppLayoutsItem[] = (layoutsData && selectedPlacement && layoutsData[selectedPlacement])
      ? layoutsData[selectedPlacement] : [];

    // Separate only filled parameters
    let noEmptyFilterParams: IFilterParams = {};

    Object.keys(filterParamData).forEach((key) => {
      if (Boolean(filterParamData[key]?.data?.length)) {
        if (filterParamData[key]?.type === ListOfDropdown.scaleRange) {
          noEmptyFilterParams = {
            ...noEmptyFilterParams,
            [key]: {
              ...filterParamData[key],
              data: filterParamData[key].data.sort((a: any, b: any) => a - b),
            },
          };
        } else {
          noEmptyFilterParams = {
            ...noEmptyFilterParams,
            [key]: filterParamData[key],
          };
        }
      }
    });

    // Start filtration
    const keysFilter = Object.keys(noEmptyFilterParams);
    const preparedInitialData = isCrossTable
      ? filterAllData[selectedPlacement]?.filter_all_data
      : _.omitBy(filterAllData[selectedPlacement]?.filter_all_data, (obj: IFilterAllData) => {
        return obj[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.hide;
      });

    let startFiltering = false;
    let cacheResult: IFilterAllData[] = [];

    keysFilter.forEach((noEmptyKey) => {
      const typeField: ListOfDropdown = noEmptyFilterParams[noEmptyKey].type;
      const selectorCacheData = _.isEmpty(cacheResult) && !startFiltering ? preparedInitialData : cacheResult;
      startFiltering = true;

      if (noEmptyKey === ListOfDropdown.all) {
        cacheResult = selectorCacheData;
      }

      if (typeField === ListOfDropdown.dataList || typeField === ListOfDropdown.dropdownSelectList) {
        const searchResult = _.filter(selectorCacheData, (item) => {
          return item[noEmptyKey] === noEmptyFilterParams[noEmptyKey].data;
        });

        cacheResult = searchResult;
      }

      if (typeField === ListOfDropdown.dropdownCheckboxList) {
        const searchResult = _.filter(selectorCacheData, (item) => {
          if (Array.isArray(item[noEmptyKey])) {
            return noEmptyFilterParams[noEmptyKey].data.some((el: any) => item[noEmptyKey].includes(el));
          }

          return noEmptyFilterParams[noEmptyKey].data.includes(item[noEmptyKey]);
        });

        cacheResult = searchResult;
      }

      if (typeField === ListOfDropdown.datalistCheckboxList) {
        const searchResult = _.filter(selectorCacheData, (item) => {
          if (Array.isArray(item[noEmptyKey])) {
            return noEmptyFilterParams[noEmptyKey].data.some((el: any) => item[noEmptyKey].includes(el));
          }

          return noEmptyFilterParams[noEmptyKey].data.includes(item[noEmptyKey]);
        });
        cacheResult = searchResult;
      }

      if (typeField === ListOfDropdown.scaleRange) {
        const searchResult = _.filter(selectorCacheData, (item) => {
          if (_.isArray(item[noEmptyKey])) {
            const multiFloorsIncludes = item[noEmptyKey]
              .some((key: any) => key >= parseFloat(noEmptyFilterParams[noEmptyKey].data[0])
              && key <= parseFloat(noEmptyFilterParams[noEmptyKey].data[1]));

            return multiFloorsIncludes;
          }

          return item[noEmptyKey] >= parseFloat(noEmptyFilterParams[noEmptyKey].data[0])
          && item[noEmptyKey] <= parseFloat(noEmptyFilterParams[noEmptyKey].data[1]);
        });

        cacheResult = searchResult;
      }
    });

    // Object to array for displayed data
    const result: IFilterAllData[] = _.values(cacheResult);

    if (Array.isArray(result) && Boolean(result.length)) {
      const combineData: any = result.reduce((accu, curu) => {
        if (_.isEmpty(curu)) {
          return accu;
        }

        _.mergeWith(accu, curu, customizer);

        return accu;
      }, {});

      const sortNewData: { [key: string]: string[] } = Object.keys(combineData).reduce((accu, cur) => {
        if (
          cur !== ListOfKeysFilterAllData.id
        && cur !== ListOfKeysFilterAllData.placementGallery
        && cur !== ListOfKeysFilterAllData.mainFilterImg
        && cur !== ListOfKeysFilterAllData.placementGalleryImagesTransform
        ) {
          const sortItem = _.isString(combineData[cur]) ? [combineData[cur]] : combineData[cur];
          const sortTransform = Boolean(sortItem) ? new Set(sortItem.sort(compareNumbers)) : sortItem;
          const sortResult = Boolean(sortItem) ? [...sortTransform].filter((el) => el != null) : sortItem;

          return {
            ...accu,
            [cur]: sortResult,
          };
        }

        return accu;
      }, {});

      const newFilterValue = previousFilterValue.map((prev) => {
        if (keysFilter.includes(prev.attribute)) {
          return prev;
        }

        if (
          prev.type === ListOfDropdown.dataList
        || prev.type === ListOfDropdown.datalistCheckboxList
        || prev.type === ListOfDropdown.dropdownSelectList
        || prev.type === ListOfDropdown.dropdownCheckboxList
        ) {
          return {
            ...prev,
            attribute: prev.attribute,
            type: prev.type,
            data: sortNewData[prev.attribute],
          };
        }

        if (prev.type === ListOfDropdown.scaleRange) {
          return {
            ...prev,
            attribute: prev.attribute,
            type: prev.type,
            data: Boolean(sortNewData[prev.attribute])
              ? {
                min: sortNewData[prev.attribute][0],
                max: sortNewData[prev.attribute][sortNewData[prev.attribute].length - 1],
              }
              : null,
          };
        }

        return {
          attribute: prev.attribute,
          type: prev.type,
          data: prev.data,
        };
      });

      yield put(ActionsValue.setFilterValue(newFilterValue));
    } else {
      yield put(ActionsValue.setFilterValue(defaultFilterValue));
    }
    // ========================

    const listFilteredIds: string[] = result.reduce((accuFilteredIds, curFilteredEl) => {
      return [...accuFilteredIds, curFilteredEl[ListOfKeysFilterAllData.id]];
    }, [] as string[]);

    const listFilteredSection: string[] = [];
    // List of filtered floors
    const listFilteredFloorsIds: string[] = result.reduce((accuFilteredIds, curFilteredEl) => {
      if (!listFilteredSection.includes(curFilteredEl[ListOfKeysFilterAllData.section])) {
        listFilteredSection.push(curFilteredEl[ListOfKeysFilterAllData.section]);
      }
      return [
        ...accuFilteredIds,
        ...curFilteredEl[ListOfKeysFilterAllData.floorIds],
      ];
    }, [] as string[]);
    const filteredFloorsIds = Array.from(new Set(listFilteredFloorsIds));

    // Generate hightlight svg
    const backlightSvg: IBacklightState = result.reduce((accuSvg: IBacklightState, curSvg) => {
      if (!isCrossTable && curSvg[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.hide) {
        return accuSvg;
      }

      return {
        [ListOfBacklightSvg.houseId]: [...accuSvg[ListOfBacklightSvg.houseId], curSvg[ListOfKeysFilterAllData.houseId]],
        [ListOfBacklightSvg.placementId]: [...accuSvg[ListOfBacklightSvg.placementId], curSvg[ListOfKeysFilterAllData.id]],
      };
    }, {
      [ListOfBacklightSvg.houseId]: [],
      [ListOfBacklightSvg.placementId]: [],
    });

    // Generate count hits over placements IHitsList
    const generateHints: IFilterAllData[] = result.length || keysFilter.length ? result : defaultSpinnerPlacementDataArray;
    const hitsList: IHitsList = generateHints.reduce((accuHits, curPlacement) => {
      const updateData: any = accuHits;
      const { houseId, section } = ListOfKeysFilterAllData;

      if (
        curPlacement[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.hide
      || curPlacement[ListOfKeysFilterAllData.placementType] !== selectedPlacement
      ) {
        return accuHits;
      }

      if (updateData[houseId]?.[curPlacement[houseId]]) {
        updateData[houseId][curPlacement[houseId]] = {
          count: updateData[houseId][curPlacement[houseId]].count + 1,
        };
      } else {
        updateData[houseId][curPlacement[houseId]] = {
          count: 1,
        };
      }

      if (updateData[section]?.[curPlacement[section]]) {
        updateData[section][curPlacement[section]] = {
          count: updateData[section][curPlacement[section]].count + 1,
        };
      } else {
        updateData[section][curPlacement[section]] = {
          count: 1,
        };
      }

      return updateData;
    }, {
      [ListOfKeysFilterAllData.houseId]: {},
      [ListOfKeysFilterAllData.section]: {},
    });

    // Generate ViewLayouts
    const layoutsView: IAppLayoutsItem[] = layoutsDataTypes.reduce((accuLayouts, curLayouts) => {
      const presenceInListOfResultIds = curLayouts[ListOfKeysFilterAllData.id].some((id) => listFilteredIds.includes(id));
      if (presenceInListOfResultIds) {
        return [...accuLayouts, curLayouts];
      }

      return [...accuLayouts];
    }, [] as IAppLayoutsItem[]);

    // We also filtering result, when filter parameters doesn't apply in hook with name "useGetFilteredData"
    const filterCrossTable = {
      selectedPlacement,
      data: result,
    };

    if (filterCrossTable.data.length === 0) {
      if (startFiltering) {
        yield put(Actions.setFilterHasNoResult(true));
      } else {
        yield put(Actions.setFilterHasNoResult(false));
      }
      if (!isCrossTable) {
        const showCrossTableElement: any = Object.values(filterAllData[selectedPlacement]?.filter_all_data)
        // @ts-ignore
          .filter((item: IFilterAllData) => item[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.show);
        filterCrossTable.data = showCrossTableElement;
      } else {
        filterCrossTable.data = Object.values(filterAllData[selectedPlacement]?.filter_all_data);
      }
    } else {
      yield put(Actions.setFilterHasNoResult(false));
    }

    yield put(ActionsFiltered.setFilteredData(filterCrossTable)); // todo this for crosstable

    yield put(ActionsSpinner.setCurrentSpinnerCounter(hitsList));
    yield put(ActionsSpinner.setCurrentSpinnerBacklightSvg(backlightSvg));
    yield put(ActionsLayouts.setLayoutsFilteredData(layoutsView));
    yield put(ActionsFloors.setFilteredIdsReducer(filteredFloorsIds));
    yield put(ActionsFloors.setFilteredSections(listFilteredSection));
  }
}

const handleFilterParams = (filterParamData: IFilterParams, key: string) => {
  if (filterParamData[key]?.isDisabled) {
    return {
      data: filterParamData[key].data,
      type: filterParamData[key].type,
      isDisabled: filterParamData[key].isDisabled,
    };
  }
  // eslint-disable-next-line no-lonely-if
  if (_.isString(filterParamData[key]?.data)) {
    return {
      data: '',
      type: filterParamData[key].type,
    };
  }
  if (_.isArray(filterParamData[key]?.data)) {
    return {
      data: [],
      type: filterParamData[key].type,
    };
  }

  return filterParamData[key];
};

export function* clearFilterParamsSaga(action: IAction) {
  const filterParamData: IFilterParams = yield select(getFilterParamData);

  const clearParams: IFilterParams = {};

  if (!action.payload) {
    // Clear all params
    Object.keys(filterParamData).forEach((key) => {
      clearParams[key] = handleFilterParams(filterParamData, key);
    });
  } else {
    // Clear specific params
    Object.keys(filterParamData).forEach((key) => {
      if (key === action.payload) {
        clearParams[key] = handleFilterParams(filterParamData, key);
      } else {
        clearParams[key] = filterParamData[key];
      }
    });
  }

  yield put(Actions.clearFilterParameter(clearParams));
}

export function* setFilterParamsWatchSaga() {
  yield takeEvery(ActionTypes.SET_FILTER_PARAMETER, setFilterParamsSaga);
  yield takeEvery(ActionTypes.CLEAR_FILTER_PARAMETER_CALL, clearFilterParamsSaga);
  yield takeEvery(ActionTypes.CLEAR_FILTER_PARAMETER, setFilterParamsSaga);
}
