import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperImageProperty: {
    borderRadius: '10px',
    border: '1px solid #F2F2F2',
    padding: '4px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '140px',
    maxHeight: '140px',
    margin: '0px 10px 15px',
    width: '210px',
    maxWidth: '210px',
    minWidth: '210px',
    '& > a': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(15vw - 20px)',
      maxWidth: 'calc(15vw - 20px)',
      minWidth: 'calc(15vw - 20px)',
      height: '20vh',
      maxHeight: '20vh',
    },
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  closeIcon: {
    top: '0px',
    right: '0px',
    position: 'absolute',
    minWidth: '44px',
    width: '44px',
    height: '44px',
    '& span[class*="startIcon"]': {
      margin: '0px',
    },
  },
}));
