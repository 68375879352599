import React, { useEffect } from 'react';
import { IntegrationType } from 'src/typings/app';
import { IDeveloper, IProject } from 'src/typings/complexes';
import {
  CreatioAuthorization,
} from 'src/components/profileComponents/Integrations/Creatio/components/CreatioAuthorization';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { pathList } from 'src/containers/Profile/routeList';
import { ButtonApp } from 'src/components/Button';
import { useGetCreatioFields } from 'src/components/profileComponents/Integrations/Creatio/hooks/useGetFields';
import { patchCreatioFieldsValues, patchCreatioProject } from 'src/api';
import { FeedbackSettings } from 'src/components/profileComponents/FeedbackSettings';
import { Fields } from 'src/components/profileComponents/Integrations/Common/Fields';
import { Loader } from 'src/components/Loader';
import CircularProgress from '@mui/material/CircularProgress';

interface ICreatioIntegration {
    integration: IntegrationType;
    project: IProject,
    developer: IDeveloper
}
export const CreatioIntegration: React.FC<ICreatioIntegration> = ({ integration, project, developer }) => {
  const {
    fields, entityValues, isLoading, fetchFields,
  } = useGetCreatioFields();

  useEffect(() => {
    if (project) {
      fetchFields(project);
    }
  }, [project]);

  return (
    <>
      <Box mb={4}>
        <CreatioAuthorization project={project} />
      </Box>
      <Box mb={4}>
        <Box fontSize="24px" mb={4} fontWeight={600}>
          Управление этапыми сделок
        </Box>
        <Link to={`/profile/${pathList.developers}/${developer.id}/creatio/category/${project.id}`}>
          <ButtonApp
            text="Create/edit"
            color="primary"
            variant="contained"
            type="submit"
            size="small"
          />
        </Link>
      </Box>
      <Box mb={4}>
        <Fields integration={integration} fields={fields} entityValues={entityValues} saveData={patchCreatioFieldsValues} />
      </Box>
      <Box mb={4}>
        <FeedbackSettings integration={integration} project={project} />
      </Box>
    </>
  );
};
