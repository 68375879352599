import React from 'react';
import { appIcon } from 'src/assets';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { SvgDownload } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgDownload';
import { useStyles } from './styles';

export const ReportIcon: React.FC<{isLoading: boolean}> = ({ isLoading }) => {
  const styles = useStyles();
  return (
    <>
      {isLoading && (
        <CircularProgress size={20} />
      )}
      {!isLoading && (
        <div className={styles.icon}>
          <SvgDownload />
        </div>
      ) }
    </>
  );
};
