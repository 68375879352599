import React from 'react';

import { ButtonApp } from 'src/components/Button';
import { useAppVisibilityBtn, useStateListOfLayout, useTranslations } from 'src/hooks';
import { PlacementDisplayedType } from 'src/typings/filter';
import { locals } from 'src/constants/locals';
import { LayoutApartmentItem } from '../LayoutApartmentItem';
import { useStyles } from './styles';
import { IComponentListOfLayoutApartments } from './types';

// The name of the component is not entirely correct.
// The layout is displayed under the filter, and the layout also has separate layers in the spinner.
export const ComponentListOfLayoutApartments: React.FC<IComponentListOfLayoutApartments> = ({
  isLayoutTab = false,
  parentScroll,
  properties,
  propertyWrapperStyles,
  propertyWrapperImageStyle,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const {
    isShowLoadMoreBtn,
    handleShowMore,
    countShowedProperty,
    lengthOfList,
  } = useStateListOfLayout(properties.length);
  const { show_filter_counter, filter_favorites } = useAppVisibilityBtn();

  return (
    <>
      {show_filter_counter && (
        <span className={styles.titleTotalCount}>
          <b>
            {`
            ${getTranslations(locals.appTranslations.appShowed)} 
            ${isShowLoadMoreBtn ? countShowedProperty : properties.length}
          `}
          </b>
            {` ${getTranslations(locals.appTranslations.appFromOut)} ${lengthOfList}`}
        </span>
      )}
      <div className={styles.wrapperListOfProperty}>
        {properties.slice(0, countShowedProperty).map((item) => {
          return (
            <LayoutApartmentItem
              key={item.id}
              isShowEye
              isLayoutTab={isLayoutTab}
              isShowFavIcon={!isLayoutTab && filter_favorites}
              placementDisplayedType={isLayoutTab ? PlacementDisplayedType.placementGroupedTileConfig : PlacementDisplayedType.filter}
              wrapperStyle={`${styles.propertyWrapper} ${propertyWrapperStyles || ''}`}
              wrapperImageStyle={propertyWrapperImageStyle}
              parentScroll={parentScroll}
              property={item}
            />
          );
        })}
        {isShowLoadMoreBtn && (
          <ButtonApp
            isFullSize
            text={getTranslations(locals.appTranslations.appLoadMore)}
            variant="contained"
            onClick={handleShowMore}
          />
        )}
      </div>
    </>
  );
};
