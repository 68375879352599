import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from 'src/store/view-2d/actions';
import { getComplexId } from 'src/store/app/selectors';
import { getView2dStatuses } from 'src/store/view-2d/selectors';
import { IStatus } from 'src/typings/complexes';
import { useGetAuthData, useBuildType, useActual } from 'src/hooks';
import { getPlacementStatusByComplex } from 'src/api';

export const useGetComplexStatuses = (id?: string|undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filteredStatuses, setFilteredStatuses] = useState<IStatus[]>([]);
  const { isCrossTable } = useBuildType();
  const statuses:IStatus[] = useSelector(getView2dStatuses);
  const complexId = useSelector(getComplexId);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const actualFetch = useActual();

  const fetchStatuses = () => {
    if (id || complexId) {
      setIsLoading(true);
      const isActual = actualFetch();
      // @ts-ignore
      getPlacementStatusByComplex(jwt, id || complexId)
        .then((res: any) => {
          if (isActual()) {
            dispatch(Actions.setView2dStatuses(res.data['hydra:member']));
            setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    dispatch(Actions.setView2dStatuses([]));
    fetchStatuses();
  }, [jwt, id]);

  useEffect(() => {
    const colors: string[] = [];
    if (statuses) {
      const filtered = statuses.reduce((acc: IStatus[], item) => {
        const color = isCrossTable ? (item.crosstableColor?.toLowerCase() || item.color.toLowerCase()) : item.color.toLowerCase();
        if (!colors.includes(color)) {
          colors.push(color);
          acc.push(item);
        }
        return acc;
      }, []);
      setFilteredStatuses(filtered);
      setIsLoading(false);
    }
  }, [statuses]);

  return {
    statuses: filteredStatuses,
    fetchStatuses,
    isLoading,
  };
};
