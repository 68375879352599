import { useSortIfArray, useSortNumber } from 'src/hooks/app/useSortIfArray';

type options = {
  lang: string,
  sortValue?: boolean,
}
export const getValueByPlacementSystemName = (patch: any | null, systemName: string, options: options): string | number => {
  let tempPatch = patch;
  if (systemName) {
    const splitedSystemName = systemName.split('.');
    let result: string = '';
    if (Array.isArray(splitedSystemName)) {
      splitedSystemName.forEach((pathStep, index) => {
        if (tempPatch && pathStep && Object.prototype.hasOwnProperty.call(tempPatch, pathStep)) {
          tempPatch = tempPatch[pathStep];
          if (Array.isArray(tempPatch)) {
            let resultArray: string[] = [];
            tempPatch.forEach((item) => {
              resultArray.push(getValueByPlacementSystemName(item, splitedSystemName[index + 1], options).toString());
            });
            if (options.sortValue) {
              resultArray = useSortNumber(resultArray);
            }
            result = resultArray.join(',');
          }
        }
      });
      if (result) {
        return result;
      }
    }
  }
  if (tempPatch !== null && typeof tempPatch === 'object' && !Array.isArray(tempPatch)) {
    return tempPatch[options.lang] || '';
  }
  return tempPatch || '';
};
