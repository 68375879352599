import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appIcon } from 'src/assets';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperBreadcrumbs: {
    fontWeight: 'bold',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& ol': {
      fontSize: '16px',
      lineHeight: '20px',
      height: '100%',
      [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
        lineHeight: '36px',
      },
      [theme.breakpoints.up(3000)]: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      '& .MuiBreadcrumbs-separator': {
        fontFamily: 'Inter',
        fontWeight: '700',
      },
    },
  },
  step: {
    fontWeight: '700',
    color: theme.palette.grey['400'],
  },

  backArrow: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: '700',
    color: theme.palette.primary.main,
  },
  backArrowIcon: {
    width: '24px',
    height: '24px',
    marginRight: '4px',
    [theme.breakpoints.up('xl')]: {
      width: '28px',
      height: '28px',
    },
    [theme.breakpoints.up(3000)]: {
      width: '36px',
      height: '36px',
    },
  },
}));
