import { action } from 'src/store/common';
import { IViewConfig } from 'src/typings/viewConfig';

export const ActionTypes = {
  SET_PLACEMENT_VIEW_CONFIG: '[PLACEMENT]SET_PLACEMENT_VIEW_CONFIG',
};

export const PlacementViewConfigActions = {
  setPlacementViewConfig: action<IViewConfig>(ActionTypes.SET_PLACEMENT_VIEW_CONFIG),
};
