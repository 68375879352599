import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { useState } from 'react';
import { getBitrixFields } from 'src/api';
import axios from 'axios';

export const useGetBitrixFields = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<any>(null);
  const [entityValues, setEntityValues] = useState<{
        [key: string]: any
    }|null>(null);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchFields = (project: IProject) => {
    setIsLoading(true);
    Promise.all([
      getBitrixFields(jwt, project.id),
      axios.get(`${project.webhookUrl}crm.deal.fields.json`),
    ]).then(([fieldsValue, values]) => {
      setFields(fieldsValue.data['hydra:member'][0]);
      const { data } = values;
      const { result } = data;
      setEntityValues(result);
      setIsLoading(false);
    });
  };

  return {
    isLoading,
    fields,
    entityValues,
    fetchFields,
  };
};
