import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Actions } from 'src/store/requests/type-of-property/actions';
import { useBuildType, useLanguageState } from 'src/hooks';
import { Actions as ActionsOfTypes } from 'src/store/type-displayed-spinner/actions';
import { IPlacementType } from 'src/store/type-displayed-spinner/types';
import { IFrontConfigTypeEnum } from 'src/typings/table';

export const useChangeValue = (listOfType: IPlacementType[]) => {
  const dispatch = useDispatch();
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();

  const handleChangeType = useCallback((value: string) => {
    const findingType = listOfType.find((type) => type.title[currentAppLanguage] === value);
    if (value) dispatch(Actions.changeSelectedTypePlacement(value));
    if (findingType) {
      dispatch(ActionsOfTypes.setTypeDisplayedSpinner(findingType.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => view.isEnabled)[0].type));
    }
  }, [dispatch, currentAppLanguage]);

  return {
    handleChangeType,
  };
};
