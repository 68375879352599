import React, { useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { IComplex, ICategory, IStatus } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { patchPlacementStatus } from 'src/api';

interface IStatusConnectDeal {
  complexes: IComplex[];
  statuses: {
    [key: string]: IStatus[];
  }
}

export const StatusConnectDeal: React.FC<IStatusConnectDeal> = ({ complexes, statuses }) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, statusId: number) => {
    patchPlacementStatus(jwt, statusId, {
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Box>
      <Box fontSize="24px" mb={2} fontWeight={600}>
        Choose statuses that allow you to attach units to the deal (via the &apos;Attach the unit to the deal&apos; button)
      </Box>
      <Box display="flex" flexWrap="wrap">
        {complexes.map((comp) => (
          <Box key={comp['@id']} mr={4} mb={4}>
            <Box mb={3} textAlign="center" fontWeight="500" fontSize="18px">{comp.title[comp.language]}</Box>
            {statuses[comp['@id']] && statuses[comp['@id']].map((status) => (
              <Box key={status['@id']} display="flex" alignItems="center" mb={2}>
                <Box
                  display="flex"
                  width="170px"
                  pr={2}
                  alignItems="center"
                  fontSize="16px"
                  lineHeight="20px"
                  fontWeight={600}
                >
                  <Box
                    width="20px"
                    height="20px"
                    mr={1}
                    borderRadius="5px"
                    title="Цвет в шахматке"
                    style={{
                      backgroundColor: status.crosstableColor || '#fff',
                    }}
                  />
                  <Box flex="1">
                    {status.crosstable_name && status.crosstable_name[comp.language]}
                  </Box>
                </Box>
                <Box pr={2}>
                  <Checkbox
                    onChange={(e) => handleCheckbox(e, status.id)}
                    name="amoCanBeSetForDeal"
                    color="primary"
                    defaultChecked={status.amoCanBeSetForDeal}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
