import { useCallback } from 'react';
import _, { isString } from 'lodash';

import { useSetFilterParams } from 'src/hooks';
import { useGetInfrastructureValues } from 'src/hooks/infrastructure';
import { IRangeAndSetData } from '../../../../typings/filter';

export const useCheckboxState = (
  nameOfField: string,
) => {
  const {
    filterParams,
    filterInfrastructureData,
    infrastructureFilters,
  } = useGetInfrastructureValues();
  const { handleWriteToReduxInfrastructure } = useSetFilterParams();

  const handleSelectSingleCheckbox = useCallback((itemValue: IRangeAndSetData, groupName: string) => {
    if (filterParams[nameOfField]?.isDisabled) {
      return;
    }

    if (filterInfrastructureData && infrastructureFilters?.length) {
      if (itemValue.value === filterParams[nameOfField]?.data) {
        handleWriteToReduxInfrastructure(
          nameOfField,
          '',
          [
            ..._.difference(filterInfrastructureData.infrastructureFilteredIds, itemValue.key),
          ],
          false,
        );
      } else {
        const infrastructureSingleElement = infrastructureFilters.filter((item) => item.attribute === groupName);
        const infrastructureSingleElementData = infrastructureSingleElement[0].data.reduce((acc: string[], cur: (string | IRangeAndSetData)) => {
          return [
            ...acc,
            isString(cur) ? cur : cur?.key,
          ];
        }, [] as string[]);
        const updateSingle = _.difference(filterInfrastructureData.infrastructureFilteredIds, infrastructureSingleElementData);

        handleWriteToReduxInfrastructure(
          nameOfField,
          itemValue.value,
          [
            ...updateSingle.concat(itemValue.key),
          ],
          false,
        );
      }
    }
  }, [
    filterParams,
    nameOfField,
    handleWriteToReduxInfrastructure,
    filterInfrastructureData,
    infrastructureFilters,
  ]);

  return {
    filterValue: filterParams[nameOfField]?.data || '',
    handleSelectSingleCheckbox,
  };
};
