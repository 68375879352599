import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { DropdownInline } from 'src/components/dropdowns/DropdownInline';
import { IFilterTypeProperty } from 'src/components/FilterTypeProperty/types';
import {
  useChangeValue,
} from 'src/components/FilterTypeProperty/hooks';
import { getInfrastructureFilters } from 'src/store/infrastructure/selectors';
import { getListOfTypeDisplayedSpinner } from 'src/store/type-displayed-spinner/selectors';
import { useAppVisibilityBtn, useBuildType, useLanguageState } from 'src/hooks';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useGetRequestAppData } from 'src/hooks/app';
import { IFrontConfigTypeEnum } from 'src/typings/table';

export const FilterTypeProperty: React.FC<IFilterTypeProperty> = ({
  title,
}) => {
  const { getTranslations } = useTranslations();
  const infrastructureFilter = useSelector(getInfrastructureFilters);
  const listOfType = useSelector(getListOfTypeDisplayedSpinner);
  const { handleChangeType } = useChangeValue(listOfType);
  const { appRequestData } = useGetRequestAppData();
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();
  const [sortedList, setSortedList] = useState<string[]>([]);
  const { show_prefilter_placement_type_selector: showPrefilterPlacementTypeSelector } = useAppVisibilityBtn();

  useEffect(() => {
    const temp: string[] = [];
    listOfType.forEach((type) => {
      const views = type.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => view.isEnabled).length;
      if (isCrossTable) {
        if (views) {
          temp.push(type.title[currentAppLanguage]);
        }
      } else if (views) {
        temp.push(type.title[currentAppLanguage]);
      }
    });
    if (infrastructureFilter && !!Object.keys(infrastructureFilter).length) {
      temp.push(locals.appTranslations.appInfrastructure);
    }
    setSortedList(temp);
  }, [listOfType, infrastructureFilter]);

  return (
    <div>
      {title ? getTranslations(title) : ''}
      {' '}
      {showPrefilterPlacementTypeSelector && (
        <>
          <DropdownInline
            dropdownInfo={sortedList}
            onChange={handleChangeType}
          />
          {' '}
          {isCrossTable ? getTranslations(locals.appTranslations.appFromIn) : ''}
          {' '}
          {isCrossTable ? appRequestData?.crosstable_title : ''}
        </>
      )}
    </div>
  );
};
