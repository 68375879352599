import React, {
  ReactEventHandler, SyntheticEvent, useEffect, useState,
} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ISpinnerDataPossibilityInnerData, IWindRose } from 'src/typings/spinner';
import { useStyles } from './styles';

export interface IWindRoseInterface {
  config: IWindRose | null;
  randomSpinnerId?: string;
  onTop?: boolean;
  isAppSpinner?: boolean;
  isGallery?: boolean;
  onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>, isLoaded: boolean) => void;
}

export const WindRose: React.FC<IWindRoseInterface> = ({
  config,
  randomSpinnerId = '',
  onTop = false,
  isAppSpinner = false,
  isGallery = false,
  onLoad,
}) => {
  const styles = useStyles({});
  return (
    <div
      id={`wind_rose${randomSpinnerId}`}
      className={`${
        styles.windRose
      } ${
        onTop && styles.onTop
      } ${
        isGallery && styles.isGallery
      }`}
      style={{
        height: '100%',
        display: (!config?.src || config?.disabled) ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${config?.bg_src})`,
      }}
    >
      <div className={`${config?.bg_src ? styles.arrowWrapperWithBg : styles.arrowWrapper} img-wrapper`}>
        <img
          alt="icon"
          className={config?.bg_src ? styles.arrowWithBg : styles.arrow}
          src={config?.src}
          onLoad={(e) => (onLoad && onLoad(e, true))}
          onError={(e) => (onLoad && onLoad(e, false))}
        />
      </div>
    </div>
  );
};
