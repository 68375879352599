import { IAppState } from 'src/store/types';

export const getCurrentSpinner = (state: IAppState) => state.spinner.currentSpinner;
export const getAppWindRose = (state: IAppState) => state.spinner.appWindRose;
export const getEntityConfig = (state: IAppState) => state.spinner.entityConfig;
export const getStopFrames = (state: IAppState) => state.spinner.spinnerStopFrames;
export const getCounters = (state: IAppState) => state.spinner.counter;
export const currentSpinnerRerenderComplete = (state: IAppState) => state.spinner.renderComplete;
export const currentSpinnerBacklightSvg = (state: IAppState) => state.spinner.backlightSvg;
export const getHighlightPolygons = (state: IAppState) => state.spinner.highlightPolygons;
export const getSpinnerMode = (state: IAppState) => state.spinner.spinnerMode;
