export enum OSType {
  MAC = 'Mac',
  Windows = 'Windows',
  Linux = 'Linux',
}

export const getOs = () => {
  const os = ['Windows', 'Linux', 'Mac']; // add your OS values
  return os.find((v) => window.navigator.userAgent.indexOf(v) >= 0);
};
