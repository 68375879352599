import React, { useEffect, useRef } from 'react';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { ButtonApp } from 'src/components/Button';
import { IComplex, ICategory, IStatus } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { patchNetHuntEmptyOpportunityStatuses, postNetHuntEmptyOpportunityStatuses } from 'src/api';
import {
  useGetEmptyOpportunityStatuses,
} from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetEmptyOpportunityStatuses';
import { useStyles } from '../styles';

interface IStatusOnZeroDeal {
  complexes: IComplex[];
  statuses: {
    [key: string]: IStatus[];
  }
}

export const StatusOnZeroDeal: React.FC<IStatusOnZeroDeal> = ({ complexes, statuses }) => {
  const styles = useStyles({});
  const refZero = useRef<any>({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const { emptyOpportunityStatuses, fetchEmptyOpportunityStatuses, isLoading } = useGetEmptyOpportunityStatuses();

  const handleChangeZero = (complex: string, value: any) => {
    refZero.current[complex] = value;
  };

  useEffect(() => {
    fetchEmptyOpportunityStatuses(complexes.map((comp) => comp['@id']));
  }, [complexes]);

  const saveChangesZero = () => {
    Object.keys(refZero.current).forEach(async (compKey) => {
      const statusIri = emptyOpportunityStatuses?.find((status) => status.complex === compKey);
      const data = {
        complex: compKey,
        placementStatus: refZero.current[compKey],
      };
      if (statusIri && statusIri['@id'] && statusIri.id) {
        await patchNetHuntEmptyOpportunityStatuses(jwt, statusIri.id, data);
      } else {
        await postNetHuntEmptyOpportunityStatuses(jwt, data);
      }
    });
  };

  return (
    <Box>
      <Box fontSize="24px" mb={2} fontWeight={600}>
        The Selection of a Zero Status
      </Box>
      {!isLoading && complexes.map((row) => {
        let defaultVal = '';
        emptyOpportunityStatuses?.forEach((status) => {
          if (status.complex === row['@id']) {
            defaultVal = status.placementStatus;
          }
        });
        return statuses[row['@id']] && (
        <Box
          key={`zeroStatus${row['@id']}`}
          fontSize="16px"
          mb={2}
          fontWeight={500}
          display="flex"
          alignItems="center"
        >
          <Box mr={4}>{row.title[row.language]}</Box>
          <Box>
            <Select
              id={`zeroStatus${row['@id']}`}
              defaultValue={defaultVal}
              style={{
                minWidth: 100,
              }}
              onChange={(e) => {
                handleChangeZero(row['@id'], e.target.value);
              }}
            >
              {statuses[row['@id']].map((status) => (
                <MenuItem key={status['@id']} value={status['@id']}>
                  <Box display="flex" alignItems="center">

                    <Box
                      width="10px"
                      height="10px"
                      mr={1}
                      borderRadius="2px"
                      title="Цвет в шахматке"
                      style={{
                        backgroundColor: status.crosstableColor || status.color,
                      }}
                    />
                    {status.crosstable_name[row.language]}
                  </Box>

                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        );
      })}
      <Box mt={4}>
        <ButtonApp
          onClick={saveChangesZero}
          text="Save changes"
          color="primary"
          variant="contained"
          size="large"
          className={styles.btn}
        />
      </Box>
    </Box>
  );
};
