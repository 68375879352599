import { IAction } from 'src/typings';
import {
  IViewConfig,
} from 'src/typings/viewConfig';
import { ActionTypes } from 'src/store/placementViewConfig/actions';

export const initialState: IViewConfig | {} = {};

export const placementViewConfigReducer = (
  state = initialState,
  { type, payload }: IAction,
): IViewConfig | {} => {
  switch (type) {
    case ActionTypes.SET_PLACEMENT_VIEW_CONFIG:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
