import { action } from 'src/store/common';
import { IStatus } from 'src/typings/complexes';
import { IView2dData, IDisplayPlacementData, EFloorType } from 'src/store/view-2d/reducer';
import { IFilterAllData } from 'src/typings/filter';

export const ActionTypes = {
  SET_FLOOR_LIST: '[VIEW2D]SET_FLOOR_LIST',
  SET_VIEW2D_DATA: '[VIEW2D]SET_VIEW2D_DATA',
  SET_VIEW2D_DATA_LIST: '[VIEW2D]SET_VIEW2D_DATA_LIST',
  SET_VIEW2D_SELECTED_ID: '[VIEW2D]SET_VIEW2D_SELECTED_ID',
  SET_VIEW2D_SELECTED_TYPE: '[VIEW2D]SET_VIEW2D_SELECTED_TYPE',
  SET_VIEW2D_SELECTED_TYPE_IS_INIT_ACTION: '[VIEW2D]SET_VIEW2D_SELECTED_TYPE_IS_INIT_ACTION',
  SET_FLOOR_ITEM_TYPE: '[VIEW2D]SET_FLOOR_ITEM_TYPE',
  SET_IS_RERENDER: '[VIEW2D]SET_IS_RERENDER',
  SET_VIEW2D_DISPLAY_TYPES: '[VIEW2D]SET_VIEW2D_DISPLAY_TYPES',
  SET_VIEW2D_FLOOR_SCROLL_SIZE: '[VIEW2D]SET_VIEW2D_FLOOR_SCROLL_SIZE',
  SET_VIEW2D_STATUSES: '[VIEW2D]SET_VIEW2D_STATUSES',
  CLEAR_VIEW2D: '[VIEW2D]CLEAR_VIEW2D',
  UPDATE_PLACEMENT: '[VIEW2D]UPDATE_PLACEMENT',
};

export const Actions = {
  setFloorList: action<string[]>(ActionTypes.SET_FLOOR_LIST),
  setView2dData: action<IView2dData>(ActionTypes.SET_VIEW2D_DATA),
  setView2dDataList: action<IFilterAllData[]>(ActionTypes.SET_VIEW2D_DATA_LIST),
  setView2dSelectedId: action<string[]>(ActionTypes.SET_VIEW2D_SELECTED_ID),
  setView2dSelectedType: action<string>(ActionTypes.SET_VIEW2D_SELECTED_TYPE),
  setView2dSelectedTypeIsInitAction: action<boolean>(ActionTypes.SET_VIEW2D_SELECTED_TYPE_IS_INIT_ACTION),
  setView2dDisplayTypes: action<IDisplayPlacementData[]>(ActionTypes.SET_VIEW2D_DISPLAY_TYPES),
  setView2dFloorScrollSize: action<number>(ActionTypes.SET_VIEW2D_FLOOR_SCROLL_SIZE),
  setView2dStatuses: action<IStatus[]>(ActionTypes.SET_VIEW2D_STATUSES),
  setFloorItemType: action<EFloorType>(ActionTypes.SET_FLOOR_ITEM_TYPE),
  clearView2d: action(ActionTypes.CLEAR_VIEW2D),
  setIsRerender: action(ActionTypes.SET_IS_RERENDER),
  updatePlacement: action(ActionTypes.UPDATE_PLACEMENT),
};
