import React from 'react';
import Box from '@mui/material/Box';
import { AppSpinner } from 'src/components/ApartmentPageV2/AppSpinner';
import { ReactImageGalleryItemWithSpinnerData } from 'src/components/ApartmentGallery/types';
import { useStyles } from '../styles';

interface IApartmentDescriptionSpinner {
  title: string;
  combineIds: string[];
  spinnerState: ReactImageGalleryItemWithSpinnerData[];
}
export const ApartmentDescriptionSpinner: React.FC<IApartmentDescriptionSpinner> = ({
  title,
  combineIds,
  spinnerState,
}) => {
  const styles = useStyles({});
  return (
    <>
      {spinnerState.map((spinner) => {
        if (spinner.spinnerData) {
          return (
            <Box key={spinner.original} className={styles.spinnerWrapperMobile}>
              <div className={styles.spinnerTitleWrapperMobile}>{title}</div>
              <AppSpinner
                spinnerData={spinner.spinnerData}
                type={spinner.type}
              />
            </Box>
          );
        }
        return '';
      })}
    </>
  );
};
