import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperDescription: {
    maxWidth: '32%',
    flexGrow: '1',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      paddingLeft: '0px',
      maxWidth: '100%',
    },
    '& .gallery-playcanvas-v2': {
      border: 'hidden',
      width: '100%',
    },
  },
  wrapperButtonControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0px',
      flexDirection: 'column',
      '& button': {
        marginTop: '20px',
        padding: '17px',
      },
    },
  },
  buttonDefault: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '19px 40px',
    minWidth: '184px',
    boxShadow: 'none',
    fontSize: '14px',
    textTransform: 'initial',
    margin: '0px 5px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 'inherit',
    },
  },
  buttonBookNow: {
    color: 'white',
  },
  buttonRequestNow: {
    color: theme.palette.primary.main,
  },
  spinnerWrapperMobile: {
    marginBottom: '36px',
    '& .spinner_place-v2': {
      [theme.breakpoints.down('md')]: {
        aspectRatio: '16 / 9',
        objectFit: 'contain',
      },
    },
  },
  spinnerTitleWrapperMobile: {
    fontSize: '16px',
    fontWeight: '700',
    height: '20px',
    margin: '0 20px 16px 20px',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 28px 16px 28px',
      height: 'unset',
      fontSize: '24px',
      lineHeight: '36px',
    },
  },

  roomsTitle: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
    height: '28px',
    margin: '0 0 20px 0',
  },

  roomsWrapperMobile: {
  },

  roomsWrapper: {
    '& .rooms-title': {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '28px',
      letterSpacing: '-0.04px',
      marginBottom: '20px',
    },
  },

  roomsTitleMobile: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
    height: '20px',
    margin: '0 20px 16px 20px',
  },

  ios: {
    padding: '0 20px 0 20px',
  },
  frameWrapper: {
    marginTop: '32px',
    '& .gallery-playcanvas-v2.mac-background': {
      [theme.breakpoints.down('md')]: {
        aspectRatio: 'unset',
        height: '136px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: '8px',
        '& svg': {
          width: '68px',
          height: '68px',
          color: theme.palette.primary.main,
        },
      },
    },
    '& .gallery-playcanvas-v2': {
      [theme.breakpoints.down('md')]: {
        aspectRatio: '9 / 9',
        objectFit: 'contain',
      },
    },
  },
  frameWrapperTitle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20px',
    marginBottom: '16px',
    padding: '0 20px',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  flatShowLogoWrapper: {
    width: '100%',
    height: '24px',
    backgroundColor: '#B3B3B3',
    marginTop: '27px',
  },
  flatShowLogo: {
    width: '100%',
    height: '24px',
  },
}));
