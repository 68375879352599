import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  rootPopOver: {
    pointerEvents: 'none',
    '& .MuiPaper-rounded': {
      borderRadius: '10px',
      overflow: 'visible',
    },
  },
}));
