import { ApiUrl, crosstableWithoutApi } from 'src/constants';
import { httpService } from 'src/services';
import { IFeedbackField } from 'src/components/profileComponents/FeedbackSettings';
import { ICreatioStage } from 'src/typings/complexes';
import { HydraResponse, Response } from 'src/api/hydraResponse';

interface TypeBase {
  '@context': string;
  '@id': string;
  '@type': string;
}

export type TypeMap<T> = {
  // @ts-ignore
  [key in T]: string;
};

type creatioFieldType = 'Edm.String' | 'Edm.Int32' | 'Edm.Decimal' | 'Edm.DateTimeOffset' | 'Edm.Guid';
type creatioFieldSeparator = ',';

export const creatioOpportunityTypeMap: TypeMap<creatioFieldType> = {
  'Edm.String': 'string',
  'Edm.Int32': 'number',
  'Edm.Decimal': 'number',
  'Edm.DateTimeOffset': 'date',
  'Edm.Guid': 'number',
};

interface CreatioFields extends TypeBase {
  creatioProject: string
  fieldEmptyValue: string
  fieldName: string
  fieldType: creatioFieldType;
  fsArrayFieldSeparator: creatioFieldSeparator;
  fsFieldName: string
  id: number
}

interface CreatioOption {
  entityName?: string;
  isNullable: boolean;
  title: string
  type: creatioFieldType
}

export interface CreatioOptionData {
  isDynamic: boolean,
  isImmutable: boolean,
  isMultiple: boolean,
  isReadOnly: boolean,
  isRequired: boolean,
  title: string,
  type: string,
}

export interface PostCreatioProject {
  domain: string;
  login: string;
  password: string;
  oauth2Enpoint: string;
  oauth2ClientId: string;
  oauth2ClientSecret: string
}

export interface PatchCreatioDeal {
  externalId?: string;
  creatioProject?: string;
  placements: string[] | null;
  creatioStage?: string;
}

export function getCreatioFieldsOptions(token: string = '', id : string|number): Promise<Response<CreatioOption[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().creatioOpportunityMetadata}?creatioProjectId=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioLeadMetadata(token: string = '', id : string|number): Promise<Response<CreatioOption[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().creatioLeadMetadata}?creatioProjectId=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioFieldsValues(token: string = '', id : string|number): Promise<HydraResponse<CreatioFields[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().creatioFields}?creatioProjectId=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchCreatioFieldsValues(token: string = '', id : string|number, data: any): Promise<HydraResponse<CreatioFields[]>> {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioFields}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioProjects(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioProjects}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioProjectById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioProjects}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postCreatioProject(token: string = '', data: PostCreatioProject) {
  return httpService.post(`${ApiUrl.getApiUrl().creatioProjects}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchCreatioProject(token: string = '', id: string, data: PostCreatioProject) {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioProjects}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postCreatioProjectByStageId(token: string = '', id: string, data: PostCreatioProject) {
  return httpService.post(`${ApiUrl.getApiUrl().creatioProjects}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioStages(token: string = ''): Promise<ICreatioStage[]> {
  return httpService.get(`${ApiUrl.getApiUrl().creatioStages}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioStageById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioStages}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioConfigMessages(token: string = ''): Promise<Response<any>> {
  return httpService.get(`${ApiUrl.getApiUrl().creatioFormConfigurationMessages}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioConfig(token: string = ''): Promise<Response<any>> {
  return httpService.get(`${ApiUrl.getApiUrl().creatioFormConfiguration}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postCreatioFormConfigurations(token: string = '', data: IFeedbackField): Promise<Response<any>> {
  return httpService.post(`${ApiUrl.getApiUrl().creatioFormConfiguration}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchCreatioFormConfigurations(token: string = '', id: string, data: IFeedbackField): Promise<Response<any>> {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioFormConfiguration}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function deleteCreatioFormConfigurations(token: string = '', id: string, data: IFeedbackField): Promise<Response<any>> {
  return httpService.delete(`${ApiUrl.getApiUrl().creatioFormConfiguration}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postGenerateCreatioStages(token: string = '', data: {creatioProjectId: number}): Promise<{data: ICreatioStage[]}> {
  return httpService.post(`${ApiUrl.getApiUrl().creatioStagesCreateAuto}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchCreatioStageById(token: string = '', id: string, data: ICreatioStage) {
  return httpService.patch(`${crosstableWithoutApi}${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postCreatioCreateAutoStage(token: string = '', projectId: string) {
  return httpService.post(`${ApiUrl.getApiUrl().creatioAutomatically}`, {
    creatioProjectId: projectId,
  }, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioPlacementStatusOnZeroDeals(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioPlacementStatusOnZeroDeals}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioPlacementStatusOnZeroDealsById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioPlacementStatusOnZeroDeals}?${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export interface CreatioPlacementStatusOnZeroDeal {
  complex: string;
  placementStatus: string;
}

export function patchCreatioPlacementStatusOnZeroDeal(token: string = '', id: string, data: CreatioPlacementStatusOnZeroDeal) {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioStages}?${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioDeals(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioOpportunities}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioDeal(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioOpportunities}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioResponsibleUsers(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioPlacementOpporunities}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchCreatioDeal(token: string = '', id: string | number, data: PatchCreatioDeal) {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioOpportunities}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexesByCreatioDeveloper(token: string = '', projectId: string|number, email: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?developers.creatioProject=${projectId}&users.email=${email}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
