import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useSetCurrentSpinner } from 'src/hooks/spinner';
import { useSetCurrentEntity } from 'src/hooks/app';
import { Actions as ActionsPano } from 'src/store/panorama/actions';
import { requestSelectors } from 'src/store/requests/selectors';
import { useGetTypeOfProperty, useSetFilterParams } from 'src/hooks';
import { ListOfDropdown, ListOfKeysFilterAllData } from 'src/typings/filter';

enum EViewTypes {
  housePanorama = 'house_panorama_v2_views'
}

export const useHouseActions = () => {
  const dispatch = useDispatch();
  const { handleSetCurrentSpinner } = useSetCurrentSpinner();
  const { handleSetCurrentEntity } = useSetCurrentEntity();
  const { handleWriteToRedux } = useSetFilterParams();
  const spinnerSvgCurrentData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const filterAllData = useSelector(requestSelectors.filter.getFilterInitialAllData);
  const { typeOfProperty } = useGetTypeOfProperty();

  const handleHouseSelected = useCallback((name: string, nameId: string, viewType: string|undefined, viewId: string|undefined) => {
    if (viewType && viewId && viewType === EViewTypes.housePanorama) {
      dispatch(ActionsPano.setLookAtMyPano(true));
      dispatch(ActionsPano.setCurrentPanorama({
        entity: name,
        entityId: nameId,
        id: Number(viewId),
      }));
    }
    handleSetCurrentSpinner(name, nameId);
    handleSetCurrentEntity(name, nameId);
    if (spinnerSvgCurrentData) {
      const houseNumber = spinnerSvgCurrentData?.[name][nameId]?.additional_data?.[ListOfKeysFilterAllData.placementHouse];
      const typeOfField = typeOfProperty && filterAllData?.[typeOfProperty]?.filter_default_values
        .filter((item) => item.attribute === ListOfKeysFilterAllData.placementHouse)[0]?.type;
      if (houseNumber !== undefined && typeOfField !== undefined) {
        if (typeOfField === ListOfDropdown.dropdownCheckboxList || typeOfField === ListOfDropdown.datalistCheckboxList) {
          handleWriteToRedux(ListOfKeysFilterAllData.placementHouse, [houseNumber], true);
        } else {
          handleWriteToRedux(ListOfKeysFilterAllData.placementHouse, houseNumber, true);
        }
      }
    }
  }, [spinnerSvgCurrentData, handleSetCurrentSpinner, handleWriteToRedux, filterAllData, typeOfProperty]);

  return {
    handleHouseSelected,
  };
};
