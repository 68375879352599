import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperHeader: {
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
  },
  headerLogo: {
    pointerEvents: 'all',
    position: 'relative',
    '& > a': {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'flex',
    },
  },
  headerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'all',
    backgroundColor: `${theme.palette.background.default}!important`,
    borderRadius: '10px',
    minWidth: '44px',
    width: '44px',
    height: '44px',
    '& #svgPath': {
      transform: 'scale(.5)',
    },

    [theme.breakpoints.down('md')]: {
      minWidth: '40px',
      width: '40px',
      height: '40px',
      backgroundColor: `${theme.palette.background.default}`,
    },
    [theme.breakpoints.up('xl')]: {
      width: '56px',
      height: '56px',
      fontSize: '16px',
      borderRadius: '12px',
      '& #svgPath': {
        transform: 'scale(.75)',
      },
      '& svg': {
        fontSize: '30px!important',
      },
    },
    [theme.breakpoints.up(3000)]: {
      width: '88px',
      height: '88px',
      fontSize: '24px',
      borderRadius: '20px',
      '& #svgPath': {
        transform: 'scale(1)',
      },
      '& svg': {
        fontSize: '40px!important',
      },
    },
    [theme.breakpoints.between('sm', 'md')]: {
      minWidth: '44px',
      width: '44px',
      height: '44px',
    },
    '&:hover': {
      backgroundColor: '#F6F7F6',
      opacity: '.7',
    },
    '& span[class*="startIcon"]': {
      margin: '0px',
    },
  },
  headerWrapperButton: {
    display: 'flex',
    '& > *': {
      marginLeft: '9px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '8px',
      },
    },
    '&:empty': {
      display: 'none',
    },
  },
  appLogo: {
    maxHeight: '44px',
    maxWidth: '140px',
    objectFit: 'contain',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: '56px',
    },
    [theme.breakpoints.up(3000)]: {
      maxHeight: '88px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 'unset',
    },
  },
  callToUs: {
    color: 'transparent',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));
