import React from 'react';
import { Pagination } from '@mui/material';
import { PaginationProps } from '@mui/material/Pagination/Pagination';
import { useStyles } from './styles';

export const AppPagination: React.FC<PaginationProps> = ({ ...props }) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Pagination
        variant="outlined"
        shape="rounded"
        {...props}
      />
    </div>
  );
};
