import React, { useCallback, useEffect } from 'react';
import { Theme, styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Slider, { SliderThumb, SliderProps } from '@mui/material/Slider';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { ButtonApp } from 'src/components/Button';
import { locals } from 'src/constants';
import { useTranslations } from 'src/hooks/translataions';
import { IRangeAndSet, ScaleStepType } from 'src/typings/filter';
import { IMainColors } from 'src/typings/app';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { useSliderState } from './useSliderState';

// const AirbnbAppSlider = withStyles((theme: Theme) => createStyles({
//   root: {
//     color: '#3a8589',
//     height: 3,
//     padding: '13px 0',
//   },
//   thumb: (props: any) => ({
//     height: 27,
//     width: 27,
//     background: props.maincolor.filterBackgroundColor,
//     border: `1px solid ${theme.palette.primary.main}`,
//     marginTop: -12,
//     marginLeft: -13,
//     boxShadow: '#ebebeb 0px 2px 2px',
//     '&:focus,&:hover': {
//       boxShadow: '#ccc 0px 2px 3px 1px',
//     },
//     '& .bar': {
//       height: 9,
//       width: 1,
//       backgroundColor: theme.palette.primary.main,
//       marginLeft: 1,
//       marginRight: 1,
//     },
//   }),
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     height: 3,
//     color: theme.palette.primary.main,
//   },
//   rail: {
//     color: '#d8d8d8',
//     opacity: 1,
//     height: 3,
//   },
// }))(Slider);

interface AirbnbAppSliderProps extends SliderProps{
  maincolor: IMainColors
}

const AirbnbAppSlider = styled(Slider)<AirbnbAppSliderProps>(({ theme, ...props }) => {
  return ({
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
      height: 27,
      width: 27,
      background: props.maincolor.filterBackgroundColor,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '#ebebeb 0px 2px 2px',
      '&:focus,&:hover': {
        boxShadow: '#ccc 0px 2px 3px 1px',
      },
      '& .airbnb-bar': {
        height: 9,
        width: 1,
        backgroundColor: theme.palette.primary.main,
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-track': {
      height: 3,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSlider-rail': {
      color: '#d8d8d8',
      opacity: 1,
      height: 3,
    },
  });
});

export interface IAirbnbSlider {
  enableSwipe?: boolean;
  range: {
    min: number,
    max: number,
  };
  setPlaceholder?: any;
}

export interface IAirbnbSliderComponent extends IAirbnbSlider {
  dropdownInfo: IRangeAndSet;
  fieldName: string;
  currentValue?: string[] | undefined;
  handlerSubmit?: Function;
}

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}
export const AirbnbSlider: React.FC<IAirbnbSliderComponent> = ({
  dropdownInfo,
  enableSwipe,
  fieldName,
  range,
  setPlaceholder,
  currentValue,
  handlerSubmit = () => {},
}) => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const { getTranslations } = useTranslations();
  const {
    sliderState,
    setSliderState,
    handleChangeFromInput,
    handleChangeToInput,
    controllerWriteValues,
  } = useSliderState(
    { range },
    fieldName,
    currentValue,
    setPlaceholder,
  );

  const handleChangeCommitted = useCallback(() => {
    if (enableSwipe) {
      controllerWriteValues();
    }
  }, [enableSwipe, controllerWriteValues]);

  const handleOnBlurInput = useCallback(() => {
    if (!sliderState[0]) {
      setSliderState([+range.min, sliderState[1]]);
    }
  }, [sliderState, range, setSliderState]);

  return (
    <div>
      <div className={styles.wrapperAirbnbGraph}>
        <AirbnbAppSlider
          components={{
            Thumb: AirbnbThumbComponent,
          }}
          // @ts-ignore
          maincolor={mainColor}
          value={sliderState}
          min={+range.min}
          max={+range.max}
          step={dropdownInfo.step_type === ScaleStepType.float ? 0.01 : 1}
          onChange={(e: any, value: any) => {
            setSliderState(value);
          }}
          onChangeCommitted={handleChangeCommitted}
        />
      </div>
      <div className={`rangeInput ${styles.rangeInput}`}>
        <div>
          <span>{`${getTranslations(locals.appTranslations.appFrom)}: `}</span>
          <div className="ui input">
            <NumberFormat
              thousandSeparator=" "
              disabled={enableSwipe}
              allowNegative={false}
              decimalScale={dropdownInfo.precision || 0}
              value={sliderState[0] || ''}
              placeholder="From"
              onValueChange={(e) => {
                if (e.floatValue !== 0) {
                  handleChangeFromInput(e);
                }
              }}
              onBlur={handleOnBlurInput}
            />
          </div>
        </div>
        <div>
          <span>{`${getTranslations(locals.appTranslations.appTo)}: `}</span>
          <div className="ui input">
            <NumberFormat
              thousandSeparator=" "
              disabled={enableSwipe}
              allowNegative={false}
              decimalScale={dropdownInfo.precision || 0}
              value={sliderState[1] || ''}
              placeholder="To"
              onValueChange={(e) => {
                if (e.floatValue !== 0) {
                  handleChangeToInput(e);
                }
              }}
              onBlur={handleOnBlurInput}
            />
          </div>
        </div>
      </div>
      {!enableSwipe && (
        <div className={styles.applyWrapperFilter}>
          <ButtonApp
            isCapitalize
            text={getTranslations(locals.appTranslations.appClear)}
            color="secondary"
            onClick={() => setPlaceholder('', true)}
          />
          <ButtonApp
            isCapitalize
            text={getTranslations(locals.appTranslations.appApply)}
            variant="contained"
            style={{ color: 'white' }}
            onClick={() => {
              handlerSubmit();
              controllerWriteValues();
            }}
          />
        </div>
      )}
    </div>
  );
};
