import { useCallback, useEffect, useState } from 'react';

import {
  useGetFilterParams,
  useSetFilterParams,
} from 'src/hooks';

export const useScaleState = (
  fieldKeyName: string,
  placeholder: string,
) => {
  const { filterParams } = useGetFilterParams();
  const { handleWriteToRedux } = useSetFilterParams();
  const [formatScalePlaceholder, setScalePlaceholder] = useState('');

  const handleSetScalePlaceholder = useCallback((range: string, isDelete?: boolean) => {
    if (isDelete) {
      setScalePlaceholder(`${range}`);

      handleWriteToRedux(fieldKeyName, []);
    } else {
      const splitRange = range.split(' - ');
      setScalePlaceholder(`${placeholder} ${range}`);

      handleWriteToRedux(fieldKeyName, splitRange);
    }
  }, [fieldKeyName, placeholder, handleWriteToRedux]);

  const handleOnClearCheckBox = useCallback(() => {
    handleWriteToRedux(fieldKeyName, []);
  }, [fieldKeyName, handleWriteToRedux]);

  const setInitValue = useCallback((value: string[] | undefined) => {
    if (value?.length) {
      handleWriteToRedux(fieldKeyName, value || []);
    }
  }, [filterParams, fieldKeyName, handleWriteToRedux]);

  useEffect(() => {
    if (filterParams[fieldKeyName]) {
      if (filterParams[fieldKeyName].data.length === 0) {
        setScalePlaceholder('');
      } else {
        const splitRange = filterParams[fieldKeyName]
          .data
          .sort((a: any, b: any) => a - b)
          .join(' - ');
        setScalePlaceholder(`${placeholder} ${splitRange}`);
      }
    }
  }, [fieldKeyName, placeholder, filterParams]);

  return {
    handleOnClearCheckBox,
    formatScalePlaceholder,
    handleSetScalePlaceholder,
    setInitValue,
  };
};
