import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { ButtonApp } from 'src/components/Button';
import { StagesTable } from 'src/components/profileComponents/Integrations/Common/StagesTable';
import { ISortedStatuses } from 'src/components/profileComponents/BitrixCategories/hooks';
import { useParams } from 'react-router-dom';
import {
  getPlacementStatusByComplex, patchCreatioStageById, postGenerateCreatioStages,
} from 'src/api';
import { useGetAuthData, useGetDeveloper } from 'src/hooks';
import { Loader } from 'src/components/Loader';
import { CRMNames, IntegrationType } from 'src/typings/app';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetProject } from 'src/components/profileComponents/Integrations/Bitrix/hooks/useGetProject';
import { toUpperCase } from 'src/helpers/toUpperCase';
import { IStage, IStatus } from 'src/typings/complexes';
import { getComplexLink, getSortedStatuses } from 'src/components/profileComponents/Integrations/tools';

export const CreatioStages: React.FC<{ integration: IntegrationType; }> = ({ integration }) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const { id, categoryId }: { id: string, categoryId: string } = useParams();
  const { isLoading, developer } = useGetDeveloper(id);
  const { project, isLoading: isLoadingProject, fetchProject } = useGetProject(developer?.creatioProject || null);
  const [statuses, setAllStatuses] = useState<ISortedStatuses | null>(null);
  const [stages, setStages] = useState<IStage[] | null>(null);
  const asyncFetching = async () => {
    if (developer) {
      const complexLink = getComplexLink(developer.complexes);
      const { data: statusData } = await getPlacementStatusByComplex(jwt, complexLink);
      const stasusesArray: IStatus[] = statusData['hydra:member'];
      setAllStatuses(getSortedStatuses(stasusesArray));
    }
  };

  useEffect(() => {
    asyncFetching();
  }, [developer]);

  useEffect(() => {
    if (project?.creatioStages) {
      setStages(project?.creatioStages);
    }
  }, [project]);

  // useEffect(() => {
  //   if (developer && stages && statuses) {
  //     const { complexes } = developer;
  //     complexes.forEach((row) => {
  //       stages.forEach((stage) => {
  //         console.log('stage!', stage);
  //         let defaultVal = '';
  //         stage.placementStatuses.forEach((status) => {
  //           const currentSt = statuses[row['@id']].find((value) => {
  //             return value['@id'] === status;
  //           });
  //           if (currentSt) {
  //             defaultVal = currentSt?.['@id'];
  //             handleChange(stage['@id'], `status${row['@id']}`, defaultVal);
  //           }
  //         });
  //       });
  //     });
  //   }
  // }, [developer, statuses, stages]);

  const ref = useRef<any>({});
  const handleChange = (stage: string, key: string, value: any) => {
    if (ref.current[stage]) {
      ref.current[stage][key] = value;
    } else {
      ref.current[stage] = {
        [key]: value,
      };
    }
  };

  const handleGenerate = async () => {
    if (project?.id) {
      const { data } = await postGenerateCreatioStages(jwt, {
        creatioProjectId: Number(project.id),
      });
      setStages(data);
    }
  };

  const saveChanges = () => {
    if (developer) {
      const { complexes } = developer;
      Object.keys(ref.current).forEach((stageKey) => {
        ref.current[stageKey].placementStatuses = complexes.reduce((acc: string[], comp) => {
          acc.push(ref.current[stageKey][`status${comp['@id']}`]);
          return acc;
        }, []);
        patchCreatioStageById(jwt, stageKey, ref.current[stageKey]).then();
      });
    }
  };

  if (isLoading || isLoadingProject || !statuses || !developer) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }
  const { title } = developer;
  const currentIntegrationName = toUpperCase(integration) as IntegrationType;

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `Developer ${title}`,
      url: `/profile/${pathList.developers}/${id}`,
    },
    {
      name: `${currentIntegrationName} Integration`,
      url: `/profile/${pathList.developers}/${id}/${integration}/integration`,
    },
    {
      name: `${currentIntegrationName} Funnel Setup`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        Настройка этапов Creatio
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box display="flex" mb={2} justifyContent="space-between">
          <ButtonApp
            text={`${!ref.current.length ? 'Generate stages' : 'Update stages'}`}
            color="primary"
            variant="contained"
            onClick={handleGenerate}
            size="large"
          />
          <ButtonApp
            text="Save changes"
            color="primary"
            variant="contained"
            onClick={saveChanges}
            size="large"
          />
        </Box>
        {statuses && (
        <StagesTable
          stages={stages}
          complexes={developer.complexes}
          statuses={statuses}
          integration={CRMNames.creatio}
          handleChange={handleChange}
        />
        )}
      </Box>
    </Box>
  );
};
