import React from 'react';

export const SvgZoomIn: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="zoom-in">
        <g clipPath="url(#clip0_9_257)">
          <g id="Group 3303">
            <path
              id="Vector"
              d="M5 10.4444C5 11.1594 5.14082 11.8674 5.41443 12.5279C5.68804 13.1885 6.08908 13.7887 6.59464
               14.2942C7.1002 14.7998 7.7004 15.2008 8.36095 15.4745C9.0215 15.7481 9.72947 15.8889 10.4444
                15.8889C11.1594 15.8889 11.8674 15.7481 12.5279 15.4745C13.1885 15.2008 13.7887 14.7998 14.2942
                 14.2942C14.7998 13.7887 15.2008 13.1885 15.4745 12.5279C15.7481 11.8674 15.8889 11.1594 15.8889
                  10.4444C15.8889 9.72947 15.7481 9.0215 15.4745 8.36095C15.2008 7.7004 14.7998 7.1002 14.2942
                   6.59464C13.7887 6.08908 13.1885 5.68804 12.5279 5.41443C11.8674 5.14082 11.1594 5 10.4444 5C9.72947
                    5 9.0215 5.14082 8.36095 5.41443C7.7004 5.68804 7.1002 6.08908 6.59464 6.59464C6.08908 7.1002
                     5.68804 7.7004 5.41443 8.36095C5.14082 9.0215 5 9.72947 5 10.4444Z"
              stroke="#204B59"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M8.11121 10.4444H12.7779"
              stroke="#204B59"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M10.4448 8.11121V12.7779"
              stroke="#204B59"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_4"
              d="M19.0003 18.9999L14.3336 14.3332"
              stroke="#204B59"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9_257">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
