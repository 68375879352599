import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  Accordion: {
    marginBottom: '40px',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiAccordion-root:not(:last-child)': {
      marginBottom: '40px',
      [theme.breakpoints.down('lg')]: {
        marginBottom: '32px',
      },
    },
    '& .MuiButtonBase-root': {
      minHeight: '28px',
    },
    '& .MuiButtonBase-root.Mui-expanded': {
      marginBottom: '20px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '16px',
      },
    },
    '& .MuiAccordion-root:before': {
      position: 'unset',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
      letterSpacing: '-0.032px',
      [theme.breakpoints.down('md')]: {
        '& .MuiTypography-root': {
          lineHeight: '20px',
        },
      },
      [theme.breakpoints.between('sm', 'md')]: {
        '& .MuiTypography-root': {
          lineHeight: '36px',
        },
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '24px',
      height: '24px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 0 20px',
      marginBottom: '32px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0 28px 0 28px',
    },
  },
  summary: {
    padding: '0',
    [theme.breakpoints.down('md')]: {
      minHeight: '40px!important',
      // marginBottom: '16px',
    },
  },
  level: {
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: '16px',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '-0.028px',
    [theme.breakpoints.up('xl')]: {
      width: '48px',
      fontSize: '18px',
      lineHeight: '28px',
    },
    [theme.breakpoints.up(3000)]: {
      width: '62px',
      fontSize: '28px',
      lineHeight: '28px',
    },
  },
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: '20px 0 20px 0',
    lineHeight: '28px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '26px',
      lineHeight: '32px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '52px',
      lineHeight: '64px',
    },
  },
  detail: {
    padding: '0',
  },
  detailTitle: {
    display: 'flex',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    color: theme.palette.text.primary,
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      lineHeight: '24px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px',
      lineHeight: '48px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '38px',
      lineHeight: '62px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px',
      lineHeight: '40px',
    },
    '&.level': {
      lineHeight: '32px',
      [theme.breakpoints.up('xl')]: {
        lineHeight: '48px',
      },
      [theme.breakpoints.up(3000)]: {
        lineHeight: '62px',
      },
    },
  },
  detailValue: {
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px',
      lineHeight: '48px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '44px',
      lineHeight: '56px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '22px',
      lineHeight: '40px',
    },
  },
  detailHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '48px',
    padding: '8px 0',
    fontSize: '14px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
      minHeight: '40px',
      // padding: '8px 0',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '14px 0',
    },
    [theme.breakpoints.up(3000)]: {
      padding: '28px 0',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid var(--Black---20, #E5E5E5)',
    },
  },
}));
