import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { requestSelectors } from 'src/store/requests/selectors';
import { IFilterAllData } from 'src/typings/filter';

export const useGetPlacementDataById = (searchId: string[]|string) => {
  const getAllData = useSelector(requestSelectors.spinner.getSpinnerData)?.['detail-info'].placement;

  const placementData = useMemo(() => {
    let result: IFilterAllData[] = [];
    if (getAllData) {
      result = Object.keys(getAllData).reduce((acc, value): IFilterAllData[] => {
        if (_.isArray(searchId)) {
          if (searchId.includes(value)) {
            acc.push(getAllData[value]);
          }
        } else if (value === searchId) {
          acc.push(getAllData[value]);
        }
        return acc;
      }, [] as IFilterAllData[]);
    }
    return result;
  }, [getAllData, searchId]);

  return {
    placementData,
  };
};
