import { IDogeFields, IDogeFieldsOptional } from 'src/typings/global-config';
import { IFile } from 'src/typings/viewConfig';

export enum IPlacementPdfOrderingEnum {
  EXTERNAL = 'External',
  INTERNAL = 'AutoGenerated',
}
export interface IPlacementPdfOrderings extends IDogeFieldsOptional {
  fileUri?: IFile,
  sortOrder: number,
  type: IPlacementPdfOrderingEnum,
}
export interface IPlacementPdfOrderingsResponse extends IDogeFields {
  placementPdfOrderings: IPlacementPdfOrderings[];
}
