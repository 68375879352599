import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { appIcon } from 'src/assets';
import { IPanoAppartments } from 'src/containers/MainPageApartmentsV2/hooks';
import {
  ApplicationPropertyTypeEnum,
  IGalleryListOfSpinner,
  ReactImageGalleryItemWithSpinnerData,
  SpinnerLabel,
} from '../types';

export const useGenerateGalleryFormat = (
  spinnerApartments: IGalleryListOfSpinner[] | [],
  panoApartments: IPanoAppartments[],
  gallery: string[],
  combineIds: string[],
) => {
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [galleryState, setGalleryState] = useState<ReactImageGalleryItemWithSpinnerData[]>([]);
  const [onlySliderState, setOnlySliderState] = useState<ReactImageGalleryItemWithSpinnerData[]>([]);
  const [onlySpinnerState, setOnlySpinnerState] = useState<ReactImageGalleryItemWithSpinnerData[]>([]);
  const [onlyRoomTourState, setOnlyRoomTourState] = useState<ReactImageGalleryItemWithSpinnerData[]>([]);
  const pictureRegex = new RegExp('(https?:\\/\\/.*\\.(?:png|jpg|jpeg|avif|webp))');

  useEffect(() => {
    if ((!spinnerApartments || !spinnerApartments.length) && (!panoApartments || !panoApartments.length)) {
      const arr = gallery.map((image) => {
        return {
          viewType: SpinnerLabel.apartment,
          original: image,
          thumbnail: image,
          originalClass: 'apartment-gallery-v2-slide-item',
          thumbnailClass: 'apartment-gallery-v2-thumbnail',
          appProperty: ApplicationPropertyTypeEnum.gallery,
        };
      });
      setGalleryState(arr);
      setOnlySliderState(arr);
    } else {
      // const panoState = panoApartments.reduce((acc: ReactImageGalleryItemWithSpinnerData[], cur: IPanoAppartments) => {
      //   return [
      //     ...acc,
      //     {
      //       original: 'panorama',
      //       thumbnail: appIcon.icon360,
      //       originalClass: 'apartment-gallery-v2-spinner',
      //       thumbnailClass: 'apartment-gallery-v2-thumbnail-spinner',
      //       ...cur,
      //     },
      //   ];
      // }, [] as ReactImageGalleryItemWithSpinnerData[]);
      // @ts-ignore
      const initialSpinnerState = spinnerApartments.reduce((acc: any, cur: IGalleryListOfSpinner) => {
        const findedPoly = cur.data.additional_data.spinner_svg
        && Object.keys(cur.data.additional_data.spinner_svg).reduce((accPoly: boolean, stopId: string) => {
          if (cur.data && isArray(cur.data.additional_data.spinner_svg[stopId])) {
            const finded = cur.data.additional_data.spinner_svg[stopId].find((svg: any) => {
              return combineIds.includes(svg.attr['entity-view-name-id']);
            });
            if (finded) return true;
          }
          return accPoly;
        }, false);

        if (!findedPoly) return acc;

        const spinnerData = {
          viewType: SpinnerLabel.spinner,
          original: SpinnerLabel.spinner,
          thumbnail: appIcon.icon360.default,
          originalClass: 'apartment-gallery-v2-spinner',
          thumbnailClass: 'apartment-gallery-v2-thumbnail-spinner',
          spinnerData: cur.data,
          icon: cur.icon,
          order: cur.order,
          type: cur.name,
          appProperty: ApplicationPropertyTypeEnum.building,
        };
        setOnlySpinnerState([...acc, spinnerData]);
        return [
          ...acc,
          spinnerData,
        ];
      }, [] as ReactImageGalleryItemWithSpinnerData[]);

      const slides = gallery.reduce((acc, cur, currentIndex) => {
        const slide: ReactImageGalleryItemWithSpinnerData = {
          viewType: SpinnerLabel.apartment,
          original: isMdScreen ? cur.replace('desktop', 'mobile') : cur,
          thumbnail: isMdScreen ? cur.replace('desktop', 'mobile') : cur,
          originalClass: 'apartment-gallery-v2-slide-item',
          thumbnailClass: 'apartment-gallery-v2-thumbnail',
          order: currentIndex,
          appProperty: ApplicationPropertyTypeEnum.roomsTour,
        };
        if (pictureRegex.test(cur)) {
          setOnlySliderState([...acc, slide]);
        } else {
          slide.viewType = SpinnerLabel.tour;
          setOnlyRoomTourState([...onlyRoomTourState, slide]);
        }
        return [
          ...acc,
          slide,
        ];
      }, [] as ReactImageGalleryItemWithSpinnerData[]);

      setGalleryState(initialSpinnerState.concat(slides).sort((a: any, b: any) => a.order - b.order));
    }
  }, [spinnerApartments, gallery]);

  return {
    galleryState,
    onlySliderState,
    onlySpinnerState,
    onlyRoomTourState,
  };
};
