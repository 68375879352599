import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { appIcon } from 'src/assets';
import { unset } from 'lodash';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartment: {
    position: 'sticky',
    top: '-32px',
    backgroundColor: theme.palette.background.paper,
    fontSize: '20px',
    fontWeight: '700',
    paddingBottom: '28px',
    zIndex: '12',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      padding: '0 20px 0 20px',
    },
    [theme.breakpoints.up(3000)]: {
      top: '-96px',
      paddingBottom: '48px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0 28px 0 28px',
    },
  },
  apartmentMobile: {
    padding: '20px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    fontSize: '20px',
    fontWeight: '700',
    borderRadius: '10px 10px 0 0',
    zIndex: '12',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '28px',
    },
  },
  apartmentTitle: {
    display: 'inline-flex',
    flexFlow: 'wrap',
    color: '#003DA6',
    fontSize: '26px',
    marginBottom: '4px',
    fontWeight: '700',
    lineHeight: '32px',
    [theme.breakpoints.down('md')]: {
      color: '#0037A6',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '24px',
      marginBottom: '4px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '32px',
      lineHeight: '44px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '40px',
      lineHeight: '48px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '62px',
      lineHeight: '84px',
    },
  },
  titleButton: {
    '& button': {
      height: '48px',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '12px 10px',
      [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
        height: '56px',
        borderRadius: '12px',
      },
      [theme.breakpoints.up(3000)]: {
        fontSize: '36px',
        height: '96px',
        borderRadius: '20px',
      },
    },
  },
  apartmentPrice: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
    color: '#A19D9D',
    textDecorationLine: 'none',
    marginRight: '8px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '26px',
      lineHeight: '32px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '44px',
      lineHeight: '56px',
    },
  },
  apartmentPriceHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      marginBottom: '8px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: '38px',
    },
    [theme.breakpoints.up(3000)]: {
      marginBottom: '42px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginBottom: '32px',
    },
  },
  apartmentPriceWithDiscount: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
    color: 'var(--Dark, #454242)',
  },
  buttonMobileWrapper: {
    width: '100%',
    '& button': {
      height: '40px',
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.between('sm', 'md')]: {
        height: '48px',
        fontSize: '20px',
        lineHeight: '40px',
      },
    },
  },
  buttonMobile: {
    width: '40px',
    minWidth: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    justifyContent: 'center',
    pointerEvents: 'all',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '48px',
      minWidth: '48px',
      height: '48px',
    },
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  pdfButton: {
    backgroundColor: `${theme.palette.background.paper}!important`,
    '& .svg': {
      height: '22px',
      width: '22px',
    },
    '& svg': {
      height: '22px',
      width: '22px',
      color: theme.palette.primary.main,
    },
  },
  favoriteButton: {
    '& .MuiButton-startIcon': {
      margin: '0',
    },
    '& svg': {
      height: '22px',
      width: '22px',
      color: theme.palette.primary.main,
    },
    backgroundColor: `${theme.palette.background.paper}!important`,
    color: theme.palette.primary.main,
    '&.favoriteButtonActive': {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
}));
