import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SettingsIcon from '@mui/icons-material/Settings';
import { ButtonApp } from 'src/components/Button';
import { IComplex, IDeveloper, IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { useStyles } from 'src/components/profileComponents/Integrations/Bitrix/styles';
import TextField from '@mui/material/TextField';
import { ControlledAutocomplete } from 'src/components/ControledAutocomplete';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  INetHuntField,
  INetHuntFolder,
  INetHuntFunnelOption,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import { translations } from 'src/constants/translations';
import { patchNetHuntFunnel, postNetHuntFunnel } from 'src/api';
import { Link } from 'react-router-dom';
import { pathList } from 'src/containers/Profile/routeList';
import MenuItem from '@mui/material/MenuItem';

interface ICategoriesTable {
  developer: IDeveloper;
  project: IProject | null;
  fetchProject: Function;
  listFolders: INetHuntFolder[];
  fields: INetHuntField[];
  selectedFolderId: (folderId: string) => void;
}

type fieldsValue = {label: string, value: string};
type FormField = {
  id?: string,
  '@id'?: string,
  folder: fieldsValue,
  sector: fieldsValue,
  project: string[],
}
type FormValues = {
    fields: FormField[]
}

const schema = yup.object().shape({});

export const NethuntFunnels: React.FC<ICategoriesTable> = ({
  developer,
  project,
  fetchProject,
  listFolders,
  fields,
  selectedFolderId,
}) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const {
    register,
    handleSubmit, control, getValues, setValue, watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const styles = useStyles({});
  const [error, setError] = useState('');
  const [categories, setCategories] = useState<FormField[] | []>([]);
  const [fieldsOptions, setFieldsOptions] = useState<INetHuntFunnelOption[] | []>([]);
  const [foldersOptions, setFoldersOptions] = useState<INetHuntFunnelOption[] | []>([]);
  const [complexesOptions, setComplexesOptions] = useState<INetHuntFunnelOption[] | []>([]);

  useEffect(() => {
    if (fieldsOptions) {
      setFieldsOptions(fields.map((item) => ({ label: item.name, value: item.name })));
    }
  }, [fields]);

  useEffect(() => {
    if (listFolders) {
      setFoldersOptions(listFolders.map((item) => ({ label: item.name, value: item.id })));
    }
  }, [listFolders]);

  useEffect(() => {
    if (developer.complexes) {
      setComplexesOptions(developer.complexes.map((item) => ({ label: item.title[item.language], value: item['@id'] })));
    }
  }, [developer]);

  useEffect(() => {
    if (project?.funnels) {
      const prepareData = project?.funnels.map((item) => {
        const complexArray = item.complexes.map((comp) => findComplex(comp));
        return {
          '@id': item['@id'],
          id: item.id,
          folder: { label: item.externalFolderName, value: item.externalFolderId },
          sector: { label: item.externalStageFieldName, value: item.externalStageFieldName },
          project: complexArray.map((complex) => (complex ? complex['@id'] : '')),
        };
      });
      setCategories(prepareData);
    }
  }, [project]);

  useEffect(() => {
    if (categories && categories.length) {
      categories.forEach((item, index) => {
        setValue(`fields.${index}.@id`, item['@id']);
        setValue(`fields.${index}.id`, item.id);
        setValue(`fields.${index}.folder`, item.folder);
        setValue(`fields.${index}.sector`, item.sector);
        setValue(`fields.${index}.project`, item.project);
      });
    }
  }, [categories]);

  const findComplex = (complexId: string): IComplex | undefined => {
    return developer.complexes.find((complex) => complex['@id'] === complexId);
  };

  const handleCreate = async () => {
    setCategories([...categories, {
      folder: { label: '', value: '' },
      sector: { label: '', value: '' },
      project: [],
    }]);
  };

  const onSave = (formData: FormValues) => {
    const saveData = formData.fields.map((item) => ({
      '@id': item['@id'],
      id: item.id,
      netHuntIntegration: developer.netHuntIntegration,
      externalFolderId: item.folder.value,
      externalFolderName: item.folder.label,
      externalStageFieldName: item.sector.value,
      complexes: item.project,
      netHuntOpportunityPlacementMappings: [],
    }));
    const promiseArray = saveData.map((item) => (item['@id'] ? patchNetHuntFunnel(jwt, item?.id, item) : postNetHuntFunnel(jwt, item)));
    Promise.all(promiseArray)
      .then((resArray) => {
        if (resArray.length && resArray.every((res) => res.ok)) {
          fetchProject();
        }
      });
  };

  useEffect(() => {
    watch();
  }, [watch]);

  return (
    <Box>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        Funnels (Folders)
      </Box>
      <Box fontSize="14px">
        <Box display="flex" justifyContent="space-between">
          <Box mb={2}>
            <ButtonApp
              text="Create Funnel"
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleCreate}
              className={styles.btn}
            />
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSave)}>
          <TableContainer component={Paper} className={styles.container}>
            <Table className={styles.table} stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="left">&nbsp;</TableCell> */}
                  <TableCell align="left">ID – Folder name</TableCell>
                  <TableCell align="left">Selector name</TableCell>
                  <TableCell>Flat show Projects</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories && categories.map((row, index) => (
                  <TableRow key={`key-${row.folder.value + index}`}>
                    <TableCell align="left">
                      <TextField hidden {...register(`fields.${index}.@id`)} />
                      <TextField hidden {...register(`fields.${index}.id`)} />
                      <ControlledAutocomplete
                        {...register(`fields.${index}.folder`)}
                        control={control}
                        options={foldersOptions}
                        defaultValue={{ value: row.folder.value, label: row.folder.label }}
                        name={`fields.${index}.folder`}
                        onChange={(data: INetHuntFunnelOption) => {
                          selectedFolderId(data.value);
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={params.name}
                            variant="outlined"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <ControlledAutocomplete
                        {...register(`fields.${index}.sector`)}
                        control={control}
                        options={fieldsOptions}
                        defaultValue={{ value: row.sector.value, label: row.sector.label }}
                        disabled={!getValues().fields[index].folder?.value}
                        name={`fields.${index}.sector`}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={params.label}
                            variant="outlined"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell width="33%">
                      <TextField
                        select
                        control={control}
                        SelectProps={{
                          multiple: true,
                          defaultValue: row.project,
                        }}
                        onChange={(e) => {
                          setValue(`fields.${index}.project`, e.target.value);
                        }}
                        // @ts-ignore
                        inputProps={register(`fields.${index}.project`)}
                        disabled={!getValues().fields[index].sector?.value}
                      >
                        {Object.values(complexesOptions).map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                    <TableCell align="right">
                      {row.id && (
                        <Link to={`/profile/${pathList.developers}/${developer.id}/nethunt/funnel/${row.id}`}>
                          <IconButton title="Filler settings" size="large">
                            <SettingsIcon />
                          </IconButton>
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={4}>
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              className={styles.btn}
            />
          </Box>
        </form>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
