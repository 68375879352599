import { useMemo } from 'react';

export const useActual = (): Function => {
  return useMemo(() => {
    let id = 0;

    return (): Function => {
      // eslint-disable-next-line no-plusplus
      const currId = ++id;
      return (): boolean => currId === id;
    };
  }, []);
};
