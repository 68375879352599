import { httpService } from 'src/services';
import { ApiUrl, crosstableWithoutApi } from 'src/constants';
import {
  INetHuntEmptyOpportunityStatuses, INetHuntFeedbackFormItem, INetHuntFeedbackFormSaveItem,
  INetHuntFunnel, ITimelineField,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import { INetHuntStages } from 'src/typings/complexes';

export function postDecodeUrl(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().netHuntDecodeUrl}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

// project
export function patchNetHuntProject(token: string = '', id : string|number, data: any) {
  return httpService.patch(`${crosstableWithoutApi}${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getNetHuntProject(token: string = '', id : string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntCreateProject}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
export function postNetHuntProject(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().netHuntCreateProject}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

// folders list
export function getNetHuntProjectListFolders(token: string = '', iri: string) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntListFolders}?netHuntIntegration=${iri}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

// fields
export function getNetHuntFields(token: string = '', iri: string, folderExternalId: string) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntFunnelFields}?netHuntIntegration=${iri}&folderExternalId=${folderExternalId}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

// funnel
export function getNetHuntFunnel(token: string = '', id: string | undefined) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntFunnel}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postNetHuntFunnel(token: string = '', data: Partial<INetHuntFunnel>): Promise<any> {
  return httpService.post(`${ApiUrl.getApiUrl().netHuntFunnel}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchNetHuntFunnel(token: string = '', id: string | undefined, data: Partial<INetHuntFunnel>) {
  return httpService.patch(`${ApiUrl.getApiUrl().netHuntFunnel}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

// stages
export function postNetHuntStages(token: string = '', data: INetHuntStages) {
  return httpService.post(`${ApiUrl.getApiUrl().netHuntStages}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchNetHuntStages(token: string = '', id : string|number, data: any) {
  return httpService.patch(`${crosstableWithoutApi}${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getNetHuntStagesByFunnel(token: string = '', funnelId: string) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntStages}?funnel=${funnelId}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

// empty opportunities status
export function getNetHuntEmptyOpportunityStatuses(token: string = '', link: string) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntEmptyOpportunityStatuses}/${link}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchNetHuntEmptyOpportunityStatuses(token: string = '', id: string, data: INetHuntEmptyOpportunityStatuses) {
  return httpService.patch(`${ApiUrl.getApiUrl().netHuntEmptyOpportunityStatuses}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postNetHuntEmptyOpportunityStatuses(token: string = '', data: INetHuntEmptyOpportunityStatuses) {
  return httpService.post(`${ApiUrl.getApiUrl().netHuntEmptyOpportunityStatuses}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

// OPPORUNITY PLACEMENT MAPPING

export function postNetHuntOpportunityPlacementMapping(token: string = '', data: INetHuntEmptyOpportunityStatuses) {
  return httpService.post(`${ApiUrl.getApiUrl().netHuntOpportunityPlacementMapping}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchNetHuntOpportunityPlacementMapping(token: string = '', id: string, data: INetHuntEmptyOpportunityStatuses) {
  return httpService.patch(`${ApiUrl.getApiUrl().netHuntOpportunityPlacementMapping}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

// Placement properties fields

export function getNetHuntPlacementPropertiesFields(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntPlacementPropertiesFields}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexesByNetHunt(token: string = '', projectId: string|number, email: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?developers.netHuntIntegration=${projectId}&users.email=${email}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBookNowForm(data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().bookNowForm}`, data, {});
}

export function getNetHuntDeal(token: string = '', dealId: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntOpportunities}/${dealId}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export interface PatchNetHuntDeal {
  placements: string[];
}

export function patchNetHuntDeal(token: string = '', id: string | number, data: PatchNetHuntDeal) {
  return httpService.patch(`${ApiUrl.getApiUrl().netHuntOpportunities}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getNetHuntResponsibleUsers(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().netHuntPlacementOpporunities}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
