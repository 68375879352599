import React from 'react';
import { locals } from 'src/constants';
import { ButtonApp } from 'src/components/ApartmentPageV2/Button';
import { useAppOpenModalBookModal, useTranslations } from 'src/hooks';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface IApartmentTitleButton {
  sx?: SxProps<Theme>,
  title: string,
  onClickEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void,
}
export const ApartmentTitleButton: React.FC<IApartmentTitleButton> = ({ sx, title, onClickEvent }) => {
  const { getTranslations } = useTranslations();
  const { handleToggleModal } = useAppOpenModalBookModal();
  return (
    <ButtonApp
      text={getTranslations(title)}
      type="button"
      color="primary"
      sx={sx}
      isCapitalize
      isFullSize
      variant="contained"
      onClick={(event) => {
        if (onClickEvent) {
          onClickEvent(event);
          return;
        }
        handleToggleModal();
      }}
    />
  );
};
