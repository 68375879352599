import { createStyles, DefaultTheme, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: DefaultTheme) => createStyles({
  wrapper: {
    '& .MuiPaginationItem-root': {
      borderColor: 'rgba(108, 89, 230, .4)',
    },
    '& .MuiPaginationItem-root[aria-current=true]': {
      borderColor: '#6c59e6',
      backgroundColor: '#eeeefb',
    },
  },
}));
