import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  crossTableTitleItemMargin,
  crossTableTitleItemSize,
  crossTableTitleSpace,
  crossTableBigItemHeight,
  crossTableBigItemWidth,
  borderColor,
} from 'src/constants';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  sectionTitle: (props: any) => ({
    whiteSpace: 'nowrap',
    padding: '4px',
    width: '100%',
    minHeight: '33px',
    textAlign: 'center',
    marginBottom: `${crossTableTitleSpace}px`,
    position: 'sticky',
    top: '0px',
    background: props.filterBackgroundColor,
    zIndex: 5,
    '& > span': {
      fontSize: '17px',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
      '& > span': {
        fontSize: '12.25px',
        fontWeight: '400',
      },
    },
  }),
  floorListWrapper: {},
  sectionContent: {
    display: 'flex',
    flexFlow: 'column',
  },
  sectionContentRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      margin: `calc(${crossTableTitleItemMargin}px * var(--crosstable-size))`,
    },
  },
  sectionContentWrapper: {
    position: 'relative',
    width: `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    height: `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    minWidth: `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    maxWidth: `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
  },
  sectionContentItem: (props: any) => ({
    position: 'absolute',
    bottom: 0,
    cursor: 'pointer',
    background: props.bgColor,
    borderRadius: '5px',
    width: `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    height: `calc(${crossTableTitleItemSize + ((crossTableTitleItemSize + 4) * (props.floors - 1))}px * var(--crosstable-size))`,
    // minWidth: `${crossTableTitleItemSize}px`,
    // maxWidth: `${crossTableTitleItemSize}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.25s',
    zIndex: 1,
    '&:hover': {
      zIndex: 2,
      transform: 'scale(1.5)',
      boxShadow: '#000000 0px 0px 5px;',
    },
    '&[aria-describedby="floorItemPopover"]': {
      transform: 'scale(1.5)',
      zIndex: 2,
    },
    '&.blockedItem': {
      opacity: '0.5',
      filter: 'grayscale(1)',
      '&:hover': {
        transform: 'none',
      },
    },
  }),
  emptyItem: (props: any) => ({
    background: 'transparent',
    width: props.isBigFloorItem ? `${crossTableBigItemWidth}px` : `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    height: props.isBigFloorItem
      ? `${crossTableBigItemHeight}px`
      : `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    minWidth: props.isBigFloorItem ? `${crossTableBigItemWidth}px` : `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    maxWidth: props.isBigFloorItem ? `${crossTableBigItemWidth}px` : `calc(${crossTableTitleItemSize}px * var(--crosstable-size))`,
    '&:hover': {
      transform: 'none',
    },
  }),
  sectionContentText: (props: any) => ({
    color: props.colorByBgColor,
    fontSize: props.fontSize,
    fontWeight: 'bold',
  }),
  selectedDeal: {
    border: '2px solid black',
  },
  sectionContentWrapperBig: {
    position: 'relative',
    width: `${crossTableBigItemWidth}px`,
    height: `${crossTableBigItemHeight}px`,
    minWidth: `${crossTableBigItemWidth}px`,
    maxWidth: `${crossTableBigItemWidth}px`,
  },
  sectionContentItemBig: (props: any) => ({
    position: 'absolute',
    bottom: 0,
    cursor: 'pointer',
    background: props.filterBackgroundColor || 'white',
    border: `1px solid ${borderColor}`,
    borderRadius: '5px',
    padding: '5px',
    width: `${crossTableBigItemWidth}px`,
    height: `calc(${crossTableBigItemHeight * (props.floors)}px)`,
    // minWidth: `${crossTableTitleItemSize}px`,
    // maxWidth: `${crossTableTitleItemSize}px`,
    transition: 'all 0.25s',
    zIndex: 1,
    '&:hover': {
      zIndex: 2,
      transform: 'scale(1.2)',
      boxShadow: '#000000 0px 0px 5px;',
    },
    '&[aria-describedby="floorItemPopover"]': {
      transform: 'scale(1.2)',
      zIndex: 2,
    },
    '&.blockedItem': {
      opacity: '0.5',
      filter: 'grayscale(1)',
      '&:hover': {
        transform: 'none',
      },
    },
  }),
  additionalCondition: {
    position: 'absolute',
    padding: '2px',
    fontSize: 'calc(6px * var(--crosstable-size))',
    color: 'black',
    fontWeight: 700,
    borderRadius: 'calc(4px * var(--crosstable-size))',
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: '1em',
    minWidth: 'calc(10px * var(--crosstable-size))',
  },
}));
