import { ActionTypes } from 'src/store/filter/filtered-data/actions';
import {
  IFilterAllData, IFilterData, IBitrixDealData, ListOfKeysFilterAllData,
} from 'src/typings/filter';
import { IAction } from 'src/typings/index';
import { IStatus } from 'src/typings/complexes';
import { IPlacementType } from 'src/store/type-displayed-spinner/types';
import { ICreatioDeal, INetHuntDeal } from 'src/store/crosstable/reducer';

export interface IFilteredParamsReducer {
  [key: string]: IFilterAllData[];
}

export const initialState: IFilteredParamsReducer = {};

export const filteredDataReducer = (
  state = initialState,
  { type, payload }: IAction,
): IFilteredParamsReducer => {
  switch (type) {
    case ActionTypes.SET_FILTERED_KEYS: {
      return {
        ...state,
        ...payload as any,
      };
    }

    case ActionTypes.SET_FILTERED_DATA: {
      return {
        ...state,
        [payload.selectedPlacement]: payload.data as any,
      };
    }

    case ActionTypes.SET_CLEAR_FILTERED_DATA: {
      return {
        ...state,
        ...payload as any,
      };
    }

    case ActionTypes.UPDATE_PLACEMENT: {
      const { data, lang }: {
        data: {
          id: string|number;
          placementType: IPlacementType;
          placementStatus: IStatus;
          bitrixDeals: IBitrixDealData[];
          creatioOpportunities: ICreatioDeal[];
          netHuntOpportunities: INetHuntDeal[];
        },
        lang: string
      } = payload;
      const {
        id,
        placementType,
        placementStatus,
        bitrixDeals,
        creatioOpportunities = [],
        netHuntOpportunities,
      } = data;
      const tempData: IFilteredParamsReducer = { ...state };

      if (tempData) {
        const tempArray = [...tempData[placementType.title[lang]]];
        const placementIndex = tempArray.findIndex((item) => item.id === String(id));
        const placement = { ...tempArray[placementIndex] };
        if (placement) {
          placement.bitrix_deals = bitrixDeals;
          placement.creatioOpportunities = creatioOpportunities;
          placement.netHuntOpportunities = netHuntOpportunities;
          placement[ListOfKeysFilterAllData.statusId] = String(placementStatus.id);
          placement[ListOfKeysFilterAllData.placementStatusColor] = placementStatus.color;
          placement[ListOfKeysFilterAllData.placementStatusCrossTableColor] = placementStatus.crosstableColor;
          placement[ListOfKeysFilterAllData.placementStatusCrossTableName] = placementStatus.crosstable_name[lang];
          placement[ListOfKeysFilterAllData.placementStatus] = placementStatus.crosstable_name[lang];
          placement[ListOfKeysFilterAllData.placementStatusModuleName] = placementStatus.module_name[lang];
          placement[ListOfKeysFilterAllData.placementCanBeSet] = placementStatus.amoCanBeSetForDeal ? '1' : '0';

          tempArray[placementIndex] = placement;
          tempData[placementType.title[lang]] = tempArray;
        }
      }
      return {
        ...tempData,
      };
    }

    default:
      return state;
  }
};
