import React from 'react';

export const SvgHeart: React.FC<{width?: string, height?: string, fill?: string}> = ({ width = 22, height = 22, fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_39)">
        <path
          d="M19.511 12.5551L12.011 19.9831L4.51102 12.5551C4.01632 12.0737 3.62666 11.4951 3.36657 10.8557C3.10647
           10.2163 2.98158 9.53003 2.99976 8.84001C3.01793 8.14999 3.17878 7.47121 3.47218 6.84641C3.76557 6.22161
            4.18515 5.66433 4.7045 5.20966C5.22386 4.75498 5.83172 4.41277 6.48983 4.20456C7.14794 3.99635 7.84202
             3.92666 8.52839 3.99988C9.21475 4.0731 9.87852 4.28763 10.4779 4.62998C11.0773 4.97233 11.5993 5.43508
              12.011 5.98908C12.4246 5.4391 12.9472 4.9804 13.5461 4.64168C14.1451 4.30297 14.8075 4.09153 15.492
               4.0206C16.1764 3.94967 16.8681 4.02077 17.5238 4.22947C18.1795 4.43816 18.785 4.77995 19.3026
                5.23344C19.8201 5.68693 20.2384 6.24236 20.5314 6.86497C20.8244 7.48758 20.9858 8.16396 21.0053
                 8.85178C21.0249 9.53961 20.9023 10.2241 20.6452 10.8623C20.388 11.5006 20.0019 12.0789 19.511 12.5611"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1549_39">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
