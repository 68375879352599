import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

type InputData = {
  title?: string | null,
  content: {id: number, title: string, prefix: string, value: string, suffix: string, level?: string}[]
};

export const ApartmentDescriptionAccordion: React.FC<{isExpanded?: boolean, data: InputData}> = ({ isExpanded = false, data }) => {
  const styles = useStyles({});
  return (
    <>
      <div className={styles.Accordion}>
        <Accordion
          defaultExpanded={isExpanded}
        >
          {Boolean(data?.title) && (
          <AccordionSummary
            className={styles.summary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={styles.title}>{data.title}</Typography>
          </AccordionSummary>
          )}
          <AccordionDetails className={styles.detail}>
            {data.content.map((item) => {
              return ((item.value !== null && item.value !== undefined && item.value !== '') && (
              <Box key={item.id} className={styles.detailHolder}>
                <div className={`${styles.detailTitle} ${item.level ? 'level' : ''}`}>
                  {item.level && (
                    <div className={styles.level}>{item.level}</div>
                  )}
                  {item.title}
                </div>
                <Box
                  className={styles.detailValue}
                  dangerouslySetInnerHTML={{
                    __html: `${item.prefix || ''} ${item.value} ${item.suffix || ''}`,
                  }}
                />
              </Box>
              ));
            })}
            <></>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
