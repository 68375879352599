import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  fullScreenContainer: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  defaultInnerPageStyles: {
    flexDirection: 'column',
    padding: '30px',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
      height: '100%',
    },
  },
  flatshowLink: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  flatshowLogo: {
    width: '32px',
    height: '34px',
  },
}));
