import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { getFloorItemType } from 'src/store/view-2d/selectors';
import { Actions } from 'src/store/view-2d/actions';
import { Actions as TableActions } from 'src/store/table/actions';
import { EFloorType } from 'src/store/view-2d/reducer';
import { useGridApiRef } from '@mui/x-data-grid';
import { IView, IVisibleConfig } from 'src/store/type-displayed-spinner/types';
import { Actions as AppActions } from 'src/store/app/actions';
import { locals } from 'src/constants';
import { getValueByPlacementSystemName } from 'src/helpers/placementSystemName';
import { ISubViewType } from 'src/typings/table';
import { useStyles } from './styles';

export const SelectFloorItemType: React.FC<{visibleConfig: IVisibleConfig, placementsReport: IView, lang: string}> = ({ visibleConfig, placementsReport, lang }) => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const floorItemType = useSelector(getFloorItemType);
  const tableApiRef = useGridApiRef();
  const viewOptions = [
    {
      key: EFloorType.moreInfo,
      text: getValueByPlacementSystemName(
        placementsReport.subViews?.find((item) => item.type === ISubViewType.DetailedSubView)?.name.translates,
        locals.appTranslations.appCrosstableDetailedSubView,
        { lang },
      ) || locals.appTranslations.appCrosstableDetailedSubView,
      value: EFloorType.moreInfo,
      visible: String(visibleConfig.detailedSubView.isEnabled),
      sortOrder: visibleConfig.detailedSubView.sortOrder,
    },
    {
      key: EFloorType.mini,
      text: getValueByPlacementSystemName(
        placementsReport.subViews?.find((item) => item.type === ISubViewType.SimpleSubView)?.name.translates,
        locals.appTranslations.appCrosstableSimpleSubView,
        { lang },
      ) || locals.appTranslations.appCrosstableSimpleSubView,
      value: EFloorType.mini,
      visible: String(visibleConfig.simpleSubView.isEnabled),
      sortOrder: visibleConfig.simpleSubView.sortOrder,
    },
    {
      key: EFloorType.list,
      text: getValueByPlacementSystemName(
        placementsReport.subViews?.find((item) => item.type === ISubViewType.ListSubView)?.name.translates,
        locals.appTranslations.appCrosstableListSubView,
        { lang },
      ) || locals.appTranslations.appCrosstableListSubView,
      value: EFloorType.list,
      visible: String(visibleConfig.listSubView.isEnabled),
      sortOrder: visibleConfig.listSubView.sortOrder,
    },
  ];

  useEffect(() => {
    if (floorItemType === EFloorType.list && tableApiRef.current) {
      dispatch(TableActions.setTableApiRef({ ...tableApiRef }));
    }
  }, [floorItemType]);

  const handleOnChange = (value: any) => {
    if (value !== floorItemType) {
      dispatch(AppActions.setIsOpenAppartSidebar(false));
      dispatch(Actions.setFloorItemType(value));
    }
  };

  const changeViewText = placementsReport?.changeSubViewButtonName?.translates?.[lang] ? placementsReport?.changeSubViewButtonName?.translates?.[lang] : placementsReport?.changeSubViewButtonName?.systemName;

  return (
    <Box className={styles.options}>
      {changeViewText}
      : &nbsp;
      <Box display="flex" gap="5px">
        { viewOptions
          .filter((item) => item.visible === 'true')
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((view) => {
            return (
              <button
                key={view.key}
                type="button"
                className={`${styles.option} ${floorItemType === view.key ? 'selected' : ''}`}
                onClick={() => handleOnChange(view.value)}
              >
                {view.text}
              </button>
            );
          }) }
      </Box>
    </Box>
  );
};
