import { useCallback, useMemo, useState } from 'react';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { CRMNames, IMessage } from 'src/typings/app';
import { useSelector } from 'react-redux';
import { getBitrixDeal, getCreatioDeal, getNetHuntDeal } from 'src/store/crosstable/selectors';
import { getCrmData } from 'src/store/app/selectors';
import { getSelectedFloor } from 'src/store/floors/selectors';

export const useSelectedDeal = () => {
  const crmData = useSelector(getCrmData);
  const bitrixDeal = useSelector(getBitrixDeal);
  const creatioDeal = useSelector(getCreatioDeal);
  const netHuntDeal = useSelector(getNetHuntDeal);
  const selectedFloorIds = useSelector(getSelectedFloor);
  const [isAnyDealSelected, setIsAnyDealSelected] = useState<boolean>(false);

  const isSelectedDeal = useCallback((fillFloorsItem: IFilterAllData | undefined) => {
    if (fillFloorsItem) {
      const { bitrix_deals: bitrixDeals, netHuntOpportunities, creatioOpportunities } = fillFloorsItem;
      if (crmData?.crm === CRMNames.amocrm) {
        if (fillFloorsItem[ListOfKeysFilterAllData.placementAmoDeals]) {
          fillFloorsItem[ListOfKeysFilterAllData.placementAmoDeals].forEach((item) => {
            setIsAnyDealSelected(true);
            return crmData && item.includes(String(crmData?.dealId));
          });
        }
      } else if (crmData?.crm === CRMNames.bitrix) {
        const found = bitrixDeals && bitrixDeals.find((item) => bitrixDeal && item.id === bitrixDeal.id);
        setIsAnyDealSelected(!!bitrixDeal?.placement);
        return !!found;
      } else if (crmData?.crm === CRMNames.creatio) {
        return !!(creatioOpportunities && creatioOpportunities.find((item) => creatioDeal && item.id === creatioDeal.id));
      } else if (crmData?.crm === CRMNames.netHunt) {
        return !!(netHuntOpportunities && netHuntOpportunities.find((item) => netHuntDeal && item.id === netHuntDeal.id));
      }
    }
    return false;
  }, [crmData, crmData, creatioDeal, netHuntDeal, bitrixDeal, selectedFloorIds]);
  return {
    isSelectedDeal,
    isAnyDealSelected,
  };
};
