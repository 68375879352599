import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    width: '100%',
    height: '100%',
  },
  modalContent: {
    display: 'flex',
    gap: '16px',
  },
  modalWideBoxWrapper: {
    width: '100%',
    height: 'calc(100vh - 152px)',
    cursor: 'pointer',
    [theme.breakpoints.up(1536)]: {
      height: 'calc(100vh - 178px)',
    },
    '&.border': {
      border: `1px solid ${theme.palette.background.default}`,
      borderRadius: '8px',
      padding: '20px',
      [theme.breakpoints.up('md')]: {
        padding: '16px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '20px',
      },
      [theme.breakpoints.up(1536)]: {
        padding: '24px',
      },
    },
    '& .floor': {
      height: '100%',
    },
  },
  modalSmallBoxesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '16px',
    pointerEvents: 'none',
    minWidth: '160px',
    maxWidth: '160px',
    [theme.breakpoints.up(1536)]: {
      minWidth: '216px',
      maxWidth: '216px',
    },
  },
  modalSmallBoxFloor: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .floor': {
      color: theme.palette.primary.main,
      fontSize: '26px',
      fontWeight: '700',
      lineHeight: '32px',
      letterSpacing: '-0.052px',
      [theme.breakpoints.up(1536)]: {
        fontSize: '40px',
        fontWeight: '700',
        lineHeight: '48px',
        letterSpacing: '-0.08px',
      },
    },
    '& .subsection': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.up(1536)]: {
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '28px',
      },
    },
  },
  modalSmallBox: {
    position: 'relative',
    minHeight: '160px',
    maxHeight: '160px',
    [theme.breakpoints.up(1536)]: {
      minHeight: '216px',
      maxHeight: '216px',
    },
    '&.border': {
      border: `1px solid ${theme.palette.background.default}`,
      borderRadius: '8px',
    },
    '& .holder': {
      width: '100%',
      height: '100%',
    },
    '& .markUpDesktop': {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      bottom: 0,
      transform: 'none',
      pointerEvents: 'none',
      touchAction: 'none',
    },
  },
  wrapperContent: {
    maxWidth: 'none',
    padding: '80px 32px 32px 32px',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      padding: '72px 20px 36px 20px',
      '& .header button': {
        top: '15px',
        right: '5px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      padding: '80px 32px 32px 32px',
      '& .header button': {
        top: '20px',
        right: '15px',
      },
    },
    [theme.breakpoints.up(1536)]: {
      padding: '88px 50px 50px 44px',
      '& .header button': {
        top: '20px',
        right: '35px',
      },
    },
    '& .header button': {
      backgroundColor: 'unset',
      cursor: 'default',
      '& svg': {
        fontSize: '24px',
      },
      '& .MuiButton-startIcon': {
        cursor: 'pointer',
      },
    },
  },
}));
