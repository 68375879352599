import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { localStorageConstants } from 'src/constants';
import { Actions } from 'src/store/app/actions';
import { getComplexId } from 'src/store/app/selectors';

export const useFavoriteListState = (id: string) => {
  const complexId = useSelector(getComplexId);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const getStorageItems = useCallback(() => localStorage.getItem(localStorageConstants.favoriteList), []);

  const checkIsActiveItem = useCallback(() => {
    const getFavList = getStorageItems();

    if (getFavList && complexId) {
      const parseFav: string[] = JSON.parse(getFavList);

      if (parseFav[complexId] && parseFav[complexId].includes(id)) {
        setIsActive(true);
      }

      return;
    }

    setIsActive(false);
  }, [id, getStorageItems]);

  const toggleFavoriteItem = useCallback(() => {
    const getFavList = getStorageItems();
    if (complexId) {
      if (getFavList) {
        const parseFav: {[key: string]: string[]} = JSON.parse(getFavList);

        if (parseFav[complexId] && parseFav[complexId].includes(id)) {
          const removeItem = parseFav[complexId].filter((item) => item !== id);

          localStorage.setItem(localStorageConstants.favoriteList, JSON.stringify({ ...parseFav, [complexId]: Array.from(new Set(removeItem)) }));
          dispatch(Actions.setFavoriteCount(removeItem.length));
          setIsActive(false);
        } else {
          if (parseFav[complexId]) {
            parseFav[complexId].push(id);
          } else {
            parseFav[complexId] = [id];
          }

          localStorage.setItem(localStorageConstants.favoriteList, JSON.stringify(parseFav));
          dispatch(Actions.setFavoriteCount(parseFav[complexId].length));
          setIsActive(true);
        }
      } else {
        localStorage.setItem(localStorageConstants.favoriteList, JSON.stringify({ [complexId]: [id] }));
        dispatch(Actions.setFavoriteCount(1));
        setIsActive(true);
      }
    }
  }, [id, getStorageItems, dispatch]);

  const handleMouseDownUp = useCallback(() => {
    setIsMouseDown(!isMouseDown);
  }, [isMouseDown]);

  useEffect(() => {
    checkIsActiveItem();
  }, [id, getStorageItems, checkIsActiveItem]);

  return {
    isMouseDown,
    isActive,
    checkIsActiveItem,
    toggleFavoriteItem,
    handleMouseDownUp,
  };
};
