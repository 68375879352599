import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export const ControlledTextField = ({
  onChange: ignored, control, defaultValue, name, hidden, disabled = false, variant,
}: any) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <TextField
          hidden={hidden}
          disabled={disabled}
          variant={variant}
          onChange={(e) => {
            if (ignored) {
              ignored(e.target.value);
            }
            onChange(e.target.value);
          }}
          {...props}
        />
      )}
      // @ts-ignore
      onChange={([, data]) => data}
      defaultValue={defaultValue || ''}
      name={name}
      control={control}
    />
  );
};
