import { httpService } from 'src/services';
import { ApiUrl } from 'src/constants';

export function getMappedPDFPaymentPlansRequest(placementId: string, lang: string, frontType: string) {
  return httpService.get(`${ApiUrl.getApiUrl().apiUrl}/mapped_placement_pdf_payment_plans?language=${lang}&placementId=${placementId}&frontType=${frontType}`);
}

export function getPDFDocumentsOrderRequest(complexId: string) {
  return httpService.get(`${ApiUrl.getApiUrl().apiUrl}/complexes/front_configs?complex=${complexId}&properties[]=placementPdfOrderings`);
}
