import React, { useCallback, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router';
import { ConfirmProvider } from 'material-ui-confirm';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams, useRouteMatch, useLocation,
} from 'react-router-dom';

import {
  useInitGTM,
  useInitJSPath,
  useInitEmbeddedJS,
  useResetRequestData,
  useCheckInitialLoadData,
  useBuildType,
  useSubsribeMessage,
  useGetAuthData,
  useReadUTM,
} from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { getComplexId } from 'src/store/app/selectors';
import { history } from 'src/store';
import {
  pathList, routesWithoutId, routesWithId, notFoundRoute,
} from 'src/route-list';
import { Actions } from 'src/store/app/actions';

const setCrumbs = (params: any, matchPath: string, dispatch: any, complexId: string|number|null = '') => {
  const crumbs = routesWithoutId
    .filter(({ path }) => matchPath.includes(path))
    .map(({ path, ...rest }) => {
      return ({
        path: Object.keys(params).length
          ? Object.keys(params).reduce(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (path, param) => path.replace(
              `:${param}`, params[param],
            ), `${path}${complexId}`,
          )
          : `${path}${complexId}`,
        name: rest.name,
      });
    });

  setTimeout(() => dispatch(Actions.setCrumbs(crumbs)), 100);
};

const ComplexIdWrapper = () => {
  const { complexId }: { complexId: string } = useParams();
  const { path } = useRouteMatch();
  const resetData = useResetRequestData();
  const dispatch = useDispatch();

  const setComplexId = useCallback(
    () => {
      if (!!complexId) {
        resetData();
        dispatch((Actions.setComplexId(complexId)));
      }
    },
    [complexId, dispatch],
  );

  useEffect(() => {
    setComplexId();
  }, [complexId, setComplexId]);

  return (
    <Switch>
      {routesWithoutId.map((route) => {
        return (
          <Route
            exact
            path={`${path}${route.path}`}
            key={route.name}
            render={(props) => {
              setCrumbs(props.match.params, props.match.path, dispatch, complexId);
              return route.Component;
            }}
          />
        );
      })}
    </Switch>
  );
};

const CrosstableRoutes: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { setJWT } = useGetAuthData();
  const user = useSelector(requestSelectors.user.getUserData);
  const userDataIsLoading: boolean = useSelector(requestSelectors.user.getUserLoading);
  const complexIdState = useSelector(getComplexId);
  const { isLoading } = useCheckInitialLoadData();
  useSubsribeMessage();

  useEffect(() => {
    const isNotIframe = window.top === window.self;
    if (isNotIframe) {
      const localJwt = localStorage.getItem('jwt_token');
      setJWT(localJwt || '');
    }
  }, []);

  if (!userDataIsLoading) {
    if (
      !location.pathname.includes(pathList.auth)
        && !user?.id
    ) {
      return (
        <Redirect to={pathList.auth} />
      );
    }
    if (location.pathname.includes(pathList.auth) && user?.id) {
      return (
        <Redirect to={pathList.home} />
      );
    }
    if (!complexIdState && user?.id && !location.pathname.includes(pathList.profile) && !isLoading) {
      return (
        <Redirect to={pathList.profileComplexes} />
      );
    }
  }

  return (
    <Switch>
      <Route
        path={pathList.home}
        exact
      >
        {!userDataIsLoading ? <Redirect to={pathList.profileComplexes} /> : null}
      </Route>
      {routesWithId.map((route) => {
        return (
          <Route
            exact={route.exact}
            path={route.path}
            key={route.name}
            render={(props) => {
              setCrumbs(props.match.params, props.match.path, dispatch);
              return route.Component;
            }}
          />
        );
      })}
      <Route path="/:complexId">
        <ComplexIdWrapper />
      </Route>
      <Route
        path={notFoundRoute.path}
        render={() => {
          return notFoundRoute.Component;
        }}
      />
    </Switch>
  );
};

const ClientRoutes = () => {
  const dispatch = useDispatch();

  return (
    <Switch>
      {routesWithoutId.map((route) => {
        return (
          <Route
            exact
            path={route.path}
            key={route.name}
            render={(props) => {
              setCrumbs(props.match.params, props.match.path, dispatch);
              return route.Component;
            }}
          />
        );
      })}
      <Route
        path={notFoundRoute.path}
        render={() => {
          return notFoundRoute.Component;
        }}
      />
    </Switch>
  );
};

export const AppRoute = () => {
  const { isCrossTable } = useBuildType();

  useInitGTM();
  useInitJSPath();
  useInitEmbeddedJS();
  useReadUTM();

  return (
    <ConnectedRouter history={history}>
      {isCrossTable
        ? (
          <ConfirmProvider>
            <Route path={pathList.home}>
              <CrosstableRoutes />
            </Route>
          </ConfirmProvider>
        ) : (
          <ClientRoutes />
        )}
    </ConnectedRouter>
  );
};
