import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperModalMobile: {
    padding: '0',
  },
  wrapperContentMobile: {
    padding: '65px 20px 40px 20px',
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
  },
  wrapperContentDesktop: {
    borderRadius: '10px',
    padding: '30px 30px 50px 30px',
    backgroundColor: theme.palette.background.paper,
  },
  wrapperContentHeader: {
    marginBottom: 'unset',
    minHeight: '44px',
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '8px 0',
    borderBottom: '1px solid #ccc',
    '&:hover': {
      backgroundColor: 'unset',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('md')]: {
      gap: '8px',
      minHeight: '56px',
    },
  },
  title: {
    fontSize: '16px',
    lineHeight: '32px',
    color: theme.palette.text.primary,
  },
  icon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));
