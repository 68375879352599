import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  mapWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: '0px',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  wrapperDesktopTypeSelector: {
    transition: 'filter 0.6s',
    display: 'flex',
    position: 'absolute',
    top: '32px',
    right: '32px',
    zIndex: 5,
    alignItems: 'center',
  },
  typesSelectorView: {
    display: 'flex',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 0px 3px 0px #d2d2d2',
    '& > div': {
      borderRight: '1px solid #F2F2F2',
      padding: '10px 15px',
      '&:first-child': {
        paddingLeft: '10px',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  fullscreenView: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 99,
    '& > div': {
      boxShadow: '0px 0px 3px 0px #d2d2d2',
      borderRadius: '0px 12px 12px 0px',
      padding: '10px 0',
    },
  },
  logo: {
    position: 'absolute',
    left: '30px',
    top: '32px',
    zIndex: '99',
    '& > div': {
      height: '44px',
      maxWidth: '200px',
    },
  },
  fullscreenViewClose: {
    left: '30px',
    '& > div': {
      borderRadius: '4px',
      padding: '14px 10px',
    },
  },
  spinnerTypesRenderStyle: {
    flex: 1,
  },
  filterOpen: {
    [theme.breakpoints.up('md')]: {
      animation: '$filterOpen 500ms forwards',
    },
  },
  filterClose: {
    animation: '$filterClose 500ms forwards',
  },
  filterAnimateStart: {
    filter: 'blur(20px)',
  },
  filterAnimateEnd: {
    filter: 'blur(0px)',
  },
  '@keyframes filterOpen': {
    '0%': {
      borderRadius: '0px 0px 0px 0px',
      filter: 'blur(20px)',
    },
    '99%': {
      filter: 'blur(20px)',
    },
    '100%': {
      borderRadius: '0px 0px 0px 0px',
      filter: 'blur(0px)',
    },
  },
  '@keyframes filterClose': {
    '0%': {
      borderRadius: '0px 0px 0px 0px',
      filter: 'blur(20px)',
    },
    '99%': {
      filter: 'blur(20px)',
    },
    '100%': {
      borderRadius: '0px 0px 0px 0px',
      filter: 'blur(0px)',
    },
  },
  mapHeader: {
    position: 'absolute',
    top: '0px',
    padding: '20px',
    zIndex: 3,
    width: '100%',
  },
  floorWrapper: {
    animation: '$floorStyle 300ms forwards',
    '& #spinner_place': {
      marginBottom: '0px',
      height: 'calc(100% - 70px) !important',
      marginTop: '0px !important',
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px',
      '& #spinner_place': {
        position: 'initial',
      },
      '& #svg_place': {
        overflow: 'hidden',
      },
    },
  },
  '@keyframes floorStyle': {
    '0%': { opacity: 0 },
    '90%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  // Cross table
  wrapperMapHeader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: '30px',
    padding: '0px 30px',
  },
}));
