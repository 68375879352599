import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    height: '20px',
    width: '20px',
    fontSize: 'unset!important',
    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
}));
