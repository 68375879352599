import React from 'react';

import { useGetFilteredData, useTranslations, useTypeDisplayedSpinner } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { ComponentHouseSpinner } from 'src/components/FilterProperty/components/FilterDesktop/components';
import { useApartmentsSorting } from 'src/hooks/layouts/useApartmentsSorting';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useSortingByKey } from 'src/hooks/layouts/useSortingByKey';
import { ComponentListOfLayoutApartments } from './component';
import { useStyles } from './styles';

interface IListOfLayoutApartments {
  scrollPosition: React.RefObject<HTMLDivElement>;
}

export const ListOfLayoutApartments: React.FC<IListOfLayoutApartments> = ({
  scrollPosition,
}) => {
  const styles = useStyles({});
  const { resultFiltering, layoutsData } = useGetFilteredData();
  const { sortApartments } = useApartmentsSorting();
  const { isLayoutView, isFloorsView, is2dView } = useTypeDisplayedSpinner();
  const { getTranslations } = useTranslations();
  if (isLayoutView) return null;
  if (isFloorsView || is2dView) return <ComponentHouseSpinner />;
  const { getUniqueByKey, getSortedByKey } = useSortingByKey();

  const sortedApartments = getSortedByKey<IFilterAllData>(
    getUniqueByKey<IFilterAllData>(sortApartments(resultFiltering, ListOfKeysFilterAllData.placementSection), ListOfKeysFilterAllData.placementSection),
    resultFiltering, ListOfKeysFilterAllData.placementSection, ListOfKeysFilterAllData.placementNumeration,
  );

  return (
    <div>
      <>
        {
            Boolean(resultFiltering.length)
              ? (
                <ComponentListOfLayoutApartments
                  properties={sortedApartments}
                  parentScroll={scrollPosition}
                />
              )
              : <p className={styles.titleTotalCount}>{getTranslations(locals.appTranslations.appNoDataToDisplay)}</p>
          }
      </>
    </div>
  );
};
