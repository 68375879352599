import React, {
  useRef, useState, useMemo, ChangeEvent,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { ApiUrl } from 'src/constants';
import { pathList } from 'src/containers/Profile/routeList';
import {
  useGetAuthData, useGetComplex, useGetHouseById, useSetDocumentTitle, useGetHouseHistory,
} from 'src/hooks';
import { importPlacements, uploadPlacementXlsxByHouse } from 'src/api';
import { borderColor } from 'src/constants/styles';
import { IComplex } from 'src/typings/complexes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AppPagination } from 'src/components/AppPagination';
import { usePagination } from 'src/hooks/usePagination';
import { IHouseHistoryItem } from 'src/typings/house';
import { useStyles } from './styles';
import { HouseHistoryBlock } from '../../HouseHistoryBlock';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ISnackData {
  type: 'error' | 'success';
  text: string;
}

export const HouseStorage = () => {
  const styles = useStyles({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { id, houseId }: { id: string; houseId: string } = useParams();
  const complex: IComplex|null = useGetComplex(id);
  const { data: house } = useGetHouseById(houseId);
  const {
    totalItems,
    isLoading,
    members: houseHistory,
    setPage,
  } = useGetHouseHistory(houseId);
  const { totalPages } = usePagination<IHouseHistoryItem>({ list: houseHistory, totalItems });

  const history = useHistory();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  useSetDocumentTitle(translations.storage);

  const [isImportDisabled, setIsImportDisabled] = useState<boolean>(false);
  const [snackData, setSnackData] = useState<ISnackData | null>(null);

  const handleCloseSnack = () => setSnackData(null);

  const handleImportClick = () => {
    // 👇️ open file input box on click of other element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj || !house) {
      return;
    }

    const formData = new FormData();
    formData.append('file', fileObj);
    setIsImportDisabled(true);
    importPlacements(jwt, house.id, formData).then((res: any) => {
      if (res.ok) {
        setSnackData({ type: 'success', text: 'Import is completed successfully' });
        window.location.reload();
        // refetch();
      } else {
        setSnackData({ type: 'error', text: 'There was a import error' });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }).finally((error: any) => {
      setIsImportDisabled(false);
    });
  };

  if (!complex || !house || isLoading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const {
    language,
    title,
  } = complex;
  const crumbs: ICrumbs[] = [

    {
      name: translations.complexesLink,
      url: `/profile/${pathList.complexes}`,
    },
    {
      name: `${translations.settings} ${title[language]}`,
      url: `/profile/${pathList.complexes}/${id}/`,
    },
    {
      name: `${translations.storage} ${title[language]}`,
      url: `/profile/${pathList.complexes}/${id}/storage`,
    },
    {
      name: `${translations.house} ${house.numeration[language]}`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <ButtonApp
        text={translations.goBack}
        onClick={() => {
          history.push(`/profile/${pathList.complexes}/${id}/`);
        }}
        type="button"
        color="inherit"
        size="small"
        isCapitalize
        variant="outlined"
        startIcon={<ArrowBackIcon />}
      />
      <Box fontSize="32px" mb={1} mt={2} fontWeight={700}>
        {`${translations.storage} ${title[language]}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box
          borderBottom={`1px solid ${borderColor}`}
          display="flex"
          justifyContent="space-between"
          py={2}
          px={2}
        >
          <Box fontSize="20px">
            {`${translations.house} ${house.numeration[language]}`}
          </Box>
          <Box display="flex">
            <Box mr={2}>
              <ButtonApp
                text={isImportDisabled ? 'Loading' : translations.import}
                onClick={handleImportClick}
                type="button"
                color="primary"
                size="small"
                isCapitalize
                disabled={isImportDisabled}
                variant="contained"
                startIcon={isImportDisabled ? (
                  <CircularProgress
                    size={18}
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : <PublishIcon />}
              />
            </Box>
            <Box>
              <ButtonApp
                onClick={() => uploadPlacementXlsxByHouse(house.id, house.numeration[language], title[language], jwt)}
                text={translations.export}
                type="button"
                color="primary"
                size="small"
                isCapitalize
                variant="contained"
                startIcon={<GetAppIcon />}
              />
            </Box>
          </Box>
        </Box>
        {houseHistory.map((item) => {
          return (
            <HouseHistoryBlock
              houseHistoryItem={item}
              houseNumeration={house.numeration[language]}
              complexName={title[language]}
              key={item['@id']}
            />
          );
        })}

        <Box
          py="48px"
          width="100%"
          alignItems="center"
          flexDirection="column"
          display="flex"
          gap="12px"
        >
          <Box
            fontSize="18px"
            lineHeight="20px"
            fontWeight={300}
          >
            {totalItems ? (
              totalPages > 1 && (
                <AppPagination
                  count={totalPages}
                  onChange={(event, page) => setPage(page)}
                />
              )
            ) : 'Empty yet!'}
          </Box>
        </Box>
      </Box>
      <Box display="none">
        <input
          ref={fileInputRef}
          onChange={handleFileChange}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </Box>
      <Snackbar open={!!snackData} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snackData?.type} sx={{ width: '100%' }}>
          {snackData?.text}
        </Alert>
      </Snackbar>
    </Box>
  );
};
