import React from 'react';
import { useSelector } from 'react-redux';

import { GlobalDataLoader } from 'src/components/GlobalDataLoader';
import { requestSelectors } from 'src/store/requests/selectors';
import {
  useAppOpenModalBookModal,
  useCheckInitialLoadData,
  useIsMobile,
  useBuildType,
} from 'src/hooks';
import { appIcon } from 'src/assets';
import { AppMenu } from 'src/components/AppMenu';
import { AppBottomMenu } from 'src/components/AppBottomMenu';
import { ModalBookNow } from 'src/components/modals/BookNow';
import { getShowFlatshow } from 'src/store/requests/app/selector';
import { IAppFullScreenContainer } from './types';
import { useStyles } from './styles';

export const AppFullScreenContainer: React.FC<IAppFullScreenContainer> = ({
  wrapperClass,
  defaultPageStyles = false,
  children,
}) => {
  const styles = useStyles({});
  const userDataIsLoading: boolean = useSelector(requestSelectors.user.getUserLoading);
  const isShowFlatshow = useSelector(getShowFlatshow);
  const { isMobileScreen } = useIsMobile();
  const { isErrors, isLoading } = useCheckInitialLoadData();
  const { isOpen, handleToggleModal } = useAppOpenModalBookModal();
  const { isCrossTable } = useBuildType();

  if (isErrors || isLoading || (isCrossTable && userDataIsLoading)) {
    return <GlobalDataLoader />;
  }

  return (
    <>
      <AppMenu />
      <div
        id="page-wrap"
        className={`${styles.fullScreenContainer} ${
          defaultPageStyles ? styles.defaultInnerPageStyles : ''
        } ${wrapperClass}`}
      >
        {children}
        { isMobileScreen && <AppBottomMenu /> }
      </div>
      {isOpen && (
        <ModalBookNow
          isOpen={isOpen}
          onClose={handleToggleModal}
        />
      )}
      {
        isShowFlatshow && (
          <a
            className={styles.flatshowLink}
            href="https://www.flatshow.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className={styles.flatshowLogo} src={appIcon.mainLogo.default} alt="logo" />
          </a>
        )
      }
    </>
  );
};
