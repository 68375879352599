import { useState } from 'react';
import { getNetHuntProjectListFolders } from 'src/api';
import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { INetHuntFolder } from 'src/components/profileComponents/Integrations/Nethunt/types';

export const useGetListFolders = () => {
  const [listFolders, setListFolders] = useState<INetHuntFolder[] | []>([]);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const getListFolders = (project: IProject) => {
    getNetHuntProjectListFolders(jwt, project['@id'])
      .then((list: {data: INetHuntFolder[]}) => {
        setListFolders(list.data);
      });
  };

  return {
    listFolders,
    getListFolders,
  };
};
