import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { useGetFilterParams } from 'src/hooks';
import { useStyles } from './styles';

export const TuneIconWithFiltersCount: React.FC = () => {
  const styles = useStyles();
  const { selectedParams } = useGetFilterParams();
  return (
    <div className={styles.iconWrapper}>
      {Boolean(selectedParams.length) && (
        <div className={styles.filtersCountWrapper}>
          <span>{`${selectedParams.length > 9 ? '9+' : selectedParams.length}`}</span>
        </div>
      )}
      <TuneIcon color="primary" />
    </div>
  );
};
