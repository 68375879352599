import React, { useEffect, useState } from 'react';

import { ApartmentFloor } from 'src/components/ApartmentPageV2/ApartmentFloor';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { FloorMarkup } from 'src/components/ApartmentPageV2/Markups';
import { useAppVisibilityBtn } from 'src/hooks/app';
import { useTranslations } from 'src/hooks/translataions';
import { useGetFloorsSpinner } from 'src/components/ApartmentPageV2/ApartmentFloor/hooks';
import { AppSpinner } from 'src/components/ApartmentPageV2/AppSpinner';
import Box from '@mui/material/Box';
import { useStyles } from 'src/containers/MainPageApartmentsV2/styles';
import { IComponentAdditionalInfo } from 'src/containers/MainPageApartmentsV2/types';
import { locals } from 'src/constants';
import { AppModal } from 'src/components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSpinnerDetailInfo, getSpinnerViewDataHouse } from 'src/store/requests/spinner-views/selector';
import { useHouseActions } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { useBuildType } from 'src/hooks';
import { getComplexId } from 'src/store/app/selectors';
import { ISpinnerFitType } from 'src/typings/spinner';
import { getListOfTypeDisplayedSpinner } from 'src/store/type-displayed-spinner/selectors';
import { useChangeValue } from 'src/components/FilterTypeProperty/hooks';
import * as popupStyles from './styles';

export const ComponentAdditionalInfo: React.FC<IComponentAdditionalInfo> = ({
  title, chooseData, isMobile = false,
}) => {
  const styles = useStyles({});
  const modalStyles = popupStyles.useStyles();
  const {
    placement_section_markup_placing,
    placement_floor_placing,
  } = useAppVisibilityBtn();
  const detailInfo = useSelector(getSpinnerDetailInfo);
  const { getTranslations } = useTranslations();
  const { isCrossTable } = useBuildType();
  const complexIdState = useSelector(getComplexId);
  const { spinnerFloor } = useGetFloorsSpinner(chooseData[ListOfKeysFilterAllData.floorIds]);
  const [windRoseOnload, setWindRoseOnload] = useState<boolean>(false);
  const [floorPopUpWindRoseOnload, setFloorPopUpWindRoseOnload] = useState<boolean>(true);
  const [openSpinnerFloorPopUp, setOpenSpinnerFloorPopUp] = useState<boolean>(false);
  const [sectionList, setSectionsList] = useState<(string | null)[]>([]);
  const [houseList, setHouseList] = useState<(string | null)[]>([]);
  const spinnerSvgCurrentDataHouse = useSelector(requestSelectors.spinner.getSpinnerViewDataHouse);
  const { handleHouseSelected } = useHouseActions();
  const listOfType = useSelector(getListOfTypeDisplayedSpinner);
  const { handleChangeType } = useChangeValue(listOfType);
  const history = useHistory();
  const wrapperClassName = 'addition-info-item';
  const onFloorClick = () => {
    setOpenSpinnerFloorPopUp(true);
  };

  useEffect(() => {
    if (detailInfo?.placement) {
      const sectionsArray = Object.keys(detailInfo?.placement).map((key) => detailInfo?.placement[key][ListOfKeysFilterAllData.placementSection]);
      const housesArray = Object.keys(detailInfo?.placement).map((key) => detailInfo?.placement[key][ListOfKeysFilterAllData.placementHouse]);
      setSectionsList([...new Set(sectionsArray)]);
      setHouseList([...new Set(housesArray)]);
    }
  }, [detailInfo?.placement]);

  const onFloorClose = () => {
    setOpenSpinnerFloorPopUp(false);
  };

  const onClick = () => {
    if (chooseData[ListOfKeysFilterAllData.houseId] && Object.keys(spinnerSvgCurrentDataHouse || {}).length) {
      handleHouseSelected('house', chooseData[ListOfKeysFilterAllData.houseId], undefined, undefined);
    }
    if (chooseData[ListOfKeysFilterAllData.placementType]) {
      handleChangeType(chooseData[ListOfKeysFilterAllData.placementType] || '');
    }
    const floor = Array.isArray(chooseData[ListOfKeysFilterAllData.placementFloor]) ? chooseData[ListOfKeysFilterAllData.placementFloor].join() || 0 : chooseData[ListOfKeysFilterAllData.placementFloor];
    history.push(`${isCrossTable ? `/${complexIdState}` : ''}/floors/${floor}/${chooseData[ListOfKeysFilterAllData.section]}/${chooseData[ListOfKeysFilterAllData.houseId]}/${chooseData[ListOfKeysFilterAllData.placementNumeration]}`);
  };

  return (
    <Box className={styles.apartmentAdditionalInfo}>
      { !isMobile && placement_floor_placing && (
      <>
        {spinnerFloor && (
        <>
          <AppSpinner
            onClick={onFloorClick}
            wrapperClassName={wrapperClassName}
            // displayIds={[chooseData[ListOfKeysFilterAllData.id]]}
            spinnerData={spinnerFloor}
            roseInTop
            objectFit={ISpinnerFitType.contain}
            hideFloor
            type="floor"
            onWindRoseOnload={(event, isLoaded) => {
              setWindRoseOnload(true);
            }}
          />
        </>
        )}
        {windRoseOnload && (
        <ApartmentFloor
          onClick={onFloorClick}
          wrapperClassName={`${wrapperClassName} floor`}
          hideWindRose
          idApartment={chooseData[ListOfKeysFilterAllData.id]}
          idFloor={chooseData[ListOfKeysFilterAllData.floorIds]}
        />
        )}
      </>
      )}
      {
        !isMobile && placement_section_markup_placing && (
        <FloorMarkup
          onClick={onFloorClick}
          wrapperClassName={`${wrapperClassName} floor-markup`}
          houseData={{
            name: 'house',
            id: chooseData[ListOfKeysFilterAllData.houseId],
          }}
          displaySectionId={chooseData[ListOfKeysFilterAllData.section]}
          isPlacementPage
        />
        )
      }
      {isMobile && (
      <div className={styles.mobileSection}>
        <div className={styles.mobileSectionTitle}>{title}</div>
        {placement_floor_placing && (
        <div className="top">
          {spinnerFloor && (
          <AppSpinner
            wrapperClassName={wrapperClassName}
            spinnerData={spinnerFloor}
            roseInTop
            hideFloor
            type="floor"
          />
          )}
          {placement_section_markup_placing && (
          <FloorMarkup
            wrapperClassName={wrapperClassName}
            houseData={{
              name: 'house',
              id: chooseData[ListOfKeysFilterAllData.houseId],
            }}
            displaySectionId={chooseData[ListOfKeysFilterAllData.section]}
            isPlacementPage
          />
          )}
        </div>
        )}
        {placement_floor_placing && (
        <Box className="bottom" onClick={() => onClick()}>
          <ApartmentFloor
            wrapperClassName={wrapperClassName}
            hideWindRose
            idApartment={chooseData[ListOfKeysFilterAllData.id]}
            idFloor={chooseData[ListOfKeysFilterAllData.floorIds]}
          />
        </Box>
        )}
      </div>
      )}
      <AppModal
        listClassName={{
          wrapperContent: modalStyles.wrapperContent,
        }}
        open={openSpinnerFloorPopUp}
        onClose={onFloorClose}
        toggleClose={onFloorClose}
      >
        <div className={modalStyles.modalWrapper}>
          <div className={modalStyles.modalContent}>
            <div className={modalStyles.modalSmallBoxesWrapper}>
              <div className={`${modalStyles.modalSmallBoxFloor} border`}>
                <div className="floor">
                  {getTranslations(locals.placement.floor)}
                  {' '}
                  {chooseData[ListOfKeysFilterAllData.placementFloor].join(',')}
                </div>
                {chooseData[ListOfKeysFilterAllData.placementSection] && sectionList.length > 1 && (
                  <div className="section subsection">
                    {getTranslations(locals.placement.section)}
                    { ' ' }
                    { chooseData[ListOfKeysFilterAllData.placementSection] }
                  </div>
                )}
                {chooseData[ListOfKeysFilterAllData.placementHouse] && houseList.length > 1 && (
                  <div className="house subsection">
                    {getTranslations(locals.placement.house)}
                    { ' ' }
                    { chooseData[ListOfKeysFilterAllData.placementHouse] }
                  </div>
                ) }
              </div>
              {floorPopUpWindRoseOnload && (
              <div className={`${modalStyles.modalSmallBox} ${spinnerFloor ? 'border' : ''}`}>
                {spinnerFloor && (
                  <>
                    <AppSpinner
                      wrapperClassName={wrapperClassName}
                      spinnerData={spinnerFloor}
                      roseInTop
                      hideFloor
                      type="floor"
                      onWindRoseOnload={(event, isLoaded) => {
                        setFloorPopUpWindRoseOnload(isLoaded);
                      }}
                    />
                  </>
                )}
              </div>
              )}
              <div className={`${modalStyles.modalSmallBox} border`}>
                <FloorMarkup
                  wrapperClassName={`${wrapperClassName} floor-markup`}
                  houseData={{
                    name: 'house',
                    id: chooseData[ListOfKeysFilterAllData.houseId],
                  }}
                  displaySectionId={chooseData[ListOfKeysFilterAllData.section]}
                  isPlacementPage
                />
              </div>
            </div>
            <Box
              className={`${modalStyles.modalWideBoxWrapper} border`}
              onClick={() => onClick()}
            >
              <ApartmentFloor
                wrapperClassName={`${wrapperClassName} floor`}
                hideWindRose
                idApartment={chooseData[ListOfKeysFilterAllData.id]}
                idFloor={chooseData[ListOfKeysFilterAllData.floorIds]}
                heightLimit={false}
              />
            </Box>
          </div>
        </div>
      </AppModal>
    </Box>
  );
};
