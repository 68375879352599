import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dropWrapper: {
    position: 'relative',
  },
  dropdown: (props: any) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    outline: '0',
    background: props.filterBackgroundColor,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '.28571429rem',
    transition: 'box-shadow .1s ease,width .1s ease',
    lineHeight: '1em',
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
  }),
  dropdownOpen: {
    borderColor: 'rgba(0, 0, 0, 0.87)',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  arrowIcon: {
    fontSize: '16px',
    position: 'absolute',
    top: '50%',
    right: '9px',
    transform: 'translateY(-50%)',
    color: theme.palette.text.primary,
  },
  arrowLeft: {
    right: 'unset',
    left: '9px',
  },
  arrowOpen: {
    transform: 'rotate(180deg)',
  },
  dropItem: (props: any) => ({
    position: 'absolute',
    top: 'calc(100% + 11px)',
    width: '100%',
    background: props.filterBackgroundColor,
    padding: '10px 15px',
    zIndex: 5,
    boxShadow: '0 2px 3px 0 rgb(34 36 38 / 15%)',
    maxHeight: '300px',
    overflowY: 'scroll',
  }),
}));
