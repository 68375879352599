/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { FeedbackSettings } from 'src/components/profileComponents/FeedbackSettings';
import { useGetDeveloper } from 'src/hooks';
import { IntegrationType } from 'src/typings/app';
import { CategoriesTable } from 'src/components/profileComponents/Integrations/Bitrix/components/CategoriesTable';
import { Fields } from 'src/components/profileComponents/Integrations/Common/Fields';
import { patchBitrixFields } from 'src/api';
import {
  useGetBitrixFields,
} from 'src/components/profileComponents/Integrations/Bitrix/hooks/useGetFields';
import { IProject } from 'src/typings/complexes';
import {
  DealAccessManagement,
} from 'src/components/profileComponents/Integrations/Bitrix/components/DealAccessManagement';

interface IBitrixIntegration {
  integration: IntegrationType;
  project: IProject,
  fetchProject: () => void
}

export const BitrixIntegration: React.FC<IBitrixIntegration> = ({ integration, project, fetchProject }) => {
  const { id }: { id: string } = useParams();
  const { isLoading, developer } = useGetDeveloper(id);
  const {
    fields, entityValues, isLoading: isLoadingFields, fetchFields,
  } = useGetBitrixFields();

  useEffect(() => {
    if (project) {
      fetchFields(project);
    }
  }, [project]);

  if (isLoading || isLoadingFields || !developer) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Box mb={4}>
        <CategoriesTable developer={developer} project={project} fetchProject={fetchProject} />
      </Box>
      <Box mb={4}>
        <Fields integration={integration} fields={fields} entityValues={entityValues} saveData={patchBitrixFields} />
      </Box>
      <Box mb={4}>
        <FeedbackSettings integration={integration} project={project} />
      </Box>
      <Box mb={4}>
        <DealAccessManagement project={project} fetchProject={fetchProject} />
      </Box>
    </>
  );
};
