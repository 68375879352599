/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ButtonApp } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDeveloper, useGetAuthData } from 'src/hooks';
import {
  patchBitrixStage, postGenerateBitrixStages, postBitrixStatusOnZeroDeal, postBitrixStage,
} from 'src/api';
import { CRMNames, IntegrationType } from 'src/typings/app';
import { StagesTable } from 'src/components/profileComponents/Integrations/Common/StagesTable';
import { toUpperCase } from 'src/helpers/toUpperCase';
import { useGetBitrixCategory, useGetBitrixStages } from './hooks';
import { StageModal } from './components/StageModal';
import { StatusOnZeroDeal } from './components/StatusOnZeroDeal';
import { StatusConnectDeal } from './components/StatusConnectDeal';
import { useStyles } from './styles';

export const BitrixCategories: React.FC<{integration: IntegrationType}> = ({ integration }) => {
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const ref = useRef<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [errorStages, setErrorStages] = useState('');
  const { id, categoryId }: { id: string, categoryId: string } = useParams();
  const { isLoading, developer } = useGetDeveloper(id);
  const { category, statuses, isLoading: isLoadingCategory } = useGetBitrixCategory(`/api/bitrix_categories/${categoryId}`);
  const {
    stages, isLoading: isLoadingStages, fetchStages, setStages,
  } = useGetBitrixStages(categoryId);

  const handleChange = (stage: string, key: string, value: any) => {
    if (ref.current[stage]) {
      ref.current[stage][key] = value;
    } else {
      ref.current[stage] = {
        [key]: value,
      };
    }
  };

  const saveChanges = () => {
    if (category) {
      const { complexes } = category;
      Object.keys(ref.current).forEach((stageKey) => {
        const savedStatuses = complexes.reduce((acc: string[], comp) => {
          const saveData = ref.current[stageKey][`status${comp['@id']}`];
          if (saveData) {
            acc.push(saveData);
          }
          return acc;
        }, []);
        ref.current[stageKey].placementStatuses = savedStatuses;
        patchBitrixStage(jwt, stageKey, ref.current[stageKey]).then();
      });
    }
  };

  useEffect(() => {
    if (category && statuses && stages) {
      const { complexes } = category;
      complexes.forEach((row) => {
        stages.forEach((stage) => {
          let defaultVal = '';
          stage.placementStatuses.forEach((status) => {
            const currentSt = statuses[row['@id']].find((value) => {
              return value['@id'] === status;
            });
            if (currentSt) {
              defaultVal = currentSt?.['@id'];
              handleChange(stage['@id'], `status${row['@id']}`, defaultVal);
            }
          });
        });
      });
    }
  }, [category, statuses, stages]);

  if (isLoading || isLoadingCategory || isLoadingStages || !developer || !category || !statuses) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const onSubmit = async (data:any) => {
    // eslint-disable-next-line no-param-reassign
    const res = await postBitrixStage(jwt, {
      bitrixCategory: category['@id'],
      bitrixExternalStageName: data.stageName,
      bitrixExternalStageId: data.stageId,
    });
    if (res.ok) {
      setModalOpen(false);
      fetchStages();
    } else {
      setErrorStages(res.data['hydra:description']);
    }
  };

  const handleGenerate = async () => {
    const { data } = await postGenerateBitrixStages(jwt, category.id);
    setStages(data['hydra:member']);
  };

  const { title } = developer;
  const { complexes } = category;
  const currentIntegrationName = toUpperCase(integration) as IntegrationType;

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `Developer ${title}`,
      url: `/profile/${pathList.developers}/${id}`,
    },
    {
      name: `${currentIntegrationName} Integration`,
      url: `/profile/${pathList.developers}/${id}/${integration}/integration`,
    },
    {
      name: `${currentIntegrationName} Funnel Setup`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`${integration} Funnel Setup | ${title}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box display="flex" mb={2} justifyContent="space-between">
          <ButtonApp
            text="Add Stage"
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => {
              setModalOpen(true);
            }}
            size="large"
            className={styles.btn}
          />
          <ButtonApp
            text="Generate stages"
            color="primary"
            variant="contained"
            onClick={handleGenerate}
            size="large"
            className={styles.btn}
          />
          <ButtonApp
            text="Save changes"
            color="primary"
            variant="contained"
            onClick={saveChanges}
            size="large"
            className={styles.btn}
          />
        </Box>
        <StagesTable
          stages={stages}
          complexes={complexes}
          statuses={statuses}
          integration={CRMNames.bitrix}
          handleChange={handleChange}
        />
        <Box mb={4}>
          <StatusOnZeroDeal
            complexes={complexes}
            statuses={statuses}
            category={category}
          />
        </Box>
        <StatusConnectDeal
          complexes={complexes}
          statuses={statuses}
        />
      </Box>
      {modalOpen && (
        <StageModal
          integration={integration}
          isOpen={modalOpen}
          handleClose={() => {
            setModalOpen(false);
          }}
          fecthStages={fetchStages}
          onSubmitAction={onSubmit}
          errorMessage={errorStages}
        />
      )}
    </Box>
  );
};
