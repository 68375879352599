import React, { RefObject, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FloorControl } from 'src/components/FloorControl';
import { getFloorItemType, getIsRerender } from 'src/store/view-2d/selectors';
import { View2dType } from 'src/typings/view-2d';
import { Actions } from 'src/store/app/actions';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { useStyles } from './styles';
import { SectionsList } from './components';
import { ICrossTableContent } from './types';
import {
  // useInteraction2d,
  useCrossTableData,
} from './hooks';
import { useGetTypeView2d } from './components/SectionsList/useGetTypeView2d';

export const CrossTableContent: React.FC<ICrossTableContent> = React.memo(({
  view2dData,
  floorList,
}) => {
  const floorItemType = useSelector(getFloorItemType);
  const isRerender = useSelector(getIsRerender);
  const crossTableContentRef: RefObject<HTMLDivElement> = useRef(null);
  const dispatch = useDispatch();
  const styles = useStyles({});

  useEffect(() => {
    return () => {
      dispatch(Actions.setIsOpenAppartSidebar(false));
      dispatch(FloorsActions.setSelectedApartmentId(undefined));
    };
  }, []);

  // const {
  //   onWheel,
  //   handleMouseDown,
  //   handleMouseUp,
  //   handleMouseMove,
  // } = useInteraction2d(crossTableContentRef);
  const { houseKey } = useCrossTableData(view2dData);
  const { getTypeView2d } = useGetTypeView2d();
  return (
    <div
      id="crossTableContent"
      ref={crossTableContentRef}
      className={`${getTypeView2d === View2dType.area ? 'appArea' : 'appRooms'}`}
      // onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      // onMouseLeave={handleMouseUp}
      // onMouseMove={handleMouseMove}
      // onWheel={onWheel}
    >
      <div className="crossTableContentInner">
        <FloorControl
          floorList={floorList}
          floorItemType={floorItemType}
        />
        <div className={styles.sections}>
          {houseKey.map((house) => {
            if (view2dData?.[house] && !isRerender) {
              return (
                <SectionsList
                  key={house}
                  floorList={floorList}
                  houseName={house}
                  houseKeys={houseKey}
                  houseData={view2dData[house]}
                />
              );
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
});
