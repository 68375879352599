import React, { useCallback, useEffect, useMemo } from 'react';

import { ListOfDropdown } from 'src/typings/filter';
import { locals } from 'src/constants/locals';
import { DropdownSingleFloors } from 'src/components/DropdownsFloors/dropdown-single';
import { useTranslations } from 'src/hooks/translataions';
import { useGetComplexConfig } from 'src/hooks/app';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { useGenerateFloorsForSection } from './hooks';
import { DesktopFloorList } from './components';

export const FloorsList: React.FC = () => {
  const styles = useStyles({});
  const {
    selectedFloorId,
    paramsName,
    renderFloors,
    handleChangeFloor,
  } = useGenerateFloorsForSection();
  const formatedFloors = useMemo(() => {
    const temp = renderFloors.map((item) => {
      return {
        key: item.floorId,
        text: item.floor,
        value: item.floorId,
      };
    });
    temp.sort((a, b) => {
      if (Number(a.text) > Number(b.text)) {
        return 1;
      }
      if (Number(a.text) < Number(b.text)) {
        return -1;
      }
      return 0;
    });
    return temp;
  }, [renderFloors]);
  // const { complexConfigEntityInnerDataRelation } = useGetComplexConfig();
  const { getTranslations } = useTranslations();
  const {
    apartmentFloor,
  }: { apartmentFloor: string } = useParams();

  const handleChange = useCallback((event, data) => {
    const { value } = data;
    const floor: any = renderFloors.find((item) => item.floorId === value);

    handleChangeFloor(
      paramsName,
      floor.floorId,
      floor.floor,
    );
  }, [renderFloors]);

  useEffect(() => {
    if (apartmentFloor) {
      const floorData = renderFloors.find((floor) => {
        return apartmentFloor.split(',')[0] === floor.floor;
      });
      if (floorData) {
        handleChangeFloor(paramsName, floorData.floorId, floorData.floor);
      }
    }
  }, [renderFloors]);

  if (renderFloors.length === 0) return null;
  return (
    <div className={styles.containerFloors}>
      <DesktopFloorList
        selectedFloorId={selectedFloorId}
        renderFloors={renderFloors}
        paramsName={paramsName}
        handleChangeFloor={handleChangeFloor}
      />
      <div className={styles.containerMobileFloorsList}>
        <DropdownSingleFloors
          dropdownInfo={{
            attribute: locals.filter.floor,
            type: ListOfDropdown.dropdownSelectList,
            data: formatedFloors,
          }}
          text={`${getTranslations(locals.appTranslations.appFloorTitle)} ${
            renderFloors.find((item) => item.floorId === selectedFloorId)?.floor || ''}`}
          value={selectedFloorId || undefined}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
