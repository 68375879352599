import React from 'react';
import FormLabel from '@mui/material/FormLabel';

import { AirbnbSlider } from 'src/components/AirbnbSlider';
import { IRangeAndSet } from 'src/typings/filter';
import { useTranslations } from 'src/hooks/translataions';
import { useScaleState } from 'src/components/dropdowns/DropdownScale/use-scale-state';

interface IAppScaleFilter {
  dropdownInfo: IRangeAndSet;
}

export const AppScaleFilter: React.FC<IAppScaleFilter> = ({
  dropdownInfo,
}) => {
  const { getTranslations, getFixedTranslations } = useTranslations();
  const {
    handleSetScalePlaceholder,
  } = useScaleState(
    dropdownInfo.attribute,
    getFixedTranslations(),
  );

  return (
    <>
      {dropdownInfo.data && (
        <>
          <FormLabel component="legend">{`${getTranslations(dropdownInfo.attribute)}`}</FormLabel>
          <AirbnbSlider
            enableSwipe
            dropdownInfo={dropdownInfo}
            fieldName={dropdownInfo.attribute}
            range={dropdownInfo.data as unknown as { min: number, max: number }}
            setPlaceholder={handleSetScalePlaceholder}
          />
        </>
      )}
    </>
  );
};
