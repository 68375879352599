import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appIcon } from 'src/assets';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  tansWrapper: {
    marginBottom: '40px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 0 20px',
      marginBottom: '32px',
    },
  },
  headerTabStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tabStyle: {
    color: theme.palette.secondary.main,
    minWidth: 'auto',
    fontSize: '17px',
    textTransform: 'capitalize',
    padding: '0px',
    marginRight: '20px',
    '&[aria-selected="true"]': {
      color: '#000000',
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px',
    },
  },
  wrapperTabInfoData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #F2F2F2',
    height: '48px',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    '&.note > span:last-child': {
      fontSize: '13px',
      fontWeight: 'normal',
    },
  },
  infoTitle: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
  },
  infoTextData: {
    fontWeight: 'bold',
    fontSize: '17px',
    textAlign: 'right',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  explicationHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  explicationSequence: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    // color: 'white',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50%',
    marginRight: '16px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  pdfStyle: {
    '& > a': {
      padding: '0px 10px',
    },
  },
  pdfIcon: {
    color: theme.palette.grey['400'],
    height: '14px',
    lineHeight: '18px',
    [theme.breakpoints.up('xl')]: {
      height: '20px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up(3000)]: {
      height: '25px',
      lineHeight: '25px',
    },
    '& svg': {
      [theme.breakpoints.up('xl')]: {
        width: '20px',
        height: '20px',
      },
      [theme.breakpoints.up(3000)]: {
        width: '25px',
        height: '25px',
      },
    },
  },
  pdfIconTitle: {
    fontFamily: 'Helvetica',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    textDecoration: 'underline',
    textTransform: 'none',
    color: theme.palette.grey['400'],
    [theme.breakpoints.up('xl')]: {
      fontSize: '18px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '28px',
      lineHeight: '36px',
    },
  },
  wrapperOrderList: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
