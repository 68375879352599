import { ParseSpeeds, PDFDocument } from 'pdf-lib';
import { isString } from 'lodash';
import { getPDFDocumentsOrderRequest } from 'src/api/paymentPlan';
import { useState } from 'react';
import { IPlacementPdfOrderings } from 'src/typings/pdf';

const pageSizes = {
  a4: {
    width: 594.96,
    height: 841.92,
  },
  letter: {
    width: 612,
    height: 792,
  },
};

export type PaperFormat = keyof typeof pageSizes;

export const useMergePdf = () => {
  const [placementPDFDocuments, setPlacementPDFDocuments] = useState<IPlacementPdfOrderings[]>([]);

  const mergedPdfFiles = async (data: (ArrayBuffer | string)[]) => {
    const mergedPdf = await PDFDocument.create();
    for (const item of data) {
      let existingPdfBytes = item;
      if (isString(item)) {
        // eslint-disable-next-line no-await-in-loop
        existingPdfBytes = await fetch(item).then((res) => res.arrayBuffer());
      }
      // eslint-disable-next-line no-await-in-loop
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // eslint-disable-next-line no-await-in-loop
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices(),
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const pdfBytes = await mergedPdf.save();
    const normalizedPageSizes = await resizeToPageFormat(pdfBytes);

    return normalizedPageSizes;
  };

  const resizeToPageFormat = async (snapshot: Uint8Array, paper_format?: PaperFormat): Promise<Uint8Array> => {
    const pdfDoc = await PDFDocument.load(snapshot, {
      parseSpeed: ParseSpeeds.Fastest,
    });

    // @ts-ignore
    const new_size = pageSizes[paper_format || 'a4'];
    const new_size_ratio = Math.round((new_size.width / new_size.height) * 100);

    const pages = pdfDoc.getPages();

    pages.forEach((page) => {
      const { width, height } = page.getMediaBox();
      const size_ratio = Math.round((width / height) * 100);
      if (Math.abs(new_size_ratio - size_ratio) > 1) {
        page.setSize(new_size.width, new_size.height);
        const scale_content = Math.min(new_size.width / width, new_size.height / height);
        page.scaleContent(scale_content, scale_content);
        const scaled_diff = {
          width: Math.round(new_size.width - scale_content * width),
          height: Math.round(new_size.height - scale_content * height),
        };
        page.translateContent(Math.round(scaled_diff.width / 2), Math.round(scaled_diff.height / 2));
      } else {
        page.scale(new_size.width / width, new_size.height / height);
      }
    });

    return pdfDoc.save();
  };

  const getPlacementPDFDocumentsOrdered = async (complexId: string) => {
    const { data: docs } = await getPDFDocumentsOrderRequest(complexId);
    setPlacementPDFDocuments(docs['hydra:member'][0].placementPdfOrderings);
  };

  return {
    placementPDFDocuments,
    mergedPdfFiles,
    resizeToPageFormat,
    getPlacementPDFDocumentsOrdered,
    setPlacementPDFDocuments,
  };
};
