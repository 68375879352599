import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { getMappedPDFPaymentPlansRequest } from 'src/api/paymentPlan';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLang } from 'src/store/app/selectors';
import { randomHash } from 'src/helpers';

export const useGetMappedPDFPaymentPlans = () => {
  const currentLang = useSelector(getCurrentLang);
  const [mappedPDFPaymentPlans, setMappedPDFPaymentPlans] = useState<any[]>([]);

  const getMappedPDFPaymentPlans = async (placementId: string, frontType: string) => {
    const { data: plans } = await getMappedPDFPaymentPlansRequest(placementId, currentLang, frontType);
    const temp = [{ id: randomHash(), type: 'allPlans', name: 'ALL PAYMENT PLANS' }, ...plans.map((plan: any, index: number) => ({ ...plan, id: index + 1 }))];
    setMappedPDFPaymentPlans(temp);
  };
  return {
    mappedPDFPaymentPlans,
    getMappedPDFPaymentPlans,
    setMappedPDFPaymentPlans,
  };
};
