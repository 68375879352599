import React, { useRef } from 'react';

import {
  useGetFilteredData,
  useScrollIntoResultFilter, useTranslations,
} from 'src/hooks';
import { ComponentListOfLayoutApartments } from 'src/components/ListOfLayoutApartments/component';
import { locals } from 'src/constants/locals';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { useApartmentsSorting } from 'src/hooks/layouts/useApartmentsSorting';
import { IAppLayoutsItem } from 'src/typings/layouts';
import { useSortingByKey } from 'src/hooks/layouts/useSortingByKey';
import { useStyles } from './styles';

export const ViewLayout: React.FC = () => {
  const styles = useStyles({});
  const scrollPosition = useRef(null);
  useScrollIntoResultFilter(scrollPosition);
  const { resultFiltering, layoutsData } = useGetFilteredData();
  const { getTranslations } = useTranslations();
  const { sortApartments } = useApartmentsSorting();
  const { getUniqueByKey, getSortedByKey } = useSortingByKey();

  const sortedApartments = getSortedByKey<IAppLayoutsItem>(
    getUniqueByKey<IAppLayoutsItem>(sortApartments(layoutsData, ListOfKeysFilterAllData.placementSection), ListOfKeysFilterAllData.placementSection),
    layoutsData, ListOfKeysFilterAllData.placementSection, ListOfKeysFilterAllData.placementNumeration,
  );

  return (
    <div
      className={styles.containerWrapper}
      ref={scrollPosition}
    >
      {
        Boolean(resultFiltering.length)
          ? (
            <ComponentListOfLayoutApartments
              isLayoutTab
              parentScroll={scrollPosition}
              properties={sortedApartments}
              propertyWrapperStyles={styles.itemLayoutStyles}
              propertyWrapperImageStyle={styles.itemLayoutWrapperImageStyles}
            />
          )
          : <p className={styles.textNoData}>{getTranslations(locals.appTranslations.appNoDataToDisplay)}</p>
      }
    </div>
  );
};
