import React, { RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetCurrentMarkup, useGetCurrentSection } from 'src/hooks/floors';
import { getFilteredSections } from 'src/store/floors/selectors';
import { IFloorMarkup } from 'src/components/ViewFloors/components/Markups/types';
import { usePolygonConfig } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { useSectionImage } from './hooks';
import { useStyles } from './styles';
import { MobileMarkup } from './ComponentMobile';

export const FloorMarkup: React.FC<IFloorMarkup> = ({
  houseData,
  displaySectionId,
  textTitle,
  isPlacementPage,
}) => {
  const { globalPolygonConfig } = usePolygonConfig();
  const styles = useStyles(globalPolygonConfig);
  const sectionRef: RefObject<HTMLImageElement> = useRef(null);
  const filteredSections = useSelector(getFilteredSections);
  const isLarge = useMediaQuery('(min-width:960px)');
  const {
    aspectHeight,
    aspectWidth,
    naturalHeight,
    naturalWidth,
    handleLoadImage,
  } = useSectionImage(sectionRef);
  const {
    renderMarkup,
    handleSelectSection,
  } = useGetCurrentMarkup(houseData, isLarge);
  const { currentSection } = useGetCurrentSection();

  if (!renderMarkup) return null;

  return (
    <div className={`wrapperMarkUp ${styles.wrapperMarkUp}`}>
      <div className={`markUpDesktop ${styles.markUpDesktop}`}>
        {textTitle && (<div><p className={styles.titleSection}>{textTitle}</p></div>)}
        <div className={styles.wrapperSections} style={{ maxWidth: aspectWidth, maxHeight: aspectHeight }}>
          <img
            alt="sections"
            src={renderMarkup.img_url}
            style={{ maxWidth: aspectWidth, maxHeight: aspectHeight }}
            ref={sectionRef}
            onLoad={handleLoadImage}
          />
          {
            sectionRef.current && renderMarkup?.svg?.length && (
              <svg viewBox={`0 0 ${naturalWidth} ${naturalHeight}`} style={{ maxWidth: aspectWidth, maxHeight: aspectHeight }}>
                {
                  renderMarkup.svg.map((svg) => {
                    const isCurrent = displaySectionId
                      ? displaySectionId === svg.attr['markup-id']
                      : currentSection['markup-id'] === svg.attr['markup-id'];
                    return React.createElement(
                      svg.tag,
                      {
                        ...svg.attr,
                        key: svg.attr['markup-id'],
                        className: `${isCurrent ? 'active' : ''} ${
                          (filteredSections.includes(svg.attr['markup-id']) && !isPlacementPage)
                            ? styles.activeFragment : styles.fragment
                        } ${isPlacementPage || styles.blink}`,
                        onClick: () => {
                          if (isCurrent) return;

                          handleSelectSection(
                            svg.attr.markup,
                            svg.attr['markup-id'],
                            true,
                          );
                        },
                      },
                    );
                  })
                }
              </svg>
            )
          }
        </div>
      </div>
      {
        currentSection['markup-id'] && (
          <MobileMarkup
            currentSection={currentSection}
            renderMarkup={renderMarkup}
            handleSelectSection={handleSelectSection}
          />
        )
      }
    </div>
  );
};
