import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  heartContainer: {
    position: 'relative',
    width: '24px',
    height: '24px',
    [theme.breakpoints.up('xl')]: {
      width: '30px',
      height: '30px',
    },
    [theme.breakpoints.up(3000)]: {
      width: '40px',
      height: '40px',
    },
  },
  heartContainerUp: {
    animation: '$addToFavUp 2000ms forwards',
  },
  heartContainerDown: {
    animation: '$addToFavDown 2000ms forwards',
  },
  heartStyles: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    fill: theme.palette.primary.main,
  },
  heartClip: {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    'clip-path': 'url(#svgPath)',
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      opacity: 0,
      transform: 'scale(0)',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      transition: 'transform .2s linear, opacity .2s linear;',
      transformOrigin: 'center 60%',
    },
  },
  heartClipFilled: {
    '&:before': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  wrapperFavoriteCount: {
    position: 'absolute',
    top: '4px',
    right: '7px',
    width: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '9px',
    },
  },
  '@keyframes addToFavUp': {
    '0%': { transform: 'scale(1)' },
    '10%': { transform: 'scale(2)' },
    '20%': { transform: 'scale(1)' },
    '30%': { transform: 'scale(1.8)' },
    '40%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.6)' },
    '60%': { transform: 'scale(1)' },
    '70%': { transform: 'scale(1.4)' },
    '80%': { transform: 'scale(1)' },
    '90%': { transform: 'scale(1.2)' },
    '100%': { transform: 'scale(1)' },
  },
  '@keyframes addToFavDown': {
    '0%': { transform: 'scale(1)' },
    '10%': { transform: 'scale(0.5)' },
    '20%': { transform: 'scale(1)' },
    '30%': { transform: 'scale(0.6)' },
    '40%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(0.7)' },
    '60%': { transform: 'scale(1)' },
    '70%': { transform: 'scale(0.8)' },
    '80%': { transform: 'scale(1)' },
    '90%': { transform: 'scale(0.9)' },
    '100%': { transform: 'scale(1)' },
  },
}));
