import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IProject } from 'src/typings/complexes';
import { IntegrationType } from 'src/typings/app';

interface INethuntMainDataForm {
  integration: IntegrationType,
  register: any,
  project: IProject | null,
}

export const NethuntMainDataFormFields: React.FC<INethuntMainDataForm> = ({ integration, register, project }) => {
  return (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="260px" mr={3}>
          Workspace ID
        </Box>
        <Box width="260px">
          <TextField
            id="workspaceId"
            size="small"
            variant="outlined"
            name="workspaceId"
            inputRef={register}
            defaultValue={project && (project.workspaceId)}
          />
        </Box>
      </Box>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="260px" mr={3}>
          Auth Key
        </Box>
        <Box width="260px">
          <TextField
            id="authKey"
            size="small"
            variant="outlined"
            name="authKey"
            inputRef={register}
            defaultValue={project && (project.authKey)}
          />
        </Box>
      </Box>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="260px" mr={3}>
          Auth Email
        </Box>
        <Box width="260px">
          <TextField
            id="authEmail"
            size="small"
            variant="outlined"
            name="authEmail"
            inputRef={register}
            defaultValue={project && (project.authEmail)}
          />
        </Box>
      </Box>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="260px" mr={3}>
          Form Endpoint
        </Box>
        <Box width="260px">
          <TextField
            id="formEndpoint"
            size="small"
            variant="outlined"
            name="formEndpoint"
            inputRef={register}
            defaultValue={project && (project.formEndpoint)}
          />
        </Box>
      </Box>
    </>
  );
};
