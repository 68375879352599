import { Theme, createTheme, adaptV4Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { purpleColor, whiteColor } from 'src/constants/styles';

export const dealsTheme = (theme: Theme) => createTheme(adaptV4Theme({
  ...theme,
  palette: {
    primary: {
      main: purpleColor,
      contrastText: whiteColor,
    },
    secondary: {
      main: '#E66159',
      contrastText: '#E66159',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '5px',
        textTransform: 'unset',
        fontSize: '18px',
        lineHeight: '28px',
        fontWeight: 'normal',
        padding: '20px 30px',
      },
    },

  },
}));

export const useStyles = makeStyles((theme: Theme) => createStyles({
  isSmallBtn: {
    borderRadius: '10px',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    padding: '5px 15px',
  },
  removeDeal: {
    backgroundColor: '#ec6372',
    cursor: 'pointer',
    transition: '0.3s all',
    color: '#212121',
    width: '0px',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '#ccc 0px 0px 6px 2px',
    },
  },
  dealBtn: {
    backgroundColor: '#F6F7F6',
    cursor: 'pointer',
    color: '#212121',
    padding: '13px 20px',
    '&:hover': {
      '& .removeBtn': {
        width: '50px',
      },
    },
  },
}));
