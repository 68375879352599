import React, { useEffect, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from 'src/components/SwitchIos';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { AppBreadcrumbs } from 'src/components/AppBreadcrumbs';
import Box from '@mui/material/Box';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton';
import { Hidden } from '@mui/material';
import { useBuildType } from 'src/hooks';
import { useStyles } from './styles';
import {
  useGetInitialData,
  useShowDifferences,
} from './hooks';
import { CompareItems } from './components';

export const CompareList: React.FC = () => {
  const styles = useStyles({});
  const {
    removeItemFromCompare,
    listOfComparePlacement,
  } = useGetInitialData();
  const {
    listOfComparePlacementFiltered,
    showDifferences,
    handleToggleShowDifferences,
  } = useShowDifferences(listOfComparePlacement);
  const { isCrossTable } = useBuildType();
  const { getTranslations } = useTranslations();
  const placementsToShow: number = 1;

  const renderButtonShowDiff = () => {
    return (
      <FormControlLabel
        style={{ marginRight: '0px' }}
        control={listOfComparePlacementFiltered.length > placementsToShow ? <IOSSwitch checked={showDifferences} onChange={handleToggleShowDifferences} name="checkedB" /> : <div />}
        label={listOfComparePlacementFiltered.length > placementsToShow ? getTranslations(locals.appTranslations.appOnlyDifferences) : ''}
        labelPlacement="start"
      />
    );
  };

  return (
    <div>
      <div className={styles.headerBlock}>
        <Box className={styles.titleBlockWrapper}>
          <Hidden mdDown>
            <AppBreadcrumbs />
          </Hidden>
          {!isCrossTable && (
          <Hidden mdDown>
            <CopyToClipboardButton title={getTranslations(locals.appTranslations.appCopyLink)} />
          </Hidden>
          )}
        </Box>
      </div>
      <div className={styles.wrapperOutputCompareData}>
        {
          listOfComparePlacementFiltered.length >= placementsToShow
            ? (
              <CompareItems
                renderButtonShowDiff={renderButtonShowDiff}
                placements={listOfComparePlacementFiltered}
                removeItemFromCompare={removeItemFromCompare}
              />
            ) : <p>{getTranslations(locals.appTranslations.appEmptyCompares)}</p>
        }
      </div>
    </div>
  );
};
