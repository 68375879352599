/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { requestSelectors } from 'src/store/requests/selectors';
import { getAvailableComplexes } from 'src/store/crosstable/selectors';
import { CRMNames } from 'src/typings/app';
import { getCrmData } from 'src/store/app/selectors';
import { translations } from 'src/constants/translations';
import { useSetDocumentTitle } from 'src/hooks';
import { IComplex } from 'src/typings/complexes';
import { SearchField } from 'src/components/SearchField';
import { TypeOfRole } from 'src/typings/user';
import { ComplexItem } from './components/ComplexItem';
import { useStyles } from './styles';
import { ButtonApp } from '../../Button';
import { ComplexModal } from './components/ComplexModal';

export const Complexes = () => {
  const styles = useStyles({});
  const crmData = useSelector(getCrmData);
  const user = useSelector(requestSelectors.user.getUserData);
  const availableComplexes = useSelector(getAvailableComplexes);
  const isLargeScreen = useMediaQuery('(min-width:1200px)');

  const [modalOpen, setModalOpen] = useState(false);
  const [editedComplex, setEditedComplex] = useState<IComplex|null>(null);

  const handleAddComplex = () => {
    setEditedComplex(null);
    setModalOpen(true);
  };

  const [filteredComplexes, setFilteredComplexes] = useState<IComplex[]>([]);

  useSetDocumentTitle(translations.complexesLink);

  useEffect(() => {
    if (crmData?.crm === CRMNames.bitrix || crmData?.crm === CRMNames.creatio) {
      setFilteredComplexes(availableComplexes || []);
    } else {
      setFilteredComplexes(availableComplexes || user?.complexes || []);
    }
  }, [availableComplexes, user, crmData]);

  const handleChangeComplexes = (searchTerm: string) => {
    const formatSearchTerm = searchTerm.toLowerCase();

    if (user) {
      const { complexes } = user;
      const workList = availableComplexes || complexes;

      if (formatSearchTerm) {
        setFilteredComplexes(workList.filter((comp: IComplex) => {
          if (!Object.keys(comp.title).find((lang) => comp.title[lang].toLowerCase().indexOf(formatSearchTerm) >= 0)) {
            if (!comp.developers.find((developer) => developer.title.toLowerCase().indexOf(formatSearchTerm) >= 0)) {
              return false;
            }
          }

          return true;
        }));
      } else {
        setFilteredComplexes(workList.slice());
      }
    }
  };
  return (
    <Box py={8} px={4} height="100%" className={styles.wrapper}>
      <Box mb={8} display="flex" flexWrap="nowrap">
        <Box fontSize="32px" mr="24px" fontWeight={700}>
          {translations.projectsLink}
        </Box>
        <Box width={340} mr="auto">
          <SearchField
            handleChange={handleChangeComplexes}
            placeholder={`${translations.project} ${translations.or} ${translations.developer}`}
          />
        </Box>
        {!crmData && user?.role?.['@id'] === TypeOfRole.superadmin && (
          <Box ml="24px">
            <ButtonApp
              text={translations.createProject}
              onClick={handleAddComplex}
              color="primary"
              variant="contained"
              type="submit"
              className={styles.btn}
            />
          </Box>
        )}
      </Box>
      <Box>
        {filteredComplexes && filteredComplexes.length ? (
          <Grid container spacing={6} pb="48px">
            {filteredComplexes.map((complex: IComplex) => {
              return complex.showInCrosstable ? (
                <Grid item xs={isLargeScreen ? 3 : 4} key={`complex${complex.id}`}>
                  <ComplexItem complex={complex} />
                </Grid>
              ) : null;
            })}
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center">
            {translations.notFoundComplexes}
          </Box>
        )}
      </Box>
      {modalOpen && (
        <ComplexModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          refreshComplexes={() => {}}
          complex={editedComplex}
        />
      )}
    </Box>
  );
};
