import React from 'react';

import { IPlacementInfo } from 'src/components/ListOfLayoutApartments/types';
import { IFilterKeyInfo } from 'src/typings/filter';
import { UseNumberFormat, useTranslations } from 'src/hooks';
import { useGenerateShowingInfo } from '../../hooks';
import { useStyles } from './styles';

export const InfoKey: React.FC<IPlacementInfo> = ({
  itemData,
  filterConfigurator,
  displayedType,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const renderItems: { [key: string]: IFilterKeyInfo | any } = useGenerateShowingInfo(filterConfigurator[displayedType]);
  const { numberSeparator, replaceFraction } = UseNumberFormat();

  return (
    <div className={styles.apartmentKeyInfo}>
      {Object.keys(renderItems).map((renderItem, index) => {
        const renderData = renderItems[renderItem];
        const placementData = itemData[renderItem];

        if (itemData[renderItem]) {
          return (
            <div
              key={renderItem}
              className={styles.keyInfo}
            >
              {`${renderData['value-prefix'] ? renderData['value-prefix'] : ''} `}
              {renderData['use-name'] && `${getTranslations(renderItem)}:`}
              {` ${numberSeparator(replaceFraction(getTranslations(placementData)))}`}
              {`${renderData['value-sufix'] ? renderData['value-sufix'] : ''}`}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};
