import React, { useEffect, useState } from 'react';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { AppGallery, ButtonApp } from 'src/components';
import { useGenerateGalleryFormat } from 'src/components/ApartmentPageV2/ApartmentGallery/hooks';
import {
  ApplicationPropertyTypeEnum,
  IApartmentGallery,
  ReactImageGalleryItemWithSpinnerData,
  SpinnerLabel,
} from 'src/components/ApartmentPageV2/ApartmentGallery/types';
import { resizeEmulate } from 'src/helpers';
import { useIsMobile } from 'src/hooks';
import { ComponentAdditionalInfo } from 'src/containers/MainPageApartmentsV2/components/ComponentAdditionalInfo';
import './styles.scss';
import { TabContext, TabPanel } from '@mui/lab';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AppSpinner } from 'src/components/ApartmentPageV2/AppSpinner';
import { ViewPanorama } from 'src/components/ViewPanorama';
import { ICurrentPano } from 'src/store/panorama/reducer';
import { ApartmentDescriptionTour } from 'src/components/ApartmentPageV2/ApartmentDescription/components/ApartmentDescriptionTour';
import { SvgFullScreen } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgFullScreen';
import { SvgArrow } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgArrow';
import { getApplicationProperty } from 'src/helpers/applicationProperty';
import { SvgFullScreenOut } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgFullScreenOut';
import { useStyles } from './styles';

function checkURL(url: string) {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export const ApartmentGallery: React.FC<IApartmentGallery> = ({
  combineIds,
  spinnerApartments,
  panoApartments,
  gallery,
  chooseData,
  isSingleId,
  setRoomTours,
  placementViewConfig,
}) => {
  const styles = useStyles({});
  const {
    galleryState, onlySliderState, onlySpinnerState, onlyRoomTourState,
  } = useGenerateGalleryFormat(
    spinnerApartments,
    panoApartments,
    gallery,
    combineIds,
  );

  useEffect(() => {
    if (setRoomTours) setRoomTours(onlyRoomTourState);
  }, [onlyRoomTourState]);

  const [tabIndex, setTabIndex] = React.useState('0');
  const [currentTab, setCurrentTab] = useState<SpinnerLabel | null>(null);
  const { isMobileScreen } = useIsMobile();

  const tabsOrder = isMobileScreen
    ? [onlySliderState[0]]
    : [onlySliderState[0], onlySpinnerState[0], onlyRoomTourState[0]];

  // const _renderItem = (item: ReactImageGalleryItemWithSpinnerData) => {
  //   return (
  //     <ComponentGalleryItem
  //       galleryItem={item}
  //       galleryImagesTransform={chooseData[ListOfKeysFilterAllData.placementGalleryImagesTransform]}
  //       floorIds={chooseData[ListOfKeysFilterAllData.floorIds]}
  //       placementId={chooseData[ListOfKeysFilterAllData.id]}
  //       combineIds={combineIds}
  //     />
  //   );
  // };

  if (galleryState.length === 0) return null;

  const customTabPanels = (item: ReactImageGalleryItemWithSpinnerData): React.ReactNode => {
    switch (item.viewType) {
      case SpinnerLabel.spinner:
        if (item.spinnerData) {
          return (
            <AppSpinner
              spinnerData={item.spinnerData}
              type={item.type}
            />
          );
        }

        return null;
      case SpinnerLabel.apartment:
        return (
          <>
            { isSingleId && !isMobileScreen && placementViewConfig.applicationProperties && (
            <ComponentAdditionalInfo
              title={
                    getApplicationProperty(
                      placementViewConfig.applicationProperties,
                      ApplicationPropertyTypeEnum.mobLocationFloor,
                    )?.translates?.[placementViewConfig.lang] || ApplicationPropertyTypeEnum.mobLocationFloor
                  }
              chooseData={chooseData}
            />
            )}
            <div className={styles.wrapperGallerySection}>
              {Boolean(onlySliderState) && (
              <AppGallery
                lazyLoad
                showFullscreenButton
                additionalClass="app-slide-wrapper-v2"
                showBullets={onlySliderState.length > 1}
                disableSwipe={!isMobileScreen}
                showNav={!isMobileScreen}
                disableKeyDown={isMobileScreen}
                showThumbnails={false}
                showPlayButton={false}
                items={onlySliderState}
                renderLeftNav={(onClick, disabled) => (
                  <button
                    type="button"
                    className="image-gallery-icon image-gallery-left-nav"
                    onClick={onClick}
                  >
                    <SvgArrow />
                  </button>
                )}
                renderRightNav={(onClick, disabled) => (
                  <button
                    type="button"
                    className="image-gallery-icon image-gallery-right-nav"
                    onClick={onClick}
                  >
                    <SvgArrow />
                  </button>
                )}
                renderFullscreenButton={(onClick, isFullscreen) => {
                  return (
                    <ButtonApp
                      style={{ position: 'absolute' }}
                      className={`image-gallery-fullscreen-button ${styles.zoomButton}`}
                      onClick={(event) => {
                        onClick(event);
                        setTimeout(resizeEmulate, 250);
                      }}
                    >
                      {
                        isFullscreen
                          ? <SvgFullScreenOut />
                          : <SvgFullScreen />
                      }
                    </ButtonApp>
                  );
                }}
              />
              )}
            </div>
          </>
        );
      case SpinnerLabel.panorama:
        return (
          <Box py={2}>
            <Box my="-10px" className="image-gallery-image">
              <ViewPanorama
                currentPano={{
                  entity: item.entity,
                  entityId: item.entityId,
                  id: item.id,
                } as ICurrentPano}
                displayIds={combineIds}
              />
            </Box>
          </Box>
        );
      default:
        if (item.original?.indexOf('playcanvas') !== -1 || !checkURL(item.original)) {
          return (
            <ApartmentDescriptionTour tours={[item]} />
          );
        }
    }
    return '';
  };

  const isWideView = currentTab && [SpinnerLabel.spinner, SpinnerLabel.tour].includes(currentTab);

  return (
    <>
      <div className={`${styles.wrapperGallery} ${isWideView ? 'wide-view' : ''}`}>
        <Box className={`${styles.tabs}`}>
          <TabContext value={tabIndex}>
            { placementViewConfig && tabsOrder && tabsOrder
              .filter((item) => item)
              .map((spinner, index) => (
                <TabPanel
                  key={spinner.original}
                  value={String(index)}
                >
                  <Box className={`${styles.galleryTab} ${isWideView ? 'spinner-wide-view' : ''}`}>
                    {customTabPanels(spinner)}
                  </Box>
                </TabPanel>
              ))}
          </TabContext>
        </Box>
        {!isMobileScreen && (
          <Box className={styles.galleryControls}>
            <Box className={styles.galleryControlsInner}>
              {tabsOrder && Boolean(Object.keys(placementViewConfig).length) && tabsOrder.map((state, index) => {
                if (state && (state.spinnerData || state.original === SpinnerLabel.panorama || state.viewType === SpinnerLabel.panorama)) {
                  return (
                    <Button
                      key={state.original}
                      className={`${String(index) === tabIndex ? 'active' : ''}`}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setCurrentTab(SpinnerLabel.spinner);
                        setTabIndex(String(index));
                      }}
                    >
                      <div className="inner">
                        {
                          getApplicationProperty(
                            placementViewConfig.applicationProperties,
                            ApplicationPropertyTypeEnum.building,
                          )?.translates?.[placementViewConfig.lang] || ApplicationPropertyTypeEnum.building
                        }
                      </div>
                    </Button>
                  );
                }
                if (state?.viewType === SpinnerLabel.tour) {
                  return (
                    <Button
                      key={state.original}
                      className={`image-gallery-thumbnail-inner ${String(index) === tabIndex ? 'active' : ''}`}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setCurrentTab(SpinnerLabel.tour);
                        setTabIndex(String(index));
                      }}
                    >
                      <div className="inner">
                        {
                          getApplicationProperty(
                            placementViewConfig.applicationProperties,
                            ApplicationPropertyTypeEnum.roomsTour,
                          )?.translates?.[placementViewConfig.lang] || ApplicationPropertyTypeEnum.roomsTour
                        }
                      </div>
                    </Button>
                  );
                }
                if (state?.viewType === SpinnerLabel.apartment) {
                  return (
                    <Button
                      key={state.original}
                      className={`image-gallery-thumbnail-inner ${String(index) === tabIndex ? 'active' : ''}`}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setCurrentTab(SpinnerLabel.apartment);
                        setTabIndex(String(index));
                      }}
                    >
                      <div className="inner">
                        {
                          getApplicationProperty(
                            placementViewConfig.applicationProperties,
                            ApplicationPropertyTypeEnum.gallery,
                          )?.translates?.[placementViewConfig.lang] || ApplicationPropertyTypeEnum.gallery
                        }
                      </div>
                    </Button>
                  );
                }
                return null;
              })}
            </Box>
          </Box>
        )}
      </div>
    </>
  );
};
