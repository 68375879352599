import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperBottomNavigator: {
    boxShadow: '0px -4px 10px rgba(101, 100, 100, 0.3)',
    position: 'relative',
  },
  wrapperFavoriteCount: {
    position: 'absolute',
    top: '5px',
    right: '7px',
    width: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '9px',
    },
  },
}));
