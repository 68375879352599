import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IComplex } from 'src/typings/complexes';
import { getComplex } from 'src/store/crosstable/selectors';
import { Actions } from 'src/store/crosstable/actions';

import { getComplexData } from 'src/api';

export const useGetComplex = (id: string|undefined|null, saveToStore?: boolean) => {
  const [currentComplex, setCurrentComplex] = useState<IComplex|null>(null);
  const savedComplex: IComplex|null = useSelector(getComplex);
  const dispatch = useDispatch();

  useEffect(() => {
    let cleanupFunction = false;
    if (id && (!savedComplex || Number(id) !== Number(savedComplex.id))) {
      getComplexData(id)
        .then((res: any) => {
          if (!cleanupFunction) {
            setCurrentComplex(res.data);
            if (saveToStore === undefined || saveToStore) {
              dispatch(Actions.setComplex(res.data));
            }
          }
        });
    } else if (savedComplex) {
      setCurrentComplex(savedComplex);
    }

    return () => {
      cleanupFunction = true;
    };
  }, [id]);

  return currentComplex;
};
