import React, { useRef, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import { AppSpinner } from 'src/components/ApartmentPageV2/AppSpinner';
import { isString } from 'lodash';
import { ISpinnerFitType } from 'src/typings/spinner';
import { namePattern } from 'src/helpers/floorSrcParser';
import { IApartmentFloor } from './types';
import { useGetFloorsSpinner } from './hooks';
import { useStyles } from './styles';

export const ApartmentFloor: React.FC<IApartmentFloor> = ({
  idApartment,
  idFloor,
  hideWindRose = false,
  hideFloor = false,
  wrapperClassName = '',
  onClick,
  heightLimit = true,
}) => {
  const [computedHeight, setComputedHeight] = useState<number|null>(null);
  const styles = useStyles({});
  const imgRef = useRef<HTMLImageElement>(null);
  const { spinnerFloor } = useGetFloorsSpinner(idFloor);

  const handleOnLoad = useCallback(() => {
    if (imgRef.current?.height) {
      setComputedHeight((heightLimit && imgRef.current?.height > 250) ? 250 : imgRef.current?.height);
    }
  }, [imgRef.current]);

  const onFloorClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  if (spinnerFloor) {
    return (
      <>
        <Box
          position="absolute"
          top={0}
          left={-9999999}
          display="none"
        >
          <img
            ref={imgRef}
            width="100%"
            src={namePattern(isString(spinnerFloor.data[0]) ? spinnerFloor.data[0] : spinnerFloor.data[0].src)[0]}
            alt="width detector"
            onLoad={handleOnLoad}
          />
        </Box>
        {computedHeight && (
          <Box
            onClick={(event) => onFloorClickHandler(event)}
            className={`${wrapperClassName} ${styles.spinnerFloor}`}
            style={{ pointerEvents: `${onClick ? 'auto' : 'none'}`, cursor: `${onClick ? 'pointer' : 'default'}` }}
          >
            <AppSpinner
              wrapperClassName={styles.wrapper}
              hideWindRose={hideWindRose}
              hideFloor={hideFloor}
              objectFit={ISpinnerFitType.contain}
              spinnerData={spinnerFloor}
              spinnerHeight={computedHeight}
              roseInTop
              type="floor"
            />
          </Box>
        )}
      </>
    );
  }

  return null;
};
