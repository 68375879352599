import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appIcon } from 'src/assets';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentWrapper: {
    flex: '1',
    height: '100%',
    padding: '32px',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '52px',
    },
    [theme.breakpoints.up(3000)]: {
      padding: '96px',
    },
  },
  headerWrapperMobile: {
    [theme.breakpoints.down('md')]: {
      margin: '16px 20px 16px 20px',
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '28px',
    },
  },
  headerButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    width: '44px',
    height: '44px',
    minWidth: '44px',
    borderRadius: '10px',
    pointerEvents: 'all',
    marginRight: '7px',
    '& .MuiButton-startIcon': {
      margin: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px',
      minWidth: '40px',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '50%',
      marginRight: '14px',
    },
  },
  scrollTop: {
    position: 'absolute',
    right: '30px',
    bottom: '160px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0',
    },
  },
  headerBlock: {
    margin: '28px 0px',
    height: '34px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('xl')]: {
      margin: '40px 0px',
    },
    [theme.breakpoints.up(3000)]: {
      margin: '56px 0px',
    },
  },
  actions: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: '400',
    gap: '4px',
    color: theme.palette.grey['400'],
    height: '100%',
    [theme.breakpoints.up('xl')]: {
      gap: '8px',
      fontSize: '28px',
    },
  },
  actionsItem: {
    fontFamily: 'Helvetica',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '4px',
    padding: '8px',
    textDecorationLine: 'underline',
    textTransform: 'none',
    color: theme.palette.grey['400'],
    '& .MuiSvgIcon-root': {
      height: '14px',
    },
    [theme.breakpoints.up('xl')]: {
      gap: '8px',
      fontSize: '18px',
      lineHeight: '36px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    [theme.breakpoints.up(3000)]: {
      gap: '8px',
      fontSize: '28px',
      '& svg': {
        width: '25px',
        height: '25px',
      },
    },
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
  actionsItemButton: {
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: theme.palette.background.default,
    },
  },

  wrapperHeader: {
    marginBottom: '30px',
  },
  apartmentPageContainer: {
    flexDirection: 'column',
    padding: '30px',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  },
  nestedInfo: {
    marginBottom: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    '& > span': {
      color: theme.palette.primary.main,
    },
    '& > span:last-child': {
      color: theme.palette.secondary.main,
    },
  },
  apartmentContainer: {
    display: 'flex',
    '& > div:not(:first-child)': {
      paddingLeft: '32px',
      [theme.breakpoints.up('xl')]: {
        paddingLeft: '48px',
      },
      [theme.breakpoints.up(3000)]: {
        paddingLeft: '64px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      '& > div:not(:first-child)': {
        padding: '20px',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& > div:not(:first-child)': {
        padding: '0',
      },
      '& .intersection-visible-wrapper': {
        marginBottom: '20px',
        [theme.breakpoints.between('sm', 'md')]: {
          marginBottom: '0',
        },
      },
    },
  },
  apartmentAdditionalInfo: {
    width: 'calc(((100vh - 275px) - 16px) / 3)',
    gap: '8px',
    display: 'grid',
    gridAutoRows: '1fr',
    [theme.breakpoints.up('xl')]: {
      width: 'calc(((100vh - 550px) - 16px) / 3)',
      gap: '12px',
    },
    [theme.breakpoints.up(3000)]: {
      width: 'calc(((100vh - 550px) - 16px) / 3)',
      gap: '16px',
    },
    '& .addition-info-item': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      [theme.breakpoints.up('xl')]: {
        borderRadius: '12px',
      },
      [theme.breakpoints.up(3000)]: {
        borderRadius: '16px',
      },
      '& .spinner_place-v2': {
        height: '100%!important',
      },
    },
    '& .addition-info-item.floor': {
      padding: '4px',
    },
    '& .addition-info-item.floor-markup': {
      // padding: '4px',
    },
    '& .addition-info-item.hide': {
      display: 'none!important',
    },
    '& .holder': {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
    '& .markUpDesktop': {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      bottom: 0,
      transform: 'none',
      pointerEvents: 'none',
      touchAction: 'none',
    },
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 'none',
      maxWidth: 'none',
      '& .markUpDesktop': {
        display: 'block',
      },
      '& .markUpMobile': {
        display: 'none',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: '0 20px 0 20px',
      // marginTop: '32px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0 28px 0 28px',
    },
  },
  textNotAvailable: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  mobileSection: {
    display: 'flex',
    flexDirection: 'column',
    '& .top': {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '8px',
      '& .addition-info-item': {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.background.default}`,
      },
    },
    '& .bottom': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '280px',
      '& .addition-info-item': {
        padding: '4px',
        width: '100%',
        border: `1px solid ${theme.palette.background.default}`,
        height: 'unset',
        maxHeight: '280px',
      },
    },
    '& #spinner_control.spinner_control': {
      bottom: '15vw',
    },
  },
  mobileSectionTitle: {
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: '16px',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
}));
