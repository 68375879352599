import React, { useMemo } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { Actions } from 'src/store/spinner/actions';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { IFirstSpinner } from 'src/typings/spinner';
import { usePolygonConfig } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { useStyles } from './styles';
import { useStyles as useStylesIcon } from '../styles';
import { IEyeIcon } from './types';

export const EyeIcon: React.FC<IEyeIcon> = React.memo(({
  itemId,
  property,
}) => {
  const dispatch = useDispatch();
  const { globalPolygonConfig } = usePolygonConfig();
  const currentSpinner: IFirstSpinner | null = useSelector(getCurrentSpinner);
  const bestStop = useMemo(() => {
    return property?.[ListOfKeysFilterAllData.placementBestSpinnerStops]?.find(
      (stop) => stop['entity-view-name'] === currentSpinner?.name,
    );
  }, [property, currentSpinner]);
  const styles = useStyles(globalPolygonConfig);
  const stylesIcon = useStylesIcon();
  if (
    !bestStop
    || currentSpinner?.name !== bestStop['entity-view-name']
  ) return null;

  const handleHighlightEyeElement = () => {
    if (document.mainSpinner && bestStop) {
      document.mainSpinner.controls.setRotateTo([bestStop.stopNumber, 0], true);
    }
    dispatch(Actions.setHighlightPolygons([itemId]));
  };

  return (
    <div className={`${stylesIcon.wrapperIcon} ${styles.wrapperEyeIcon}`}>
      <VisibilityIcon
        className={`${stylesIcon.imageIcon}`}
        onClick={handleHighlightEyeElement}
      />
    </div>
  );
});
