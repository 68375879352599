import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';
import { Observable } from 'redux';

export function getPlacementStatus(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacementStatus}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getPlacementStatusByComplex(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacementStatus}?complex=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getPlacementStatusByComplexes(token: string = '', link: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacementStatus}/${link}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postPlacementStatus(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getPlacementStatus}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchPlacementStatus(token: string = '', id: string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getPlacementStatus}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}
