import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { checkboxes } from 'src/components/profileComponents/BitrixCategories/constants';
import Checkbox from '@mui/material/Checkbox';
import { useStyles } from 'src/components/profileComponents/BitrixCategories/styles';
import Table from '@mui/material/Table';
import { IComplex, IStage } from 'src/typings/complexes';
import { ISortedStatuses } from 'src/components/profileComponents/BitrixCategories/hooks';
import { CRMNames } from 'src/typings/app';

// interface externalStage {key: string, value: string}
//
// type stageList = {
//   '@id': string;
//   '@type': string;
//   id: number;
//   priority: number;
//   placementStatuses: string[];
// } & (IBitrixStage[] | ICreatioStage[]);

interface props {
  stages: IStage[] | null,
  complexes: IComplex[],
  statuses: ISortedStatuses,
  integration: CRMNames;
  handleChange: (stage: string, key: string, value: any) => void;
}

export const StagesTable: React.FC<props> = ({
  stages, complexes, statuses, integration, handleChange,
}) => {
  const styles = useStyles({});
  return (
    <Box>
      <Box mb={2}>
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.table} stickyHeader size="small">
            <TableHead className={styles.head}>
              <TableRow>
                <TableCell className={styles.sticky}>{`STAGE ID IN ${integration.toUpperCase()}`}</TableCell>
                {stages && stages.map((stage: any) => (
                  <TableCell align="center" key={stage['@id']}>
                    <InputBase
                      multiline
                      inputProps={{
                        style: {
                          textAlign: 'center',
                        },
                      }}
                      defaultValue={stage?.bitrixExternalStageId || stage?.externalId}
                      onBlur={(e) => handleChange(stage['@id'], 'externalId', e.target.value)}
                    />

                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky}>{`STAGE NAME IN ${integration.toUpperCase()}`}</TableCell>
                {stages && stages.map((stage: any) => (
                  <TableCell align="center" key={stage['@id']}>
                    <InputBase
                      multiline
                      inputProps={{
                        style: {
                          textAlign: 'center',
                        },
                      }}
                      defaultValue={stage?.bitrixExternalStageName || stage.externalName}
                      onBlur={(e) => handleChange(stage['@id'], 'externalName', e.target.value)}
                    />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky}>Stage Priority</TableCell>
                {stages && stages.map((stage: any) => (
                  <TableCell align="center" key={stage['@id']}>
                    <Box display="inline-block" width={100}>
                      <TextField
                        id={`priority${stage['@id']}`}
                        type="number"
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                        defaultValue={stage.priority}
                        onBlur={(e) => handleChange(stage['@id'], 'priority', Number(e.target.value))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {complexes.map((row) => statuses[row['@id']] && (
                <TableRow key={row['@id']}>
                  <TableCell className={styles.sticky} align="left">
                    <Box display="flex" alignItems="center" gap="12px">
                      <Checkbox
                        color="primary"
                        onChange={(e) => handleChange(row['@id'], 'activeComplexes', e.target.checked ? [row['@id']] : [])}
                      />
                      {row.title[row.language]}
                    </Box>
                  </TableCell>
                  {stages && stages.map((stage: any) => {
                    let defaultVal = '';
                    stage.placementStatuses.forEach((status: any) => {
                      const currentSt = statuses[row['@id']].find((value) => {
                        return value['@id'] === status;
                      });
                      if (currentSt) {
                        defaultVal = currentSt?.['@id'];
                      }
                    });
                    return (
                      <TableCell align="center" key={stage['@id']}>
                        <Select
                          id={`status${stage['@id']}`}
                          defaultValue={defaultVal}
                          onChange={(e) => {
                            handleChange(stage['@id'], `status${row['@id']}`, e.target.value);
                          }}
                        >
                          {statuses[row['@id']].map((status) => (
                            <MenuItem key={status['@id']} value={status['@id']}>
                              <Box display="flex" alignItems="center">

                                <Box
                                  width="10px"
                                  height="10px"
                                  mr={1}
                                  borderRadius="2px"
                                  title="Цвет в шахматке"
                                  style={{
                                    backgroundColor: status.crosstableColor || status.color,
                                  }}
                                />
                                {status.crosstable_name[row.language]}
                              </Box>

                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
              {Object.keys(checkboxes).map((checkKey) => (
                <TableRow key={checkKey}>
                  <TableCell className={styles.sticky}>
                    <div dangerouslySetInnerHTML={{ __html: checkboxes[checkKey] }} />
                  </TableCell>
                  {stages && stages.map((stage: any) => (
                    <TableCell align="center" key={stage['@id']}>
                      <Checkbox
                        defaultChecked={stage[checkKey]}
                        color="primary"
                        onChange={(e) => handleChange(stage['@id'], checkKey, e.target.checked)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
