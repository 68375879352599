import { useSelector } from 'react-redux';
import { IFilterAllData, ListOfKeysFilterAllData } from '../../../../../typings/filter';
import { getView2dStatuses } from '../../../../../store/view-2d/selectors';
import { requestSelectors } from '../../../../../store/requests/selectors';
import { TypeOfRole } from '../../../../../typings/user';
import { useBuildType } from '../../../../../hooks';

export const useIsPlacementNeedConnect = (placement: IFilterAllData) => {
  const user = useSelector(requestSelectors.user.getUserData);
  const { isCrossTable } = useBuildType();
  const statuses = useSelector(getView2dStatuses);
  const statusId = placement[ListOfKeysFilterAllData.statusId];
  const { bitrix_deals: bitrixDeals, netHuntOpportunities, creatioOpportunities } = placement;
  const isConnected = bitrixDeals?.length || netHuntOpportunities?.length || creatioOpportunities?.length;
  return isCrossTable && !isConnected && statusId !== String(statuses[0]?.id) && user?.role?.['@id'] !== TypeOfRole.manager;
};
