import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { patchNetHuntFunnel } from 'src/api';
import { useGetAuthData } from 'src/hooks';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { useStyles } from 'src/components/profileComponents/Integrations/Bitrix/styles';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  INetHuntField,
  INetHuntFunnel,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

interface IDirectorySelector { funnel: INetHuntFunnel | undefined, funnelFields: INetHuntField[] }

export const ResponsibleAndDealSelector: React.FC<IDirectorySelector> = ({
  funnel, funnelFields,
}) => {
  const [error, setError] = useState('');
  const [
    selectedResponsible,
    setSelectedResponsible,
  ] = useState<string>(funnel?.externalResponsibleUserPropertyName || '');
  const [
    isMultiple,
    setIsMultiple,
  ] = useState<boolean>(funnel?.isMultipleExternalResponsibleUserProperty || false);
  const [
    selectedDeal,
    setSelectedDeal,
  ] = useState<string>(funnel?.opportunityPropertyExternalName || '');
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const onSubmit = async () => {
    if (funnel) {
      const res = await patchNetHuntFunnel(jwt, funnel.id, {
        opportunityPropertyExternalName: selectedDeal,
        externalResponsibleUserPropertyName: selectedResponsible,
        isMultipleExternalResponsibleUserProperty: isMultiple,
      });
      if (!res.ok) {
        setError(res.data['hydra:description']);
      }
    }
  };

  return (
    <>
      <Box>
        <Box mb={4} fontSize="24px" fontWeight={600}>
          {translations.attachedDealDetails}
        </Box>
        <Box fontSize="14px">
          {funnelFields && Boolean(funnelFields.length) && (
            <>
              <Box mb={4} display="flex" alignItems="center">
                <Box width="260px" mr={3}>
                  {translations.responsibleUser}
                </Box>
                <Box minWidth="260px" mr={10}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel>Field name</InputLabel>
                    <Select
                      fullWidth
                      variant="outlined"
                      onChange={(event) => setSelectedResponsible(event.target?.value)}
                      value={selectedResponsible}
                      label="Field name"
                    >
                      {funnelFields.map((field) => (
                        <MenuItem key={field.name} value={field.name}>
                          {field.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" gap="12px">
                  <Checkbox
                    onChange={(event, checked) => setIsMultiple(checked)}
                    checked={isMultiple}
                  />
                  {translations.multipleResponsible}
                </Box>
              </Box>
              <Box mb={4} display="flex" alignItems="center">
                <Box width="260px" mr={3}>
                  {translations.dealName}
                </Box>
                <Box minWidth="260px">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel>Field name</InputLabel>
                    <Select
                      fullWidth
                      variant="outlined"
                      onChange={(event) => setSelectedDeal(event.target?.value)}
                      value={selectedDeal}
                      label="Field name"
                    >
                      {funnelFields.map((field) => (
                        <MenuItem key={field.name} value={field.name}>
                          <Box display="flex" alignItems="center">
                            {field.name}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box width="260px">
                <ButtonApp
                  text={translations.saveChanges}
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="small"
                  onClick={onSubmit}
                  className={styles.btn}
                />
              </Box>
            </>
          )}
          <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
            <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};
