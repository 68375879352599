import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popover from '@mui/material/Popover';
import { Theme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { requestSelectors } from 'src/store/requests/selectors';
import { getAvailablePanoramas, getCurrentPanorama, getLookAtMyPano } from 'src/store/panorama/selectors';
import { getCurrentLang, getCurrentEntity } from 'src/store/app/selectors';
import { useBuildType, useTranslations } from 'src/hooks';
import { getSelectedView } from 'src/store/type-displayed-spinner/selectors';
import { IListOfTypes } from 'src/store/type-displayed-spinner/constant';
import { IPanoramaWrapper } from 'src/store/panorama/reducer';
import { Actions as ActionsPano } from 'src/store/panorama/actions';
import { IView } from 'src/store/type-displayed-spinner/types';
import { AroundImage } from './index';
import { useStyles as useStylesSpinner } from '../styles-spinner';
import { useStyles as useStylesCrossTable } from '../styles-crosstable';
import { useStyles } from './styles';

interface IMasterPlanDropdown {
  view: IView;
  isActive: boolean;
  getSelectedViewId: string;
  handleClick: Function;
}

export const MasterPlanDropdown: React.FC<IMasterPlanDropdown> = ({
  view,
  isActive,
  getSelectedViewId,
  handleClick,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const theme: Theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [curPano, setCurPano] = useState<IPanoramaWrapper|null>(null);
  const { isSpinner } = useBuildType();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const lookAtMyPano = useSelector(getLookAtMyPano);
  const currentPano = useSelector(getCurrentPanorama);
  const availablePanoramas = useSelector(getAvailablePanoramas);
  const selectedView = useSelector(getSelectedView);
  const currentEntity = useSelector(getCurrentEntity);
  const currentLang = useSelector(getCurrentLang);
  const stylesDrop = useStyles({});
  const stylesSpinner = useStylesSpinner(mainColor);
  const stylesCross = useStylesCrossTable();
  const styles = isSpinner ? stylesSpinner : stylesCross;
  const { getTranslations } = useTranslations();

  const handleClose = useCallback((e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  }, []);

  const openPano = useCallback((pano: IPanoramaWrapper) => {
    if (currentEntity && currentEntity.name && currentEntity.id) {
      if (view.type !== selectedView) {
        handleClick();
      }
      dispatch(ActionsPano.setLookAtMyPano(true));
      dispatch(ActionsPano.setCurrentPanorama({
        entity: currentEntity.name,
        entityId: currentEntity.id,
        id: pano.id,
      }));
    }
  }, [dispatch, currentEntity, view, selectedView, handleClick]);

  const backToSpinner = useCallback((e:any) => {
    dispatch(ActionsPano.setLookAtMyPano(false));
    setCurPano(null);
    handleClose(e);
    if (isMdScreen && view.type !== selectedView) {
      handleClick();
    }
  }, [dispatch, handleClose, handleClick, isMdScreen, view, selectedView]);

  const handleClickBtn = useCallback(() => {
    handleClick();
  }, [isMdScreen, availablePanoramas, currentEntity, handleClick]);

  useEffect(() => {
    if (lookAtMyPano && currentPano && availablePanoramas && availablePanoramas[currentPano.entity]
      && availablePanoramas[currentPano.entity][currentPano.entityId]) {
      const findedPano = availablePanoramas[currentPano.entity][currentPano.entityId].find((item) => item.id === currentPano.id);
      if (findedPano) {
        setCurPano(findedPano);
      }
    }
    if (!lookAtMyPano) {
      setCurPano(null);
    }
  }, [currentPano, lookAtMyPano, availablePanoramas]);

  return (
    <div
      className={`${styles.wrapperTypeItem} ${
        view.type === getSelectedViewId && isActive ? styles.selectedStyle : ''}`}
      role="button"
      tabIndex={0}
      aria-hidden="true"
      onClick={handleClickBtn}
      ref={ref}
    >
      <Box className={styles.iconTypeStyles}>
        <AroundImage />
      </Box>
      <span>
        {
        // lookAtMyPano
        //   ? (curPano && curPano?.name[currentLang])
        //   : getTranslations(type.label)
          view.name.translates?.[currentLang] || view.name.systemName
      }
      </span>
      {/* {availablePanoramas && currentEntity.name && currentEntity.id && availablePanoramas[currentEntity.name]
          && availablePanoramas[currentEntity.name][currentEntity.id] && (
            <>
              {!isMdScreen ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  m="-15px -15px -15px 5px"
                  padding="12px 10px"
                  className={stylesDrop.dropBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log('Handle click drop btn');
                    setIsOpen(true);
                  }}
                >
                  <ArrowDropDownIcon />
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  top="-5px"
                  left="calc(50%-3px)"
                >
                  <ArrowDropUpIcon />
                </Box>
              )}
              <Popover
                open={isOpen}
                anchorEl={ref.current}
                onClose={handleClose}
                anchorOrigin={isMdScreen ? {
                  vertical: 'top',
                  horizontal: 'center',
                } : {
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={isMdScreen ? {
                  vertical: 'bottom',
                  horizontal: 'center',
                } : {
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box>
                  <Box
                    p={2}
                    className={`${stylesDrop.dropMenuBtn} ${(!lookAtMyPano && type.id === selectedView) && stylesDrop.activeMenuBtn}`}
                    onClick={backToSpinner}
                  >
                    {getTranslations(type.label)}
                  </Box>
                  {availablePanoramas[currentEntity.name][currentEntity.id].map((pano) => {
                    if (pano.panoramaV2 || pano.nightPanoramaV2) {
                      return (
                        <Box
                          key={pano.id}
                          p={2}
                          className={`${stylesDrop.dropMenuBtn} ${(curPano && pano.id === curPano.id) && stylesDrop.activeMenuBtn}`}
                          onClick={(e) => {
                            openPano(pano);
                            handleClose(e);
                          }}
                        >
                          {pano.name[currentLang]}
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </Popover>
            </>
      )} */}
    </div>
  );
};
