import { useSelector } from 'react-redux';
import _ from 'lodash';

import { IFilterAllData, ListOfKeysFilterAllData, PlacementShowModule } from 'src/typings/filter';
import { getFilteredData } from 'src/store/filter/filtered-data/selectors';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { requestSelectors } from 'src/store/requests/selectors';
import { useGetFilterParams } from 'src/hooks/use-get-filter-params';
import { IAppLayouts, IAppLayoutsItem } from 'src/typings/layouts';
import { getFilteredLayouts } from 'src/store/layouts/selectors';
import { getFilterHasNotResults } from 'src/store/filter/filter-params/selectors';

export const useGetFilteredData = (): {layoutsData: IAppLayoutsItem[], resultFiltering: IFilterAllData[]} => {
  const { selectedParams } = useGetFilterParams(3);
  const filterInitialData = useSelector(requestSelectors.filter.getFilterInitialData);
  const layoutsData: IAppLayouts | undefined = useSelector(requestSelectors.layouts.getLayoutsRequestData);
  const filteredData: { [key: string]: IFilterAllData[] } = useSelector(getFilteredData);
  const filteredLayoutData: IAppLayoutsItem[] = useSelector(getFilteredLayouts);
  const typeOfProperty: any = useSelector(getSelectedPlacement);
  const filterHasNoResults = useSelector(getFilterHasNotResults);
  return {
    resultFiltering:
      (!filterHasNoResults && filteredData[typeOfProperty]?.length && filteredData[typeOfProperty])
      || (!filterHasNoResults && selectedParams.length === 0 && (
        filterInitialData
            && typeOfProperty
            && _.values(filterInitialData[typeOfProperty]?.filter_all_data)
              .filter((item) => item[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.show)
      )
      )
      || [],
    layoutsData:
      (selectedParams.length === 0 && layoutsData && layoutsData[typeOfProperty])
      || filteredLayoutData,
  };
};
