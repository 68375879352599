import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { requestSelectors } from 'src/store/requests/selectors';
import { getAvailablePanoramas } from 'src/store/panorama/selectors';
import { getComplexId } from 'src/store/app/selectors';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { IAppLayouts } from 'src/typings/layouts';
import { IGalleryListOfSpinner, SpinnerLabel } from 'src/components/ApartmentGallery/types';
import { useDisplayApartmentIds } from 'src/hooks/useDisplayApartmentIds';

export interface IPanoAppartments {
  order: number;
  weight: number;
  id: number;
  entityId: string;
  entity: string;
  icon: string | null;
}

export const useGetParamsData = () => {
  const { Id }: { Id: string } = useParams();
  const { displayIds } = useDisplayApartmentIds();
  const isSingleId = displayIds.length === 1 || false;
  const [chooseData, setChooseData] = useState<IFilterAllData>();
  const [spinnerApartments, setSpinnerApartments] = useState<IGalleryListOfSpinner[] | []>([]);
  const [panoApartments, setPanoApartments] = useState<IPanoAppartments[] | []>([]);
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerData);
  const spinnerGalleryPosition = useSelector(requestSelectors.app.getGallerySpinnerPosition);
  const galleryPanoramaPosition = useSelector(requestSelectors.app.getGalleryPanoramaPosition);
  const galleryPreviews = useSelector(requestSelectors.app.getGalleryPreviews);
  const availablePanoramas = useSelector(getAvailablePanoramas);
  const complexId = useSelector(getComplexId);
  const layoutsData: IAppLayouts | undefined = useSelector(requestSelectors.layouts.getLayoutsRequestData);

  useEffect(() => {
    if (isSingleId) {
      // @ts-ignore
      const spinnerInitData = spinnerData?.['detail-info']?.placement?.[Id];
      setChooseData(
        spinnerData
        && Id
          ? spinnerInitData as IFilterAllData
          : undefined,
      );

      // @ts-ignore
      window.appartmentData = spinnerInitData;
    }

    if (layoutsData && !isSingleId) {
      let layoutFilteredData;

      Object.keys(layoutsData).forEach((layoutsPlacement) => {
        layoutsData[layoutsPlacement].forEach((layout) => {
          if (_.isEqual(layout[ListOfKeysFilterAllData.id].sort(), displayIds.sort())) {
            layoutFilteredData = layout;
          }
        });
      });

      if (layoutFilteredData) {
        setChooseData(layoutFilteredData);

        // @ts-ignore
        window.appartmentData = layoutFilteredData;
      }
    }
  }, [Id, isSingleId, layoutsData, displayIds, spinnerData]);

  useEffect(() => {
    return () => {
      // @ts-ignore
      if (window.appartmentData) {
        // @ts-ignore
        window.appartmentData = null;
      }
    };
  }, []);

  // List of spinner
  useEffect(() => {
    if (spinnerGalleryPosition && galleryPanoramaPosition && chooseData) {
      const spinnerOrder: IGalleryListOfSpinner[] = [];
      const listOfSpinner: IGalleryListOfSpinner[] = Object.keys(spinnerGalleryPosition)
        .reduce((acc, cur) => {
          if (spinnerGalleryPosition[cur] !== null) {
            const floorsId: any = ListOfKeysFilterAllData.floorIds[0];
            const floor: any = spinnerData?.spinners[cur][floorsId];

            if (
              cur === SpinnerLabel.complex
              && spinnerData?.spinners.firstSpinner.name === SpinnerLabel.complex
              && spinnerData?.spinners[cur][spinnerData?.spinners.firstSpinner['name-id']]
            ) {
              return [
                ...acc,
                {
                  name: cur,
                  order: spinnerGalleryPosition[cur],
                  data: spinnerData?.spinners[cur][spinnerData?.spinners.firstSpinner['name-id']],
                  icon: galleryPreviews?.placement_gallery_complex_spinner_preview_url,
                },
              ];
            }

            if (
              cur === SpinnerLabel.house
              && spinnerData?.spinners[cur][chooseData[ListOfKeysFilterAllData.houseId]]
            ) {
              return [
                ...acc,
                {
                  name: cur,
                  order: spinnerGalleryPosition[cur],
                  data: spinnerData?.spinners[cur][chooseData[ListOfKeysFilterAllData.houseId]],
                  icon: galleryPreviews?.placement_gallery_house_spinner_preview_url,
                },
              ];
            }

            if (
              cur === SpinnerLabel.section && isSingleId
              && spinnerData?.spinners[cur][chooseData[ListOfKeysFilterAllData.sectionId]]
            ) {
              return [
                ...acc,
                {
                  name: cur,
                  order: spinnerGalleryPosition[cur],
                  data: spinnerData?.spinners[cur][chooseData[ListOfKeysFilterAllData.sectionId]],
                  icon: galleryPreviews?.placement_gallery_section_spinner_preview_url,
                },
              ];
            }

            if (
              cur === SpinnerLabel.placement
              && isSingleId && spinnerData?.spinners[cur][Id]
            ) {
              return [
                ...acc,
                {
                  name: cur,
                  order: spinnerGalleryPosition[cur],
                  data: spinnerData?.spinners[cur][Id],
                  icon: galleryPreviews?.placement_gallery_placement_spinner_preview_url,
                },
              ];
            }

            if (
              cur === SpinnerLabel.floor
              && isSingleId && spinnerData?.spinners[cur][floor]
            ) {
              return [
                ...acc,
                {
                  name: cur,
                  order: spinnerGalleryPosition[cur],
                  data: spinnerData?.spinners[cur][floor],
                  icon: galleryPreviews?.placement_gallery_floor_spinner_preview_url,
                },
              ];
            }

            return acc;
          }

          return acc;
        }, [] as any);

      if (availablePanoramas) {
        const panoramas = Object.keys(galleryPanoramaPosition).reduce((acc: IPanoAppartments[], item) => {
          if (
            item === SpinnerLabel.complex
              && availablePanoramas[item]
          ) {
            Object.keys(availablePanoramas[item]).forEach((compItem) => {
              if (String(complexId) === compItem) {
                availablePanoramas[item][compItem].forEach((panoItem) => {
                  if (panoItem.panoramaV2 && panoItem.panoramaV2?.config?.children) {
                    let polyWeight: number | null = null;
                    panoItem.panoramaV2.config.children.forEach((polyItem: any) => {
                      if (polyItem.data.transition && displayIds.includes(String(polyItem.data.transition.id))) {
                        const mesh = new window.Panorama2[polyItem.type](polyItem);
                        const pos = window.Panorama2.getObjectCenter(mesh);
                        if (!polyWeight || pos.s > polyWeight) {
                          polyWeight = pos.s;
                        }
                      }
                    });
                    if (polyWeight) {
                      acc.push({
                        weight: polyWeight,
                        order: Number(galleryPanoramaPosition[item]),
                        id: panoItem.panoramaV2.id,
                        entityId: compItem,
                        entity: item,
                        icon: galleryPreviews?.placement_gallery_complex_panorama_preview_url || null,
                      });
                    }
                  }
                });
              }
            });
          }
          if (
            item === SpinnerLabel.house
          && availablePanoramas[item]
          ) {
            Object.keys(availablePanoramas[item]).forEach((compItem) => {
              if (chooseData[ListOfKeysFilterAllData.houseId] === compItem || chooseData[ListOfKeysFilterAllData.houseId].includes(compItem)) {
                availablePanoramas[item][compItem].forEach((panoItem) => {
                  if (panoItem.panoramaV2 && panoItem.panoramaV2?.config?.children) {
                    let polyWeight: number | null = null;
                    panoItem.panoramaV2.config.children.forEach((polyItem: any) => {
                      if (polyItem.data.transition && displayIds.includes(String(polyItem.data.transition.id))) {
                        const mesh = new window.Panorama2[polyItem.type](polyItem);
                        const pos = window.Panorama2.getObjectCenter(mesh);
                        if (!polyWeight || pos.s > polyWeight) {
                          polyWeight = pos.s;
                        }
                      }
                    });
                    if (polyWeight) {
                      acc.push({
                        weight: polyWeight,
                        order: Number(galleryPanoramaPosition[item]),
                        id: panoItem.id,
                        entityId: compItem,
                        entity: item,
                        icon: galleryPreviews?.placement_gallery_house_panorama_preview_url || null,
                      });
                    }
                  }
                });
              }
            });
          }
          return acc;
        }, []);

        panoramas.sort((a, b) => {
          if (a.weight > b.weight) {
            return -1;
          }
          if (a.weight < b.weight) {
            return 1;
          }
          return 0;
        });
        setPanoApartments(panoramas);
      }

      listOfSpinner.forEach((spinner) => {
        spinnerOrder[spinner.order] = spinner;
      });

      setSpinnerApartments(spinnerOrder);
    }
  }, [isSingleId, spinnerData, chooseData, spinnerGalleryPosition, availablePanoramas, Id, galleryPreviews]);

  return {
    isSingleId,
    spinnerApartments,
    panoApartments,
    chooseData,
  };
};
