import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { whiteColor } from 'src/constants';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  crossTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: '30px',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 20px 0px 0px',
      height: 'calc(100% - 60px)',
    },
  },
  crossTableContainerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '0',
      marginTop: '0',
      marginBottom: '0',
    },
  },
  logo: {
    marginRight: '20px',
    '& > div': {
      height: '44px',
      maxWidth: '200px',
      '& a': {
        position: 'static',
        maxWidth: '200px',
        '& img': {
          [theme.breakpoints.up('lg')]: {
            maxWidth: '200px',
          },
        },
      },
    },
  },
  statusList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      overflow: 'auto',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: '0',
    },
  },
  statusListItem: {
    whiteSpace: 'nowrap',
  },
  closedFilters: {
    marginLeft: '60px',
  },
  crossTable: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    overflowY: 'auto',
    '& #crossTableContent': {
      width: '100%',
      height: '100%',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      overflowY: 'auto',
      overflowX: 'auto',
      flex: 1,
      '& .crossTableContentInner': {
        width: '100%',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
        overflowX: 'auto',
        paddingBottom: '10px',
        flex: 1,
      },
    },
  },
  controlTableContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '22px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  noDisplayData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > p': {
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  zoom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    minHeight: '70px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
    '&.hidden': {
      display: 'none',
    },
  },
  exportBtn: {
    fontSize: '13px',
    lineHeight: '19.5px',
    padding: '12px 28px',
    height: '44px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.background.paper,
      '& .MuiCircularProgress-root': {
        color: theme.palette.background.paper,
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '9px',
    },
  },
}));
