import { useState } from 'react';
import {
  getComplexLink,
  getComplexLinkByComplexId,
  getSortedStatuses,
} from 'src/components/profileComponents/Integrations/tools';
import { getPlacementStatusByComplexes } from 'src/api';
import { IComplex, IDeveloper, IStatus } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { ISortedStatuses } from 'src/components/profileComponents/BitrixCategories/hooks';

export const useGetStatuses = () => {
  const [sortedStatuses, setSortedStatuses] = useState<ISortedStatuses | null>(null);
  const [statuses, setStatuses] = useState<IStatus[] | null>(null);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchStatuses = async (complexes: string[]) => {
    const complexLink = getComplexLinkByComplexId(complexes);
    const { data: statusData } = await getPlacementStatusByComplexes(jwt, complexLink);
    const stasusesArray: IStatus[] = statusData['hydra:member'];
    setStatuses(stasusesArray);
    setSortedStatuses(getSortedStatuses(stasusesArray));
  };

  return {
    isLoading,
    sortedStatuses,
    statuses,
    fetchStatuses,
  };
};
