import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { localStorageConstants } from 'src/constants';
import { favoriteCountState, getComplexId } from 'src/store/app/selectors';

export const useFavoriteStorage = () => {
  const storeFavCount = useSelector(favoriteCountState);
  const complexId = useSelector(getComplexId);
  const [isUp, setIsUp] = useState<boolean | null>(null);
  const getStorageItemsSelector: string | null = localStorage.getItem(localStorageConstants.favoriteList);
  const favIdsListStorage = getStorageItemsSelector ? JSON.parse(getStorageItemsSelector) : [];
  const [favIdsList, setFavIdsList] = useState<string[]>([]);
  const [componentCount, setCount] = useState<number>(favIdsList ? favIdsList.length : 0);

  useEffect(() => {
    if (complexId !== null) {
      setFavIdsList(favIdsListStorage[complexId] || []);
    }
    if (storeFavCount !== componentCount) {
      if (storeFavCount > componentCount) {
        setIsUp(null);
        setTimeout(() => setIsUp(true), 10);
      } else {
        setIsUp(null);
        setTimeout(() => setIsUp(false), 10);
      }

      setCount(storeFavCount);
    }
  }, [storeFavCount, componentCount, complexId]);

  return {
    isUp,
    favIdsList,
    storeFavCount,
  };
};
