import React from 'react';
import { useStyles } from 'src/components/profileComponents/Integrations/Bitrix/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { postDecodeUrl } from 'src/api';
import { useGetAuthData } from 'src/hooks';

const schema = yup.object().shape({
  workspaceUrl: yup.string(),
  decodeResult: yup.string(),
});

export const UrlDecoder: React.FC = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const styles = useStyles({});
  const {
    register, handleSubmit, setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const onSubmit = async (data: {workspaceUrl: string, decodeResult: string}) => {
    setValue('decodeResult', '');
    const res = await postDecodeUrl(jwt, { url: data.workspaceUrl });
    if (res.ok) {
      setValue('decodeResult', `{\n "workspaceId": ${res.data.workspaceId},\n "workspaceDashboard": {}\n}`);
    } else {
      setValue('decodeResult', res.data.title);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={4} display="flex" alignItems="center">
          <Box width="260px" mr={3}>
            Workspace URL
          </Box>
          <Box width="260px">
            <TextField
              id="workspaceUrl"
              size="small"
              variant="outlined"
              name="workspaceUrl"
              inputRef={register}
            />
          </Box>
        </Box>
        <Box mb={4} display="flex" alignItems="center">
          <Box width="260px" mr={3}>
            Decode result
          </Box>
          <Box width="360px">
            <TextField
              disabled
              multiline
              rows={4}
              maxRows={6}
              id="decodeResult"
              size="small"
              variant="outlined"
              name="decodeResult"
              inputRef={register}
            />
          </Box>
        </Box>
        <Box width="260px">
          <ButtonApp
            text={translations.decode}
            color="primary"
            variant="contained"
            type="submit"
            size="small"
            className={styles.btn}
          />
        </Box>
      </form>
    </>
  );
};
