import React from 'react';

export const SvgHeartSmall: React.FC<{fill?: string}> = ({ fill }) => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill={fill || 'none'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1249 7.43297L7.50396 13L1.88296 7.43297C1.51221 7.07219 1.22017 6.63855 1.02523 6.15935C0.830303
       5.68016 0.736701 5.1658 0.750323 4.64865C0.763945 4.13151 0.884496 3.62279 1.10439 3.15452C1.32427 2.68626 1.63874
        2.26859 2.02797 1.92783C2.41721 1.58707 2.87279 1.33059 3.36601 1.17454C3.85924 1.0185 4.37944 0.966267 4.89384
         1.02114C5.40825 1.07602 5.90572 1.2368 6.35493 1.49338C6.80414 1.74996 7.19536 2.09677 7.50396 2.51198C7.81389
          2.09979 8.20556 1.75601 8.65446 1.50215C9.10336 1.24829 9.59983 1.08983 10.1128 1.03667C10.6258 0.98351 11.1442
           1.0368 11.6356 1.19321C12.127 1.34962 12.5809 1.60578 12.9687 1.94565C13.3566 2.28553 13.6701 2.70181 13.8897
            3.16843C14.1093 3.63505 14.2302 4.14198 14.2449 4.65748C14.2595 5.17298 14.1677 5.68596 13.9749 6.16431C13.7822
             6.64266 13.4929 7.07609 13.1249 7.43747"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
