import { IAction } from 'src/typings';
import { ActionTypes } from 'src/store/placement/actions';

export interface IPlacement {
    [key: string]: string | number| Array<any> | object | null,
}

export const initialState: IPlacement | {} = {};

export const placementReducer = (
  state = initialState,
  { type, payload }: IAction,
): IPlacement => {
  switch (type) {
    case ActionTypes.SET_PLACEMENT_DATA:
      return {
        ...state,
        placement: payload.placement,
      };
    default:
      return state;
  }
};
