import { IAppState } from 'src/store/types';

export const getComplex = (state: IAppState) => state.crosstable.complex;
export const getStatuses = (state: IAppState) => state.crosstable.statuses;
export const getHouses = (state: IAppState) => state.crosstable.houses;
export const getBitrixDeal = (state: IAppState) => state.crosstable.bitrixDeal;
export const getCreatioDeal = (state: IAppState) => state.crosstable.creatioDeal;
export const getNetHuntDeal = (state: IAppState) => state.crosstable.netHuntDeal;
export const getAvailableComplexes = (state: IAppState) => state.crosstable.availableComplexes;
export const getDefaultPlacementType = (state: IAppState) => state.crosstable.defaultPlacementType;
