import { IComplex, IStatus } from 'src/typings/complexes';
import { ISortedStatuses } from 'src/components/profileComponents/BitrixCategories/hooks';

export const getComplexLink = (complexes: IComplex[]): string => {
  return complexes.reduce((acc: string, item, idx) => {
    if (idx === 0) {
      return `${acc}?complex[]=${item.id}`;
    }
    return `${acc}&complex[]=${item.id}`;
  }, '');
};

export const getComplexLinkByComplexId = (complexes: string[]): string => {
  return complexes.reduce((acc: string, item, idx) => {
    if (idx === 0) {
      return `${acc}?complex[]=${item}`;
    }
    return `${acc}&complex[]=${item}`;
  }, '');
};

export const getSortedStatuses = (statuses: IStatus[]): ISortedStatuses => {
  const sortedStatuses: ISortedStatuses = {};
  statuses.forEach((item) => {
    if (sortedStatuses[item.complex]) {
      sortedStatuses[item.complex].push(item);
    } else {
      sortedStatuses[item.complex] = [item];
    }
  });
  return sortedStatuses;
};
