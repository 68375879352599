/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { UploadButton } from 'src/components/UploadButton';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IDeveloper } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import { uploadFile, postDeveloperData, patchDeveloperData } from 'src/api';
import { useStyles } from '../styles';

interface StatusModal {
  isOpen: boolean;
  handleChangeOpen: Function;
  refreshDevelopers: Function;
  developer?: IDeveloper|null;
}

const schema = yup.object().shape({
  title: yup.string().required(translations.requiredField),
});

export const DevModal: React.FC<StatusModal> = ({
  isOpen, handleChangeOpen, developer, refreshDevelopers,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const [icon, setIcon] = useState(developer ? developer.logoUri : '');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const {
    register, handleSubmit, formState, watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const handleUploadIcon = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('logoUri', event.target.files[0]);

      uploadFile(formData).then((res: any) => {
        setIcon(res.data.logoUri);
      });
    }
  };

  const close = () => handleChangeOpen(false);

  const onSubmit = async (data:any) => {
    let res;
    if (developer) {
      res = await patchDeveloperData(jwt, developer.id, data);
    } else {
      res = await postDeveloperData(jwt, data);
    }
    if (res.ok) {
      close();
      refreshDevelopers();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="600px" maxHeight="90vh" overflow="auto" py={10} px={11}>
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {developer ? translations.developerEditing : translations.addDeveloper}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box flex="1" mr={2}>
                  <Box mb={6} display="flex" flexDirection="column">
                    { translations.logo }
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="166px"
                      height="166px"
                      mt={2}
                      border={`1px solid ${borderColor}`}
                      borderRadius="3px"
                      style={{
                        backgroundColor: '#FDFDFD',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${icon})`,
                      }}
                    >
                      <input type="hidden" ref={register} name="logoUri" value={icon || ''} />
                      <label htmlFor="devIcon">
                        <input
                          accept="image/*,.jpg,.jpeg,.png,.svg,.webp"
                          style={{
                            display: 'none',
                          }}
                          id="devIcon"
                          type="file"
                          onChange={handleUploadIcon}
                        />
                        <UploadButton isBig withoutBorder />
                      </label>

                    </Box>
                  </Box>
                  <Box mb={6} display="flex" flexDirection="column">
                    { translations.name }
                    <Box width="260px" mt={2}>
                      <TextField
                        id="title"
                        variant="outlined"
                        name="title"
                        inputRef={register}
                        defaultValue={developer && (developer.title)}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end" gap="20px">
                  <ButtonApp
                    onClick={close}
                    text={translations.cancel}
                    color="primary"
                    variant="outlined"
                    type="button"
                    className={styles.btn}
                  />
                  <ButtonApp
                    text={translations.saveChanges}
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={styles.btn}
                    disabled={!formState.isValid && !developer}
                  />
                </Box>
              </form>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
