import React from 'react';

import { AppSpinner } from 'src/components/AppSpinner';
import { useHouseSpinner } from 'src/components/FilterProperty/components/FilterDesktop/hooks';
import { floorsAdditionalSpinnerId } from 'src/constants';
import { useStyles } from './styles';
import { useGetSelectedApartment } from './useGetSelectedApartment';

export const ComponentHouseSpinner: React.FC = () => {
  const { selectedApartmentId } = useGetSelectedApartment();
  const {
    filteredIds, spinnerData, combineFilterIds, type,
  } = useHouseSpinner();
  const styles = useStyles({});

  const currentListApartment = (): string[] => {
    if (selectedApartmentId?.length) return [selectedApartmentId];
    if (filteredIds?.placement?.length) return filteredIds?.placement;

    return combineFilterIds;
  };

  if (!spinnerData) return null;

  return (
    <div id={floorsAdditionalSpinnerId} className={styles.wrapperFloorsHouseSpinner}>
      <AppSpinner
        type={type}
        hideWindRose
        customDisplayIds={currentListApartment()}
        spinnerData={spinnerData}
        hideControls
      />
    </div>
  );
};
