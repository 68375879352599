import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { requestSelectors } from 'src/store/requests/selectors';
import { FilterApartmentsMobile } from 'src/components/FilterProperty/components/FilterMobile';
import { TypeDisplayedSpinner } from 'src/components/FilterSpinner/components/TypeDisplayedSpinner';
import { MobileFilterCaller } from 'src/components/AppBottomMenu/components/FilterCaller';
import { pathList } from 'src/route-list';
import { FavoritesBottom } from 'src/components/AppBottomMenu/components/FavoritesBottom';
import { useHideFilterZoneState } from 'src/hooks/app';
import { useStyles } from './styles';
import { ISpinnerMenuMobile } from './types';

export const SpinnerMenuMobile: React.FC<ISpinnerMenuMobile> = () => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const location = useLocation();
  const { isHideFilterZone } = useHideFilterZoneState();
  const [pathState, setPathState] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const menuPaths = [pathList.home, pathList.floors, pathList.compare];

  useEffect(() => {
    if (menuPaths.includes(location.pathname)) {
      setPathState(location.pathname);
    }
  }, []);

  useEffect(() => {
    setIsActive((menuPaths.includes(location.pathname) || location.pathname.startsWith(pathState)) && pathState !== pathList.compare);
  }, [pathState]);

  return (
    <>
      <FilterApartmentsMobile />
      <div className={styles.mobileBottomMenuWrapper}>
        {!isHideFilterZone && <MobileFilterCaller />}
        <TypeDisplayedSpinner
          isActive={isActive}
          pathChanged={setPathState}
        />
        <FavoritesBottom isActive={location.pathname === pathList.compare} />
      </div>
    </>
  );
};
