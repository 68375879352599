import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  compareRow: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  compareRowInner: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  },
  wrapperCompareTitle: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '7px',
    position: 'sticky',
    top: '0px',
    left: '-30px',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      left: '-20px',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      padding: '0 0 8px 0',
      '&.title': {
        padding: '0 0 12px 0',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&.title': {
        padding: '0',
      },
    },
    '&.title': {
      alignItems: 'flex-start',
    },
  },
  wrapperCompareType: {
    width: '230px',
    maxWidth: '230px',
    minWidth: '230px',
    '& button': {
      color: 'black',
      padding: '20px 10px',
    },
    '& .createPdf': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      '& button': {
        margin: '0',
        height: '40px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      width: '15vw',
      maxWidth: '15vw',
      minWidth: '15vw',
    },
  },
  wrapperCompareValues: {
    '& > div:nth-child(2n)': {
      backgroundColor: '#F6F7F6',
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent',
      },
    },
  },
  compareValue: {
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '7px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
    },
  },

  desktopShowDiff: {
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
