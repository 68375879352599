import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IProject } from 'src/typings/complexes';
import { IntegrationType } from 'src/typings/app';

interface ICreatioMainDataForm {
  integration: IntegrationType,
  register: any,
  project: IProject | null,
}

export const CreatioMainDataFormFields: React.FC<ICreatioMainDataForm> = ({ integration, register, project }) => {
  return (
    <>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="260px" mr={3}>
          { `Domain of ${integration.toUpperCase()} account` }
        </Box>
        <Box width="260px">
          <TextField
            id="domain"
            size="small"
            variant="outlined"
            name="domain"
            inputRef={register}
            defaultValue={project && (project.domain)}
          />
        </Box>
      </Box>
      <Box mb={4} display="flex" alignItems="center">
        <Box width="260px" mr={3}>
          Server IP
        </Box>
        <Box width="260px">
          <TextField
            id="serverIp"
            size="small"
            variant="outlined"
            name="serverIp"
            inputRef={register}
            defaultValue={project && (project.serverIp)}
          />
        </Box>
      </Box>
    </>
  );
};
