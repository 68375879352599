import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';

import { useTranslations } from 'src/hooks/translataions';
import { useStyles } from './styles';
import { useGenerateCrumbs } from './useGenerateCrumbs';

export const AppBreadcrumbs: React.FC = () => {
  const styles = useStyles({});
  const { crumbs } = useGenerateCrumbs();
  const { getTranslations } = useTranslations();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={styles.wrapperBreadcrumbs}>
      {crumbs.map((crumb, index) => {
        if (index + 1 === crumbs.length) {
          return <span key={crumb.name}>{getTranslations(crumb.name)}</span>;
        }

        return (
          <Link key={crumb.name} to={crumb.path} className={styles.firstStep}>
            {getTranslations(crumb.name)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
