import { Theme, createTheme, adaptV4Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appIcon } from 'src/assets';
import { greyColor, purpleColor, whiteColor } from 'src/constants/styles';

export const profileTheme = (theme: Theme) => createTheme({
  ...theme,
  palette: {
    primary: {
      main: purpleColor,
      contrastText: whiteColor,
    },
    secondary: {
      main: greyColor,
      contrastText: '#201E2A',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          borderColor: '#E9E9E9',
          textTransform: 'unset',
          fontSize: 18,
          lineHeight: '20px',
          fontWeight: 'normal',
          padding: '15px 30px',
        },
        outlinedPrimary: {
          borderColor: purpleColor,
        },
        outlinedSecondary: {
          color: '#201E2A',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '16px 10px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          fontSize: '18px',
          marginLeft: '5px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    // MuiSelect: {
    //   selectMenu: {
    //     height: 'auto',
    //     overflow: 'hidden',
    //     minHeight: '1.1876em',
    //     whiteSpace: 'nowrap',
    //     textOverflow: 'ellipsis',
    //   },
    // },
  },
});

export const useStyles = makeStyles((theme: Theme) => createStyles({
  pageBg: {
    backgroundImage: `url(${appIcon.authBG})`,
    minHeight: '100%',
  },
  pageWrapper: {
    paddingLeft: '217px',
    width: '100%',
    display: 'block !important',
    overflowY: 'scroll',
  },
}));
