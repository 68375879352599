import { useSelector } from 'react-redux';

import { currentSpinnerBacklightSvg } from 'src/store/spinner/selectors';
import { useDisplayApartmentIds } from 'src/hooks/useDisplayApartmentIds';
import { useCallback } from 'react';

export const useGetFilteredIds = () => {
  const filteredIds = useSelector(currentSpinnerBacklightSvg);
  const { displayIds } = useDisplayApartmentIds();

  const getIdsByType = useCallback((type?: string): string[] => {
    // @ts-ignore
    return type && filteredIds[type] ? filteredIds[type] : displayIds;
  }, [displayIds, filteredIds]);

  return {
    filteredIds: displayIds.length ? displayIds : filteredIds,
    getIdsByType,
  };
};
