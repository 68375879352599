import React, { useEffect } from 'react';

import { AirbnbSlider } from 'src/components';
import { useTranslations } from 'src/hooks/translataions';
import { IDropdownGenerator } from 'src/components/dropdowns/types';
import { CustomDropdown } from '../CustomDropdown';
import { useScaleState } from './use-scale-state';

interface IDropdownScale extends IDropdownGenerator {}

export const DropdownScale: React.FC<IDropdownScale> = ({
  dropdownInfo,
}) => {
  const { getFixedTranslations } = useTranslations(dropdownInfo.attribute);
  const {
    formatScalePlaceholder,
    handleSetScalePlaceholder,
    setInitValue,
  } = useScaleState(
    dropdownInfo.attribute,
    getFixedTranslations(),
  );

  useEffect(() => {
    if (dropdownInfo.initValue?.length) {
      setInitValue(dropdownInfo.initValue);
    }
  }, [dropdownInfo.initValue?.length]);

  const ButtonDrop: React.FC = () => (
    <div>
      {formatScalePlaceholder || getFixedTranslations()}
    </div>
  );

  return (
    <CustomDropdown
      button={<ButtonDrop />}
      isFuncChild
    >
      {(onClose: Function) => (
        <div>
          <p className="header-additional-text">{getFixedTranslations()}</p>
          {dropdownInfo.data && (
          <AirbnbSlider
            dropdownInfo={dropdownInfo}
            fieldName={dropdownInfo.attribute}
            range={dropdownInfo.data as unknown as { min: number, max: number }}
            currentValue={formatScalePlaceholder.replace(getFixedTranslations(), '').split(' - ')}
            setPlaceholder={handleSetScalePlaceholder}
            handlerSubmit={onClose}
          />
          )}
        </div>
      )}

    </CustomDropdown>
  );
};
