import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { translations } from 'src/constants/translations';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { ButtonApp } from 'src/components/Button';
import { IProject } from 'src/typings/complexes';
import { updateBitrixProject } from 'src/api';
import { useGetAuthData } from 'src/hooks';
import { useStyles } from '../../Bitrix/styles';

export const DealAccessManagement: React.FC<{project: IProject, fetchProject: Function, }> = ({ project, fetchProject }) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const styles = useStyles();
  const [error, setError] = useState('');
  const [showDealState, setShowDealState] = useState<boolean>(project.frontConfig.dealConfig.isShowDealDataForManagers);
  const handleSave = async () => {
    const saveData: Partial<IProject> = {
      '@id': project['@id'],
      '@type': project['@type'],
      '@context': project['@context'],
      id: project.id,
      frontConfig:
        {
          '@id': project.frontConfig['@id'],
          '@type': project.frontConfig['@type'],
          '@context': project.frontConfig['@context'],
          id: Number(project.frontConfig.id),
          bitrixProject: project.frontConfig.bitrixProject,
          dealConfig:
            {
              '@id': project.frontConfig.dealConfig['@id'],
              '@type': project.frontConfig.dealConfig['@type'],
              '@context': project.frontConfig.dealConfig['@context'],
              id: project.frontConfig.dealConfig.id,
              isShowDealDataForManagers: showDealState,
            },
        },
    };
    if (project) {
      const res = await updateBitrixProject(jwt, String(project.id), saveData);
      if (!res.ok) {
        setError(res.data['hydra:description']);
      }
    }
  };

  return (
    <Box>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        {translations.dealDataAccessManagementHeader}
      </Box>
      <Box fontSize="14px" mb={4}>
        <Box display="flex" gap="12px">
          <Checkbox checked={showDealState} onChange={(event, checked) => setShowDealState(checked)} />
          <ListItemText primary={translations.dealDataAccessManagementForManagers} />
        </Box>
      </Box>
      <Box>
        <ButtonApp
          text={translations.saveChanges}
          color="primary"
          variant="contained"
          type="submit"
          size="large"
          className={styles.btn}
          onClick={() => handleSave()}
        />
      </Box>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};
