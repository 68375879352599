import { useGetAuthData } from 'src/hooks';
import { useState } from 'react';
import { INetHuntFunnel, INetHuntIntegration } from 'src/components/profileComponents/Integrations/Nethunt/types';
import { getNetHuntFunnel, getNetHuntProject } from 'src/api';

export const useGetFunnel = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [funnel, setFunnel] = useState<INetHuntFunnel>();

  const fetchFunnel = (id: string) => {
    if (id) {
      setIsLoading(true);
      getNetHuntFunnel(jwt, id)
        .then((res: any) => {
          const { data } = res;
          setFunnel(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return {
    isLoading,
    fetchFunnel,
    funnel,
  };
};
