/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ButtonBackSpinner } from 'src/components/ButtonBackSpinner';
import { WindRose } from 'src/components/WindRose';
import { AppPopover } from 'src/components/AppPopover';
import {
  useAppVisibilityBtn,
  useDraggableMobileMenu,
  useShowInfrastructure,
  useAppVisibilitySpinnerBtn,
} from 'src/hooks/app';
import { getIsRerender } from 'src/store/view-2d/selectors';
import { ShowInfrastructure } from 'src/components/ViewSpinner/components/Infrastructure';
import { useInfrastructureIds } from 'src/hooks/infrastructure';
import { makeWindRoseData } from 'src/components/WindRose/state';
import { ListOfBacklightSvg } from 'src/typings/spinner';
import { appIcon } from 'src/assets';
import Box from '@mui/material/Box';
import { Actions } from 'src/store/spinner/actions';
import { spinnerMode } from 'src/store/spinner/reducer';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import {
  useBuildType,
  useGetCounters,
  useGetFilteredData,
  useGetFilterParams,
  useIsMobile,
  useTranslations,
} from 'src/hooks';
import {
  useDetectAdditionalParams,
  useFloorSpinnerInteraction,
  useHouseActions,
  usePolygonConfig,
  usePopoverState,
} from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import {
  getSpinnerDetailInfo,
  getSpinnerHoverText,
  getSpinnerInfrastructure,
} from 'src/store/requests/spinner-views/selector';
import { requestSelectors } from 'src/store/requests/selectors';
import {
  useFilledPolygons,
  useGetFilteredIds,
} from 'src/components/ApartmentPageV2/ViewSpinner/components/RenderSvg/hooks';
import { PolygonTriggerEvent } from 'src/typings/app';
import { usePopup } from 'src/components/ViewSpinner/context';
import { isTouch, locals } from 'src/constants';
import { getHighlightPolygons } from 'src/store/spinner/selectors';
import { pathList } from 'src/route-list';
import { useHistory } from 'react-router';
import { getComplexId, getCrmData } from 'src/store/app/selectors';
import { IMouseEvent } from 'src/components/ViewSpinner/components/RenderSvg/types';
import { InfrastructureType, RenderHoverText } from 'src/components/ViewSpinner/components/RenderSvg/components';
import { randomHash } from 'src/helpers';
import { useSelectedDeal } from 'src/hooks/useSelectedDeal';
import { useStyles } from './styles';
import { IViewSpinner } from './types';
import {
  useInitSpinner,
  useGetSpinnerSvg, useSpinnerStyle,
} from './hooks';

export const ViewSpinnerInner: React.FC<IViewSpinner> = React.memo(({
  isFloors,
  setFullSizeFloor,
}) => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const { isOpenDraggableMenu } = useDraggableMobileMenu();
  const {
    displayedSvgData, currentSpinner, currentSpinnerMode, spinnerSvgCurrentDataAlt,
  } = useGetSpinnerSvg();
  const { infrastructureFilteredIds } = useInfrastructureIds();
  const { filteredIds, getIdsByType } = useGetFilteredIds();
  const { mainSpinnerBtnViews } = useAppVisibilitySpinnerBtn();
  const { isInfrastructure, showInfrastructure } = useShowInfrastructure();
  const [initWindRoseData, setInitWindRoseData] = useState<Function | null>(null);
  const spinnerRef = useRef<HTMLDivElement | null>(null);
  const { state } = usePopup();
  const {
    isOpenPopover,
    setOpenPopover,
    setTargetIsEnter,
  } = usePopoverState();
  const { getSpinnerStyles } = useSpinnerStyle();
  const { infrastructure_button } = useAppVisibilityBtn();
  const { isCrossTable } = useBuildType();
  const { globalPolygonConfig } = usePolygonConfig();
  const triggerEvent: PolygonTriggerEvent = useMemo(() => globalPolygonConfig?.trigger_event
    || PolygonTriggerEvent.hover, [globalPolygonConfig]);
  const detailInfo = useSelector(getSpinnerDetailInfo);
  const hoverData = useSelector(getSpinnerHoverText);
  const hoverDataInfrastructure = useSelector(getSpinnerInfrastructure);
  const { resultFiltering } = useGetFilteredData();
  const { selectedParams } = useGetFilterParams();
  const { getTranslations } = useTranslations();
  const getPopupConfig = useSelector(requestSelectors.app.getPopupConfig);
  const { handleIsSelectedFillColor } = useFilledPolygons();
  const highlightPolygonsArray = useSelector(getHighlightPolygons);
  const { handleHouseSelected } = useHouseActions();
  const { isMobileScreen } = useIsMobile();
  const { counters } = useGetCounters();
  const history = useHistory();
  const crmData = useSelector(getCrmData);
  const { isSelectedDeal, isAnyDealSelected } = useSelectedDeal();
  const complexIdState = useSelector(getComplexId);
  const [infrastructureId, setInfrastructureId] = useState<string | undefined>(undefined);
  const [isInfrastructureOpen, setIsInfrastructureOpen] = useState<boolean>(false);
  const hoverConfigRef = useRef<{ [key: string]: {active: boolean, nameId: string, hoverId: string }} | {}>({});
  const [activeEntityNameId, setActiveEntityNameId] = useState<string>('');
  const {
    trackMouse, isEnableHoverPopup, isHoverTextPolygon, setTrackMouse, setIsHoverPolygon,
    handleInfrastructureToggle,
  } = useDetectAdditionalParams(false);
  const {
    handleActionForFloor,
  } = useFloorSpinnerInteraction();
  const highlightPolygonsArrayRef = useRef(highlightPolygonsArray);
  highlightPolygonsArrayRef.current = highlightPolygonsArray;
  const detailInfoRef = useRef(detailInfo);
  detailInfoRef.current = detailInfo;
  const crmDataRef = useRef(crmData);
  crmDataRef.current = crmData;
  const isSelectedDealRef = useRef(isSelectedDeal);
  isSelectedDealRef.current = isSelectedDeal;
  const isAnyDealSelectedRef = useRef(isAnyDealSelected);
  isAnyDealSelectedRef.current = isAnyDealSelected;
  const countersRef = useRef(counters);
  countersRef.current = counters;
  const handleIsSelectedFillColorRef = useRef(handleIsSelectedFillColor);
  handleIsSelectedFillColorRef.current = handleIsSelectedFillColor;
  const filteredIdsRef = useRef(filteredIds);
  filteredIdsRef.current = filteredIds;
  const resultFilteringRef = useRef(resultFiltering);
  resultFilteringRef.current = resultFiltering;
  const crmSelectedDealFillOpacity = '.2';
  const crmSelectedDealStrokeOpacity = '.2';
  const showCount = (counterId: string, placeCounter: string) => {
    if (placeCounter === 'house_id') {
      return countersRef.current.house[counterId]?.count || 0;
    }
    if (placeCounter === 'section_id') {
      return countersRef.current.section[counterId]?.count || 0;
    }
    return 0;
  };

  const {
    listOfStops,
    spinnerState,
    spinner,
  } = useInitSpinner(
    isFloors,
    setFullSizeFloor,
    initWindRoseData,
    spinnerRef,
    displayedSvgData,
    appendChildHandler,
  );

  useEffect(() => {
    setInitWindRoseData(makeWindRoseData);
    return () => {
      setInitWindRoseData(null);
    };
  }, []);

  const handleSetPlacementId = useCallback((event: any, name: string, svg) => {
    if (name === ListOfBacklightSvg.placementId) {
      setTargetIsEnter(true);
      setOpenPopover(true);
      if (state.debounceOpen) {
        state.debounceOpen({
          svg,
          target: event.currentTarget,
        });
      }
    }
  }, [state, setOpenPopover, setTargetIsEnter]);

  const handleSvgClick = useCallback((event: any, redirect: string, name: string, nameId: string, viewType: string, viewId: string, svg: { tag: string, attr: any }): boolean => {
    if (redirect) {
      window.open(redirect, '_blank');
      return false;
    }
    if (isFloors) {
      handleActionForFloor(nameId);
      if (isMobileScreen && triggerEvent === PolygonTriggerEvent.hover) {
        handleSetPlacementId(event, name, svg);
      }
      if (!isMobileScreen && triggerEvent === PolygonTriggerEvent.click) {
        handleSetPlacementId(event, name, svg);
      }
      return false;
    }

    handleInfrastructureToggle();
    if (!name) return false;

    if (triggerEvent === PolygonTriggerEvent.click || isTouch) {
      if (name === ListOfBacklightSvg.houseId) {
        handleHouseSelected(name, nameId, viewType, viewId);
      } else {
        handleSetPlacementId(event, name, svg);
      }
    }
    if (triggerEvent === PolygonTriggerEvent.hover) {
      if (name === ListOfBacklightSvg.houseId) {
        handleHouseSelected(name, nameId, viewType, viewId);
      }
    }
    return true;
  }, [isFloors, isTouch, isMobileScreen, handleInfrastructureToggle, handleSetPlacementId, handleHouseSelected]);

  const handleSvgClickRef = useRef<Function>(handleSvgClick);
  handleSvgClickRef.current = handleSvgClick;

  const getStaticBodyColor = useCallback((nameId: string) => {
    return ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
      && detailInfoRef.current?.placement?.[nameId]) ? detailInfoRef.current?.placement[nameId][isCrossTable ? (
        ListOfKeysFilterAllData.placementStatusCrossTableColor
      ) : ListOfKeysFilterAllData.placementStatusColor] : `${globalPolygonConfig?.static_body_color}`;
  }, [getPopupConfig, globalPolygonConfig, detailInfo, isCrossTable]);

  const getHoverBodyColor = useCallback((nameId: string) => {
    return ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
      && detailInfoRef.current
      && detailInfoRef.current?.placement
      && detailInfoRef.current?.placement[nameId]
    ) ? `${
        detailInfoRef.current.placement[nameId][isCrossTable ? (
          ListOfKeysFilterAllData.placementStatusCrossTableColor || ListOfKeysFilterAllData.placementStatusColor
        ) : ListOfKeysFilterAllData.placementStatusColor]
      }` : `${globalPolygonConfig?.hover_body_color}`;
  }, [getPopupConfig, globalPolygonConfig, detailInfo, isCrossTable]);

  const isFilterActive = (name: string): boolean => {
    // @ts-ignore
    return Array.isArray(filteredIdsRef.current) ? !!filteredIdsRef.current.length : !!filteredIdsRef.current[name]?.length;
  };

  const setAttributes = (el: Element, attrs: object) => {
    Object.keys(attrs).forEach((key: string) => {
      // @ts-ignore
      el.setAttribute(key, attrs[key]);
    });
  };

  function appendChildHandler({ child, target }: {target: any, child: any}) {
    const { domElement, type } = child;
    domElement.id = randomHash();

    const {
      'hover-text': hoverText,
      infrastructure,
      'infrastructure-view-type': infrastructureViewType,
      'entity-view-id': entityViewId,
      'entity-view': entityView,
      'entity-view-name': entityViewName,
      'entity-view-type': entityViewType,
      'entity-view-name-id': entityViewNameId,
      'entity-view-stop': entityViewStop,
      'data-is-terrace': dataIsTerrace,
      'counter-id': counterId,
      'place-counter': placeCounter,
      redirect,
    } = child.userData;

    const clickHandler = (event: Event) => {
      const svg = { tag: 'polygon', attr: child.userData };
      const isRedirect = handleSvgClickRef.current(event, redirect, entityViewName, entityViewNameId, entityViewType, entityViewId, svg);
      if (triggerEvent === PolygonTriggerEvent.click) {
        handleSetPlacementId(event, entityViewName, svg);
        return;
      }
      if (isRedirect && entityViewName === ListOfBacklightSvg.placementId && !isMobileScreen) {
        history.push(`${isCrossTable ? `/${complexIdState}` : ''}${pathList.own}/${entityViewNameId}`);
      }
    };

    if (type === 'point') {
      const image = document.createElement('img');
      image.ondragstart = (ev) => ev.preventDefault();
      domElement.append(image);
      domElement.addEventListener('click', clickHandler);
      if (entityViewName) {
        domElement.addEventListener('mouseenter', () => { domElement.children[0].style.cursor = 'pointer'; });
        domElement.addEventListener('mouseleave', () => { domElement.children[0].style.cursor = 'default'; });
      }

      const { src: imageSrc, size: [imageWidth, imageHeight] = [] } = child.userData.image || {};

      if (counterId && placeCounter) {
        const countSize = { width: 200, height: 35 };
        if (showCount(counterId, placeCounter)) {
          const element = document.createElement('div');
          element.classList.add('counter');
          element.style.width = `${countSize.width}px`;
          element.style.height = `${countSize.height}px`;
          element.style.fill = 'white';
          element.style.top = '-66px';
          element.style.left = '-100px';
          const elementInner = document.createElement('div');
          elementInner.classList.add('counter-inner');
          // eslint-disable-next-line no-param-reassign
          child.updateCounter = () => {
            const showCountLocal = showCount(counterId, placeCounter);
            element.style.display = !showCountLocal ? 'none' : '';
            elementInner.innerHTML = `${showCountLocal} ${getTranslations(locals.appTranslations.appUnits)}`;
          };
          child.updateCounter();
          element.appendChild(elementInner);
          domElement.parentElement.appendChild(element);
        }
      }

      if (imageSrc) {
        image.src = imageSrc;
        image.style.width = imageWidth ? `${imageWidth}px` : '';
        image.style.height = imageHeight ? `${imageHeight}px` : '';
        domElement.classList.add('spinner-image');
      }
    }
    if (type === 'area') {
      if (entityViewName && entityViewNameId) {
        setAttributes(domElement, { 'entity-view-name': entityViewName, 'entity-view-name-id': entityViewNameId });
      }
      domElement.addEventListener('click', clickHandler);

      domElement.addEventListener('mouseenter', (event: Event) => {
        if (!entityViewNameId && !entityViewName) {
          setAttributes(domElement, { cursor: 'default' });
          return;
        }
        setAttributes(domElement, { cursor: 'pointer' });
        const mouseOverStyle = {
          stroke: globalPolygonConfig?.hover_border_color,
          'stroke-opacity': '1',
          'stroke-width': globalPolygonConfig?.global_border_width,
          fill: getHoverBodyColor(entityViewNameId),
          'fill-opacity': globalPolygonConfig?.hover_body_opacity,
        };

        const listOfPolygons: any[] = domElement.parentElement.querySelectorAll(`[entity-view-name="${entityViewName}"][entity-view-name-id="${entityViewNameId}"]`);
        if (listOfPolygons) {
          listOfPolygons.forEach((itemPolygon: any) => {
            if (itemPolygon && domElement.id !== itemPolygon.id) {
              setAttributes(itemPolygon, mouseOverStyle);
            }
          });
        }

        setAttributes(domElement, mouseOverStyle);
        if (triggerEvent === PolygonTriggerEvent.hover) {
          handleSetPlacementId(event, entityViewName, { tag: 'polygon', attr: child.userData });
        }
      });

      const crossMouseleave = (isDeal: boolean) => {
        const isActive = isFilterActive(entityViewName) || !resultFilteringRef.current.length;
        return {
          stroke: crmDataRef.current
            ? (isActive && !isDeal && !handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)) ? 'transparent' : globalPolygonConfig?.static_border_color
            : !isActive || handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId) ? globalPolygonConfig?.static_border_color : 'transparent',
          'stroke-opacity': (crmDataRef.current && isAnyDealSelectedRef.current && !isDeal && !isActive) ? '0' : '1',
          fill: crmDataRef.current
            ? (isActive && !isDeal && !handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)) ? 'transparent' : getStaticBodyColor(entityViewNameId)
            : !isActive || handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId) ? getStaticBodyColor(entityViewNameId) : 'transparent',
          'fill-opacity': crmDataRef.current
            ? (isAnyDealSelectedRef.current && !isDeal && !handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)) ? crmSelectedDealFillOpacity : globalPolygonConfig?.static_body_opacity
            : globalPolygonConfig?.static_body_opacity,
        };
      };

      const moduleMouseleave = (isDeal: boolean) => {
        if (highlightPolygonsArrayRef.current.includes(entityViewNameId)) {
          return {};
        }
        return {
          stroke: handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)
            ? globalPolygonConfig?.static_border_color
            : ((isCrossTable && !isFilterActive(entityViewName)) ? globalPolygonConfig?.static_border_color : 'transparent'),
          'stroke-opacity': (crmDataRef.current && isAnyDealSelectedRef.current && !isDeal && !isFilterActive(entityViewName)) ? '0' : '1',
          fill: handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)
            ? getStaticBodyColor(entityViewNameId)
            : ((isCrossTable && !isFilterActive(entityViewName)) ? getStaticBodyColor(entityViewNameId) : 'transparent'),
          'fill-opacity': handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)
            ? globalPolygonConfig?.static_body_opacity
            : isCrossTable ? globalPolygonConfig?.static_body_opacity : '0',
        };
      };

      domElement.addEventListener('mouseleave', () => {
        if (!entityViewNameId && !entityViewName) return;
        const isDeal = isSelectedDealRef.current(detailInfoRef.current?.placement[entityViewNameId]);
        const mouseLeaveStyle = isCrossTable ? crossMouseleave(isDeal) : moduleMouseleave(isDeal);
        const listOfPolygons: any[] = domElement.parentElement.querySelectorAll(`[entity-view-name="${entityViewName}"][entity-view-name-id="${entityViewNameId}"]`);
        if (listOfPolygons) {
          listOfPolygons.forEach((itemPolygon: any) => {
            if (itemPolygon && domElement.id !== itemPolygon.id) {
              setAttributes(itemPolygon, mouseLeaveStyle);
            }
          });
        }

        setAttributes(domElement, mouseLeaveStyle);

        setTargetIsEnter(false);
        if (state.debounceClose) {
          state.debounceClose();
        }
      });
    }

    if (infrastructure && infrastructureViewType) {
      domElement.addEventListener('click', () => {
        setInfrastructureId(infrastructure);
        setIsInfrastructureOpen(true);
      });
      domElement.addEventListener('mousemove', (event: IMouseEvent) => {
        setTrackMouse({ x: event.clientX, y: event.clientY });
      });

      domElement.addEventListener('mouseenter', (event: any) => {
        setActiveEntityNameId(infrastructure);
        setIsHoverPolygon(true);
      });
      domElement.addEventListener('mouseleave', (event: any) => {
        setActiveEntityNameId(infrastructure);
        setIsHoverPolygon(false);
      });

      hoverConfigRef.current = { ...hoverConfigRef.current, [infrastructure]: { active: true, nameId: infrastructure, hoverId: infrastructure } };
    }

    if (hoverText) {
      domElement.addEventListener('mousemove', (event: IMouseEvent) => {
        setTrackMouse({ x: event.clientX, y: event.clientY });
      });
      domElement.addEventListener('mouseenter', (event: any) => {
        setActiveEntityNameId(hoverText);
        setIsHoverPolygon(true);
      });
      domElement.addEventListener('mouseleave', (event: any) => {
        setActiveEntityNameId(hoverText);
        setIsHoverPolygon(false);
      });
      hoverConfigRef.current = { ...hoverConfigRef.current, [hoverText]: { active: true, nameId: hoverText, hoverId: hoverText } };
    }

    child.emit('update-user-data');
  }

  const crossHighlighted = useCallback((entityViewName: string, entityViewNameId: string, isDeal: boolean) => {
    const isActive = isFilterActive(entityViewName);
    return {
      stroke: isActive
        ? globalPolygonConfig?.static_border_color
        : 'transparent',
      'stroke-opacity': crmData && !isDeal && !isActive ? '0' : '1',
      fill: isActive
        ? getStaticBodyColor(entityViewNameId)
        : 'transparent',
      'fill-opacity': isActive
        ? globalPolygonConfig?.static_body_opacity
        : 'transparent',
    };
  }, [detailInfo, globalPolygonConfig, crmData, isAnyDealSelected, isSelectedDeal, resultFiltering, filteredIds]);

  const moduleHighlighted = useCallback((entityViewName: string, entityViewNameId: string, isDeal: boolean) => {
    const isActive = isFilterActive(entityViewName);
    return {
      stroke: handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)
        ? globalPolygonConfig?.static_border_color
        : (((!isCrossTable && !isActive && globalPolygonConfig?.is_pre_highlight_polygons_in_module) || isCrossTable) ? globalPolygonConfig?.static_border_color : 'transparent'),
      'stroke-opacity': crmData && !isDeal && !isActive ? '0' : '1',
      fill: handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)
        ? getStaticBodyColor(entityViewNameId)
        : (((!isCrossTable && !isActive && globalPolygonConfig?.is_pre_highlight_polygons_in_module) || isCrossTable) ? getStaticBodyColor(entityViewNameId) : 'transparent'),
      'fill-opacity': handleIsSelectedFillColorRef.current(entityViewName, entityViewNameId)
        ? globalPolygonConfig?.static_body_opacity
        : (((!isCrossTable && !isActive && globalPolygonConfig?.is_pre_highlight_polygons_in_module) || isCrossTable) ? crmSelectedDealFillOpacity : globalPolygonConfig?.hover_body_opacity),
    };
  }, [detailInfo, globalPolygonConfig, crmData, isAnyDealSelected, isSelectedDeal, resultFiltering, filteredIds]);

  const crossNotHighlighted = useCallback((entityViewName: string, entityViewNameId: string, isDeal: boolean) => {
    const isActive = isFilterActive(entityViewName) || !resultFiltering.length;
    if (isDeal && !highlightPolygonsArray.length) {
      dispatch(Actions.setHighlightPolygons([entityViewNameId]));
    }
    return {
      stroke: crmData && isAnyDealSelected
        ? isDeal ? globalPolygonConfig?.static_border_color : 'transparent'
        : !isActive ? globalPolygonConfig?.static_border_color : 'transparent',
      'stroke-opacity': crmData && isAnyDealSelected
        ? !isDeal ? crmSelectedDealStrokeOpacity : '1'
        : !isActive ? '1' : '0',
      fill: crmData && isAnyDealSelected
        ? getStaticBodyColor(entityViewNameId)
        : !isActive ? getStaticBodyColor(entityViewNameId) : 'unset',
      'fill-opacity': crmData && isAnyDealSelected
        ? !isDeal ? crmSelectedDealFillOpacity : globalPolygonConfig?.static_body_opacity
        : !isActive ? globalPolygonConfig?.static_body_opacity : globalPolygonConfig?.hover_body_opacity,
    };
  }, [detailInfo, globalPolygonConfig, crmData, isAnyDealSelected, isSelectedDeal, resultFiltering, filteredIds]);

  const moduleNotHighlighted = useCallback(() => {
    return {
      stroke: 'transparent',
      'stroke-opacity': '0',
      fill: 'transparent',
      'fill-opacity': '0',
    };
  }, []);

  useEffect(() => {
    spinner?.children.forEach((item: any) => {
      const entityViewName = item.userData['entity-view-name'];
      const entityViewNameId = item.userData['entity-view-name-id'];
      const isDeal = isSelectedDeal(detailInfo?.placement[entityViewNameId]);
      if (isDeal) {
        const bestStop = detailInfo?.placement[entityViewNameId]?.[ListOfKeysFilterAllData.placementBestSpinnerStops]?.find(
          (stop) => stop['entity-view-name'] === currentSpinner?.name,
        );
        if (bestStop) {
          spinner.controls.setRotateTo([bestStop.stopNumber, 0], true);
        }
      }
      if (entityViewNameId && entityViewName) {
        if (handleIsSelectedFillColor(entityViewName, entityViewNameId)) {
          const attributeStyle = isCrossTable
            ? crossHighlighted(entityViewName, entityViewNameId, isDeal)
            : moduleHighlighted(entityViewName, entityViewNameId, isDeal);
          setAttributes(item.domElement, attributeStyle);
        } else {
          const attributeStyle = isCrossTable
            ? crossNotHighlighted(entityViewName, entityViewNameId, isDeal)
            : moduleNotHighlighted();
          setAttributes(item.domElement, attributeStyle);
        }
      }
      if (item.updateCounter) item.updateCounter();

      item.domElement.classList.remove('highlightPolygon');
      if (highlightPolygonsArray.includes(entityViewNameId)) {
        const highlightPolygonStyle = {
          stroke: globalPolygonConfig?.static_border_color,
          'stroke-opacity': '1',
          fill: getStaticBodyColor(entityViewNameId),
          'fill-opacity': globalPolygonConfig?.static_body_opacity,
        };
        setAttributes(item.domElement, highlightPolygonStyle);
        item.domElement.classList.add('highlightPolygon');
      }
    });
  }, [spinner?.children, selectedParams, resultFiltering, filteredIds, highlightPolygonsArray, crmData, detailInfo, isAnyDealSelected, isSelectedDeal]);

  const isSpinnerModeChangeBtnVisible = useMemo(() => {
    if (spinnerSvgCurrentDataAlt && currentSpinner) {
      return !!Object.keys(spinnerSvgCurrentDataAlt[currentSpinner.name]).length;
    }
    return false;
  }, [spinnerSvgCurrentDataAlt, currentSpinner]);

  const changeSpinnerMode = (mode: spinnerMode) => {
    dispatch(Actions.setSpinnerMode(mode));
  };

  return (
    <>
      <div
        id="spinner_place"
        className={
          `${isFloors ? styles.floorsView : ''} ${
            isOpenDraggableMenu ? '' : styles.blurStyle}`
        }
      >
        {!isFloors && <ButtonBackSpinner className={styles.backButton} />}
        <div>
          <div id="spinner_canvas" ref={spinnerRef} className={`${styles.svgPlace} ${styles.styleCanvas}`} />
        </div>
        <div id="spinner_control" className={`${mainSpinnerBtnViews}`}>
          {
            listOfStops.length > 1 && (
              <>
                <div id="turn_left"><i className="material-icons">arrow_back</i></div>
                {isSpinnerModeChangeBtnVisible && (
                  <>
                    {currentSpinnerMode === spinnerMode.night && (
                      <Box id="day_night" className="day" onClick={() => changeSpinnerMode(spinnerMode.day)}><img src={appIcon.day.default} alt="night" /></Box>
                    )}
                    {currentSpinnerMode === spinnerMode.day && (
                      <Box id="day_night" className="night" onClick={() => changeSpinnerMode(spinnerMode.night)}><img src={appIcon.night.default} alt="night" /></Box>
                    )}
                  </>
                )}
                <div id="turn_right"><i className="material-icons">arrow_forward</i></div>
              </>
            )
          }
          <div id="spinner_zoom"><i className="material-icons">zoom_in</i></div>
          <div id="spinner_unzoom"><i className="material-icons">zoom_out</i></div>
        </div>

        {initWindRoseData && <WindRose initWindRoseData={initWindRoseData} />}
        <div id="porgress_bar" />
        {infrastructure_button && !isFloors ? <ShowInfrastructure /> : null}
        <AppPopover />
        {infrastructureId && (
          <InfrastructureType
            isOpen={isInfrastructureOpen}
            infrastructureId={infrastructureId}
            handleToggle={() => setIsInfrastructureOpen(false)}
          />
        )}
        {hoverData && Object.keys(hoverConfigRef.current || {}).map((key) => {
          // @ts-ignore
          const dataObject = hoverConfigRef.current[key];
          return (isHoverTextPolygon && activeEntityNameId === key && (
            <Box key={key}>
              <RenderHoverText
                isEnableHoverPopup={dataObject.active}
                nameId={dataObject.nameId}
                hoverId={dataObject.hoverId}
                trackMouse={trackMouse}
                handleHover={setIsHoverPolygon}
              />
            </Box>
          ));
        })}
      </div>
    </>
  );
});

ViewSpinnerInner.displayName = 'ViewSpinnerInner';
