import { useSelector } from 'react-redux';
import country from 'country-list-js';
import { useCallback, useState } from 'react';
import { requestSelectors } from 'src/store/requests/selectors';
import { getLanguagesList, getComplexId, getCurrentLang } from 'src/store/app/selectors';
import { IAppMenuRequest } from 'src/typings/app';
import { useIsDevMode } from 'src/hooks';
import ISO6391 from 'iso-639-1';

export const useLanguageState = () => {
  const complexId = useSelector(getComplexId);
  const currentAppLanguage = useSelector(getCurrentLang);
  const listOfAvailableLanguage = useSelector(getLanguagesList);
  const listOfLinks: (IAppMenuRequest) [] = useSelector(requestSelectors.appMenu.getAppClientMenuData);
  const defaultLang: any = useSelector(requestSelectors.app.getLang);
  const [renderLanguageList] = useState(listOfAvailableLanguage.filter((lng) => lng !== currentAppLanguage));
  const { isDevMode } = useIsDevMode();

  const handleChangeAppLanguage = useCallback((updateLanguage: string) => {
    if (updateLanguage) {
      // тут происходит сложная и не понятная обработка локалей
      // если кратко, по умолчанию у нас есть 2 вида локале: пустота (дефолтный язык) и любая локаль в формате ISO 639-1
      // но некоторые заказчики используют ua вместо uk для обознаения украинского
      // у нас нигде об этом инфы нет, единственное место откуда я смог взять инфу о UA это ссылки бокового меню
      const originSplit = window.location.href.split(window.location.origin);
      const linkParts = originSplit[1].split('/');
      const iso2Code = linkParts[1] === 'uk' ? 'ua' : linkParts[1];
      const checkedLocale = ISO6391.getName(iso2Code);
      const countryName = country.findByIso2(iso2Code.toUpperCase());

      if (checkedLocale || countryName) {
        linkParts.splice(1, 1);
        if (updateLanguage !== defaultLang) {
          window.location.href = `${window.location.origin}/${updateLanguage}${linkParts.join('/')}`;
        } else if (isDevMode) {
          window.location.href = `${window.location.origin}${linkParts.join('/')}`;
        } else if (listOfLinks.length && listOfLinks[0]?.rows[0]?.link_url) {
          const firstSideLink = listOfLinks[0].rows[0].link_url;
          const splitSideLink = firstSideLink.split(window.location.origin);
          const sideLinkParts = splitSideLink[1]?.split('/');
          if (sideLinkParts?.[1] === 'ua') {
            window.location.href = `${window.location.origin}/ua${linkParts.join('/')}`;
          } else {
            window.location.href = `${window.location.origin}${linkParts.join('/')}`;
          }
        } else {
          window.location.href = `${window.location.origin}${linkParts.join('/')}`;
        }
      } else {
        window.location.href = `${window.location.origin}/${updateLanguage}${linkParts.join('/')}`;
      }
    }
  }, [complexId]);

  return {
    currentAppLanguage,
    renderLanguageList,
    handleChangeAppLanguage,
  };
};
