import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { ButtonApp } from 'src/components/Button';
import { ControlledAutocomplete } from 'src/components/ControledAutocomplete';
import { borderColor } from 'src/constants/styles';
import { translations } from 'src/constants/translations';
import { useGetAuthData } from 'src/hooks';
import { IntegrationType } from 'src/typings/app';
import { toUpperCase } from 'src/helpers/toUpperCase';
import CircularProgress from '@mui/material/CircularProgress';
import {
  INetHuntFolder, INetHuntFunnel,
  INetHuntPlacementPropertiesFields,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import { ControlledTextField } from 'src/components/ControledTextField';
import { patchNetHuntOpportunityPlacementMapping, postNetHuntOpportunityPlacementMapping } from 'src/api';
import { useStyles } from 'src/components/profileComponents/Integrations/Nethunt/components/Categories/styles';

interface IBitrixFields {
  integration: IntegrationType;
  fields: INetHuntPlacementPropertiesFields[];
  entityValues: { [key: string]: any } | null;
  saveData?: (token: string, id: string, data: any) => Promise<any>;
  funnel: INetHuntFunnel,
  fetchFunnel: (funnelId: string) => void
}

const schema = yup.object().shape({});

const fieldType = ['string', 'numeric', 'array'];

export const Fields: React.FC<IBitrixFields> = ({
  integration, fields, entityValues, saveData, funnel, fetchFunnel,
}) => {
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const ref = useRef<any>({});
  const {
    handleSubmit, control, register, setValue, watch, formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (fields) {
      fields.map((item, index) => setValue(`fields.${index}.placementPropertySystemName`, item.value));
    }
  }, [fields]);

  useEffect(() => {
    if (funnel) {
      funnel.netHuntOpportunityPlacementMappings
        .forEach((item, index) => {
          if (item.placementPropertySystemName) {
            fields.forEach((field, fieldIndex) => {
              if (item.placementPropertySystemName === field.value) {
                setValue(`fields.${fieldIndex}.id`, item.id);
                setValue(`fields.${fieldIndex}.placementPropertySystemName`, item.placementPropertySystemName);
                setValue(`fields.${fieldIndex}.externalPropertyEmptyValue`, item.externalPropertyEmptyValue);
                setValue(`fields.${fieldIndex}.externalPropertyName`, { name: item.externalPropertyName });
                setValue(`fields.${fieldIndex}.externalPropertyType`, item.externalPropertyType);
              }
            });
          }
        });
    }
  }, [funnel]);

  const handleChange = (index: number) => {
    ref.current[index] = true;
  };

  const onSubmit = async (data:any) => {
    if (data) {
      const promisesArray: Promise<any>[] = [];
      fields.map((field, index) => {
        const fieldData = data.fields[index];
        fieldData.netHuntFunnel = funnel['@id'];
        if (fieldData.externalPropertyName && fieldData.externalPropertyType
          && Object.keys(ref.current).includes(String(index))) {
          if (fieldData.id) {
            promisesArray.push(patchNetHuntOpportunityPlacementMapping(jwt, fieldData.id, { ...fieldData, externalPropertyName: fieldData.externalPropertyName.name }));
          } else {
            promisesArray.push(postNetHuntOpportunityPlacementMapping(jwt, { ...fieldData, externalPropertyName: fieldData.externalPropertyName.name }));
          }
        }
        return index;
      });
      const resArray: {ok: boolean}[] = await Promise.all(promisesArray);
      ref.current = {};
      if (resArray.length && resArray.every((res) => res.ok) && funnel.id) {
        fetchFunnel(funnel.id);
      }
    }
  };

  return (
    <Box py={5}>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        {`${toUpperCase(integration)} deal fields mapping`}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!fields && !entityValues && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress style={{ color: 'black' }} size="24px" />
        </Box>
        )}
        {fields && entityValues && (
        <>
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Grid container spacing={2} display="flex" flexDirection="column">
              {fields.map((item, index) => (
                <Grid key={item.value} item xs={12} display="flex" flexDirection="row" gap="8px">
                  <Box width="100%">
                    <TextField hidden {...register(`fields.${index}.id`)} />
                    <TextField hidden {...register(`fields.${index}.placementPropertySystemName`)} />
                    <ControlledAutocomplete
                      control={control}
                      name={`fields.${index}.externalPropertyName`}
                      options={entityValues}
                      getOptionLabel={(option: INetHuntFolder) => {
                        return option && `${option.name}`;
                      }}
                      onChange={(e: any) => handleChange(index)}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={item.label}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                  <Box width="100%">
                    <ControlledAutocomplete
                      control={control}
                      name={`fields.${index}.externalPropertyType`}
                      options={fieldType}
                      getOptionLabel={(option: string) => {
                        return option && `${option}`;
                      }}
                      onChange={(e: any) => handleChange(index)}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Field Type"
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                  <Box width="100%">
                    <ControlledTextField
                      control={control}
                      name={`fields.${index}.externalPropertyEmptyValue`}
                      onChange={(e: any) => handleChange(index)}
                      defaultValue=""
                    />
                  </Box>
                </Grid>
              )) }
            </Grid>
          </Box>
          <Box>
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              className={styles.btn}
            />
          </Box>
        </>
        )}
      </form>
    </Box>
  );
};
