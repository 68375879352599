import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  zoomWrapper: {
    boxShadow: '0px 0px 3px 0px #d2d2d2',
    borderRadius: '10px',
    padding: '10px',
    gap: '5px',
    width: '170px',
    height: '44px',
    '& .MuiGrid-item': {
      padding: '0',
      '& .MuiSlider-root': {
        padding: '10px 0',
      },
    },
  },
  zoom: {
    padding: '0',
  },
}));
