import React, { RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetCurrentMarkup, useGetCurrentSection } from 'src/hooks/floors';
import { getFilteredSections } from 'src/store/floors/selectors';
import { usePolygonConfig } from 'src/components/ApartmentPageV2/ViewSpinner/components/RenderSvg/hooks';
import { useIsMobile } from 'src/hooks';
import Box from '@mui/material/Box';
import { IFloorMarkup } from './types';
import { useSectionImage } from './hooks';
import { useStyles } from './styles';
import { MobileMarkup } from './ComponentMobile';

export const FloorMarkup: React.FC<IFloorMarkup> = ({
  houseData,
  displaySectionId,
  textTitle,
  isPlacementPage,
  wrapperClassName,
  onClick,
}) => {
  const { globalPolygonConfig } = usePolygonConfig();
  const styles = useStyles(globalPolygonConfig);
  const sectionRef: RefObject<HTMLImageElement> = useRef(null);
  const filteredSections = useSelector(getFilteredSections);
  const { isMobileScreen } = useIsMobile();
  const isLarge = useMediaQuery('(min-width:960px)');
  const {
    aspectHeight,
    aspectWidth,
    naturalHeight,
    naturalWidth,
    handleLoadImage,
  } = useSectionImage(sectionRef, isMobileScreen);
  const {
    renderMarkup,
    handleSelectSection,
  } = useGetCurrentMarkup(houseData, isLarge, true);
  const { currentSection } = useGetCurrentSection();
  const onMarkupClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  if (!renderMarkup) return null;

  return (
    <Box className={`${wrapperClassName} wrapperMarkUp ${styles.wrapperMarkUp}`} onClick={(event) => onMarkupClick(event)}>
      <div className={`markUpDesktop ${styles.markUpDesktop}`}>
        {textTitle && (<div><p className={styles.titleSection}>{textTitle}</p></div>)}
        <div className={styles.wrapperSections}>
          <img
            className="image"
            alt="sections"
            src={renderMarkup.img_url}
            style={{
              aspectRatio: '1/1',
              objectFit: 'contain',
            }}
            ref={sectionRef}
            onLoad={handleLoadImage}
          />
          {
            sectionRef.current && (
              <svg
                viewBox={`0 0 ${naturalWidth} ${naturalHeight}`}
                style={{
                  aspectRatio: '1/1',
                  objectFit: 'contain',
                }}
              >
                {renderMarkup?.svg?.length
                  && renderMarkup.svg.map((svg) => {
                    const isCurrent = displaySectionId
                      ? displaySectionId === svg.attr['markup-id']
                      : currentSection['markup-id'] === svg.attr['markup-id'];
                    return React.createElement(
                      svg.tag,
                      {
                        ...svg.attr,
                        key: svg.attr['markup-id'],
                        className: `${isCurrent ? 'active' : ''} ${
                          (filteredSections.includes(svg.attr['markup-id']) && !isPlacementPage)
                            ? styles.activeFragment : styles.fragment
                        } ${isPlacementPage || styles.blink}`,
                        onClick: () => {
                          if (isCurrent) return;

                          handleSelectSection(
                            svg.attr.markup,
                            svg.attr['markup-id'],
                            true,
                          );
                        },
                      },
                    );
                  })}
              </svg>
            )
          }
        </div>
      </div>
      {
        currentSection['markup-id'] && (
          <MobileMarkup
            currentSection={currentSection}
            renderMarkup={renderMarkup}
            handleSelectSection={handleSelectSection}
          />
        )
      }
    </Box>
  );
};
