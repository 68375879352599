import { debounce } from 'lodash';
import {
  MutableRefObject,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { requestSelectors } from 'src/store/requests/selectors';
import { getCurrentSpinner, getSpinnerMode, getStopFrames } from 'src/store/spinner/selectors';
import { ISpinner, ISpinnerSvgData } from 'src/typings/spinner';
import { Actions } from 'src/store/spinner/actions';
import { resizeEmulate } from 'src/helpers';
import { spinnerMode } from 'src/store/spinner/reducer';
// @ts-ignore
import { fsUtils } from 'viewer';
import { getSpinnerDetailInfo } from 'src/store/requests/spinner-views/selector';
import { usePolygonConfig } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { usePopup } from 'src/components/ViewSpinner/context';
import { IRotateEvent, ISpinnerState } from '../types';

export const useInitSpinner = (
  isFloors: boolean,
  setFullSizeFloor: (val: boolean) => void,
  initWindRoseData: Function | null,
  spinnerRef: MutableRefObject<HTMLDivElement | null>,
  displayedSvgData: ISpinnerSvgData | undefined,
  appendChildHandler: ({ child }: { target: any, child: any; }) => void,
) => {
  const { devicePixelRatio } = window;
  const theme: Theme = useTheme();
  const { dispatch: dispatchPopup } = usePopup();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState<any>();
  const { globalPolygonConfig } = usePolygonConfig();
  const detailInfo = useSelector(getSpinnerDetailInfo);
  const [spinnerLoadEnd, setSpinnerLoadEnd] = useState<boolean>(false);
  const [spinnerState, setSpinnerState] = useState<ISpinnerState>({
    stopFrame: '0',
    dw: 0,
    dh: 0,
    dx: 0,
    dy: 0,
  });
  const [svgSize, setSvgSize] = useState<{w: number, h: number}>({ w: 0, h: 0 });
  const spinnerDataOriginal = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const spinnerDataAlt = useSelector(requestSelectors.spinner.getSpinnerViewDataAlt);
  const currentSpinnerMode = useSelector(getSpinnerMode);
  const stopFrames = useSelector(getStopFrames);
  const currentSpinner = useSelector(getCurrentSpinner);

  const spinnerCanvas: HTMLCanvasElement | null = document.querySelector('#spinner_canvas');
  const spinnerPlace: HTMLElement | null = document.querySelector('#spinner_place');
  const spinnerControl: HTMLElement | null = document.querySelector('#spinner_control');
  const progressBar: HTMLElement | null = document.querySelector('#porgress_bar');
  const turnLeft: HTMLElement | null = document.querySelector('#turn_left');
  const turnRight: HTMLElement | null = document.querySelector('#turn_right');
  const spinnerZoom: HTMLElement | null = document.querySelector('#spinner_zoom');
  const spinnerUnZoom: HTMLElement | null = document.querySelector('#spinner_unzoom');
  const windRose: HTMLElement | null = document.querySelector('#wind_rose');

  const spinnerData = useMemo(() => {
    if (spinnerDataOriginal && currentSpinnerMode && currentSpinner) {
      return (currentSpinnerMode === spinnerMode.day || currentSpinner.name !== 'house') ? spinnerDataOriginal : (spinnerDataAlt || spinnerDataOriginal);
    }
    return spinnerDataOriginal;
  }, [spinnerDataOriginal, currentSpinnerMode, spinnerDataAlt, currentSpinner]);

  const handleSetStopFrame = useCallback((isHide?: boolean) => {
    if (isHide) {
      setSpinnerState({
        ...spinnerState,
        stopFrame: '-1',
      });
    }
  }, [spinnerState]);

  const handleResizeForSvg = useCallback(() => {
    if (
      spinnerState.dh !== spinner?.viewBox?.dh
      || spinnerState.dw !== spinner?.viewBox?.dw
      || spinnerState.dx !== spinner?.viewBox?.dx
      || spinnerState.dy !== spinner?.viewBox?.dy
      || spinnerState.stopFrame !== String(spinner.controls.frame.x)
    ) {
      setSpinnerState({
        stopFrame: spinner?.controls.frame.x || '0',
        dw: spinner?.viewBox?.dw || 0,
        dh: spinner?.viewBox?.dh || 0,
        dx: spinner?.viewBox?.dx || 0,
        dy: spinner?.viewBox?.dy || 0,
      });
    }
  }, [spinner]);

  const handleCanvasMouseMove = useCallback((e) => {
    if (e.buttons !== 1) return;

    handleResizeForSvg();
  }, [spinner]);

  function control(this: any, e: any) {
    if (this.classList.contains('disabled')) return;
    if (this === turnLeft) spinner.controls.turnLeft();
    else if (this === turnRight) spinner.controls.turnRight();
    else if (spinnerCanvas) {
      if (this === spinnerZoom) spinner.zoomFrame(0.1);
      else spinner.zoomFrame(-0.1);
      handleResizeForSvg();
    }
  }

  // todo fix this function
  function controlActivity(this: any) {
    const { zoom } = this._size;
    const rotate = this._rotate?.loop;

    if (zoom > 1 || rotate) {
      if (!rotate) {
        // if (spinnerUnZoom) spinnerUnZoom.classList.remove('disabled');
        // if (turnLeft) turnLeft.classList.add('disabled');
        // if (turnRight) turnRight.classList.add('disabled');
      }
    } else if (currentSpinner && stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']]) {
      dispatch(Actions.setSpinnerStopFrame({
        [currentSpinner.name]: {
          [currentSpinner['name-id']]: this._frames.i,
        },
      }));
    }
    if (spinnerZoom) {
      // if (zoom < 5 && !rotate) spinnerZoom.classList.remove('disabled');
      // else spinnerZoom.classList.add('disabled');
    }
  }

  function windRoseRotate(event: IRotateEvent) {
    const windRoseImg = windRose?.querySelector('div');
    if (initWindRoseData) {
      const windRoseData = initWindRoseData();
      if (windRoseImg && windRoseData && event?.progress) {
        // @ts-ignore
        windRoseImg.style.transform = `rotateZ(${windRoseData.degree + (360 * event.progress.x)}deg)`;
      }
    }
  }

  const canvasResize = () => {
    if (spinnerCanvas && spinnerPlace) {
      spinnerCanvas.style.width = `${spinnerPlace.clientWidth}px`;
      spinnerCanvas.style.height = `${spinnerPlace.clientHeight}px`;

      spinner.resize(true);
    }
  };

  const handleWindowResize = () => {
    canvasResize();
    handleResizeForSvg();
  };

  useEffect(() => {
    if (spinnerData && currentSpinner && spinnerCanvas && !spinner
      && !(isFloors && currentSpinner.name !== 'floor')) {
      // Write to redux initial stopFrame
      if (
        !stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']]
        && spinnerData[currentSpinner.name]?.[currentSpinner['name-id']]?.startFrame !== undefined
      ) {
        dispatch(Actions.setSpinnerStopFrame({
          [currentSpinner.name]: {
            [currentSpinner['name-id']]: spinnerData[currentSpinner.name]?.[currentSpinner['name-id']]?.startFrame || 0,
          },
        }));
      }

      if (spinnerData[currentSpinner.name][currentSpinner['name-id']]?.objectFit === 'cover' && isFloors) {
        setFullSizeFloor(true);
      } else {
        setFullSizeFloor(false);
      }
      let config = spinnerData[currentSpinner.name][currentSpinner['name-id']];

      if (isMdScreen) {
        config = JSON.parse(JSON.stringify(config));
        // @ts-ignore
        config.data = [config.data.pop()];
      }
      if (!spinner && config) {
        const spinnerConf = {
          stops: config.stops,
          maxZoom: 5,
          turnSpeed: 30,
          ...config,
          startFrame:
            stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']] !== undefined
              ? stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']]
              : config.startFrame,
          objectFit: isFloors ? 'contain' : 'cover',
        };

        const tempSpinner = fsUtils.createSpinner(appendChildHandler);

        const strokeWidth = Number(globalPolygonConfig?.global_border_width);

        const reScaleHandler = () => {
          const { viewBox: { dh }, _frame } = tempSpinner;
          const naturalHeight = _frame?._img?.naturalHeight || 1080;

          for (const child of tempSpinner.children) {
            const isHidden = child.rootElement.style.getPropertyValue('display') === 'none';
            if (!isHidden) {
              if (child.isPoint) {
                child.domElement.style.scale = `${dh / 1080}`;
              } else if (child.isArea) {
                child.domElement.style.strokeWidth = `${(strokeWidth * (dh / naturalHeight))}`;
              }
            }
          }
        };

        tempSpinner.addEventListener('view-box-update', reScaleHandler);
        tempSpinner.addEventListener('background-render', reScaleHandler);

        fsUtils.mergeConfig(
          spinnerConf, currentSpinner.name, currentSpinner['name-id'],
        ).then((tempConfig: any) => {
          fsUtils.loadSpinnerConfig(tempSpinner, tempConfig);
          spinnerRef.current?.append(tempSpinner.domElement);
          setSpinner(tempSpinner);
        });
      }
    }
  }, [spinner, spinnerData, currentSpinner, spinnerCanvas, setFullSizeFloor]);

  useEffect(() => {
    if (spinner) {
      document.mainSpinner = spinner;

      canvasResize();
      window.addEventListener('resize', debounce(handleWindowResize, 300));
      spinner.addEventListener('first-frame-load', () => {
        if (spinnerZoom) spinnerZoom.classList.remove('disabled');

        canvasResize();
        setTimeout(resizeEmulate, 100);
        setTimeout(resizeEmulate, 1000);
      });

      // spinner.addEventListener('rotateready', function (this: any) {
      //   controlActivity.call(this);
      //   this.addEventListener('rotationend', controlActivity);
      //   handleResizeForSvg();
      // });

      // spinner.on('framezoom', function (this: any) {
      //   // eslint-disable-next-line no-unused-expressions
      //   if (this._size.zoom >= this.maxZoom) spinnerZoom?.classList.add('disabled');
      //   // eslint-disable-next-line no-unused-expressions
      //   else spinnerZoom?.classList.remove('disabled');
      //
      //   // eslint-disable-next-line no-unused-expressions
      //   if (this._size.zoom <= 1) spinnerUnZoom?.classList.add('disabled');
      //   // eslint-disable-next-line no-unused-expressions
      //   else spinnerUnZoom?.classList.remove('disabled');
      // });

      if (spinnerCanvas) {
        spinnerCanvas.addEventListener('mousemove', handleCanvasMouseMove);
      }

      if (turnLeft) { turnLeft.addEventListener('click', control); }
      if (turnRight) { turnRight.addEventListener('click', control); }
      if (spinnerZoom) { spinnerZoom.addEventListener('click', control); }
      if (spinnerUnZoom) { spinnerUnZoom.addEventListener('click', control); }

      if (spinnerPlace) {
        spinnerPlace.style.touchAction = 'none';

        // spinnerPlace.addEventListener('touchend', touchend);
        // spinnerPlace.addEventListener('touchleave', touchend);

        // spinnerPlace.addEventListener('touchstart', (e) => {
        //   if (touchstart) return;
        //
        //   mx = e.touches[0].pageX;
        //   my = e.touches[0].pageY;
        //
        //   touchstart = true;
        // });
        //
        // spinnerPlace.addEventListener('touchmove', (e) => {
        //   if (!touchstart) return;
        //
        //   const x = e.touches[0].pageX;
        //   const y = e.touches[0].pageY;
        //
        //   spinner.moveFrame(x - mx, y - my);
        //
        //   mx = x;
        //   my = y;
        //
        //   // @ts-ignore
        //   spinner.zoomFrame((e.scale - lastZoom));
        //   // @ts-ignore
        //   lastZoom = e.scale;
        //
        //   handleResizeForSvg();
        // });
      }

      spinner.on('first-frame-load', (e: any) => {
        if (spinnerControl) {
          spinnerControl.classList.remove('hide');
        }
        // @ts-ignore
        windRoseRotate.call(spinner);
        if (windRose) {
          windRose.classList.remove('hide');
        }
        handleSetStopFrame();
        setSvgSize({ w: spinner.viewBox.width, h: spinner.viewBox.height });
        setTimeout(resizeEmulate, 500);
      });
      spinner.addEventListener('camera-rotate', (event: IRotateEvent) => windRoseRotate(event));

      // SVG
      spinner.addEventListener('rotate-start', () => {
        dispatchPopup({
          type: 'close',
          payload: null,
        });
        handleSetStopFrame(true);
      });
      spinner.addEventListener('rotate-end', () => {
        handleResizeForSvg();
      });
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (spinner) {
        spinner.dispose();
        // @ts-ignore
        document.mainSpinner = null;
      }
      if (spinnerCanvas) {
        spinnerCanvas.removeEventListener('mousemove', handleCanvasMouseMove);
      }
      if (turnLeft) { turnLeft.removeEventListener('click', control); }
      if (turnRight) { turnRight.removeEventListener('click', control); }
      if (spinnerZoom) { spinnerZoom.removeEventListener('click', control); }
      if (spinnerUnZoom) { spinnerUnZoom.removeEventListener('click', control); }
    };
  }, [spinner]);

  useEffect(() => {
    let intervalTimes: any;

    if (spinner && svgSize.w === 0 && svgSize.h === 0) {
      intervalTimes = setInterval(() => {
        setSvgSize({ w: spinner.viewBox.width, h: spinner.viewBox.height });
        resizeEmulate();
      }, 1000);
    }

    return () => {
      clearInterval(intervalTimes);
    };
  }, [spinner, svgSize]);

  const fetchConfig = async () => {
    if (spinnerData && currentSpinner && spinner) {
      const config = await fsUtils.mergeConfig(
        spinnerData[currentSpinner.name][currentSpinner['name-id']], currentSpinner.name, currentSpinner['name-id'],
      );

      if (config && spinner && initWindRoseData) {
        fsUtils.loadSpinnerConfig(spinner, { ...config, startFrame: parseInt(spinner.controls.frame.x, 10) })
          .then(() => spinner._updateViewBox());
        if (initWindRoseData) {
          initWindRoseData(config._windRose);
        }
      }
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [spinner, spinnerData]);

  return {
    spinnerLoadEnd,
    listOfStops: (currentSpinner && spinnerData?.[currentSpinner.name]?.[currentSpinner?.['name-id']]?.stops) || [],
    svgSize,
    devicePixelRatio,
    spinnerState,
    spinner,
  };
};
