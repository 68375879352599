import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { appIcon } from 'src/assets';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentLabels: {
    listStyle: 'none',
    padding: '0',
    marginTop: '0',
    marginBottom: '40px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 0 20px',
      marginBottom: '32px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0 28px 0 28px',
    },
    '& li': {
      minHeight: '40px',
      [theme.breakpoints.up('xl')]: {
        minHeight: '48px',
      },
      [theme.breakpoints.up(3000)]: {
        minHeight: '92px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        minHeight: '48px',
      },
    },
  },

  availability: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  value: {
    display: 'inline-block',
    minWidth: '24px',
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    marginRight: '16px',
    [theme.breakpoints.down('md')]: {
      marginRight: '12px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      minWidth: '32px',
      width: '32px',
      height: '32px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '32px',
      width: '32px',
      height: '32px',
    },
    [theme.breakpoints.up(3000)]: {
      minWidth: '52px',
      width: '52px',
      height: '52px',
    },
  },

  title: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px',
      lineHeight: '32px',
    },
    [theme.breakpoints.up(3000)]: {
      fontSize: '38px',
      lineHeight: '56px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px',
    },
  },
  valueAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));
