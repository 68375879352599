import React from 'react';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { IAction } from 'src/typings';
import { ActionTypes } from 'src/store/table/actions';
import { IFilterAllData } from 'src/typings/filter';
import { GridColDef } from '@mui/x-data-grid';

export interface ITable {
  apiRef: React.MutableRefObject<GridApiCommunity> | undefined;
  rows: IFilterAllData[];
  columns: GridColDef[]
}

export const initialState: ITable = {
  apiRef: undefined,
  rows: [],
  columns: [],
};

export const tableReducer = (
  state = initialState,
  { type, payload }: IAction,
): ITable => {
  switch (type) {
    case ActionTypes.SET_TABLE_API_REF:
      return {
        ...state,
        apiRef: payload,
      };
    case ActionTypes.SET_TABLE_ROWS:
      return {
        ...state,
        rows: payload,
      };
    case ActionTypes.SET_TABLE_COLUMNS:
      return {
        ...state,
        columns: payload,
      };
    default:
      return state;
  }
};
