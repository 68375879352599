import {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { isFilterOpen } from 'src/store/app/selectors';
import { getPolygonConfig } from 'src/store/requests/app/selector';
import { getAvailablePanoramas, getCurrentPanorama } from 'src/store/panorama/selectors';
import { requestSelectors } from 'src/store/requests/selectors';
import { getHighlightPolygons } from 'src/store/spinner/selectors';
import { getView2dSelectedIds } from 'src/store/view-2d/selectors';
import { IGlobalPolygonConfig, PolygonTriggerEvent } from 'src/typings/app';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { useGetFilterParams, useBuildType } from 'src/hooks';
import { usePopup } from 'src/components/ViewSpinner/context';
import { ICurrentPano, IPanoramaWrapper } from 'src/store/panorama/reducer';
import { makeId } from 'src/helpers';
import { rootUrl } from 'src/constants';
import { blinkAnimate } from '../helpers';

export const useInitPano = (defaultPano?: ICurrentPano, displayIds?: string[]) => {
  const [globalVarId, setGlobalVarId] = useState(defaultPano ? `pano${makeId(5)}` : 'mainPano');
  const [isNight, setIsNight] = useState(false);
  const [camera, setCamera] = useState<any>(null);
  const { isCrossTable } = useBuildType();
  const panoRef = useRef<HTMLDivElement>(null);
  const [pano, setPano] = useState<any>(null);
  const globalPolygonConfig: IGlobalPolygonConfig | undefined = useSelector(getPolygonConfig);
  const getPopupConfig = useSelector(requestSelectors.app.getPopupConfig);
  const selectedIds = useSelector(getView2dSelectedIds);
  const getAllData = useSelector(requestSelectors.spinner.getSpinnerData)?.['detail-info'].placement;
  const availablePanoramas = useSelector(getAvailablePanoramas);
  const getCurrentPano = useSelector(getCurrentPanorama);
  const isFilter = useSelector(isFilterOpen);
  const { selectedParams } = useGetFilterParams();
  const highlightPolys = useSelector(getHighlightPolygons);
  const triggerEvent: PolygonTriggerEvent = useMemo(() => globalPolygonConfig?.trigger_event
    || PolygonTriggerEvent.hover, [globalPolygonConfig]);
  const { state } = usePopup();
  const currentPano = defaultPano || getCurrentPano;

  const toggleNightMode = () => {
    if (panoRef.current && pano) {
      setCamera(pano.camera);
      pano.dispose();
      setPano(null);
      setIsNight(!isNight);
    }
  };

  useEffect(() => {
    if (panoRef.current && currentPano && availablePanoramas
      && availablePanoramas[currentPano.entity] && availablePanoramas[currentPano.entity][currentPano.entityId]
      && !pano && globalPolygonConfig
      && getAllData && state.debounceOpen) {
      const panoWrapperData = availablePanoramas[currentPano.entity][currentPano.entityId]
        .find((item: IPanoramaWrapper) => item.id === currentPano.id);
      if (panoWrapperData) {
        const panoData = isNight ? panoWrapperData?.nightPanoramaV2 : panoWrapperData?.panoramaV2;
        panoRef.current.innerHTML = '';
        const tempPano: any = new window.Panorama2(panoRef.current);

        if (displayIds) {
          tempPano.scene.addEventListener('mouseenter', (e: any) => {
            const { target } = e;
            if (target.styleList.has('blink')) {
              target.styleList.delete('blink');
            }
          });
          if (triggerEvent === PolygonTriggerEvent.hover) {
            tempPano.scene.addEventListener('mouseenter', (e: any) => {
            // @ts-ignore
              if (e.target instanceof window.THREE.Mesh) {
                const cursorY = e.parentEvent.clientY;
                const cursorX = e.parentEvent.clientX;
                const dataPoly = e.target.dataSet.get('transition');
                if (state.debounceOpen) {
                  state.debounceOpen({
                    placement: getAllData[dataPoly.id],
                    cursorPoint: {
                      x: cursorX,
                      y: cursorY,
                    },
                  });
                }
              }
            });
            tempPano.scene.addEventListener('mouseleave', (e: any) => {
            // @ts-ignore
              if (e.target instanceof window.THREE.Mesh) {
                if (state.debounceClose) {
                  state.debounceClose();
                }
              }
            });
          } else {
            tempPano.scene.addEventListener('click', (e: any) => {
            // @ts-ignore
              if (e.target instanceof window.THREE.Mesh) {
                const cursorY = e.parentEvent.clientY;
                const cursorX = e.parentEvent.clientX;
                const dataPoly = e.target.dataSet.get('transition');
                if (state.debounceOpen) {
                  state.debounceOpen({
                    placement: getAllData[dataPoly.id],
                    cursorPoint: {
                      x: cursorX,
                      y: cursorY,
                    },
                  });
                }
              }
            });
          }
        }

        tempPano.scene.addEventListener('style-update', (e: any) => {
          const { target } = e;
          if (target.visible) {
            const { material, styleList, dataSet } = target;

            const selected = styleList.has('select');
            const hovered = styleList.has(':hover') || styleList.has('hover');
            const dataPoly = dataSet.get('transition');
            // @ts-ignore
            if (target instanceof window.THREE.Mesh) {
              const style: {
                color: number | string;
                opacity: number | string;
              } = {
                color: globalPolygonConfig.static_body_color,
                opacity: 0,
              };

              const outlineStyle: {
                color: number | string;
                opacity: number | string;
              } = {
                color: globalPolygonConfig.static_border_color,
                opacity: 0,
              };

              if (styleList.has('blink')) {
                blinkAnimate(target);
              } else {
                window.clearAnimate(target.aid);
                target.aid = 0;
              }

              if (dataPoly && getAllData[dataPoly.id] && (getPopupConfig?.enable_polygon_status_colors || isCrossTable)) {
                const tempColor = getAllData[dataPoly.id][isCrossTable
                  ? ListOfKeysFilterAllData.placementStatusCrossTableColor : ListOfKeysFilterAllData.placementStatusColor];
                style.color = tempColor || getAllData[dataPoly.id][ListOfKeysFilterAllData.placementStatusColor];
              }

              if (selected) {
                style.opacity = globalPolygonConfig.static_body_opacity;
                outlineStyle.opacity = 1;
              }
              if (hovered) {
                style.color = globalPolygonConfig.hover_body_color;
                style.opacity = globalPolygonConfig.hover_body_opacity;
                outlineStyle.color = globalPolygonConfig.hover_border_color;
                outlineStyle.opacity = 1;
              }

              material.setValues(style);
              if (target.outline) target.outline.material.setValues(outlineStyle);
              // @ts-ignore
            } else if (target instanceof window.THREE.Sprite) {
              const style = { color: 0xffffff, opacity: 1 };

              if (selected) {
                style.color = 0x00ffff;
              } else if (hovered) {
                style.opacity = 0.5;
              }

              material.setValues(style);
            }
          }
        });

        const parsed = JSON.parse(JSON.stringify(panoData.config).replace(/\\"\/files\//g, `"${rootUrl}/files/`));
        tempPano.load(parsed);
        if (camera) {
          tempPano.camera = camera;
        }

        // @ts-ignore
        document[globalVarId] = tempPano;
        setPano(tempPano);
      }
    }
  }, [panoRef, availablePanoramas, currentPano, globalPolygonConfig, getAllData, state, isNight]);

  useEffect(() => {
    if (pano) {
      pano.resize();

      setTimeout(() => {
        pano.resize();
      }, 1000);
    }
  }, [pano, isFilter]);

  useEffect(() => {
    return () => {
      if (pano) {
        pano.dispose();
        setPano(null);
        // @ts-ignore
        document[globalVarId] = null;
      }
    };
  }, [pano]);

  useEffect(() => {
    if (pano && (selectedIds.length || displayIds)) {
      const cldrns = pano.scene.children;
      const hlghtItem = cldrns.reduce((acc: any, child: any) => {
        // @ts-ignore
        if (child instanceof window.THREE.Mesh) {
          const dataPoly = child.dataSet.get('transition');
          if (dataPoly) {
            const weight = window.Panorama2.getObjectCenter(child);
            if ((displayIds && displayIds.includes(String(dataPoly.id)))
            || (selectedIds.includes(String(dataPoly.id)) && selectedParams.length)) {
              child.styleList.add('select');
              if (acc) {
                const accWeight = window.Panorama2.getObjectCenter(child);
                if (accWeight < weight) {
                  return child;
                }
              } else {
                return child;
              }
            } else {
              child.styleList.delete('select');
            }
          }
        }
        return acc;
      }, null);
      if (hlghtItem) {
        console.log('PODKRUTKA', hlghtItem);
        pano.controls.lookAt(hlghtItem, false, { duration: 500, timing: 'ease-in-out' });
      }
    }
  }, [pano, selectedParams, displayIds]);

  useEffect(() => {
    if (pano && highlightPolys.length) {
      const cldrns = pano.scene.children;
      const hlghtItem = cldrns.find((child: any) => {
        // @ts-ignore
        if (child instanceof window.THREE.Mesh) {
          const dataPoly = child.dataSet.get('transition');
          if (dataPoly) {
            return String(highlightPolys[0]) === String(dataPoly.id);
          }
        }
        return false;
      });

      if (hlghtItem) {
        pano.controls.lookAt(hlghtItem, false, { duration: 1000, timing: 'ease-in-out' });
        hlghtItem.styleList.add('blink');
      }
    }
  }, [pano, highlightPolys]);

  return {
    panoRef,
    isNight,
    toggleNightMode,
  };
};
