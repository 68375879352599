import { postBookNowForm } from 'src/api';

export const useBookNowForm = () => {
  const sendBookNowForm = (data: any): Promise<any> => {
    return postBookNowForm(data);
  };
  return {
    sendBookNowForm,
  };
};
