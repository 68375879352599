import { ApiUrl, crosstableUrl, crosstableWithoutApi } from 'src/constants';
import { httpService } from 'src/services';
import { IFeedbackField, ITimelineField } from 'src/components/profileComponents/FeedbackSettings';
import { IProject } from 'src/typings/complexes';
import { Response } from 'src/api/hydraResponse';

export function getBitrixClient(token: string = '', clientId: string) {
  return httpService.get(`${ApiUrl.getApiUrl().bitrixDealClients}?bitrixClientId=${clientId}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixDeal(token: string = '', bitrixDealId : string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getBitrixDeals}/${bitrixDealId}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixDeal(token: string = '', bitrixDealId : string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getBitrixDeals}/${bitrixDealId}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexesByBitrixDomain(token: string = '', categoryId: number, email: string, domain: string) {
  const link = categoryId ? `?users.email=${email}&bitrixCategories=${categoryId}&developers.bitrixProject.domain=${domain}` : `?users.email=${email}&developers.bitrixProject.domain=${domain}`;
  return httpService.get(
    `${ApiUrl.getApiUrl().getComplexes}${link}`,
    {}, {
      Authorization: `Bearer ${token}`,
    },
  );
}

export function updateBitrixProject(token: string = '', projectId: string, data: any) {
  return httpService.put(`${ApiUrl.getApiUrl().getBitrixProjects}/${projectId}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getBitrixProject(token: string = '', projectId: string): Promise<Response<IProject>> {
  return httpService.get(`${ApiUrl.getApiUrl().getBitrixProjects}/${projectId}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBitrixProject(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getBitrixProjects}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixCategory(token: string = '', id : string|number) {
  return httpService.get(`${crosstableWithoutApi}${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBitrixCategory(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getBitrixCategories}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixCategory(token: string = '', id : string|number, data: any) {
  return httpService.patch(`${crosstableWithoutApi}${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixStage(token: string = '', id : string|number) {
  return httpService.get(`${crosstableWithoutApi}${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixStageByCategory(token: string = '', id : string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getBitrixStages}?bitrixCategory=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBitrixStage(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getBitrixStages}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postGenerateBitrixCategories(token: string = '', id: string|number) {
  return httpService.post(`${crosstableUrl}/bitrix/create-categories-automatically`, {
    bitrixProjectId: id,
  }, {
    Authorization: `Bearer ${token}`,
  });
}
export function postGenerateBitrixStages(token: string = '', id: string|number) {
  return httpService.post(`${crosstableUrl}/bitrix/create-stages-automatically`, {
    bitrixCategoryId: id,
  }, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBitrixStatusOnZeroDeal(token: string = '', data: any) {
  return httpService.post(`${crosstableUrl}/bitrix_category_placement_status_on_zero_deals`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixStage(token: string = '', id : string|number, data: any) {
  return httpService.patch(`${crosstableWithoutApi}${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixProject(token: string = '', id : string|number, data: any) {
  return httpService.patch(`${crosstableWithoutApi}${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixFields(token: string = '', id : string|number): Promise<any> {
  return httpService.get(`${ApiUrl.getApiUrl().bitrixFields}?bitrixProject=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixFields(token: string = '', id : string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().bitrixFields}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixFormConfigurations(token: string = '', id : string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().bitrixFormConfigurations}?bitrixProject=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBitrixFormConfigurations(token: string = '', data: IFeedbackField) {
  return httpService.post(`${ApiUrl.getApiUrl().bitrixFormConfigurations}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function deleteBitrixFormConfigurations(token: string = '', id : string|number) {
  return httpService.delete(`${ApiUrl.getApiUrl().bitrixFormConfigurations}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixFormConfigurations(token: string = '', id : string|number, data: IFeedbackField) {
  return httpService.patch(`${ApiUrl.getApiUrl().bitrixFormConfigurations}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getBitrixFormConfigurationTimelines(token: string = '', id : string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().bitrixFormConfigurationTimelines}?bitrixProject=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postBitrixFormConfigurationTimelines(token: string = '', data: ITimelineField) {
  return httpService.post(`${ApiUrl.getApiUrl().bitrixFormConfigurationTimelines}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchBitrixFormConfigurationTimelines(token: string = '', id : string|number, data: ITimelineField) {
  return httpService.patch(`${ApiUrl.getApiUrl().bitrixFormConfigurationTimelines}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function deleteBitrixFormConfigurationTimelines(token: string = '', id : string|number) {
  return httpService.delete(`${ApiUrl.getApiUrl().bitrixFormConfigurationTimelines}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
