import { errors } from './errors';
import { filter } from './filter';
import { appTranslations } from './appTranslation';
import { placement } from './placement';

export const locals = {
  appTranslations,
  errors,
  filter,
  placement,
};
