import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { useState } from 'react';
import { CRMNames } from 'src/typings/app';
import {
  creatioOpportunityTypeMap, CreatioOptionData,
  getCreatioFieldsOptions,
  getCreatioFieldsValues,
} from 'src/api';
import axios from 'axios';

export const useGetCreatioFields = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<any>(null);
  const [entityValues, setEntityValues] = useState<{
        [key: string]: any
    }|null>(null);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchFields = (project: IProject) => {
    setIsLoading(true);
    Promise.all([
      getCreatioFieldsValues(jwt, project.id),
      getCreatioFieldsOptions(jwt, project.id),
    ]).then(([fieldsValue, fieldsOptions]) => {
      const { data: fieldsValueData } = fieldsValue;
      const { data: options } = fieldsOptions;
      const fieldsValueResult: {[index: string]:string | object} = {
        id: String(project.id),
      };
      const fieldsOptionsResult: {[key: string]: CreatioOptionData} = {};
      fieldsValueData['hydra:member'].map((item) => {
        if (item.fsFieldName.indexOf('.') > 0) {
          const fieldName = item.fsFieldName.split('.');
          if (fieldsValueResult[fieldName[0]] && typeof fieldsValueResult[fieldName[0]] === 'object') {
            fieldsValueResult[fieldName[0]] = fieldName[1];
          } else {
            fieldsValueResult[fieldName[0]] = { [fieldName[1]]: item.fieldName };
          }
        } else {
          fieldsValueResult[item.fsFieldName] = item.fieldName;
        }

        // const foundOption = options.find((option) => option.title === item.fieldName);

        return item;
      });
      options.forEach((option) => {
        fieldsOptionsResult[option.title] = {
          isDynamic: false,
          isImmutable: false,
          isMultiple: false,
          isReadOnly: false,
          isRequired: false,
          title: option.title,
          type: option.type,
        };
      });
      setFields(fieldsValueResult);
      setEntityValues(fieldsOptionsResult);
      // setFields(fieldsValue.data['hydra:member'][0]);
      setIsLoading(false);
    });
  };

  // useEffect(() => {
  // setIsLoading(true);
  // Promise.all([
  //   getBitrixFields(jwt, project.id),
  //   axios.get(`${project.webhookUrl}crm.deal.fields.json`),
  // ]).then(([fieldsValue, values]) => {
  //   setFields(fieldsValue.data['hydra:member'][0]);
  //   const { data } = values;
  //   const { result } = data;
  //   setEntityValues(result);
  //   setIsLoading(false);
  // });
  // getBitrixFields(jwt, project.id).then((result: any) => {
  //   const { data } = result;
  //   if (data['hydra:member']) {
  //     setFields(data['hydra:member'][0]);
  //     setIsLoading(false);
  //   }
  // }).catch((err: any) => {
  //   console.log('RES', err);
  //   setIsLoading(false);
  // });
  // if (project.webhookUrl) {
  //   axios.get(`${project.webhookUrl}crm.deal.fields.json`)
  //     .then((response) => {
  //       // handle success
  //       const { data } = response;
  //       const { result } = data;
  //       setEntityValues(result);
  //       return result;
  //     })
  //     .catch((error) => {
  //       // handle error
  //       console.log(error);
  //       return error;
  //     });
  // }
  // }, [project]);

  return {
    isLoading,
    fields,
    entityValues,
    fetchFields,
  };
};
