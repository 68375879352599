import { useCallback } from 'react';
import _ from 'lodash';

import { useSetFilterParams } from 'src/hooks';
import { useGetInfrastructureValues } from 'src/hooks/infrastructure';
import { IRangeAndSetData } from '../../../../typings/filter';

export const useCheckboxState = (
  nameOfField: string,
) => {
  const { filterParams, filterInfrastructureData } = useGetInfrastructureValues();
  const { handleWriteToReduxInfrastructure } = useSetFilterParams();

  const handleSelectSingleCheckbox = useCallback((itemValue: IRangeAndSetData) => {
    if (filterParams[nameOfField]?.isDisabled) {
      return;
    }

    if (filterInfrastructureData) {
      if (filterParams[nameOfField]?.data.includes(itemValue.value)) {
        handleWriteToReduxInfrastructure(
          nameOfField,
          filterParams[nameOfField]?.data.filter((item: string) => item !== itemValue.value),
          [
            ..._.difference(filterInfrastructureData.infrastructureFilteredIds, itemValue.key),
          ],
          false,
        );
      } else {
        handleWriteToReduxInfrastructure(
          nameOfField,
          [...filterParams[nameOfField]?.data, itemValue.value],
          [
            ...filterInfrastructureData.infrastructureFilteredIds,
            ...itemValue.key,
          ],
          false,
        );
      }
    }
  }, [filterParams, handleWriteToReduxInfrastructure, nameOfField, filterInfrastructureData]);

  return {
    filterValue: filterParams[nameOfField]?.data || [],
    handleSelectSingleCheckbox,
  };
};
