import { useGetAuthData } from 'src/hooks';
import { useState } from 'react';
import { INetHuntEmptyOpportunityStatuses } from 'src/components/profileComponents/Integrations/Nethunt/types';
import {
  getNetHuntEmptyOpportunityStatuses,
  getNetHuntFunnel,
  patchNetHuntEmptyOpportunityStatuses,
} from 'src/api';
import { getComplexLinkByComplexId } from 'src/components/profileComponents/Integrations/tools';

export const useGetEmptyOpportunityStatuses = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emptyOpportunityStatuses, setEmptyOpportunityStatuses] = useState<INetHuntEmptyOpportunityStatuses[]>();

  const fetchEmptyOpportunityStatuses = (complexes: string[]) => {
    setIsLoading(true);
    const complexLink = getComplexLinkByComplexId(complexes);
    getNetHuntEmptyOpportunityStatuses(jwt, complexLink)
      .then((res: any) => {
        const { data } = res;
        setEmptyOpportunityStatuses(data['hydra:member']);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const patchEmptyOpportunityStatuses = (placementStatusId: string, sendData: any) => {
    setIsLoading(true);
    patchNetHuntEmptyOpportunityStatuses(jwt, placementStatusId, sendData)
      .then((res: any) => {
        const { data } = res;
        setEmptyOpportunityStatuses(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    emptyOpportunityStatuses,
    fetchEmptyOpportunityStatuses,
  };
};
